import React from 'react';
import Cookies from 'universal-cookie';

import { setAuthToken } from '../api';
import { authApi } from '../api';
import { HelperProps } from '../shared-types/types';
import { TFunction } from 'i18next';
import { ReplaceStringsProps } from '../shared-types/constants';

export const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';
export const TEST_LIVE_URL = BASE_URL;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const APP_NAME = process.env.REACT_APP_APP_NAME;
export const APP_PUBLISH_URL = 'https://edomlegacy.com';
export const cookies = new Cookies();

const TOKEN = 'bearertoken';

export let BEARER = cookies.get(TOKEN);
export let USER = {};

export function setBearerToken(bearer: string) {
  cookies.set(TOKEN, bearer);
  BEARER = bearer;
  setAuthToken(bearer);
}

export const LoaderContext = React.createContext({
  loader: true,
  setLoader: (arg: boolean) => {},
});

export let defaultTransition = 'transition transform ease-in-out hover:-translate-y-1 hover:scale-105  duration-300 ';
export let lightTransition = 'transition transform ease-in-out hover:scale-105  duration-300 ';
export let themeButtonClass =
  'text-sm font-bold transition ease-in-out  duration-500  hover:scale-105 p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1';
export let greenThemeButtonClass =
  'bg-green-600 text-gray-100 font-bold transition ease-in-out  duration-500  hover:scale-105 p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1';
export let grayThemeButtonClass =
  'bg-gray-600 text-gray-100 font-bold transition ease-in-out  duration-500  hover:scale-105 p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1';
export let inputButtonClass =
  'appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-100 focus:border-green-800 focus:z-10 sm:text-sm';

export let avatarClass = 'shadow-lg rounded-lg';

export function stripBbCode(string: string) {
  let pattern = '|[[/!]*?[^[]]*?]|si';
  let replace = '';
  return string.replace(pattern, replace);
}

export async function removeCookie() {
  BEARER = null;
  cookies.remove(TOKEN);
  return authApi.get('/api/revoketoken');
}
const replaceStrings = ({ string, valueToBeReplaced, type = 1, searchByDelimiter = false }: ReplaceStringsProps) => {
  if (searchByDelimiter) {
    return string.replace(/;/g, valueToBeReplaced);
  }

  if (type === 1) {
    return string.replace(/%%1%%/g, valueToBeReplaced);
  }
  if (type === 2) {
    return string.replace(/%%2%%/g, valueToBeReplaced);
  }
  if (type === 3) {
    return string.replace(/%%3%%/g, valueToBeReplaced);
  }
  if (type === 4) {
    return string.replace(/%%4%%/g, valueToBeReplaced);
  }
  return string;
};
export const lawsIdsInUse = [1, 2, 3, 4, 5, 6, 7, 8, 9, 12, 13, 14, 15, 16];

/* eslint-disable */
export const lawHelper = {
  1: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_1');

    if (law['VALUE2'] === '0') {
      text = replaceStrings({ string: text, valueToBeReplaced: law['VALUE1'] + ' ' + t('GAME_GOLD') });
    } else {
      text = replaceStrings({ string: text, valueToBeReplaced: helpers.Countries[law['VALUE2']]['Currency'] });
    }

    return (text = replaceStrings({ string: text, valueToBeReplaced: law['VALUE4'], type: 2 }));
  },
  2: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_2');
    text = replaceStrings({ string: text, valueToBeReplaced: helpers.Items[law['VALUE1']]['Name'] });
    let text_1 = replaceStrings({ string: law['VALUE2'], valueToBeReplaced: '% ', searchByDelimiter: true });
    let text_2 = replaceStrings({ string: law['VALUE3'], valueToBeReplaced: '%', searchByDelimiter: true });
    text = replaceStrings({ string: text, valueToBeReplaced: text_1 });
    text = replaceStrings({ string: text, valueToBeReplaced: text, type: 3 });
    return text;
  },
  3: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_3');
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE2'] + ' ' + helpers.Countries[law['COUNTRY']]['Currency'],
    });
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE3'] + ' ' + helpers.Countries[law['COUNTRY']]['Currency'],
      type: 2,
    });
    return text;
  },
  4: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_4');
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE1'],
    });
    return text;
  },
  5: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_5');
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE2'] + ' ' + helpers.Countries[law['COUNTRY']]['Currency'],
    });
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE1'] + ' ' + t('GAME_GOLD'),
      type: 2,
    });

    return text;
  },
  6: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_6');

    text = replaceStrings({
      string: text,
      valueToBeReplaced: 1,
    });
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE2'] + law['VALUE3'],
      type: 2,
    });
    return text;
    // return api
    //   .get('/api/user/shortprofile/' + law['VALUE1'])
    //   .then((resp) => {
    //     let overthrowPresident = resp.data;
    //     text = replaceStrings({
    //       string: text,
    //       valueToBeReplaced: overthrowPresident['user'],
    //     });
    //     text = replaceStrings({
    //       string: text,
    //       valueToBeReplaced: law['VALUE2'] + law['VALUE3'],
    //       type: 2,
    //     });
    //     return text;
    //   })
    //   .catch((err) => {
    //     return text;
    //   });
  },
  7: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    return ''; // this is alliance. will not be implemented yet.
  },
  8: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_8');
    text = replaceStrings({
      string: text,
      valueToBeReplaced: helpers.Countries[law['VALUE1']]['Name'],
    });
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE2'] + ' ' + t('GAME_GOLD'),
      type: 2,
    });

    return text;
  },
  9: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    // this is newcomer message, we will implement it later.
    return '';
    // let text = t('GAME_COUNTRY_LAW_TEXT_8');
    // text = replaceStrings({
    //   string: text,
    //   valueToBeReplaced: helpers.Countries[law['VALUE1']],
    // });
    // text = replaceStrings({
    //   string: text,
    //   valueToBeReplaced: law['VALUE2'] + ' ' + t('GAME_GOLD'),
    //   type: 2,
    // });
  },
  12: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_12');
    text = replaceStrings({
      string: text,
      valueToBeReplaced: helpers.Countries[law['COUNTRY']]['Name'],
    });
    text = replaceStrings({
      string: text,
      valueToBeReplaced: helpers.Countries[law['VALUE1']]['Name'],
      type: 2,
    });

    return text;
  },
  13: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_13');
    text = replaceStrings({
      string: text,
      valueToBeReplaced: helpers.Countries[law['COUNTRY']]['Name'],
    });

    return text;
  },
  14: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_14');
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE2'],
    });
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE1'],
      type: 2,
    });

    return text;
  },
  15: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_15');
    text = replaceStrings({
      string: text,
      valueToBeReplaced: helpers.Countries[law['COUNTRY']]['Name'],
    });
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE2'] + ' ' + t('GAME_GOLD'),
      type: 2,
    });

    return text;
  },
  16: (law: Record<string, any>, helpers: HelperProps, t: TFunction) => {
    let text = t('GAME_COUNTRY_LAW_TEXT_16');
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE1'] + ' ' + helpers.Countries[law['COUNTRY']]['Currency'],
    });
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE2'] + ' ' + helpers.Countries[law['COUNTRY']]['Currency'],
      type: 2,
    });
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE3'] + ' ' + helpers.Countries[law['COUNTRY']]['Currency'],
      type: 3,
    });
    text = replaceStrings({
      string: text,
      valueToBeReplaced: law['VALUE4'] + ' ' + helpers.Countries[law['COUNTRY']]['Currency'],
      type: 4,
    });

    return text;
  },
};

export default LoaderContext;
