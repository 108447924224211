import { useTranslation } from 'react-i18next';

import { SectionHeader } from '../../../../components/SectionHeader';
import { Divider } from '../../../../components/Divider';
import { fromCDN } from '../../../../helpers/Images';
import {imageAsBackground} from '../../../../helpers/image-as-background';
import { EmployeeCard } from './EmployeeCard';
import { useEmployee } from './hooks/useEmployee';
import { EmploymentOffer } from './EmploymentOffer';
import { Loader } from '../../../../components/Loader';

export const ManageEmployees = () => {
  const { employees, isLoading, jobOffers, handleRemoveJobOffer, handleChangeSalary, handlePlaceJobOffer } =
    useEmployee();
  const { t } = useTranslation('common');

  const employeesValues = Object.values(employees ?? {});

  const renderedOffers = employeesValues.length ? (
    <div className="w-4/4 grid grid-cols-2 m-5 sm:grid-cols-3 lg:grid-cols-6 justify-center gap-4 comps">
      {employeesValues.map((employee) => (
        <EmployeeCard key={employee.ID} employee={employee} onSalaryChange={handleChangeSalary} />
      ))}
    </div>
  ) : null;

  return (
    <>
      <div
        className="min-h-screen max-w-13xl mx-auto bg-fixed"
        style={imageAsBackground(fromCDN.fetchStaticImgImage('/mybuildingsbg'))}
      >
        <div className="min-h-screen max-w-13xl mx-auto text-gray-200">
          <div className="max-w-7xl mx-auto py-2">
            <SectionHeader title={t('GAME_MANAGE_EMPLOYEES')} />

            <EmploymentOffer
              jobOffers={jobOffers}
              onPlaceJobOffer={handlePlaceJobOffer}
              onRemoveJobOffer={handleRemoveJobOffer}
            />

            <Divider margin="m-5 mb-12" />

            {isLoading ? <Loader /> : renderedOffers}
          </div>
        </div>
      </div>
    </>
  );
};
