import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TEST_LIVE_URL } from '../../constants/constants';
import ProfileFriends from './ProfileFriends';
import { authApi } from '../../api';
import { Divider } from '../../components/Divider';

export default function ProfilePoliticsUnitNewsPaper({ user, translation, isPublicProfile = false }) {
  const [newsPaperAndUnit, setNewsPaperAndUnit] = useState();
  const [unitTitle, setUnitTitle] = useState();
  const navigate = useNavigate();

  let shouldLoad = false;

  if (user.PARTY > 0 || user.UNIT > 0 || user.NEWSPAPER > 0) {
    shouldLoad = true;
  }

  const militaryUnit = newsPaperAndUnit?.UNIT;
  const newspaper = newsPaperAndUnit?.NEWSPAPER;

  function loadDetails() {
    authApi.get(`/api/user/profiledetails/${user.id}`).then((response) => {
      setNewsPaperAndUnit(response.data);
    });
  }

  useEffect(() => {
    if (shouldLoad && !isPublicProfile) {
      loadDetails();
    }
  }, [user]);

  useEffect(() => {
    if (newsPaperAndUnit === undefined) {
      return;
    }

    if (militaryUnit !== undefined) {
      if (militaryUnit.OWNER === user.id) {
        return setUnitTitle(translation('GAME_UNIT_COMMANDER'));
      }

      if ([militaryUnit.CAPTAIN, militaryUnit.CAPTAIN2, militaryUnit.CAPTAIN3].includes(user.id)) {
        return setUnitTitle(translation('GAME_UNIT_CAPTAIN'));
      }
    }
  }, [newsPaperAndUnit]);

  const renderMilitaryUnit = () => {
    return (
      <div className="w-1/2">
        <h5 className="font-bold mb-2">{unitTitle}</h5>
        <div className="flex flex-col items-center gap-2">
          <img
            onClick={() => navigate(`/military-unit/${militaryUnit.ID}`)}
            className="h-10 w-10 sm:h-12 sm:w-12 shadow-lg cursor-pointer rounded default-transition"
            src={`${TEST_LIVE_URL}/upload/group/${militaryUnit.AVATAR}.jpg`}
          />
          <span className="font-medium">{militaryUnit.NAME}</span>
        </div>
      </div>
    );
  };

  const renderNewsPaper = () => {
    return (
      <div className="w-1/2">
        <h5 className="font-bold mb-2">{translation('GAME_NEWSPAPER')}</h5>
        <div className="flex flex-col items-center gap-2 " onClick={() => navigate('/newspaper/' + newspaper.ID)}>
          <img
            className="h-10 w-10 sm:h-12 sm:w-12 shadow-lg cursor-pointer rounded default-transition"
            src={`${TEST_LIVE_URL}/upload/party/${newspaper.AVATAR}.jpg`}
          />
          <span className="font-medium">{newspaper.NAME}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="w-2/2 flex text-center bg-black/40 py-8">
        {militaryUnit && renderMilitaryUnit()}
        {newspaper && renderNewsPaper()}
      </div>

      {newsPaperAndUnit && <ProfileFriends user={user} translation={translation} friends={newsPaperAndUnit.FRIENDS} />}
    </>
  );
}
