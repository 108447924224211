import { FC } from 'react';
import { Link } from 'react-router-dom';

import { fromCDN } from '../helpers/Images';

export interface AvatarProps {
  avatarId: string;
  userId: string;
  className?: string;
  defaultImageClass?: string;
  defaultLinkClass?: string;
  dataFor?: string;
}

const fallBackOnerror = (e: any) => {
  e.target.src = "";
};

export const Avatar: FC<AvatarProps> = ({
  avatarId,
  userId,
  className = '',
  defaultImageClass = 'h-12 w-12 cursor-pointer rounded-lg default-transition',
  defaultLinkClass = 'z-10 drop-shadow-[0_3px_3px_rgba(0,0,0,0.6)]',
  dataFor,
}) => {
  return (
    <Link to={`/profile/${userId}`} className={defaultLinkClass}>
      <img
        data-tip={true}
        data-for={dataFor}
        className={defaultImageClass + ' ' + className}
        src={fromCDN.fetchUserAvatar(avatarId)}
        alt="avatar"
        onError={fallBackOnerror}
      />
    </Link>
  );
};

export default Avatar;
