import { SectionHeader } from '../../../components/SectionHeader';
import { storageItems } from '../../../helpers/Helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StorageDisplay from './StorageDisplay';
import { useUser } from '../../../hooks/use-user';
import { fromCDN } from '../../../helpers/Images';
import {imageAsBackground} from '../../../helpers/image-as-background';

export default function Storage() {
  const { t } = useTranslation('common');
  const { user } = useUser();
  const [myStorageItems, setMyStorageItems] = useState(storageItems({ user: user }));
  return (
    <>
      <div
        className="min-h-screen max-w-13xl mx-auto bg-fixed"
        style={imageAsBackground(fromCDN.fetchStaticImgImage('/mybuildingsbg'))}
      >
        <div className="max-w-7xl mx-auto py-2">
          <SectionHeader title={t('GAME_STORAGE')}>
            <div className="h-6 w-6 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]">
              <img src={fromCDN.fetchStaticGameImage('/v2/Icons/Storage', 'svg')}></img>
            </div>
          </SectionHeader>
          <h3 className="mt-3 ml-6 sm:ml-9 font-semibold flex items-center">
            <span className="text-base text-gray-200">{t('GAME_FINAL_PRODUCTS')}</span>
            <svg
              data-tip={true}
              data-for="marketplaceTip"
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-blue-500 ml-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
              <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
            </svg>
            <div className="ml-5">
              <span className="text-indigo-300">{user.StorageCap.Stock} / </span>
              <span className="text-red-300">{user.StorageCap.Max}</span>
            </div>
          </h3>

          <StorageDisplay myStorageItems={myStorageItems} setMyStorageItems={setMyStorageItems} t={t} />
        </div>
      </div>
    </>
  );
}
