import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '../../components/Loader';

const appendZeroIfOneDigit = (number) => number.toString().padStart(2, 0);

export default function EnergyCalculation({ user }) {
  const { t } = useTranslation('common');

  const [recoverIn, setRecoverIn] = useState();
  const [energyIdle, setEnergyIdle] = useState();
  const [intervalOn, setIntervalOn] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  let energyMax = user.myEnergy;
  let energyTime = user.myEnergyTime;
  let energyRestore = user.energyRestore;
  let energyTimeCoolDown = user.ENERGY;
  let energyC = energyTime / energyRestore;
  let energyTimeNow = user.GameTimeStamp;

  let foodTimeRestore;
  let energyFullTime;

  const updateEnergy = () => {
    energyTimeNow++;
    let foodTimeTotal = Math.floor((energyTimeNow - energyTimeCoolDown) / energyC);
    foodTimeTotal = Math.max(0, foodTimeTotal);

    foodTimeRestore = foodTimeTotal;
    if (foodTimeRestore >= energyMax) {
      foodTimeRestore = energyMax;
    }

    energyFullTime = Math.floor((energyMax / energyRestore) * energyTime - (energyTimeNow - energyTimeCoolDown));
    setRecoverIn(t('GAME_RECOVER_ENERGY_IN') + formatTime(energyFullTime));
  };

  const formatTime = (duration) => {
    let hour = 0;
    let min = 0;
    let sec = 0;

    if (duration) {
      if (60 <= duration) {
        min = Math.floor(duration / 60);
        sec = duration % 60;
      } else {
        sec = duration;
      }

      if (60 <= min) {
        hour = Math.floor(min / 60);
        min = min - hour * 60;
      }
    }

    return `${appendZeroIfOneDigit(hour)}:${appendZeroIfOneDigit(min)}:${appendZeroIfOneDigit(sec)}`;
  };

  function handleUpdate() {
    updateEnergy();
    setIsUpdating(false);
    setEnergyIdle(foodTimeRestore);

    if (foodTimeRestore >= energyMax || energyFullTime < 0) {
      setRecoverIn(t('GAME_USE_ENERGY'));
      clearInterval(intervalOn);
    }
  }

  useEffect(() => {
    setIsUpdating(true);
    clearInterval(intervalOn);
    setIntervalOn(setInterval(handleUpdate, 1000));
  }, [user]);

  if (isUpdating) {
    return <Loader className="h-5 w-5" />;
  }

  return (
    <div className="flex flex-col items-center text-center ">
      <div>
        <span>{t('GAME_RECOVER_ENERGY_RECOVER_NOW')}</span>
        <span className={`${energyIdle !== 0 ? 'text-green-600' : 'text-red-600'} ml-1 font-bold`}>{energyIdle}</span>
      </div>
      <span className="text-white">{recoverIn}</span>
    </div>
  );
}
