interface NormalizeValueProps {
  customLength: number;
}

export const LENGTH = {
  USERNAME: 20,
  REGION_NAME: 30,
};

export function normalizeValue(value: string, { customLength }: NormalizeValueProps) {
  if (customLength < value.length) return value.substring(0, customLength);

  return value;
}

export function normalizeUserName(name: string) {
  return normalizeValue(name, { customLength: LENGTH.USERNAME });
}

export function normalizeRegionName(name: string) {
  return normalizeValue(name, { customLength: LENGTH.REGION_NAME });
}
