import { SectionHeader } from '../../../components/SectionHeader';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {imageAsBackground} from '../../../helpers/image-as-background';
import { useParams } from 'react-router-dom';
import NoParty from './NoParty';
import { authApi } from '../../../api';
import Avatar from '../../../components/Avatar';
import { Divider } from '../../../components/Divider';
import { Loader } from '../../../components/Loader';
import { GLOBAL_CONSTANTS } from '../../../constants/enum';
import { gameTimeZone } from '../../../helpers/game-time';
import { Pagination } from '../../../components/Pagination';
import JoinParty from './JoinParty';
import LeaveParty from './LeaveParty';
import ApplicationAlert from '../../../components/ApplicationAlert';
import NominatePresident from './NominatePresident';
import CancelNominatedCandidate from './CancelNominatedCandidate';
import NominateCongressMan from './NominateCongressMan';
import CancelNominateCongressMan from './CancelNominateCongressMan';
import { fromCDN } from '../../../helpers/Images';
import { useUser } from '../../../hooks/use-user';
import { getPartyDetailsApi } from '../../../api/shared-api';

export default function PoliticalParty() {
  const { t } = useTranslation('common');
  const { user, setUser } = useUser();
  let params = useParams();

  const [partyId, setPartyId] = useState(params.id);
  const [partyDetails, setPartyDetails] = useState();
  const [congressDetails, setCongressDetails] = useState();
  const [partyPresidentApplication, setPartyPresidentApplication] = useState();
  const [partyMembers, setPartyMembers] = useState();
  const [partyMembersList, setPartyMembersList] = useState();
  const [congressCandidates, setCongressCandidates] = useState();
  const [nominatedPresident, setNominatedPresident] = useState();
  const [loading, setLoading] = useState(<Loader />);

  const getPartyDetails = async () => {
    try {
      const resp = await getPartyDetailsApi({
        id: params.id,
      });
      setPartyDetails(resp.data['PARTY'][0]);
      setCongressDetails(resp.data['CONGRESS']);
      setPartyPresidentApplication(resp.data['PARTY_P_CANDIDATE']);
      setNominatedPresident(resp.data['NOMINATED_PRESIDENCY_CANDIDATE'][0]);
      setCongressCandidates(resp.data['CONGRESS_CANDIDATES']);
      setLoading(false);
      getPartyMembers();
    } catch (err) {}
  };

  const getPartyMembers = () => {
    authApi
      .get('api/parties/' + params.id + '/members')
      .then((resp) => {
        setPartyMembers(resp.data);
        setPartyMembersList(resp.data.data);
      })
      .catch((err) => {});
  };

  const applyPartyPresident = () => {
    try {
      const isCandidateInList = partyPresidentApplication?.map((userInfo) => {
        if (userInfo.USER === user.id) {
          return true;
        }
        return false;
      });

      if (isCandidateInList.includes(true)) {
        return ApplicationAlert(t('GAME_ALREADY_APPLIED')).error();
      }
    } catch (e) {}

    authApi
      .get('api/parties/' + params.id + '/apply/ppresidency')
      .then((resp) => {
        setPartyDetails(resp.data['PARTY'][0]);
        setCongressDetails(resp.data['CONGRESS']);
        setPartyPresidentApplication(resp.data['PARTY_P_CANDIDATE']);
        setCongressCandidates(resp.data['CONGRESS_CANDIDATES']);
        return ApplicationAlert(t('GAME_SUCCESS')).success();
      })
      .catch((err) => {
        return ApplicationAlert(t(err.response.data)).error();
      });
  };

  const cancelPartyPresident = () => {
    authApi
      .get('api/parties/' + params.id + '/cancelapply/ppresidency')
      .then((resp) => {
        getPartyDetails();
        return ApplicationAlert(t('GAME_SUCCESS')).success();
      })
      .catch((err) => {
        return ApplicationAlert(t(err.response.data)).error();
      });
    };
  useEffect(() => {
    setPartyMembersList(partyMembers?.data);
  }, [partyMembers]);

  useEffect(() => {
    setPartyId(params.id);
    getPartyDetails();
    setPartyPresidentApplication();
  }, [params, setPartyDetails]);

  return (
    <>
      <div
        className="min-h-screen max-w-13xl mx-auto bg-fixed"
        style={imageAsBackground(fromCDN.fetchStaticImgImage('/mybuildingsbg'))}
      >
        <div className="max-w-7xl mx-auto py-2 ">
          <SectionHeader
            title={t('GAME_COMMUNITY_PARTY')}
            children={
              <div>
                <p className="">
                  {gameTimeZone().getHowManyDaysLeftToElections({
                    electionDay: GLOBAL_CONSTANTS.ELECTION_DAYS_IN_MONTH.PARTY,
                  })}{' '}
                  {t('GAME_DAYS_LEFT_TILL_ELECTION')}
                </p>
                {Number(user.PARTY) === Number(partyId) && (
                  <LeaveParty partyId={partyId} setterForParent={setPartyDetails} />
                )}
              </div>
            }
            parentChildren={
              user.PARTY == 0 ? (
                <JoinParty partyId={partyId} setterForParent={setPartyDetails} />
              ) : partyPresidentApplication && partyPresidentApplication.length > 0 && partyPresidentApplication[0].USER === user.id ? (
                <div className="flex justify-end items-center mt-0 sm:-mt-4 mr-12 relative">
                  <button
                    onClick={() => cancelPartyPresident()}
                    className='text-gray-800 default-red-button'
                  >
                    {t('GAME_CANCEL_NOMINATE_PARTRY_PRESIDENT')}
                  </button>
                  <p className="text-gray-200 mt-2 ml-2 text-l">{t('GAME_PARTY_PRESIDENT')}</p>
                </div>
              ) : (
                <div className="flex justify-end items-center mt-0 sm:-mt-4 mr-12 relative">
                <button
                  onClick={() => applyPartyPresident()}
                  className='text-gray-800 default-green-button'
                >
                  {t('GAME_PARTY_APPLY_PARTY_PRESIDENT')}
                </button>
                <p className="text-gray-200 mt-2 ml-2 text-l">{t('GAME_PARTY_PRESIDENT')}</p>
              </div>
              )
            }
          />
          <div className="text-white absolute ml-15 "></div>
          <div className="m-5 bg-gradient-to-br from-indigo-900/70 to-red-900/70">
            {partyId === '0' && !partyDetails ? <NoParty /> : ''}

            {partyDetails ? (
              <>
                <div className="text-gray-200">
                  <div className="text-center">
                    <Avatar avatarId={partyDetails.U_AVATAR} userId={partyDetails.U_ID} className="mx-auto" />
                    {t('GAME_PARTY_PRESIDENT')} {partyDetails.U_NAME}
                  </div>
                  <div className="items-center mt-3 text-center justify-center grid grid-cols-3 sm:grid-cols-8">
                    {partyPresidentApplication && partyPresidentApplication.length > 0 && (
                      <>
                        {partyPresidentApplication.map((item) => {
                          return (
                            <div key={item.id}>
                              <p className="break-words pb-1 text-xs">{t('GAME_PARTY_PRESIDENT_CANDIDATE')}</p>
                              {Number(user.id) === Number(partyDetails.PRESIDENT) && (
                                <>
                                  {Number(user.id) === Number(partyDetails.PRESIDENT) &&
                                  nominatedPresident?.USER === item.U_ID ? (
                                    <CancelNominatedCandidate
                                      partyId={partyDetails.ID}
                                      candidateId={item.U_ID}
                                      trigger={setNominatedPresident}
                                    />
                                  ) : (
                                    <NominatePresident
                                      partyId={partyDetails.ID}
                                      candidateId={item.U_ID}
                                      trigger={setNominatedPresident}
                                    />
                                  )}
                                </>
                              )}
                              <Avatar
                                avatarId={item.U_AVATAR}
                                className={
                                  nominatedPresident && nominatedPresident.USER === item.U_ID
                                    ? ' shadow-lg shadow-green-500/100' + ' mx-auto '
                                    : ' mx-auto'
                                }
                                userId={item.U_ID}
                              />
                              <p>{item.U_NAME}</p>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>

                {congressDetails && congressDetails.length > 0 && (
                  <>
                    <Divider />
                    <div className="text-gray-100 text-center justify-center -mt-3">
                      {t('GAME_PARTY_CONGRESS')} - <span className="text-green-300">{congressDetails.length}</span> /{' '}
                      <span className="text-blue-200">{GLOBAL_CONSTANTS.TOTAL_CONGRESSMAN_RATE}</span>
                      <p>
                        {gameTimeZone().getHowManyDaysLeftToElections({
                          electionDay: GLOBAL_CONSTANTS.ELECTION_DAYS_IN_MONTH.CONGRESS,
                        })}{' '}
                        {t('GAME_DAYS_LEFT_TILL_ELECTION')}
                      </p>
                    </div>
                  </>
                )}
                <div className="container mx-auto justify-center items-center content-center text-center grid grid-cols-4 sm:grid-cols-10">
                  {congressDetails && congressDetails.length > 0 && (
                    <>
                      {congressDetails.map((item) => {
                        return (
                          <>
                            <div className="text-gray-200 break-words">
                              <p className="p-3">{t('GAME_PROFILE_MEDAL_NAME_3')}</p>
                              <Avatar avatarId={item.U_AVATAR} className="mx-auto" userId={item.U_ID} />
                              <p>{item.U_NAME}</p>
                            </div>
                          </>
                        );
                      })}
                    </>
                  )}
                </div>
              </>
            ) : (
              ''
            )}
            {partyMembersList && (
              <>
                <Divider />
                <div className="text-gray-100 text-center justify-center -mt-3 mb-3">
                  {t('GAME_MILITARY_MEMBERS_UNIT')} - <span className="text-green-300">{partyMembers?.total}</span>
                </div>
                <div className="container mx-auto justify-center items-center content-center text-center grid grid-cols-4 sm:grid-cols-10 text-gray-200">
                  {partyMembersList
                    ? partyMembersList.map((item) => {
                        const ask =
                          congressCandidates &&
                          congressCandidates.map((ccandidate) => {
                            if (Number(ccandidate.USER) === Number(item.id)) {
                              return Number(item.id);
                            }
                          });
                        return (
                          <div className="p-1" key={item.id}>
                            {Number(user.id) === Number(partyDetails.PRESIDENT) && (
                              <>
                                {ask.includes(item.id) ? (
                                  <CancelNominateCongressMan
                                    partyId={partyDetails.ID}
                                    candidateId={item.id}
                                    trigger={setCongressCandidates}
                                  />
                                ) : (
                                  <NominateCongressMan
                                    partyId={partyDetails.ID}
                                    candidateId={item.id}
                                    trigger={setCongressCandidates}
                                  />
                                )}
                              </>
                            )}
                            <Avatar
                              avatarId={item.AVATAR}
                              className={
                                nominatedPresident && nominatedPresident.USER === item.id
                                  ? ' shadow-lg shadow-green-500/100' + ' mx-auto '
                                  : ' mx-auto'
                              }
                              userId={item.id}
                            />
                            <p>{item.user}</p>
                          </div>
                        );
                      })
                    : loading}
                </div>
                <div className="m-5">
                  {partyMembers && <Pagination items={partyMembers} setItems={setPartyMembers} />}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
