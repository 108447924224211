import { FC, useState, Fragment } from 'react';
import { fromCDN } from '../../helpers/Images';
import { Divider } from '../../components/Divider';
import { AppModal } from '../../components/AppModal';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { TutorialInformationProps } from './types/types';

export const TutorialInformation: FC<TutorialInformationProps> = (props) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');

  return (
    <>
      <AppModal
        body={
          <div className="grid grid-cols-1 gap-4 m-5 sm:ml-10 max-w-2xl text-white">
            <p className="mx-auto font-semibold mb-1">
              <img className="w-6 h-6 mr-2 inline" src={fromCDN.fetchStaticImage('/game/icons/info', 'svg')} />
              Information
            </p>
            {props.headerExplanation}
            <Divider margin="mx-0 mb-2 mt-4" />
            {props.sections.map((section, index) => {
              return (
                <Fragment key={index}>
                  {section}
                  <Divider margin="mx-0 my-2" />
                </Fragment>
              );
            })}

            {props.extraNotes}
          </div>
        }
        open={open}
        setOpen={setOpen}
        backgroundStyle={{
          backgroundColor: '#1E293B',
        }}
      />
      <div
        onClick={() => {
          setOpen(true);
        }}
        data-tip={true}
        data-for="informationcompanies"
        className="md:inline-flex top-0  cursor-pointer"
      >
        <span className="static -ml-5 p-1 shadow-inner inline-flex rounded transition duration-100 scale-50">
          <img className="w-12 h-12" src={fromCDN.fetchStaticImage('/game/icons/info', 'svg')} />
        </span>
      </div>
      <ReactTooltip className="font-bold" id="informationcompanies" data-html={true}>
        {t('GAME_INFORMATION')}
      </ReactTooltip>
    </>
  );
};
