import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { SectionHeader } from '../../components/SectionHeader';

import { FeedBackground } from '../../assets/Styles/Styles';
import ApplicationAlert from '../../components/ApplicationAlert';
import { PageContainer } from '../../components/PageContainer';
import { fromCDN } from '../../helpers/Images';
import { submitTicketApi } from '../../api/shared-api';

const issueTypes = [
  { value: 0, label: 'In game related issues' },
  { value: 1, label: 'Bug report' },
  { value: 2, label: 'Harassment report' },
  { value: 3, label: 'Content report' },
];

export const Support = () => {
  const [issueDescription, setIssueDescription] = useState('');
  const [issueType, setIssueType] = useState<{ value: number; label: string }>(issueTypes[0]);

  const { t } = useTranslation('common');

  const submitTicket = async () => {
    try {
      await submitTicketApi({
        message: issueDescription,
        subject: issueType.label,
      });
      ApplicationAlert(t('GAME_SUCCESS')).success();
    } catch (err) {
      ApplicationAlert(t('GAME_ERROR')).error();
    }
    setIssueDescription('');
    setIssueType(issueTypes[0]);
  };

  return (
    <PageContainer style={FeedBackground()}>
      <SectionHeader title={t('GAME_FOOTER_SUPPORT')} />

      <div className="pt-5 px-3">
        <div className="rounded-lg w-full mx-auto text-gray-900 text-base bg-white p-6 shadow-lg flex flex-col items-center text-center gap-4">
          <img src={fromCDN.fetchStaticImgImage('/logo')} alt="logo" />
          <div>Our costumer support is available on our official ticket system below. Please pick the subject</div>
          <div className="w-full flex flex-col items-center gap-5">
            <Select
              className="basic-single max-w-xs w-full"
              options={issueTypes}
              isSearchable={false}
              value={issueType}
              onChange={(item) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setIssueType(item);
              }}
            />
            <textarea
              value={issueDescription}
              onChange={(e) => {
                setIssueDescription(e.target.value);
              }}
              className="h-40 resize-none shadow-xl w-full rounded-xl border border-gray-400 p-2 outline-none focus:ring-gray-400 focus:ring-1"
            />
          </div>
          <button
            className="px-3 py-1 bg-slate-800 text-white rounded-md uppercase default-transition tracking-wide"
            onClick={submitTicket}
          >
            {t('GAME_SUBMIT_TICKET')}
          </button>
        </div>
      </div>
    </PageContainer>
  );
};
