import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ReactNode } from 'react';

const MySwal = withReactContent(Swal);
const emailAdmin = process.env.REACT_APP_EMAIL_ADMIN;

export default function ApplicationAlert(message: string | ReactNode, title = null) {
  const status = {
    success: () => {
      MySwal.fire({
        icon: 'success',
        title: title ?? <span className="text-gray-100">Successfully Done</span>,
        html: <div className="text-gray-100">{message}</div>,
        footer: (
          <a href={`mailto:${emailAdmin ? emailAdmin : ''} `} className="text-white cursor-default">
            In case you need any help,{' '}
            <span className="text-red-400 hover:text-red-300 font-semibold cursor-pointer">
              contact the administrator
            </span>
          </a>
        ),
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        background: 'rgb(30, 41, 59)',
        backdrop: 'swal2-backdrop-hide',
      });
    },
    error: () => {
      MySwal.fire({
        icon: 'error',
        title: <span className="text-gray-100">{title ?? 'Something is wrong'}</span>,
        html: <div className="text-red-400 font-semibold">{message}</div>,
        footer: (
          <a href={`mailto:${emailAdmin ? emailAdmin : ''} `} className="text-white cursor-default">
            In case you need any help,{' '}
            <span className="text-red-400 hover:text-red-300 font-semibold cursor-pointer">
              contact the administrator
            </span>
          </a>
        ),
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        background: 'rgb(30, 41, 59)',
        backdrop: 'swal2-backdrop-hide',
      });
    },
    info: () => {
      MySwal.fire({
        icon: 'info',
        html: <div className="text-gray-100">{message}</div>,
        footer: (
          <a href={`mailto:${emailAdmin ? emailAdmin : ''} `} className="text-white cursor-default">
            In case you need any help,{' '}
            <span className="text-red-400 hover:text-red-300 font-semibold cursor-pointer">
              contact the administrator
            </span>
          </a>
        ),
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        background: 'rgb(30, 41, 59)',
        backdrop: 'swal2-backdrop-hide',
      });
    },
  };

  return status;
}
