import { authApi } from './index';
import {
  CreateUnitApiProps,
  GetBattlesReturn,
  GetJobOffersApiReturn,
  GetPartyApiProps,
  SubmitTicketProps,
} from './types';
import { UserProps } from '../shared-types/user-type';
import { AxiosResponse } from 'axios';

export const getBattles = async (): Promise<GetBattlesReturn | undefined> => {
  return authApi.get('/api/battles');
};

export const getMilitaryUnits = async (countryId: number) => {
  return await authApi.get(`/api/military-units/${countryId}`);
};

export const updateProfile = async <T>(props: T) => {
  return authApi.post('/api/user/editprofile', props);
};

export const createUnitApi = async (props: CreateUnitApiProps): Promise<UserProps | undefined> => {
  return authApi.post('/api/military-unit/create-unit', props);
};

export const consumeEnergyBar = async ({ itemType, itemQuality }: { itemType: number; itemQuality: number }) => {
  return await authApi.post('/api/useenergybar', {
    itemType: itemType,
    itemQuality: itemQuality,
  });
};

export const submitTicketApi = async ({ message, subject }: SubmitTicketProps) => {
  return await authApi.post(`/api/messages/conversation/send/1`, {
    message,
    subject,
  });
};

export const getPartyDetailsApi = async ({ id }: GetPartyApiProps) => {
  return await authApi.get(`api/parties/get/${id as unknown as string}`);
};

export const getJobMarketOffersApi = async ({
  countryId,
  page,
}: {
  countryId: number;
  page: number;
}): Promise<AxiosResponse<GetJobOffersApiReturn[]>> => {
  return await authApi.get(`/api/business/jobmarket/${countryId}/${page}`);
};

export const applyToJobApi = async (props: { employer: number; orgType: number; salary: number }) => {
  return await authApi.post(`/api/business/jobapply`, props);
};
