import { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { authApi } from '../../../api';
import { getMaxInputValue } from '../../../helpers/get-max-input-value';

import { countryFlags } from '../../../helpers/Helpers';
import { fromCDN } from '../../../helpers/Images';
import { useUser } from '../../../hooks/use-user';
import { validate } from '../../../helpers/Validations';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { AvatarWithDefaultFallback } from '../../../components/ImageWithFallback';
import { normalizeUserName } from '../../../helpers/normalize';
import { Button } from '../../../components/Buttons/Button';
import { MarketplaceCardProps, MarketParamProps, PurchaseResponse, PurchaseItemProps } from './types';
import {useHelper} from "../../../hooks/use-helper";

function purchaseItem({ id, amount, countryId, quality }: PurchaseItemProps): Promise<PurchaseResponse> {
  return authApi.post('/api/business/marketplacepurchase', {
    offer: id,
    amount,
    marketCountry: countryId,
    marketItem: quality,
  });
}

export const MarketplaceCard = ({ item, updateOffer }: MarketplaceCardProps) => {
  const { helpers } = useHelper()
  const { user, setUser } = useUser();
  const { countryId, quality } = useParams() as unknown as MarketParamProps;
  const country = Number.parseInt(countryId);
  const { t } = useTranslation('common');
  const [purchaseAmount, setPurchaseAmount] = useState(0);

  if (!validate.validNumbers([countryId])) return null;

  function purchase(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    purchaseItem({
      id: item.ID,
      amount: purchaseAmount,
      countryId,
      quality,
    })
      .then((response) => {
        setUser(response.data.USER);
        ApplicationAlert(t(response.data.MESSAGE)).success();
        updateOffer({ offerId: item.ID, purchasedAmount: purchaseAmount });
      })
      .catch((error) => {
        console.error(t(error.response.data) ?? 'Error placing order');
        ApplicationAlert(t(error.response.data)).error();
      });
  }

  return (
    <form
      className="bg-gray-100 hover:bg-gray-200 shadow-inner rounded-lg animate__animated animate__fadeIn p-3 flex flex-col gap-y-3"
      onSubmit={purchase}
    >
      <div className="mt-5">
        <AvatarWithDefaultFallback
          className="w-12 h-12 mx-auto rounded-lg shadow-sm shadow-gray-500"
          avatarId={item.U_AVATAR}
        />

        <span className="font-semibold">{normalizeUserName(item.U_NAME)}</span>
      </div>
      <div className="bg-gray-600 p-2 rounded-lg text-white flex flex-col">
        <div>
          <img
            className="w-10 h-10 mx-auto"
            src={fromCDN.fetchStaticGameImage(`/items/${helpers.Items[item.TYPE]['Img']}`)}
          />
          <p>Q{item.QUALITY}</p>
          <span className="text-green-300">x{item.STOCK}</span>
        </div>
        <div className="flex justify-center items-center gap-x-1 border-t border-t-gray-400 pt-2 mt-2">
          <span>{item.PRICE}</span>
          <span>{countryFlags(country)}</span>
        </div>
      </div>

      <div>
        {user.COUNTRY === country ? (
          <div className="flex flex-col gap-2.5 items-center">
            <input
              type="number"
              min="1"
              max={item.STOCK}
              value={purchaseAmount.toString()}
              onChange={(e) => {
                const valueToSet = getMaxInputValue(e);
                setPurchaseAmount(valueToSet);
              }}
              className="appearance-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-100 focus:border-green-800"
              placeholder={t('GAME_QUANTITY')}
            />
            <Button disabled={purchaseAmount === 0} type="submit" color="green">
              {t('GAME_PURCHASE')}
            </Button>
          </div>
        ) : (
          <div className="text-center p-3 bg-gray-300 shadow-inner text-gray-900 rounded-lg font-semibold">
            {t('GAME_LOCATED_SAME')}
          </div>
        )}
      </div>

      {purchaseAmount > 0 ? (
        <div className="flex flex-col gap-y-px items-center text-gray-900 border-t-2 border-t-slate-900/25 pt-2">
          <div className="flex gap-x-1 font-semibold">
            <span>{(item.PRICE * purchaseAmount).toString()}</span>
            <span>{countryFlags(country)}</span>
          </div>
        </div>
      ) : null}
    </form>
  );
};
