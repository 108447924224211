import { authApi } from '../../../api';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ApplicationAlert from '../../../components/ApplicationAlert';

export default function NominatePresident({ partyId, candidateId, trigger }) {
  const { t } = useTranslation('common');

  const handleNomination = () => {
    authApi
      .get('api/parties/' + partyId + '/nominate/' + candidateId + '/president')
      .then((resp) => {
        trigger(resp.data['NOMINATED_PRESIDENCY_CANDIDATE'][0]);
        return ApplicationAlert(t('GAME_SUCCESS')).success();
      })
      .catch((err) => {
        return ApplicationAlert(t(err.response.data)).error();
      });
  };

  return (
    <>
      <span onClick={() => handleNomination()} data-tip data-for={'nominatepresident'} className={'mx-auto'}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-green-800 hover:text-green-600 cursor-pointer default-transition mx-auto"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
          />
        </svg>
      </span>
      <ReactTooltip id={'nominatepresident'} effect="solid" data-html={true}>
        {t('GAME_NOMINATE_COUNTRY_PRESIDENT')}
      </ReactTooltip>
    </>
  );
}
