import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ButtonMailTo } from '../../components/Buttons/ButtonMailTo';
import { PageContainer } from '../../components/PageContainer';
import { SectionHeader } from '../../components/SectionHeader';

export default function PrivacyPolicy() {
  const { t } = useTranslation('common');

  return (
    <PageContainer>
      <SectionHeader title={t('GAME_FOOTER_PRIVACY')} titleClass="text-xl" />

      <div className="text-center mt-6 mb-10">
        <h1 className="text-gray-900 text-2xl font-semibold">eDominations Privacy Policy</h1>

        <span className="text-lg">Date of last revision: 16.12.2019</span>
      </div>

      <div className="px-6 pb-20">
        <ol className="list-decimal list-inside text-base space-y-8">
          <li>
            <span className="font-semibold text-xl">Who are we?</span> The website accessible on{' '}
            <Link to="/" className="text-link">
              https://www.edominations.com/
            </Link>{' '}
            (the “<em>Website</em>”) allows people to register and engage and participate in a variety of activities in
            a mass multiplayer online social strategy game called eDominations (&raquo;
            <em>Game</em>&laquo;). Website is operated by IT storitve Ugur Omer Atagun registered under Nr. 8299641000
            (&raquo;eDominations&laquo;, "we", “us” or “our”).
          </li>

          <li className="space-y-4">
            <span className="font-semibold text-xl">What is this document?</span> This Privacy Policy outlines which
            personal data we collect from you, and how we process it. Please read it carefully to understand our views
            and practices regarding your personal data and how we will treat it.
            <span className="block">
              We reserve the right to amend or modify this Privacy Policy at any time and in response to changes in
              applicable law or our business practices.
            </span>
            <span className="block">
              Any changes we may make to our privacy policy in the future will be posted on this page and, when
              appropriate and necessary, notified to you by e-mail. Please check back frequently to see any updates or
              changes to our privacy policy.
            </span>
          </li>

          <li>
            <h3 className="inline font-semibold text-xl">What personal data we collect and why?</h3>

            <ul className="list-disc list-inside pl-6 space-y-4 pt-4">
              <li>
                <span className="font-semibold">Upon Website visit;</span> we collect some information, such as your IP
                address with cookies. The cookies help us improve your user experience by distinguishing between users
                from different countries and localizing the Website, shortening loading times, etc.
              </li>
              <li>
                <span className="font-semibold">Upon registration;</span> we require you to provide an email and
                username, so we can create your unique account.
              </li>
              <li>
                <span className="font-semibold">Upon item purchase;</span> we forward you to our payment processor who
                collects other personal data, such as your banking information, personal name, address, etc. for the
                purpose of payment processing. Please refer to their Checkout User Terms and Conditions (
                <a
                  href="https://stripe.com/en-si/checkout/legal"
                  target="_blank"
                  className="text-link"
                  rel="noreferrer"
                >
                  https://stripe.com/en-si/checkout/legal
                </a>
                ) and/or Privacy Policy (
                <a href="https://stripe.com/en-si/privacy" target="_blank" className="text-link" rel="noreferrer">
                  https://stripe.com/en-si/privacy
                </a>
                ) for more information. If you perform payments via PayPal, we may receive your personal name and
                surname as relates to the transaction.
              </li>
            </ul>

            <ol className="mt-6 ml-6 list-decimal list-inside space-y-5 list--character">
              <li>
                <span className="font-semibold text-lg">Information we collect about you</span>

                <ul className="space-y-4 mt-4">
                  <p>
                    With regard to each of your visits to the Website and accessing the Game we may automatically
                    collect the following information:
                  </p>

                  <ol className="space-y-4 list-disc list-inside ml-3">
                    <li>
                      information about your visits, including the full Uniform Resource Locators (URL) click stream to,
                      through and from the Website (including date and time);
                    </li>
                    <li>
                      products you viewed or searched for; page response times, download errors, length of visits to
                      certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and
                      methods used to browse away from the page; and
                    </li>

                    <li>
                      technical information, including the internet protocol (IP) address used to connect your computer
                      to the internet, your login information, browser type and version, time zone setting, browser
                      plug-in types etc.
                    </li>
                  </ol>

                  <p>
                    We may receive information about you from publicly available sources. We are also working closely
                    with third parties (including, for example, business partners, sub-contractors in technical, payment
                    and delivery services, advertising networks, analytics providers, search information providers) and
                    may receive information about you from them.
                  </p>
                </ul>
              </li>

              <li>
                <h3 className="inline font-semibold text-lg">Our use of information</h3>

                <div className="mt-4 space-y-4">
                  <p>We use information held about you in the following ways:</p>

                  <ul className="list-disc list-inside pl-2 space-y-4">
                    <li>to enable functionalities of the Game;</li>
                    <li>
                      to provide you with information about other games and services that we offer or suggest other
                      similar games, products and services that you may like;
                    </li>
                    <li>to notify you about important changes to the policies or on the Website;</li>
                    <li>
                      to ensure that content from the Website is presented in the most effective manner for you and for
                      your computer;
                    </li>
                    <li>to prevent fraud and other prohibited or illegal activities;</li>
                    <li>
                      to protect the rights and safety of our affiliated entities and third parties, as well as our own;
                    </li>
                    <li>to monitor compliance with our Terms of Use[LP1];</li>
                    <li>to ensure you receive information relevant to you;</li>
                    <li>
                      to administer the Website and for internal operations, including troubleshooting, data analysis,
                      testing, research, statistical and survey purposes;
                    </li>
                    <li>
                      to allow you to participate in interactive features of our service when you choose to do so;
                    </li>
                    <li>as part of our efforts to keep the Website functional, safe and secure.</li>
                  </ul>

                  <p>
                    We may also email you a newsletter and/or updates about the Services or the company. You can opt-out
                    of receiving these communications by following the instructions at the bottom of each message, or at
                    any time by contacting us at: <ButtonMailTo mailto="edominationgame@gmail.com" />
                  </p>
                </div>
              </li>
            </ol>
          </li>

          <li>
            <h3 className="inline font-semibold text-xl">
              In what cases we share or disclose your information with 3rd parties
            </h3>

            <div className="mt-4 space-y-4">
              <p>
                We do not distribute or share any customer personal information, except as noted in this Privacy Policy,
                or as may be required by law and when we believe that disclosure is necessary to protect our rights
                and/or to comply with a judicial proceeding, court order, or other legal action.
              </p>

              <p>We may also disclose your personal information to third parties in any of the following events:</p>
              <ul className="list-disc list-inside pl-2 space-y-4">
                <li>
                  if our company or the Website are acquired by a third party, in which case personal data will be one
                  of the transferred assets;
                </li>
                <li>
                  if we are under a duty to disclose or share your personal data in order to comply with any legal
                  obligation;
                </li>
                <li>
                  or to protect the rights, property, or safety, of our own, our customers, or others. This includes
                  exchanging information with other companies and organisations for the purposes of fraud protection and
                  credit risk reduction.
                </li>
              </ul>

              <p>
                We will not otherwise provide your personal data to a third party without obtaining your prior consent.
              </p>
            </div>
          </li>

          <li>
            <h3 className="inline font-semibold text-xl">Correcting, Deleting and Updating Your Information</h3>

            <div className="mt-4 space-y-4">
              <p>
                Upon request, we will provide you with information about whether we hold or process any of your personal
                information, whether on behalf of a third party or otherwise. To request this information, please
                contact us at: <ButtonMailTo mailto="edominationgame@gmail.com" />
              </p>
              <p>
                If your personally identifiable information changes, or if you no longer desire our Services, you may
                correct, update, discontinue the use of our Services or delete it by making the change on our member
                information page or by emailing our Customer Support at{' '}
                <ButtonMailTo mailto="edominationgame@gmail.com" /> We will respond to your request to access within 30
                days.
              </p>
              <p>
                We will retain your information for as long as your account is active. We will retain and use your
                information as necessary to comply with our legal obligations, resolve disputes, and enforce our
                agreements.
              </p>
              <p>
                We may use third-party intermediaries to manage credit card processing. These intermediaries are solely
                links into the distribution chain. They are not permitted to store, retain, or use the information
                provided, except for the sole purpose of credit card processing.
              </p>
              <p>
                If we use a third-party to host our Website or any of its functionalities they do not have any access to
                the personal information that you submit on our website.
              </p>
              <p>
                We will view or access individual records only with your permission (for example, to resolve a problem
                or support issue).
              </p>
              <p>
                You alone are responsible for maintaining the confidentiality and security of your user registration
                information and password(s).
              </p>
            </div>
          </li>

          <li>
            <h3 className="inline font-semibold text-xl">Where we store your personal data</h3>

            <div className="mt-4 space-y-4">
              <p>
                The data that we collect will be stored on the cloud with the hosting infrastructure based in the
                European Economic Area ("EEA").<sup>[a]</sup>
              </p>
              <p>
                We will take all steps reasonably necessary to ensure that your data is treated securely and in
                accordance with this Privacy Policy.
              </p>
            </div>
          </li>

          <li>
            <h3 className="inline font-semibold text-xl">Safeguarding personal data</h3>

            <div className="mt-4 space-y-4">
              <p>You should always make sure that your browser can validate the Website's certificate.</p>
              <p>
                Please be reminded that you must protect any passwords or other credentials associated with your
                account, and take full responsibility for any use of the account under your password. We ask you not to
                share your password with anyone.
              </p>
              <p>
                Unfortunately, the transmission of information via the internet is not completely secure and we cannot
                guarantee the security of your data transmitted to the Website, although we will do our best to protect
                your personal data. Once we have received your information, we will use strict procedures and security
                features to try to prevent unauthorized access.
              </p>
              <p>
                In the case of a personal data breach, we shall without undue delay and, where feasible, not later than
                72 hours after having become aware of it, notify the personal data breach to the supervisory authority
                competent in accordance with{' '}
                <a href="https://gdpr-info.eu/art-55-gdpr/" target="_blank" className="text-link" rel="noreferrer">
                  Article 55
                </a>
                , of the General Data Protection Regulation of the European Union unless the personal data breach is
                unlikely to result in a risk to the rights and freedoms of natural persons. In such case we will also
                notify you without undue delay and, where feasible, not later than 72 hours after having become aware of
                the breach, and suggest any actions to be taken to secure your personal information (change your
                username and password, etc.).
              </p>
            </div>
          </li>

          <li>
            <h3 className="inline font-semibold text-xl">Your rights and access to information</h3>

            <div className="mt-4 space-y-4">
              <p>
                You may ask us not to process your personal data for a particular purpose, or to access such
                information, by contacting us at <ButtonMailTo mailto="edominationgame@gmail.com" /> Such request may
                result in you being unable to use the full scope of the functionalities of the Website where removal or
                cease of information processing shall result in impaired functionality of the Services.
              </p>
              <p>
                You may ask us to receive your{' '}
                <a
                  href="https://www.i-scoop.eu/gdpr/gdpr-personal-data-identifiers-pseudonymous-information/"
                  target="_blank"
                  className="text-link"
                  rel="noreferrer"
                >
                  personal information
                </a>
                , which you have provided to us, in a structured, commonly used and machine-readable format and shall
                have the right to transmit such information to another controller. You may submit such request by
                contacting us at <ButtonMailTo mailto="edominationgame@gmail.com" /> Please note that the processing of
                such request may take up to 14 days to complete.
              </p>
              <p>
                The Website may, from time to time, contain links to other third party websites. If you follow a link to
                any of these websites, please note that these websites have their own privacy policies and that we do
                not accept any responsibility or liability in respect of these policies.
              </p>
            </div>
          </li>

          <li>
            <h3 className="inline font-semibold text-xl">Information about our use of cookies (COOKIE POLICY)</h3>

            <div className="mt-4 space-y-4">
              <p>
                Our Website uses cookies to distinguish you from other users of the Website. This helps us to provide
                you with a good experience when you browse the Website and also allows us to improve our site. By
                continuing to browse the site, you are agreeing to our use of cookies.
              </p>
              <p>
                A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
                computer if you agree. Cookies contain information that is transferred to your computer's hard drive.
              </p>

              <p>We use the following cookies:</p>

              <ul className="list-disc list-inside pl-2 space-y-4">
                <li>
                  <span className="font-medium">Strictly necessary cookies.</span> These are cookies that are required
                  for the operation of the Website. They include, for example, cookies that enable you to log into
                  secure areas of the Website, use a shopping cart or make use of e-billing services.
                </li>
                <li>
                  <span className="font-medium">Analytical/performance cookies.</span> They allow us to recognize and
                  count the number of visitors and to see how visitors move around the Website when they are using it.
                  This helps us to improve the way the Website works, for example, by ensuring that users are finding
                  what they are looking for easily.
                </li>
                <li>
                  <span className="font-medium">Functionality cookies.</span> These are used to recognize you when you
                  return to the Website. This enables us to personalize our content for you, greet you by name and
                  remember your preferences (for example, your choice of language or region).
                </li>
                <li>
                  <span className="font-medium">Marketing cookies.</span> They are used to track visitors across
                  websites. The intention is to display ads that are relevant and engaging for the individual user and
                  thereby more valuable for publishers and third-party advertisers.
                </li>
              </ul>

              <p>
                You can find more information about the individual cookies we use and the purposes for which we use them
                in the table below:
              </p>

              <div className="py-4 px-1">
                <table className="table-auto">
                  <thead>
                    <tr>
                      <th className="w-1/4">Cookie Name</th>
                      <th className="w-1/4">Cookie Type</th>
                      <th className="w-1/2">Purpose</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-gray-200">
                      <td className="pl-2">GPS</td>
                      <td>Marketing</td>
                      <td className="p-2 pl-0">
                        Registers a unique ID on mobile devices to enable tracking based on geographical GPS location.
                      </td>
                    </tr>
                    <tr>
                      <td className="pl-2">YSC</td>
                      <td>Marketing</td>
                      <td className="p-2 pl-0">
                        Registers a unique ID to keep statistics of what videos from YouTube the user has seen.
                      </td>
                    </tr>
                    <tr className="bg-gray-200">
                      <td className="pl-2">_gat_gtag_UA_144001673_1</td>
                      <td>Strictly necessary</td>
                      <td className="p-2 pl-0">
                        Tracking the visitors behavior used by Google to understand data related to a marketing
                        campaigns.
                      </td>
                    </tr>
                    <tr>
                      <td className="pl-2">_gid</td>
                      <td>Analytical/performance</td>
                      <td className="p-2 pl-0">
                        Registers a unique ID that is used to generate statistical data on how the visitor uses the
                        website.
                      </td>
                    </tr>
                    <tr className="bg-gray-200">
                      <td className="pl-2">_ga</td>
                      <td>Analytical/performance</td>
                      <td className="p-2 pl-0">
                        Registers a unique ID that is used to generate statistical data on how the visitor uses the
                        website.
                      </td>
                    </tr>
                    <tr>
                      <td className="pl-2">IDE</td>
                      <td>Marketing</td>
                      <td className="p-2 pl-0">
                        Used by Google DoubleClick to register and report the website user's actions after viewing or
                        clicking one of the advertiser's ads with the purpose of measuring the efficacy of an ad and to
                        present targeted ads to the user.
                      </td>
                    </tr>
                    <tr className="bg-gray-200">
                      <td className="pl-2">PREF</td>
                      <td>Marketing</td>
                      <td className="p-2 pl-0">
                        Registers a unique ID that is used by Google to keep statistics of how the visitor uses YouTube
                        videos across different websites.
                      </td>
                    </tr>
                    <tr>
                      <td className="pl-2">VISITOR_INFO1_LIVE</td>
                      <td>Marketing</td>
                      <td className="p-2 pl-0">
                        Tries to estimate the users' bandwidth on pages with integrated YouTube videos.
                      </td>
                    </tr>
                    <tr className="bg-gray-200">
                      <td className="pl-2">PHPSESSID</td>
                      <td>Strictly necessary</td>
                      <td className="p-2 pl-0">
                        Registers a unique id in between server and a user to allow visit each page as logged-in user
                        data.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p>
                Please note that third parties (including, for example, advertising networks and providers of external
                services like web traffic analysis services) may also use cookies, over which we have no control. These
                cookies are likely to be analytical/performance cookies or targeting cookies.
              </p>
              <p>
                You block cookies by activating the setting on your browser that allows you to refuse the setting of all
                or some cookies. However, if you use your browser settings to block all cookies (including essential
                cookies) you may not be able to access all or parts of our site.
              </p>
            </div>
          </li>

          <li>
            <h3 className="inline font-semibold text-xl">Opt-Out Statement</h3>

            <div className="mt-4 space-y-4">
              We offer you a means to choose how we may use the information you provide. If, at any time after
              registering for information or ordering the Services, you change your mind about receiving certain email
              communications from us or about sharing personal information with third parties, send us a request to
              opt-out from receiving promotional emails or from having your personal information shared with third
              parties. Send your request to <ButtonMailTo mailto="edominationgame@gmail.com" />
            </div>
          </li>

          <li>
            <h3 className="inline font-semibold text-xl">Social Media Widgets</h3>

            <p className="mt-4 space-y-4">
              Our Website may include Social Media Features, such as the Facebook button and Widgets, such as the Share
              this button or an interactive mini-programs that run on our site. These features may collect your IP
              address, which page you are visiting on our site, and may set a cookie to enable the Feature to function
              properly. Social Media Features and Widgets are either hosted by a third party or hosted directly on our
              Website. Your interactions with these Features are governed by the privacy policy of the company providing
              it.
            </p>
          </li>
          <li>
            <h3 className="inline font-semibold text-xl">Contact Information</h3>
            <div className="mt-4 space-y-4">
              You may direct any questions, concerns or suggestions regarding this Privacy Policy or our security
              practices at <ButtonMailTo mailto="edominationgame@gmail.com" />
            </div>
          </li>
        </ol>
      </div>
    </PageContainer>
  );
}
