import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ArticleProps } from '../../../shared-types/NewspaperTypes';

export interface NewspaperCardProps {
  article: ArticleProps;
}

export const ArticleCard = ({ article }: NewspaperCardProps) => {
  const { t } = useTranslation('common');

  return (
    <Link
      className="m-3 text-center bg-gray-400 rounded-xl p-3 default-transition cursor-pointer"
      to={`/article/${article.ID}`}
    >
      <div className="break-words font-bold">
        <p className="bg-gray-700 text-white rounded-xl py-2 shadow-lg">{article.TITLE.substring(0, 10)}</p>
        <div className="text-gray-100 flex justify-between px-3 py-4">
          <div>
            <p>{t('GAME_COMMENTS')}</p>
            <p>{article.COMMENTS}</p>
          </div>
          <div>
            <p>{t('GAME_TOTAL_VOTES')}</p>
            <p>{article.VOTES}</p>
          </div>
        </div>

        <time className="mt-2">{moment(article.DATE).format('LLL')}</time>
      </div>
    </Link>
  );
};
