import { imageAsBackground } from '../../helpers/image-as-background';

import { fromCDN } from '../../helpers/Images';
import { CardsContainer } from '../../components/Card/CardsContainer';

export const MyBuildings = () => {
  const cards = [
    {
      to: '/buildings/workout',
      imgUrl: fromCDN.fetchStaticGameImage('/v2/Icons/workout', 'svg'),
      title: 'GAME_WORKOUT_AREA',
      'data-testid': 'workout',
      color: 'bg-edom-dark-red',
      infoText: 'GAME_WORKOUT_EXPLANATION',
    },
    {
      to: '/buildings/companies',
      imgUrl: fromCDN.fetchStaticGameImage('/v2/Icons/Companies', 'svg'),
      title: 'GAME_COMPANIES',
      'data-testid': 'companies',
      color: 'bg-edom-dark-yellow',
      infoText: 'GAME_COMPANIES_EXPLANATION',
    },
    {
      to: '/buildings/storage',
      imgUrl: fromCDN.fetchStaticGameImage('/v2/Icons/Storage', 'svg'),
      title: 'GAME_STORAGE',
      'data-testid': 'storage',
      color: 'bg-edom-dark-emerald',
      infoText: 'GAME_STORAGE_EXPLANATION',
    },
    {
      to: '/buildings/boosts',
      imgUrl: fromCDN.fetchStaticGameImage('/v2/Icons/Boosts', 'svg'),
      title: 'GAME_BOOSTS',
      'data-testid': 'boosts',
      color: 'bg-edom-dark-blue',
      infoText: 'GAME_BOOSTS_EXPLANATION',
    },
  ];

  return (
    <CardsContainer
      innerContainerClasses="max-w-screen-2xl grid sm:grid-cols-2 lg:grid-cols-4 mx-auto py-12 gap-x-10 gap-y-8 sm:gap-y-10 px-2 2xl:px-0 drop-shadow-[0_8px_6px_rgba(0,0,0,0.4)]"
      cards={cards}
      style={imageAsBackground(fromCDN.fetchStaticImgImage('/mybuildingsbg'))}
    />
  );
};
