import { ReactNode } from 'react';

export interface SectionHeaderProps {
  title: string | ReactNode;
  containerSpacing?: string;
  titleClass?: string;
  children?: ReactNode;
  parentChildren?: ReactNode;
}

export const SectionHeader = ({
  title,
  containerSpacing = 'py-4 sm:py-6 px-4',
  titleClass = 'font-bold text-base',
  children,
  parentChildren,
}: SectionHeaderProps) => {
  return (
    <header
      className={`bg-gradient-to-r from-zinc-700 to-zinc-500 border-zinc-600 border-l-4 border-b-2 text-white shadow-lg rounded mx-2 mt-6 ${containerSpacing}`}
    >
      {parentChildren}
      <div className="max-w-6xl mx-auto flex flex-col sm:flex-row items-center gap-2 sm:gap-4">
        <h1 className={titleClass}>{title}</h1>
        {children}
      </div>
    </header>
  );
};
