import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, Outlet } from 'react-router-dom';

import { FeedBackground } from '../../../../assets/Styles/Styles';
import { fromCDN } from '../../../../helpers/Images';
import { Country } from '../../../../shared-types/shared-types';

import { CountrySelector } from '../../../../components/CountrySelector';
import { SectionHeader } from '../../../../components/SectionHeader';
import { CountryTab } from './CountryTab';

const societyIconImage = fromCDN.fetchStaticGameImage('/icons/societynew');
const societyIconImageHover = fromCDN.fetchStaticGameImage('/icons/societynewchange');

const economyIconImage = fromCDN.fetchStaticGameImage('/icons/economynew');
const economyIconImageHover = fromCDN.fetchStaticGameImage('/icons/economynewchange');

const politicsIconImage = fromCDN.fetchStaticGameImage('/icons/politicsnew');
const politicsIconImageHover = fromCDN.fetchStaticGameImage('/icons/politicsnewchange');

const militaryIconImage = fromCDN.fetchStaticGameImage('/icons/militarynew');
const militaryIconImageHover = fromCDN.fetchStaticGameImage('/icons/militarynewchange');

const adminIconImage = fromCDN.fetchStaticGameImage('/icons/adminisnew');
const adminIconImageHover = fromCDN.fetchStaticGameImage('/icons/adminisnewchange');

export const WithCountryTabs = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [currentCountry, setCurrentCountry] = useState(Number.parseInt(id ?? '1'));
  const { t } = useTranslation('common');

  function handleMarketChange(market: Country) {
    setCurrentCountry(market.ID);
    const tab = location.pathname.split('/')[2];
    navigate(`/country/${tab}/${market.ID}`);
  }

  return (
    <div className="max-w-screen mx-auto pt-3 bg-gray-400" style={FeedBackground()}>
      <div className="max-w-7xl mx-auto">
        <SectionHeader title={t('GAME_SOCIETY')}>
          <CountrySelector countryId={id ?? 1} handleChange={handleMarketChange} />
        </SectionHeader>

        <div className="m-5 text-center gap-8 justify-center">
          <div className="inline-flex">
            <CountryTab
              tooltipText={t('GAME_SOCIETY')}
              to={`society/${currentCountry}`}
              img={societyIconImage}
              hoverImg={societyIconImageHover}
              testId={{ 'data-testid': 'societyDescr' }}
            />
            <CountryTab
              tooltipText={t('GAME_ECONOMY')}
              to={`economy/${currentCountry}`}
              img={economyIconImage}
              hoverImg={economyIconImageHover}
              testId={{ 'data-testid': 'economyDescr' }}
            />
            <CountryTab
              tooltipText={t('GAME_POLITICS')}
              to={`politics/${currentCountry}`}
              img={politicsIconImage}
              hoverImg={politicsIconImageHover}
              testId={{ 'data-testid': 'politicsDescr' }}
            />
            <CountryTab
              tooltipText={t('GAME_MILITARY')}
              to={`military/${currentCountry}`}
              img={militaryIconImage}
              hoverImg={militaryIconImageHover}
              testId={{ 'data-testid': 'militaryDescr' }}
            />
            <CountryTab
              tooltipText={t('GAME_ADMINISTRATION')}
              to={`administration/${currentCountry}`}
              img={adminIconImage}
              hoverImg={adminIconImageHover}
              testId={{ 'data-testid': 'adminDescr' }}
            />
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto min-h-screen">
        <Outlet />
      </div>
    </div>
  );
};
