import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {imageAsBackground} from '../helpers/image-as-background';

import { fromCDN } from '../helpers/Images';
const landingBackground = fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg');

export const NotFound = () => {
  let navigate = useNavigate();
  const { t } = useTranslation('common');

  return (
    <main className="min-h-screen" style={imageAsBackground(landingBackground)} data-testid="not-found">
      <div className="max-w-7xl mx-auto py-7 sm:px-6 lg:px-8">
        <div className="w-9/12 m-auto flex items-center justify-center">
          <div className="shadow-inner bg-gray-200 overflow-hidden sm:rounded-lg pb-8">
            <div className="text-center pt-8">
              <h3 className="text-9xl font-bold text-purple-400">{t('GAME_404')}</h3>
              <h3 className="font-medium py-8">{t('GAME_404_NOTFOUND')}</h3>
              <p className="pb-8 px-12 font-medium">{t('GAME_404_NOTFOUND_1')}</p>
              <button
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
                className="bg-gradient-to-r from-indigo-400 to-blue-500 hover:from-indigo-500 hover:to-orange-500 text-white font-semibold px-6 py-3 rounded-md"
              >
                {t('GAME_GO_BACK')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
