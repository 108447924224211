import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Swal from 'sweetalert2';

import { cookies, BASE_URL } from '../../../../constants/constants';
import { allowedLanguages } from '../../../../constants/allowedLanguages.ts';
import { Divider } from '../../../../components/Divider';
import ApplicationAlert from '../../../../components/ApplicationAlert';

import { FileUpload } from '../../../../components/FileUploadProfile';
import { fromCDN } from '../../../../helpers/Images';
import { useUser } from '../../../../hooks/use-user';
import { MESSAGE_MAX_LENGTH } from '../../../../constants/enum';
import { RemainingCharactersHint } from '../../../../components/RemainingCharactersHint';
import { updateProfile } from '../../../../api/shared-api';

const NAME_LENGTH = 30;

export default function EditProfile() {
  const { user, setUser } = useUser();

  const { t, i18n } = useTranslation('common');

  const [alertModal, setAlertModal] = useState();
  const [aboutMe, setAboutMe] = useState('');
  const [username, setUsername] = useState('');
  const [language, setLanguage] = useState();

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 60,
    }),
  };

  function handleAboutChange(e) {
    setAboutMe(e.target.value);
  }

  function handleUserNameChange(e) {
    setUsername(e.target.value);
  }

  const submitUserNameChange = async () => {
    try {
      const result = await updateProfile({ name: username });
      setUser(result.data);
      ApplicationAlert(t('GAME_DONE')).success();
    } catch (err) {}
  };
  const submitAboutChange = async () => {
    try {
      const result = await updateProfile({ description: aboutMe });
      setUser(result.data);
      ApplicationAlert(t('GAME_DONE')).success();
    } catch (err) {}
  };
  const submitLanguageChange = async () => {
    try {
      const result = await updateProfile({ language });
      cookies.set('mylanguage', language);
      await i18n.changeLanguage(language);
      setUser(result.data);
      ApplicationAlert(t('GAME_DONE')).success();
    } catch (err) {}
  };

  const handleLanguageChange = (language) => {
    setLanguage(language.code);
  };

  const onButtonClick = (callBack) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, do it',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          callBack();
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire('', '', 'error');
        }
      });
  };

  return (
    <>
      <div className="text-left">
      <div className="grid grid-cols-2 mt-5">
      <div className="text-xs">
      <div className="text-left">
        <p className="mt-8 mb-3 font-bold"> {t('GAME_EDIT_AVATAR')}</p>
      </div>
      <div className="grid grid-cols-2 mt-5 text-gray-800 items-center">
        <FileUpload url={`${BASE_URL ?? ''}/api/user/editprofile`} setter={setUser} />
        <span className="text-gray-200 text-opacity-80 text-left"> {t('GAME_ACCEPTED_IMAGE_TYPES')}</span>
      </div>
      </div>
      </div>
        <p className="mt-8 mb-3 font-bold">{t('GAME_EDIT_PROFILE_1')}</p>
      </div>
      <Divider margin="" />
      <div className="grid grid-cols-2 mt-5">
        <div className="text-xs">
          <input
            type="name"
            maxLength={NAME_LENGTH}
            className="border-0 px-3 py-3 mb-1 placeholder-gray-400 text-gray-200 bg-zinc-500/70 rounded text-sm shadow w-full focus-ring"
            placeholder={user['user']}
            name="name"
            onChange={(e) => handleUserNameChange(e)}
          />

          <p className="text-gray-200 text-opacity-80 text-left">
            4-{NAME_LENGTH} {t('GAME_NAME_CHAR_LIMIT')}
          </p>
          <div className="flex gap-1 font-medium items-center">
            <span>{t('GAME_COST')}: 30</span>
            <img src={fromCDN.fetchStaticGameImage('/icons/gold-icon-s')} className="h-5 w-5" />
          </div>
        </div>

        <button
          data-testid="savenickname"
          className="bg-green-700 hover:bg-green-600 text-white shadow hover:shadow-lg outline-none uppercase font-bold px-3 py-2 mt-1 rounded transition hover:scale-105 justify-self-center self-start"
          onClick={() => onButtonClick(submitUserNameChange)}
        >
          {t('GAME_SAVE')}
        </button>
      </div>


      <div className="text-left">
        <p className="mt-12 mb-3 font-bold">{t('GAME_EDIT_PROFILE_2')}</p>
      </div>
      <Divider margin="" />
      <div className="grid grid-cols-2 mt-5">
        <div>
          <textarea
            maxLength={MESSAGE_MAX_LENGTH}
            name="text"
            autoComplete="text"
            className="h-24 w-full px-3 py-2 mb-1 placeholder-gray-400 text-gray-200 bg-zinc-500/70 rounded focus-ring"
            placeholder={user['ABOUT']}
            onChange={(e) => handleAboutChange(e)}
          />
          <RemainingCharactersHint text={aboutMe} />
        </div>

        <button
          data-testid="saveaboutme"
          className="bg-green-700 hover:bg-green-600 text-white shadow hover:shadow-lg outline-none uppercase font-bold px-3 py-2 rounded transition hover:scale-105 justify-self-center self-start"
          onClick={() => onButtonClick(submitAboutChange)}
        >
          {t('GAME_SAVE')}
        </button>
      </div>

      <div className="text-left">
        <p className="mt-12 mb-3 font-bold">{t('GAME_EDIT_PROFILE_3')}</p>
      </div>
      <Divider margin="" />
      <div className="grid grid-cols-2 mt-5 text-gray-800 items-center">
        <Select
          className="form-select shadow-md focus-ring"
          placeholder={t('GAME_SELECT_LANGUAGE')}
          styles={customStyles}
          onChange={handleLanguageChange}
          isSearchable={true}
          getOptionLabel={(allowedLangs) => allowedLangs.label}
          getOptionValue={(allowedLangs) => allowedLangs.code}
          options={allowedLanguages({
            t,
          })}
        />

        <button
          data-testid="savelanguage"
          className="bg-green-700 hover:bg-green-600 text-white shadow hover:shadow-lg outline-none uppercase font-bold px-3 py-2 mt-3 rounded transition hover:scale-105 justify-self-center self-start"
          onClick={submitLanguageChange}
        >
          {t('GAME_SAVE')}
        </button>
      </div>
    </>
  );
}
