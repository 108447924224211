import { GLOBAL_CONSTANTS } from './enum';

import { UserProps } from '../shared-types/user-type';

interface UserRestrictionsReturnType {
  isEligibleToCreateLaw: (currentLocation: string | number | boolean, countryId: number | string) => boolean;
}

export const UserRestrictions = (user: UserProps): UserRestrictionsReturnType => {
  return {
    isEligibleToCreateLaw: (currentLocation, countryId): boolean => {
      if (
        (user.CITIZENSHIP === currentLocation && user.POLITICS > 0) ||
        (user.CITIZENSHIP === countryId && user.MINISTRY > 0) ||
        user.id === GLOBAL_CONSTANTS.ADMIN_ID
      ) {
        return true;
      }
      return false;
    },
  };
};
