export interface CountryResourceProps {
  src: string;
  bonuses: number;
}

export const CountryResource = ({ src, bonuses }: CountryResourceProps) => {
  return (
    <div>
      <img className="mx-auto" src={src} alt="Resource image" />
      <span className="font-bold text-yellow-500">{Number.parseInt(`${100 * (bonuses - 1)}`)}%</span>
    </div>
  );
};
