import { useId, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

export interface CountryTabProps {
  to: string;
  img: string;
  hoverImg: string;
  tooltipText: string;
  testId?: {
    'data-testid': string;
  };
}

export const CountryTab = ({ to, img, hoverImg, testId, tooltipText }: CountryTabProps) => {
  const id = useId();
  const [isHover, setIsHover] = useState(false);

  const toggleHover = () => {
    setIsHover((prevState) => !prevState);
  };

  return (
    <div data-tip={true} data-for={id} className="light-transition" {...testId}>
      <Link to={`/country/${to}`}>
        <img
          className="mx-auto cursor-pointer h-16 w-16"
          src={isHover ? hoverImg : img}
          onMouseOver={toggleHover}
          onMouseLeave={toggleHover}
        />
      </Link>
      <ReactTooltip id={id} effect="solid" data-html={true}>
        {tooltipText}
      </ReactTooltip>
    </div>
  );
};
