import { useTranslation } from 'react-i18next';

import { fromCDN } from '../../../../../helpers/Images';
import { UserProps } from '../../../../../shared-types/user-type';
import {
  ACHIEVEMENTS,
  ACHIEVEMENT_COLUMN_NAMES,
  DEFAULT_MEDALS,
  SPECIAL_AWARDS,
  USER_NO_MEDAL,
} from './achievements-constants';
import { Medal } from '../Medal';

export interface NewbieMedalsProps {
  '1': number;
  '2': string;
  '3': string;
}

export interface VeteransMedalsProps {
  V_1: number;
  V_2: number;
  V_3: number;
  V_4: number;
  V_5: number;
}

export interface AchievementsProps {
  user: UserProps;
  newbieMedals: NewbieMedalsProps | null;
  veteransMedals: VeteransMedalsProps | null;
}

const Achievements = ({ user, newbieMedals, veteransMedals }: AchievementsProps) => {
  const { t } = useTranslation('common');

  // All achievements
  const renderedAchievements = [] as JSX.Element[];
  ACHIEVEMENTS.forEach((value, i) => {
    const medal = user[ACHIEVEMENT_COLUMN_NAMES[i] as keyof UserProps];
    const imageUrl = `achievement_${value}_${USER_NO_MEDAL < medal ? 'on' : 'off'}`;

    if (i < DEFAULT_MEDALS || medal > USER_NO_MEDAL) {
      renderedAchievements.push(
        <Medal
          key={value}
          src={fromCDN.fetchStaticGameImage(`/achievements/${imageUrl}`)}
          tooltipText={
            <>
              <p>{t(`GAME_PROFILE_MEDAL_NAME_${value}`)}</p>
              <p>{t(`GAME_PROFILE_MEDAL_TEXT_${value}`)}</p>
            </>
          }
          totalMedals={medal}
          isAchieved={medal !== USER_NO_MEDAL}
        />,
      );
    }
  });

  // 2020 awards
  const rendered2020Award = SPECIAL_AWARDS[user.id as keyof typeof SPECIAL_AWARDS] ? (
    <Medal
      src={fromCDN.fetchStaticGameImage('/achievements/awards2020')}
      tooltipText={SPECIAL_AWARDS[user.id as keyof typeof SPECIAL_AWARDS]}
      totalMedals={1}
      isAchieved={true}
    />
  ) : null;

  // Newbie medals
  const renderedNewbieMedals = [] as JSX.Element[];
  if (newbieMedals) {
    for (let key in newbieMedals) {
      if (2 < Number.parseInt(key)) continue;

      const medal = newbieMedals[key as keyof NewbieMedalsProps];

      if (medal) {
        const numberMedal = Number.parseInt(medal.toString());
        renderedNewbieMedals.push(
          <Medal
            key={medal}
            src={fromCDN.fetchStaticGameImage(`/achievements/newbiemedals/${key}`)}
            tooltipText={t(`GAME_NEWBIE_MEDALS_${key}`)}
            totalMedals={numberMedal}
            isAchieved={numberMedal !== USER_NO_MEDAL}
          />,
        );
      }
    }
  }

  // Veterans medal
  const renderedVeteransMedals = [] as JSX.Element[];
  if (veteransMedals) {
    Object.keys(veteransMedals).forEach((key) => {
      renderedVeteransMedals.push(
        <Medal
          key={key}
          src={fromCDN.fetchStaticGameImage(`/achievements/veterans/${key}`)}
          tooltipText={
            <>
              <span>{t(`GAME_VETERANS_MEDAL_${key}_TITLE`)}</span>
              <span>{t(`GAME_VETERANS_MEDAL_${key}_DESCRIPTION`)}</span>
            </>
          }
          totalMedals={veteransMedals[key as keyof VeteransMedalsProps]}
          isAchieved={true}
        />,
      );
    });
  }

  return (
    <div data-testid="achievements">
      <p className="p-2 text-center bg-yellow-400 bg-opacity-30 hover:bg-opacity-50 cursor-default font-bold">
        {t('GAME_ACHIEVEMENTS')}
      </p>
      <div className="bg-yellow-400 bg-opacity-10 px-8 pb-8 pt-4 rounded-b-lg">
        {renderedAchievements}
        {renderedNewbieMedals}
        {rendered2020Award}
        {renderedVeteransMedals}
      </div>
    </div>
  );
};

export default Achievements;
