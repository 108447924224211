import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../../components/SectionHeader';
import EnergyCenters from './EnergyCenters';
import MyActiveHouses from './MyActiveHouses';
import { FeedBackground } from '../../../assets/Styles/Styles';
import { useUser } from '../../../hooks/use-user';
import { fromCDN } from '../../../helpers/Images';
import {imageAsBackground} from '../../../helpers/image-as-background';

export default function Boosts() {
  const { user } = useUser();
  const { t } = useTranslation('common');

  return (
    <>
      <div
        className="min-h-screen max-w-13xl mx-auto bg-fixed"
        style={imageAsBackground(fromCDN.fetchStaticImgImage('/mybuildingsbg'))}
      >
        <div className="max-w-7xl mx-auto py-2">
          <SectionHeader title={t('GAME_BOOSTS')}>
            <div className="h-8 w-8 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]">
              <img src={fromCDN.fetchStaticGameImage('/v2/Icons/Boosts', 'svg')}></img>
            </div>
          </SectionHeader>
          <MyActiveHouses />
          <EnergyCenters user={user} t={t} />
        </div>
      </div>
    </>
  );
}
