import { useTranslation } from 'react-i18next';

export interface NoOfferProps {
  className?: string;
  translationKey?: string;
}

export const NoOffer = ({ translationKey, className }: NoOfferProps) => {
  const { t } = useTranslation('common');
  const content = translationKey ?? 'GAME_NO_OFFERS';

  return (
    <div
      className={`bg-red-600 bg-opacity-70 rounded-lg w-full h-20 text-lg flex items-center justify-center text-gray-900 font-semibold shadow-md shadow-neutral-700 px-3 text-center ${
        className ?? ''
      }`}
      data-testid="marketplace-no-offers"
    >
      {t(content)}
    </div>
  );
};
