import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { countryFlags, percent } from '../../helpers/Helpers';
import Avatar from '../../components/Avatar';
import EnergyCalculation from '../Energy/EnergyCalculation';
import { authApi } from '../../api';
import ApplicationAlert from '../../components/ApplicationAlert';
import { animate } from '../../util/animate';
import Travel from '../Profile/Tabs/Travel/Travel';
import StarIcon from '../Util/Icons/star/StarIcon';
import { fromCDN } from '../../helpers/Images';
import { useHelper } from '../../hooks/use-helper';
import { useUser } from '../../hooks/use-user';
import WhoIsOnline from './WhoIsOnline/WhoIsOnline';
import { ADMIN_AUTHORIZATION, GLOBAL_CONSTANTS } from '../../constants/enum';
import { showLiveTime } from '../../hooks/show-live-time';
import { EyeIcon } from '@heroicons/react/solid';

export const MainHeader = () => {
  const [headerOpen, setHeaderOpen] = useState(true);
  const [travelModal, setTravelModal] = useState(false);
  const { user, setUser } = useUser();
  const { helpers } = useHelper();

  const { t } = useTranslation('common');

  const id = 'energyBar';

  function handleEnergyRecovery() {
    if (user.myEnergy === user.WELLNESS) {
      ApplicationAlert(t('GAME_FULL_ENERGY')).error();
      return;
    }

    authApi
      .get('/api/recoverenergy')
      .then((response) => {
        setUser(response.data);
        animate({ targetElementId: id, animation: 'tada' });
        return;
      })
      .catch((error) => {
        ApplicationAlert(error.response.data).error();
        return;
      });
  }

  useEffect(() => {
    animate({ targetElementId: 'globalonline', animation: 'flash' });
  }, [helpers]);

  return (
    <>
      {helpers && user && (
        <>
          {/*<Tutorial />*/}
          <div className="border-b border-gray-700" data-testid="mainheader">
            <div
              id="mainheadertutorial"
              className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 flex justify-around sm:justify-between pt-5 pb-3 h-42 sm:h-42"
            >
              <div className="flex justify-start items-start gap-4 sml:gap-10">
                <div className="flex flex-col text-white h-full justify-between">
                  <div className="flex flex-col items-center gap-2">
                    <Link
                      data-testid="messages"
                      to={`/messages/${user.id}`}
                      className="bg-gray-400 bg-opacity-25 py-1 px-2 rounded-lg hover:bg-opacity-40 text-xs outline-none focus:border-white relative"
                    >
                      <img
                        src={fromCDN.fetchStaticImgImage('/header/icons/message', 'svg')}
                        alt="Message"
                        className="h-5 w-5"
                      />
                      {user.UNREADMSG > 0 && (
                        <span className="absolute -top-2 -right-1 grid place-items-center bg-red-600 text-white rounded h-4 w-4 leading-none">
                          {user.UNREADMSG}
                        </span>
                      )}
                    </Link>
                    <Link
                      to={`profile/${user.id}/3`}
                      className="bg-gray-400 bg-opacity-25 py-1 px-2 rounded-lg hover:bg-opacity-40 text-xs outline-none focus:border-white"
                    >
                      <img
                        src={fromCDN.fetchStaticImgImage('/header/icons/settings', 'svg')}
                        alt="Settings"
                        className="h-5 w-5"
                      />
                    </Link>
                    <div className="flex flex-col items-center gap-1">
                      <button
                        data-testid="mainheadertravelsetter"
                        className="rounded-lg shadow hover:shadow-lg bg-gray-400 bg-opacity-25 py-1 px-2 hover:bg-opacity-40 text-xs outline-none focus:border-white"
                        onClick={() => setTravelModal((prevState) => !prevState)}
                      >
                        <img
                          src={fromCDN.fetchStaticImgImage('/header/icons/travel', 'svg')}
                          alt="Airplane"
                          className="h-5 w-5"
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex gap-2 sm:gap-10 flex-col sm:flex-row">
                  <div className="flex flex-col text-white items-center">
                    <div className="flex">
                      {Object.keys(user).length && countryFlags(user.CITIZENSHIP, 'h-4 w-4 absolute z-20 -mt-1')}
                      <Avatar avatarId={user.AVATAR} userId={user.id} />

                      <div className="flex flex-col">
                        <span className="bg-red-900 text-xs font-semibold -ml-3 py-px flex justify-center items-center w-24 clip--background h-5">
                          Lvl {user.Level}
                        </span>

                        <div className="bg-gray-400 bg-opacity-25 relative clip--background overflow-hidden w-full -ml-1">
                          <div className="bg-yellow-500 h-5" style={{ width: `${user.ExpProgress}%` }}>
                            <span className="absolute pl-4 left-1/3 transform -translate-x-1/2 top-1/2 -translate-y-1/2 font-semibold flex items-center drop-shadow-[0_2px_1px_rgba(0,0,0,0.6)]">
                              <StarIcon className="h-4 w-4 text-yellow-300" />
                              <span id={GLOBAL_CONSTANTS.EXPERIENCE_BAR_ID}>{user.CurrentExp}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      {user.ADMINGM > ADMIN_AUTHORIZATION.USER && (
                        <Link
                          to="admin"
                          className=" py-1 px-2 rounded-lg hover:bg-opacity-40 text-xs outline-none focus:border-white"
                        >
                          <EyeIcon className="text-white w-5 h-5 cursor-pointer default-transition" />
                        </Link>
                      )}
                    </div>
                    <span>{user.user}</span>
                    <span>{t('GAME_CURRENT_LOCATION')}</span>
                    <button
                      className="cursor-pointer flex gap-1 items-center hover:font-medium"
                      onClick={() => setTravelModal((prevState) => !prevState)}
                    >
                      <span className="mt-0.5">{countryFlags(user['COUNTRY'])}</span>
                      {helpers.Regions[user.REGION].Name.substr(0, 22)}
                    </button>
                  </div>
                  <div className="flex flex-row md:flex-col lg:flex-col gap-1 text-white">
                    <div className="flex gap-2 items-center">
                      <img
                        src={fromCDN.fetchStaticImgImage('/header/icons/gold', 'svg')}
                        alt="gold"
                        className="h-6 w-6"
                      />
                      <span>{user.GOLD.toFixed(2)}</span>
                    </div>
                    <div className="flex gap-2 items-center">
                      {countryFlags(user.COUNTRY, 'h-6 w-6')}
                      <span>{user[`W_${helpers.Countries[user.COUNTRY].Currency}`].toFixed(2)}</span>
                    </div>
                  </div>
                </div>

                <div className="text-white flex flex-col gap-2">
                  <div
                    className="bg-gray-400 bg-opacity-25 relative clip--background rounded-l-lg overflow-hidden w-30"
                    id={id}
                  >
                    <div
                      className="bg-green-700 h-5 text-center"
                      style={{ width: `${percent(user.WELLNESS, user.myEnergy)}%` }}
                      title={`Energy Level: ${user.WELLNESS}/${user.myEnergy}`}
                    >
                      <span
                        className="absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 font-semibold "
                        style={{ fontSize: 'smaller' }}
                      >
                        {user.WELLNESS} / {user.myEnergy}
                      </span>
                    </div>
                  </div>
                  <button
                    data-testid="recover-energy"
                    className="flex items-center rounded-lg shadow hover:shadow-lg bg-gray-400 bg-opacity-25 py-1 px-2 hover:bg-opacity-40 text-xs outline-none focus:border-white"
                    onClick={handleEnergyRecovery}
                  >
                    <img
                      src={fromCDN.fetchStaticImgImage('/header/icons/food', 'svg')}
                      className="h-5 w-5"
                      alt="Food"
                    />
                    {t('GAME_RECOVER_ENERGY')}
                  </button>
                  <EnergyCalculation user={user} />
                </div>
                <WhoIsOnline />
              </div>

              <ul className="text-gray-200 list-disc hidden lg:block custom-disc text-sm -mt-5">
                <li>
                  {t('GAME_GENERAL_TOTAL_REGISTER')}: {helpers.GLOBAL[0].ACTIVE}
                </li>
                <li>
                  {t('GAME_GENERAL_TODAY_REGISTER')}: {helpers.GLOBAL[0].NEW}
                </li>
                <li id="globalonline">
                  {t('GAME_SOCIETY_ONLINE')}: {helpers.GLOBAL[0].ONLINE}
                </li>
                <li>
                  {t('GAME_DAY')}: {helpers.Game.Day} {t('GAME_TIME')}: {helpers.Game.Time}
                </li>
                <li>
                  {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                  {showLiveTime()}
                </li>
              </ul>
            </div>
          </div>
          {/*<BreadCrumbs/>*/}
          <button
            className="absolute right-1 text-white p-1 rounded-b-lg bg-gray-800"
            onClick={() => setHeaderOpen((prevState) => !prevState)}
          >
            {headerOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 11l7-7 7 7M5 19l7-7 7 7" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
              </svg>
            )}
          </button>
          <Travel open={travelModal} setOpen={setTravelModal} />
        </>
      )}
    </>
  );
};
