import React, { ReactNode, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

interface SocketContextProps {
  socket: Socket | null;
}

interface SocketContextProviderProps {
  children: ReactNode;
}

// Create context with an initial null socket
export const SocketContext = React.createContext<SocketContextProps>({ socket: null });

const SocketContextProvider = ({ children }: SocketContextProviderProps) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const newSocket = io('http://38.242.155.61:5000/', {
      path: '/socket.io', // Ensure this matches your server's path
      transports: ['websocket'], // Enforce WebSocket transport
      reconnection: true, // Enable reconnection
      reconnectionAttempts: 5, // Number of reconnection attempts
      reconnectionDelay: 2000, // Delay between reconnections
    });

    // Set the socket in state
    setSocket(newSocket);

    // Optional: Add listeners for specific events
    newSocket.on('connect', () => {
      console.log('Socket connected');
    });

    newSocket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    // Clean up on component unmount
    return () => {
      newSocket.off();
      newSocket.close();
    };
  }, []);

  // Return the provider with the socket state
  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

export default SocketContextProvider;
