import { fromCDN } from '../../helpers/Images';

const feedBackground = fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg');
const userDetailsBg = fromCDN.fetchStaticGameImage('/v2/backgrounds/header2', 'svg');

export const FeedBackground = () => {
  return {
    backgroundImage: `url(${feedBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
};

export const UserDetailsBackground = () => {
  return {
    backgroundImage: `url(${userDetailsBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
};
