import { CompareTwoInputsIfTheyAreEqualProps } from '../shared-types/types';

export const compareTwoInputsIfTheyAreEqual = (Props: CompareTwoInputsIfTheyAreEqualProps): boolean => {
  return Props.value1 === Props.value2;
};

export const numberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const deepKeyEqual = (obj1: Record<any, any>, obj2: Record<any, any>): boolean => {
  const keysA = Object.keys(obj1);
  const keysB = Object.keys(obj2);

  let c;
  for (let key of keysA) {
    // c checks whether the key is part of keysB array
    c = keysB[keysB.indexOf(key)];
    if (key !== c) {
      return false;
    }
    if (typeof obj1[key] === 'object' && typeof obj2[c] === 'object' && obj1[key] !== null && obj2[c] !== null) {
      return deepKeyEqual(obj1[key], obj2[c]);
    }
  }
  return true;
};
