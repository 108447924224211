import { useTranslation } from 'react-i18next';
import { authApi } from '../../../api';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { useUser } from '../../../hooks/use-user';

export default function LeaveParty({ partyId, setterForParent }) {
  const { t } = useTranslation('common');
  const { user, setUser } = useUser();

  const handleLeaveParty = () => {
    authApi
      .post('/api/parties/leave/' + partyId)
      .then((resp) => {
        setUser(resp.data.USER);
        setterForParent(resp.data.PARTY);
        return ApplicationAlert(t('GAME_SUCCESS')).success();
      })
      .catch((err) => {});
  };
  return (
    <>
      {Number(user.PARTY) !== 0 && (
        <button onClick={() => handleLeaveParty()} className={'relative default-red-button mx-auto'}>
          {t('GAME_UNIT_LEAVE_PARTY')}
        </button>
      )}
    </>
  );
}
