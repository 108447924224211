import { AttackerParentStyle, DefenderParentStyle } from './Styles/BattlefieldStyles';
import { countryFlags } from '../../helpers/Helpers';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useHelper } from '../../hooks/use-helper';
import { BattlefieldTypesApiProps } from '../../shared-types/battlefield-types';
interface BattlefieldCountryIndicatorsProps {
  battleDetails: BattlefieldTypesApiProps['BATTLE'];
  agressorAllies: string[] | [];
  defenderAllies: string[] | [];
}
export const BattleFieldCountryIndicators = ({
  battleDetails,
  agressorAllies,
  defenderAllies,
}: BattlefieldCountryIndicatorsProps) => {
  const { helpers } = useHelper();
  let navigate = useNavigate();
  return (
    <>
      <div className="absolute w-32 h-20 left-1 top-2" style={AttackerParentStyle()}>
        <div
          data-tip={true}
          data-for="battleagresorname"
          className="absolute top-4 left-7 cursor-pointer"
          onClick={() => {
            navigate(`/country/society/${battleDetails['AGRESOR'] ?? 0}`);
          }}
        >
          {countryFlags(battleDetails['AGRESOR'], 'mx-auto w-12 h-12 ', true)}
          <ReactTooltip id="battleagresorname" effect="solid" data-html={true}>
            {helpers.Countries[battleDetails['AGRESOR']].Name}
          </ReactTooltip>
        </div>
        <div
          data-tip={true}
          data-for="battleagresorallies"
          className="absolute top-10 right-4 font-bold bg-blue-600 rounded text-gray-300 p-1 cursor-pointer"
        >
          {agressorAllies.length ? agressorAllies.length : 0}
          <ReactTooltip id="battleagresorallies" effect="solid" data-html={true}>
            <div className="grid grid-cols-1 gap-2">
              {agressorAllies.map((item, key) => {
                if (item !== '') {
                  return (
                    <div className="inline-flex">
                      <span>{countryFlags(Number(item), 'w-5 h-5 mx-auto')}</span>
                      <span className="ml-0.5">{helpers.Countries[Number(item)].Name}</span>
                    </div>
                  );
                }
              })}
            </div>
          </ReactTooltip>
        </div>
      </div>

      <div className="absolute w-32 h-20 right-1 top-2 z-10" style={DefenderParentStyle()}>
        <div
          data-tip={true}
          data-for="battledefendername"
          className="absolute top-4 right-6 cursor-pointer"
          onClick={() => {
            navigate(`/country/society/${battleDetails['DEFENDER'] ?? 0} `);
          }}
        >
          {countryFlags(battleDetails['DEFENDER'], 'w-12 h-12 ', true)}
          <ReactTooltip id="battledefendername" effect="solid" data-html={true}>
            {helpers.Countries[battleDetails['DEFENDER']].Name}
          </ReactTooltip>
        </div>
        <div
          data-tip={true}
          data-for="battledefenderallies"
          className="absolute top-10 left-5 font-bold bg-blue-600 rounded text-gray-300 p-1 cursor-pointer"
        >
          {defenderAllies.length ? defenderAllies.length : 0}
          <ReactTooltip id="battledefenderallies" effect="solid" data-html={true}>
            <div className="grid grid-cols-1 gap-2">
              {defenderAllies.map((item, key) => {
                if (item !== '') {
                  return (
                    <div className="inline-flex" key={key}>
                      <span>{countryFlags(Number(item), 'w-5 h-5 mx-auto')}</span>
                      <span className="ml-0.5">{helpers.Countries[Number(item)].Name}</span>
                    </div>
                  );
                }
              })}
            </div>
          </ReactTooltip>
        </div>
      </div>
    </>
  );
};
