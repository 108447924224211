import { Tab } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { joinClasses } from '../../../helpers/joinClasses';

export interface TabProps {
  id: number;
  'data-testid'?: string;
  text: string;
  plainText?: string;
  isHidden?: () => boolean;
}

export interface TabListProps {
  tabs: TabProps[];
  className?: string;
}

const TabList = ({ tabs, className }: TabListProps) => {
  const { t } = useTranslation('common');

  return (
    <Tab.List
      className={`w-full max-w-md text-left flex rounded-lg bg-zinc-500/70 divide-x divide-zinc-500/90 overflow-hidden ${
        className ?? ''
      }`}
    >
      {tabs.map((tab) => {
        if (tab.isHidden && !tab.isHidden()) return null;

        return (
          <Tab
            key={tab.id}
            {...(tab['data-testid'] ? { 'data-testid': tab['data-testid'] } : {})}
            className={({ selected }) =>
              joinClasses(
                'w-full py-3 text-sm font-medium text-white',
                'ring-white ring-opacity-60 ring-offset-edom-darker-yellow focus:outline-none',
                selected ? 'bg-green-700' : 'hover:bg-white/[0.2]',
              )
            }
          >
            {tab.plainText ?? t(tab.text)}
          </Tab>
        );
      })}
    </Tab.List>
  );
};

export default TabList;
