import { Route, Routes } from 'react-router-dom';
import { FeedBackground } from '../assets/Styles/Styles';
import { Loader } from '../components/Loader';
import { MyBuildings } from '../containers/MyBuildings/MyBuildings';
import { BusinessCenter } from '../containers/BusinessCenter/BusinessCenter';
import { Marketplace } from '../containers/BusinessCenter/Marketplace/Marketplace';
import { JobMarket } from '../containers/BusinessCenter/JobMarket/JobMarket';
import { Monetary } from '../containers/BusinessCenter/Monetary/Monetary';
import Storage from '../containers/MyBuildings/Storage/Storage';
import Workout from '../containers/MyBuildings/Workout/Workout';
import Companies from '../containers/MyBuildings/Companies/Companies';
import { ManageEmployees } from '../containers/MyBuildings/Companies/Employment/ManageEmployees';
import Boosts from '../containers/MyBuildings/Boosts/Boosts';
import Profile from '../containers/Profile/Profile';
import Messages from '../containers/Messages/Messages';
import ReadMessages from '../containers/Messages/ReadMessages';
import { Battles } from '../containers/Battles/Battles';
import { BattleFieldContextProvider } from '../containers/Battlefield/context/battlefield-context-provider';
import { BattleField } from '../containers/Battlefield/Battlefield';
import { Community } from '../containers/Community/Community';
import { WithCountryTabs } from '../containers/Community/Country/CountryTabs/WithCountryTabs';
import Society from '../containers/Community/Country/Society';
import Economy from '../containers/Community/Country/Economy';
import Politics from '../containers/Community/Country/Politics/Politics';
import Military from '../containers/Community/Country/Military/Military';
import Administration from '../containers/Community/Country/Administration/Administration';
import MilitaryUnit from '../containers/Community/Unit/MilitaryUnit';
import { NoMilitaryUnit } from '../containers/Community/Unit/NoMilitaryUnit';
import PoliticalParty from '../containers/Community/Party/PoliticalParty';
import CreateParty from '../containers/Community/Party/CreateParty';
import { Newspaper } from '../containers/Community/Journalism/Newspaper';
import { CreateNewspaper } from '../containers/Community/Journalism/CreateNewspaper';
import { CreateArticle } from '../containers/Community/Journalism/CreateArticle';
import { Article } from '../containers/Community/Journalism/Article';
import Elections from '../containers/Community/Elections/Elections';
import CongressElections from '../containers/Community/Elections/Congress/CongressElections';
import PartyElections from '../containers/Community/Elections/Party/PartyElections';
import PresidentialElections from '../containers/Community/Elections/Presidency/PresidentialElections';
import MapView from '../containers/Map/MapView';
import { RankingsMain } from '../containers/Community/Rankings/RankingsMain';
import Rules from '../containers/Footer/Rules';
import { Support } from '../containers/Footer/Support';
import PrivacyPolicy from '../containers/Footer/PrivacyPolicy';
import TermsOfUse from '../containers/Footer/TermsOfUse';
import { AboutUs } from '../containers/Footer/AboutUs';
import LatestUpdates from '../containers/Footer/LatestUpdates';
import AdministrationPanel from '../containers/Administration/AdministrationPanel';
import { NotFound } from '../components/NotFound';
import { FC, lazy, Suspense } from 'react';

const Feeds = lazy(() => import('../containers/Feed/Feed'));

export const AuthDashboard: FC = () => {
  return (
    <Routes>
      <Route
        path="/feed"
        element={
          <Suspense
            fallback={
              <main className="min-h-screen" style={FeedBackground()}>
                <Loader fullScreen={true} />
              </main>
            }
          >
            <Feeds />
          </Suspense>
        }
      />

      <Route path="/business">
        <Route index={true} element={<BusinessCenter />} />
        <Route path="marketplace/:countryId/:itemType/:quality/:page" element={<Marketplace />} />
        <Route path="jobs/:countryid/:page" element={<JobMarket />} />
        <Route path="monetary-market/:countryId" element={<Monetary />} />
      </Route>

      <Route path="/buildings" element={<MyBuildings />} />
      <Route path="/buildings/storage" element={<Storage />} />
      <Route path="/buildings/workout" element={<Workout />} />
      <Route path="/buildings/companies" element={<Companies />} />
      <Route path="/buildings/manage-employees" element={<ManageEmployees />} />
      <Route path="/buildings/boosts" element={<Boosts />} />

      <Route path="/profile/:id" element={<Profile />} />
      <Route path="/profile/:id/:tabId" element={<Profile />} />
      <Route path="/messages/:id" element={<Messages />} />
      <Route path="/messages/read/:id" element={<ReadMessages />} />
      <Route path="/battles" element={<Battles />} />
      <Route
        path="/battlefield/:id/:side"
        element={
          <BattleFieldContextProvider>
            <BattleField />
          </BattleFieldContextProvider>
        }
      />
      <Route path="/community" element={<Community />} />
      <Route path="/country" element={<WithCountryTabs />}>
        <Route path="society/:id/" element={<Society />} />
        <Route path="economy/:id/" element={<Economy />} />
        <Route path="politics/:id/" element={<Politics />} />
        <Route path="military/:id/" element={<Military />} />
        <Route path="administration/:id/" element={<Administration />} />
      </Route>

      <Route path="/military-unit/:id/" element={<MilitaryUnit />} />
      <Route path="/military-unit/create" element={<NoMilitaryUnit />} />
      <Route path="/party/:id/" element={<PoliticalParty />} />
      <Route path="/party/create" element={<CreateParty />} />
      <Route path="/newspaper/:id" element={<Newspaper />} />
      <Route path="/newspaper/create/new" element={<CreateNewspaper />} />
      <Route path="/newspaper/create-article/:id" element={<CreateArticle />} />
      <Route path="/article/:id" element={<Article />} />
      <Route path="/elections/:countryId" element={<Elections />} />
      <Route path="/elections/:countryId/congress" element={<CongressElections />} />
      <Route path="/elections/:countryId/party" element={<PartyElections />} />
      <Route path="/elections/:countryId/presidential" element={<PresidentialElections />} />
      <Route path="/map" element={<MapView />} />

      <Route path="/rankings/:rankingType/:frompage/:topage/:countryid" element={<RankingsMain />} />

      <Route path="/rules" element={<Rules />} />
      <Route path="/support" element={<Support />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/tos" element={<TermsOfUse />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/latest-updates" element={<LatestUpdates />} />
      <Route path="/admin" element={<AdministrationPanel />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
