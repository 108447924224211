import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../../components/SectionHeader';
import { GreenButton } from '../../../components/Buttons/GreenButton';
import { Info } from '../../../components/Info';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { DisabledButton } from '../../../components/Buttons/DisabledButton';
import ReclamationIcon from '../../Util/Icons/ReclamationIcon';
import Ability from './Ability';
import WorkoutUpgrade from './WorkoutUpgrade';
import { Loader } from '../../../components/Loader';
import { authApi } from '../../../api';
import { Stars } from '../../Util/Icons/star/Stars';

import RewardAlert from '../../Util/Reward/RewardAlert';
import DoubleUpIcon from '../../Util/Icons/DoubleUpIcon';
import { FeedBackground } from '../../../assets/Styles/Styles';
import { fromCDN } from '../../../helpers/Images';
import { useHelper } from '../../../hooks/use-helper';
import { useUser } from '../../../hooks/use-user';

export default function Workout() {
  const { user, setUser } = useUser();
  const { helpers } = useHelper();
  const { t } = useTranslation('common');

  const [isUpgradeRequested, setIsUpgradeRequested] = useState(null);
  const [canTrain, setCanTrain] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  let trainingGroundImage = 'tg4_q' + user.TG1;

  function train() {
    if (!canTrain) {
      return false;
    }

    setIsLoading(true);
    authApi
      .post('/api/trainstrength', { train: 1 })
      .then((response) => {
        ApplicationAlert(<RewardAlert rewards={response.data} helper={helpers} t={t} />).success();
        setUser(response.data.USER);
        setCanTrain(false);
        setIsLoading(false);
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (parseInt(user.TG1_D) === helpers.Game.Day || user.STRENGTH >= helpers.Game.MaximumStrength) {
      setCanTrain(false);
    }
  }, []);

  return (
    <>
      <div className="min-h-screen max-w-13xl mx-auto bg-fixed" style={FeedBackground()}>
        <div className="max-w-7xl mx-auto py-2">
          <SectionHeader title={t('GAME_WORKOUT')}>
            <div className="h-8 w-8 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]">
              <img src={fromCDN.fetchStaticGameImage('/v2/Icons/workout', 'svg')}></img>
            </div>
          </SectionHeader>

          <div className="relative grid grid-cols-1 gap-32 p-2 mb-8 mt-10 lg:grid lg:grid-cols-2 rounded-lg">
            {isUpgradeRequested ? (
              isUpgradeRequested
            ) : (
              <div className="w-2/2 sm:w-2/2 bg-edom-dark-red/90 py-10 px-6 rounded-lg shadow-[inset_0_-1px_5px_rgba(0,0,0,0.6)]">
                <div className="justify-center">
                  <div className="relative flex gap-4">
                    <img
                      src={fromCDN.fetchStaticGameImage(`/tg/${trainingGroundImage}`)}
                      className="relative rounded-lg -top-6 -mb-4 h-20 w-20"
                      loading="lazy"
                    />
                    <div className="flex flex-col w-full">
                      <div className="flex flex-row justify-between">
                        <div className="flex items-center gap-1">
                          <span className="text-base font-medium text-gray-200">{t('GAME_BOOTCAMP')}</span>
                          <span className="text-white bg-gray-800 rounded py-px px-2">Q{user.TG1}</span>
                          {user.TG1 < 4 && (
                            <span
                              className="cursor-pointer default-transition ml-2"
                              onClick={() =>
                                setIsUpgradeRequested(<WorkoutUpgrade setIsUpgradeRequested={setIsUpgradeRequested} />)
                              }
                            >
                              <DoubleUpIcon className="h-6 w-6 mx-auto text-gray-200 bg-yellow-400/60 hover:bg-yellow-400/70 rounded animate__animated animate__flipInX" />
                            </span>
                          )}
                        </div>

                        <a className="text-gray-500" href="#">
                          <i className="fa-solid fa-trash"></i>
                        </a>
                      </div>
                      <div className="text-gray-400 text-sm flex gap-1 items-center">
                        <Stars totalStars={user.TG1} />
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2">
                    <div className="justify-center text-left mt-5 mb-2 h-8" onClick={train}>
                      {isLoading ? (
                        <Loader className="w-10 h-10" circleStrokeWidth="5" customPaddingForTheCircle="p-0" />
                      ) : (
                        <>
                          {canTrain ? (
                            <GreenButton text={t('GAME_WORKOUT')} />
                          ) : (
                            <DisabledButton
                              icon={<ReclamationIcon />}
                              text={
                                user.STRENGTH >= helpers.Game.MaximumStrength
                                  ? t('GAME_FULL_STRENGTH')
                                  : t('GAME_TRAINED')
                              }
                            />
                          )}
                        </>
                      )}

                      <div className="flex ml-4 sm:ml-6 obsolute font-medium">
                        <div className="text-center">
                          <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                            <img
                              className="h-6 w-6 sm:h-8 sm:w-8 mx-auto"
                              src={fromCDN.fetchStaticImage('/game/icons/strength', 'png')}
                              alt="Workflow"
                            />
                          </span>
                          <span className="sm:text-sm text-gray-200 bg-green-600/70 p-1.5 rounded-lg">
                            +{helpers.TG[1]['STR'][user.TG1]}
                          </span>
                        </div>
                        <div className="text-center">
                          <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                            <img
                              className="h-6 w-6 sm:h-8 sm:w-8 mx-auto"
                              src={fromCDN.fetchStaticImage('/game/icons/energy-s', 'png')}
                              alt="Workflow"
                            />
                          </span>
                          <span className="sm:text-sm text-gray-200 bg-red-600/70 p-1.5 rounded-lg">
                            -{helpers.TG[1]['HP'][user.TG1]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <img
                        className="w-8 h-8 bg-black/40 rounded-t p-1 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]"
                        src={fromCDN.fetchStaticImage('/game/icons/info', 'svg')}
                      ></img>
                      <Info text={t('GAME_WORKOUTINFO')} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Ability />
          </div>
        </div>
      </div>
    </>
  );
}
