import { imageAsBackground } from '../../helpers/image-as-background';

import { RANKINGS_PROPS } from '../../constants/enum';

import { fromCDN } from '../../helpers/Images';
import { useUser } from '../../hooks/use-user';
import { CardsContainer } from '../../components/Card/CardsContainer';

export const Community = () => {
  const { user } = useUser();
  const cards = [
    {
      to: `/country/society/${user.COUNTRY}`,
      imgUrl: fromCDN.fetchStaticGameImage('/icons/country', 'svg'),
      title: 'GAME_COMMUNITY_COUNTRY',
      'data-testid': 'society',
      color: 'bg-edom-dark-red',
      infoText: 'GAME_COMMUNITY_MENU_MY_COUNTRY_DESCRIPTION',
    },
    {
      to: `/military-unit/${user.UNIT}`,
      imgUrl: fromCDN.fetchStaticGameImage('/icons/unit', 'svg'),
      title: 'GAME_MILITARY_UNIT',
      'data-testid': 'unit',
      color: 'bg-edom-dark-yellow',
      infoText: 'GAME_COMMUNITY_MENU_MILITARY_UNIT_DESCRIPTION',
    },
    {
      to: `/newspaper/${user.NEWSPAPER}`,
      imgUrl: fromCDN.fetchStaticGameImage('/icons/newspaper', 'svg'),
      title: 'GAME_COMMUNITY_NEWSPAPER',
      'data-testid': 'newspaper',
      color: 'bg-edom-dark-blue',
      infoText: 'GAME_COMMUNITY_MENU_MY_NEWSPAPER_DESCRIPTION',
    },
    {
      to: `/party/${user.PARTY}`,
      imgUrl: fromCDN.fetchStaticGameImage('/icons/party', 'svg'),
      title: 'GAME_COMMUNITY_PARTY',
      'data-testid': 'party',
      color: 'bg-edom-dark-purple',
      infoText: 'GAME_COMMUNITY_MENU_MY_PARTY_DESCRIPTION',
    },
    {
      to: `/rankings/${RANKINGS_PROPS.TYPE}/${RANKINGS_PROPS.FROM}/${RANKINGS_PROPS.TO}/${RANKINGS_PROPS.COUNTRY_ID}`,
      imgUrl: fromCDN.fetchStaticGameImage('/icons/ranking', 'svg'),
      imgClass: 'w-2/4',
      title: 'GAME_COMMUNITY_RANKINGS',
      'data-testid': 'rankings',
      color: 'bg-edom-darker-yellow',
      infoText: 'GAME_COMMUNITY_MENU_RANKINGS_DESCRIPTION',
    }, 
    {
      to: `/elections/${user.CITIZENSHIP}`,
      imgUrl: fromCDN.fetchStaticGameImage('/icons/elections', 'svg'),
      imgClass: 'w-2/4',
      title: 'GAME_COMMUNITY_ELECTIONS',
      'data-testid': 'elections',
      color: 'bg-edom-dark-emerald',
      infoText: 'GAME_COMMUNITY_MENU_ELECTIONS_DESCRIPTION',
    },
  ];

  return <CardsContainer cards={cards} style={imageAsBackground(fromCDN.fetchStaticImage('t_electionsbg2', 'svg'))} />;
};
