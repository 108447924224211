import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StarIcon from '../../Util/Icons/star/StarIcon';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import ReactTooltip from 'react-tooltip';
import { inputButtonClass, lightTransition, themeButtonClass } from '../../../constants/constants';
import ApplicationAlert from '../../../components/ApplicationAlert';
import Select from 'react-select';
import { authApi } from '../../../api';
import { fromCDN } from '../../../helpers/Images';
import { useUser } from '../../../hooks/use-user';

export default function CreateParty({ setCreateUnit = () => {} }) {
  let navigate = useNavigate();
  const { user, setUser } = useUser();
  const { t } = useTranslation('common');
  const [economySelection, setEconomySelection] = useState();
  const [socialSelection, setSocialSelection] = useState();

  const economicalSelectionOptions = [
    {
      value: 1,
      label: 'Far-left',
    },
    {
      value: 2,
      label: 'Center-left',
    },
    {
      value: 3,
      label: 'Center',
    },
    {
      value: 4,
      label: 'Center-right',
    },
    {
      value: 5,
      label: 'Far-right',
    },
  ];

  const socialSelectionOptions = [
    {
      value: 1,
      label: 'Totalitarian',
    },
    {
      value: 2,
      label: 'Authoritarian',
    },
    {
      value: 3,
      label: 'Libertarian',
    },
    {
      value: 4,
      label: 'Anarchist',
    },
  ];

  const handleEcoSelection = (selectedValue) => {
    setEconomySelection(selectedValue.value);
  };

  const handleSocialSelection = (selectedValue) => {
    setSocialSelection(selectedValue.value);
  };
  const [unitName, setUnitName] = useState('eDominators');

  const createParty = () => {
    authApi
      .post('/api/parties/create', {
        name: unitName,
        economical: economySelection,
        social: socialSelection,
      })
      .then((response) => {
        setUser(response.data);
        navigate('/party/' + response.data.PARTY);
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });
  };

  useEffect(() => {}, [unitName]);

  return (
    <>
      <div className={'justify-center text-center m-3 text-white'}>
        <p className={'font-bold '}>{t('GAME_UNIT_REQUIREMENTS_DESC')}</p>
        <ArrowLeftIcon
          data-tip
          data-for={'returnbacktojoin'}
          className={'h-10 w-10 mx-auto cursor-pointer text-green-600 ' + lightTransition}
          onClick={() => setCreateUnit(null)}
        />
        <ReactTooltip id={'returnbacktojoin'} effect="solid" data-html={true}>
          {t('GAME_BACK_TO_UNIT_LIST')}
        </ReactTooltip>
      </div>
      <div className={'justify-center text-center grid grid-cols-2 gap-8 m-5'}>
        <div className={'bg-yellow-400 p-2 rounded shadow-md'}>
          <p className={'bg-gray-200 p-1 font-bold shadow-inner rounded'}>{t('GAME_UNIT_REQUIREMENTS_1')}</p>
          <p className={'inline-flex mt-2'}>
            <span className={'font-extrabold text-big'}>40</span>{' '}
            <img className={'h-5 w-5 mx-auto ml-1'} src={fromCDN.fetchStaticGameImage('/icons/gold-icon')} />
          </p>
        </div>

        <div className={'bg-gray-400 p-2 rounded shadow-md'}>
          <p className={'bg-gray-200 p-1 font-bold shadow-inner rounded'}>{t('GAME_EXPERIENCE_AND_LEVEL')}</p>
          <p className={'inline-flex mt-2'}>
            <span className={'font-extrabold text-big'}>16 </span>
            <sup>
              <StarIcon className={'h-5 w-5 mx-auto text-red-600'} />
            </sup>
          </p>
        </div>
      </div>
      <div className={'text-center justify-center mx-auto'}>
        <div className={'m-5'}>
          <Select
            className={'basic-select w-48 mx-3 inline-block'}
            classNamePrefix={'select'}
            onChange={handleEcoSelection}
            isSearchable
            options={economicalSelectionOptions}
          />
          <Select
            className={'basic-select w-48 mx-3 inline-block'}
            onChange={handleSocialSelection}
            isSearchable
            options={socialSelectionOptions}
          />
        </div>

        <p className={'w-48 m-5 mx-auto'}>
          <input
            className={inputButtonClass + ' '}
            placeholder={t('GAME_PARTY_REQUIREMENTS_2')}
            onChange={(e) => setUnitName(e.target.value)}
          />
        </p>
        <p>
          <button onClick={() => createParty()} className={themeButtonClass + ' bg-green-400'}>
            {t('GAME_PARTY_CREATE_PARTY')}
          </button>
        </p>
      </div>
    </>
  );
}
