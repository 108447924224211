import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

export default function Charts({
  chartPosition = 'top',
  labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  dataInput = [],
}) {
  const { t, i18n } = useTranslation('common');

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  ChartJS.defaults.color = 'rgb(196,182,137)';
  ChartJS.defaults.color = 'rgb(196,182,137)';
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: chartPosition,
      },
      title: {
        display: true,
        text: t('GAME_DAILY_TAX_REVENUE'),
        backgroundColor: 'rgb(234 179 8)',
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Gold',
        data: labels.map((item, key) => {
          return dataInput[item] ? dataInput[item]['Gold'] : 0;
        }),
        color: 'rgb(234 179 8)',
        backgroundColor: 'rgb(234 179 8)',
      },
      {
        label: 'Currency',
        data: labels.map((item, key) => {
          return dataInput[item] ? dataInput[item]['CC'] : 0;
        }),
        color: 'rgb(234 179 8)',
        backgroundColor: 'rgb(21 94 117)',
      },
    ],
  };

  return <Bar options={options} data={data} />;
}
