import common_en from './en/common.json';
import common_de from './de/common.json';
import common_si from './si/common.json';
import common_tr from './tr/common.json';

export const resources = {
  en: {
    common: common_en, // 'common' is our custom namespace
  },
  de: {
    common: common_de,
  },
  si: {
    common: common_si,
  },
  tr: {
    common: common_tr,
  },
};
