import { useTranslation } from 'react-i18next';

import { CURRENCY } from '../../../../constants/community_enum';
import { fromCDN } from '../../../../helpers/Images';
import { UserProps } from '../../../../shared-types/user-type';
import { OfferProps } from '../Offer';
import { normalizeUserName } from '../../../../helpers/normalize';

interface MyOfferCardProps {
  user: UserProps;
  offer: OfferProps;
  onRemove: (id: number) => void;
}

const goldImage = fromCDN.fetchStaticImgImage('/header/icons/gold', 'svg');

export const MyOfferCard = ({ user, offer, onRemove }: MyOfferCardProps) => {
  const { t } = useTranslation('common');

  const sellingIcon =
    CURRENCY.GOLD === offer.OFFER ? goldImage : fromCDN.fetchStaticGameImage(`/flagscountry/${offer.OFFER}`);
  const rateIcon =
    CURRENCY.GOLD === offer.OFFER ? fromCDN.fetchStaticGameImage(`/flagscountry/${offer.FOR}`) : goldImage;
  const value = offer.MONEY * offer.RATE;

  if (CURRENCY.GOLD === offer.OFFER) {
    return (
      <div className="border-2 border-yellow-500/90 bg-gradient-to-r from-zinc-800/90 to-zinc-600/90 py-5 rounded-lg text-center shadow-[inset_0_-1px_5px_rgba(0,0,0,0.6)]">
        <div>
          <img className="h-12 w-12 ml-4 absolute" src={goldImage} />
          <img
            className="w-12 h-12 mx-auto rounded-lg mb-1 shadow-md shadow-black"
            src={fromCDN.fetchUserAvatar(user.AVATAR)}
          />
        </div>
        <span className="font-semibold">{normalizeUserName(user.user)}</span>

        <div className="flex gap-x-1 justify-center mt-3 mb-2 mx-2 p-2 rounded-lg bg-black/50">
          <span>{offer.MONEY}</span>
          <img className="h-5 w-5" src={sellingIcon} />
        </div>
        <div className="p-2 rounded-lg bg-black/50 mx-2">
          <span className="font-medium">{t('EXCHANGE_RATE')}</span>
          <div className="flex justify-center mt-2 text-gray-200 gap-x-1">
            <span className="text-red-400">1</span>
            <img className="h-5 w-5" src={sellingIcon} />
          </div>
          <img src={fromCDN.fetchStaticImage('t_arrowdownwhite', 'svg')} className="h-3 w-3 opacity-20 mx-auto my-1" />
          <div className="flex justify-center text-gray-200 gap-x-1">
            <span className="text-green-400">{offer.RATE}</span>
            <img className="h-5 w-5" src={rateIcon} />
          </div>
        </div>
        <button
          className="mt-4 bg-red-700 text-neutral-100 hover:bg-red-600 text-sm font-semibold transition ease-in-out duration-200 hover:scale-105 p-2 rounded outline-none focus:outline-none"
          onClick={() => {
            onRemove(offer.ID);
          }}
        >
          {t('GAME_REMOVE')}
        </button>
        <div className="mt-1 text-green-400">
          + {value}
          <img className="h-5 w-5 ml-1 inline" src={rateIcon} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="border-2 border-green-500/90 bg-gradient-to-r from-zinc-800/90 to-zinc-600/90 py-5 rounded-lg text-center shadow-[inset_0_-1px_5px_rgba(0,0,0,0.6)]">
        <div>
          <img className="h-10 w-10 ml-4 absolute" src={fromCDN.fetchStaticImage('t_cc', 'svg')} />
          <img
            className="w-12 h-12 mx-auto rounded-lg mb-1 shadow-md shadow-black"
            src={fromCDN.fetchUserAvatar(user.AVATAR)}
          />
        </div>
        <span className="font-semibold">{normalizeUserName(user.user)}</span>

        <div className="flex gap-x-1 justify-center mt-3 mb-2 mx-2 p-2 rounded-lg bg-black/50">
          <span>{offer.MONEY}</span>
          <img className="h-5 w-5" src={sellingIcon} />
        </div>
        <div className="p-2 rounded-lg bg-black/50 mx-2">
          <span className="font-medium">{t('EXCHANGE_RATE')}</span>
          <div className="flex justify-center mt-2 text-gray-200 gap-x-1">
            <span className="text-red-400">1</span>
            <img className="h-5 w-5" src={sellingIcon} />
          </div>
          <img src={fromCDN.fetchStaticImage('t_arrowdownwhite', 'svg')} className="h-3 w-3 opacity-20 mx-auto my-1" />
          <div className="flex justify-center text-gray-200 gap-x-1">
            <span className="text-green-400">{offer.RATE}</span>
            <img className="h-5 w-5" src={rateIcon} />
          </div>
        </div>
        <button
          className="mt-4 bg-red-700 text-neutral-100 hover:bg-red-600 text-sm font-semibold transition ease-in-out duration-200 hover:scale-105 p-2 rounded outline-none focus:outline-none"
          onClick={() => {
            onRemove(offer.ID);
          }}
        >
          {t('GAME_REMOVE')}
        </button>
        <div className="mt-1 text-green-400">
          + {value}
          <img className="h-5 w-5 ml-1 inline" src={rateIcon} />
        </div>
      </div>
    );
  }
};
