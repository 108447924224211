import { AxiosResponse } from 'axios';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { authApi } from '../../../api';
import { MESSAGE_MAX_LENGTH } from '../../../constants/enum';
import { ArticleProps } from '../../../shared-types/NewspaperTypes';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { RemainingCharactersHint } from '../../../components/RemainingCharactersHint';

export interface PostCommentProps {
  articleDetails: ArticleProps;
  setArticleDetails: (updateDetails: ArticleProps) => void;
}

export const PostComment = ({ articleDetails, setArticleDetails }: PostCommentProps) => {
  const { t } = useTranslation('common');
  const [comment, setComment] = useState('');

  const handleCommentSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!comment.trim().length) {
      ApplicationAlert(t('GAME_EMPTY_COMMENT')).error();
      return;
    }

    authApi
      .post('/api/article/comment/post', {
        articleId: articleDetails.ID,
        comment,
      })
      .then((resp: AxiosResponse<ArticleProps>) => {
        setArticleDetails(resp.data);
        ApplicationAlert(t('GAME_SUCCESS')).success();
        return;
      })
      .catch((err) => {
        ApplicationAlert(t(err.response.data)).error();
      });

    setComment('');
  };

  return (
    <form className="m-5" onSubmit={handleCommentSubmit}>
      <textarea
        className="default-dark-input h-40 bg-gray-700 resize-none mb-2"
        value={comment}
        maxLength={MESSAGE_MAX_LENGTH}
        onChange={(e) => {
          setComment(e.target.value);
        }}
      />
      <RemainingCharactersHint text={comment} />
      <button className="default-dark-save-button text-gray-200 !mt-4" type="submit">
        {t('GAME_COMMENT')}
      </button>
    </form>
  );
};
