import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { countryFlags } from '../../helpers/Helpers';
import { Loader } from '../../components/Loader';
import { useLocation } from 'react-router-dom';
import { BattleCard } from './BattleCard';
import { FeedBackground } from '../../assets/Styles/Styles';
import { useHelper } from '../../hooks/use-helper';
import { useUser } from '../../hooks/use-user';
import { AppPageTemplate } from '../../components/AppPageTemplate';
import { BattlesProps } from '../../shared-types/battles-types';
import { authApi } from '../../api';

export const Battles = () => {
  const location = useLocation();
  const { t } = useTranslation('common');
  const { helpers } = useHelper();
  const [battles, setBattles] = useState<BattlesProps>([]);
  const { user } = useUser();
  const isLoading = <Loader />;

  let _myBattles: any[] = [];

  async function getBattles() {
    await authApi
      .get('/api/battles')
      .then((response: AxiosResponse<BattlesProps>) => {
        setBattles(response.data);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getBattles();
  }, []);

  return (
    <>
      <AppPageTemplate
        mainStyle={FeedBackground()}
        section={{
          title: t('GAME_BATTLES'),
        }}
        children={
          <>
            <div className=" justify-center text-center">
              <div className="inline-flex mt-5 text-gray-50">
                <span data-testid="battlescountryflag">{countryFlags(user.COUNTRY, 'mx-auto')}</span>
                <span className="ml-1">{helpers.Countries[user.COUNTRY].Name}</span>
                <span className="ml-3">{t('GAME_BATTLES_CAMPAIGNS_ALLIES')}</span>
              </div>
            </div>
            <div className="text-center justify-center mt-5">{battles ? '' : isLoading}</div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 m-5 gap-8" data-testid="country-battles">
              {/* eslint-disable  */}
              {battles
                ? Object.values(battles).map((item, key) => {
                    if (
                      item['AGRESOR'] === user.COUNTRY ||
                      item['DEFENDER'] === user.COUNTRY ||
                      item.AlliesAttackerArr.find((elem: never) => parseInt(elem) === user.COUNTRY) ||
                      item.AlliesDefenderArr.find((elem: never) => parseInt(elem) === user.COUNTRY)
                    ) {
                      _myBattles.push(item.ID);
                      // @ts-ignore
                      return <BattleCard key={key} item={item} isUnitCaptain={location.state} />;
                    }
                  })
                : ''}
              {/* eslint-enable  */}
            </div>

            <div className=" justify-center text-center ">
              <div className="inline-flex mt-5">
                <span data-testid="battlesworldcampaigns" className="ml-3 text-white">
                  {t('GAME_BATTLES_ALL_CAMPAIGNS')}
                </span>
              </div>
              <div className="text-center justify-center mt-5">{battles ? '' : isLoading}</div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 m-5 gap-8" data-testid="world-battles">
                {battles
                  ? Object.values(battles).map((item, key) => {
                      if (!_myBattles.includes(item.ID)) {
                        return <BattleCard key={key} item={item} isUnitCaptain={{ unitId: 0 }} />;
                      }
                    })
                  : ''}
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
