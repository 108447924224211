import { Link, NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

export const HeaderNavLink = ({ src, to, text }) => {
  return (
    <NavLink
      data-testid={to}
      to={to}
      className={({ isActive }) =>
        isActive
          ? 'py-3 px-3 duration-100 rounded-lg bg-gray-700 text-white'
          : 'py-3 px-2 hover:rounded-lg hover:bg-gray-700'
      }
    >
      <span className="flex gap-2 items-center">
        <img src={src} className="h-3 w-7 sm:w-4" alt={to} />
        {isMobile ? '' : text}
      </span>
    </NavLink>
  );
};
