import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BEARER } from '../../constants/constants';

const App = () => {
  const { t } = useTranslation('common');

  return (
    <footer className="w-full px-2 text-white mt-auto bg-edom-dark-blue flex-shrink-0">
      <div className="flex flex-col items-center  bg-opacity-80 rounded-xl py-3">
        <nav className="mb-5 sm:mb-2 flex">
          <ul className="flex flex-wrap justify-center sm:flex-nowrap">
            <li className="pb-2 px-3">
              <a href="mailto:edominationgame@gmail.com" className="text-sm  hover:text-indigo-600 transition-all">
                {t('GAME_FOOTER_CONTACT')}
              </a>
            </li>
            <li className="pb-2 px-3">
              <Link
                to={BEARER ? '/about-us' : '/no-auth/about-us'}
                className="text-sm  hover:text-indigo-600 transition-all"
              >
                {t('GAME_FOOTER_ABOUT')}
              </Link>
            </li>
            <li className="pb-2 px-3">
              <Link
                to={BEARER ? '/latest-updates' : '/no-auth/latest-updates'}
                className="text-sm  hover:text-indigo-600 transition-all"
              >
                {t('GAME_FOOTER_LATEST_UPDATES')}
              </Link>
            </li>
            <li className="pb-2 px-3">
              <Link to={BEARER ? '/rules' : '/no-auth/rules'} className="text-sm  hover:text-indigo-600 transition-all">
                {t('GAME_FOOTER_RULES')}
              </Link>
            </li>
            <li className="pb-2 px-3">
              <Link
                to={BEARER ? '/support' : '/no-auth/support'}
                className="text-sm  hover:text-indigo-600 transition-all"
              >
                {t('GAME_FOOTER_SUPPORT')}
              </Link>
            </li>
            <li className="pb-2 px-3">
              <Link
                to={BEARER ? '/privacy-policy' : '/no-auth/privacy-policy'}
                className="text-sm  hover:text-indigo-600 transition-all"
              >
                {t('GAME_FOOTER_PRIVACY')}
              </Link>
            </li>
            <li className="pb-2 px-3">
              <Link to={BEARER ? '/tos' : '/no-auth/tos'} className="text-sm  hover:text-indigo-600 transition-all">
                {t('GAME_FOOTER_TERMS')}
              </Link>
            </li>
          </ul>
        </nav>

        <div className="flex justify-center">
          <span className="text-xs ">Copyright &copy; 2017-{new Date().getFullYear()} eDominations</span>
        </div>
      </div>
    </footer>
  );
};

export default App;
