import {
  AttackerScoreStyle,
  DefenderScoreStyle,
  RoundStyle,
  ScoreBarAnimation,
  ScoreBarTimeLeft,
  ScoreRateBarFrame,
} from './Styles/BattlefieldBarStyle';
import React, { useEffect } from 'react';
import Countdown from 'react-countdown';
import { gameTimeZone } from '../../helpers/game-time';
import { useHelper } from '../../hooks/use-helper';
import { BattlefieldBattleObjectProps } from '../../shared-types/battlefield-types';

export const BattlefieldProgressAndScoreBar = ({ battleDetails }: { battleDetails: BattlefieldBattleObjectProps }) => {
  const { helpers } = useHelper();

  useEffect(() => {}, [battleDetails]);

  return (
    <>
      <div className="relative block left-1/2 text-white top-5">
        <div className="-ml-24 text-center" style={RoundStyle()}>
          <span className="text-center ml-3">{helpers.Regions[battleDetails.REGION].Name.substring(0, 17)}</span>
          <div className="absolute -left-28 -mt-6" style={AttackerScoreStyle()}>
            <span className="left-7 text-blue-500 top-1.5 absolute">{battleDetails.RoundScores.A}</span>
          </div>
          <div className="absolute left-20 -mt-6" style={DefenderScoreStyle()}>
            <span className="left-4 text-red-500 top-1.5 absolute">{battleDetails.RoundScores.D}</span>
          </div>
        </div>

        <div className=" -ml-80 " style={ScoreRateBarFrame()}>
          <div className="battleProgressBarParent">
            <div className="" style={ScoreBarAnimation()}>
              <div
                className="battleVersusBar transition ease delay-50 duration-300 "
                style={{ width: `${parseFloat(String(battleDetails.PercentageScores['A']))}%`, float: 'left' }}
              >
                <span></span>
              </div>
              <div className="battleVersusBarAttackerRate z-10">% {battleDetails.PercentageScores['A']}</div>
              <div className="battleVersusrBarDefenderRate z-10">{battleDetails.PercentageScores['D']} %</div>

              <div className="battleVersusBar battleVersusBar2 color3">
                <span></span>
              </div>
            </div>
            <div
              className="battleProgressBarParent-1"
              style={{ width: `${parseFloat(String(battleDetails.PercentageScores['D']))}%` }}
            >
              <span className="text-green-500"></span>
            </div>
          </div>
          <div className="text-white" style={ScoreBarTimeLeft()}>
            {' '}
            <Countdown
              date={gameTimeZone().getDifferenceFromGameTime({ date: battleDetails.DATE })}
              daysInHours={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
