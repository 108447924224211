export const CountryData = () => {
  return {
    1: {
      name: 'Afghanistan',
      flag: 'Afghanistan',
      color: '#b360ca',
    },
    2: {
      name: 'Albania',
      flag: 'Albania',
      color: '#776359',
    },
    3: {
      name: 'Algeria',
      flag: 'Algeria',
      color: '#14a92a',
    },
    4: {
      name: 'Argentina',
      flag: 'Argentina',
      color: '#c0a15f',
    },
    5: {
      name: 'Armenia',
      flag: 'Armenia',
      color: '#c17585',
    },
    6: {
      name: 'Australia',
      flag: 'Australia',
      color: '#a3c49d',
    },
    7: {
      name: 'Austria',
      flag: 'Austria',
      color: '#635087',
    },
    8: {
      name: 'Azerbaijan',
      flag: 'Azerbaijan',
      color: '#de9254',
    },
    9: {
      name: 'Belarus',
      flag: 'Belarus',
      color: '#cac6ac',
    },
    10: {
      name: 'Belgium',
      flag: 'Belgium',
      color: '#a0371c',
    },
    11: {
      name: 'Bolivia',
      flag: 'Bolivia',
      color: '#ded57f',
    },
    12: {
      name: 'Bosnia and Herzegovina',
      flag: 'Bosnia-and-Herzegovina',
      color: '#1ca039',
    },
    13: {
      name: 'Brazil',
      flag: 'Brazil',
      color: '#009c3b',
    },
    14: {
      name: 'Bulgaria',
      flag: 'Bulgaria',
      color: '#5da573',
    },
    15: {
      name: 'Canada',
      flag: 'Canada',
      color: '#794f7a',
    },
    16: {
      name: 'Chile',
      flag: 'Chile',
      color: '#abc067',
    },
    17: {
      name: 'China',
      flag: 'China',
      color: '#ef1e0c',
    },
    18: {
      name: 'Colombia',
      flag: 'Colombia',
      color: '#d08195',
    },
    19: {
      name: 'Croatia',
      flag: 'Croatia',
      color: '#76172a',
    },
    20: {
      name: 'Cuba',
      flag: 'Cuba',
      color: '#cc69ca',
    },
    21: {
      name: 'Cyprus',
      flag: 'Cyprus',
      color: '#bbaf85',
    },
    22: {
      name: 'Czech Republic',
      flag: 'Czech-Republic',
      color: '#ce8c5e',
    },
    23: {
      name: 'Denmark',
      flag: 'Denmark',
      color: '#69d26b',
    },
    24: {
      name: 'Ecuador',
      flag: 'Ecuador',
      color: '#9857ba',
    },
    25: {
      name: 'Egypt',
      flag: 'Egypt',
      color: '#abcf8e',
    },
    26: {
      name: 'Estonia',
      flag: 'Estonia',
      color: '#dda3c3',
    },
    27: {
      name: 'Finland',
      flag: 'Finland',
      color: '#a2becb',
    },
    28: {
      name: 'France',
      flag: 'France',
      color: '#b68aa3',
    },
    29: {
      name: 'Georgia',
      flag: 'Georgia',
      color: '#c1b6c9',
    },
    30: {
      name: 'Germany',
      flag: 'Germany',
      color: '#4e6ec3',
    },
    31: {
      name: 'Greece',
      flag: 'Greece',
      color: '#1f2181',
    },
    32: {
      name: 'Hungary',
      flag: 'Hungary',
      color: '#016000',
    },
    33: {
      name: 'India',
      flag: 'India',
      color: '#deb15a',
    },
    34: {
      name: 'Indonesia',
      flag: 'Indonesia',
      color: '#7fb0b8',
    },
    35: {
      name: 'Iran',
      flag: 'Iran',
      color: '#99d959',
    },
    36: {
      name: 'Ireland',
      flag: 'Ireland',
      color: '#5c7d6d',
    },
    37: {
      name: 'Israel',
      flag: 'Israel',
      color: '#155cb2',
    },
    38: {
      name: 'Italy',
      flag: 'Italy',
      color: '#33343a',
    },
    39: {
      name: 'Japan',
      flag: 'Japan',
      color: '#a49b97',
    },
    40: {
      name: 'Latvia',
      flag: 'Latvia',
      color: '#c88bc0',
    },
    41: {
      name: 'Lithuania',
      flag: 'Lithuania',
      color: '#c9a89a',
    },
    42: {
      name: 'Luxembourg',
      flag: 'Luxembourg',
      color: '#897db5',
    },
    43: {
      name: 'Malaysia',
      flag: 'Malaysia',
      color: '#e08a93',
    },
    44: {
      name: 'Mexico',
      flag: 'Mexico',
      color: '#9cb753',
    },
    45: {
      name: 'Montenegro',
      flag: 'Montenegro',
      color: '#849600',
    },
    46: {
      name: 'Netherlands',
      flag: 'Netherlands',
      color: '#c0b995',
    },
    47: {
      name: 'New Zealand',
      flag: 'New-Zealand',
      color: '#e170cc',
    },
    48: {
      name: 'North Korea',
      flag: 'North-Korea',
      color: '#1b8406',
    },
    49: {
      name: 'Norway',
      flag: 'Norway',
      color: '#e1bcca',
    },
    50: {
      name: 'Pakistan',
      flag: 'Pakistan',
      color: '#d7c4d5',
    },
    51: {
      name: 'Paraguay',
      flag: 'Paraguay',
      color: '#213dd8',
    },
    52: {
      name: 'Peru',
      flag: 'Peru',
      color: '#9f885b',
    },
    53: {
      name: 'Poland',
      flag: 'Poland',
      color: '#d856a9',
    },
    54: {
      name: 'Portugal',
      flag: 'Portugal',
      color: '#6a725e',
    },
    55: {
      name: 'Republic of China (Taiwan)',
      flag: 'Taiwan',
      color: '#843002',
    },
    56: {
      name: 'Republic of Macedonia (FYROM)',
      flag: 'Macedonia',
      color: '#ed682a',
    },
    57: {
      name: 'Republic of Moldova',
      flag: 'Moldova',
      color: '#8ba6c6',
    },
    58: {
      name: 'Romania',
      flag: 'Romania',
      color: '#eede38',
    },
    59: {
      name: 'Russia',
      flag: 'Russia',
      color: '#8c9778',
    },
    60: {
      name: 'Saudi Arabia',
      flag: 'Saudi-Arabia',
      color: '#9c7d73',
    },
    61: {
      name: 'Serbia',
      flag: 'Serbia',
      color: '#81a9e1',
    },
    62: {
      name: 'Singapore',
      flag: 'Singapore',
      color: '#aa7e5c',
    },
    63: {
      name: 'Slovakia',
      flag: 'Slovakia',
      color: '#81ce9c',
    },
    64: {
      name: 'Slovenia',
      flag: 'Slovenia',
      color: '#945180',
    },
    65: {
      name: 'South Africa',
      flag: 'South-Africa',
      color: '#751719',
    },
    66: {
      name: 'South Korea',
      flag: 'South-Korea',
      color: '#7e54a5',
    },
    67: {
      name: 'Spain',
      flag: 'Spain',
      color: '#7f6aa5',
    },
    68: {
      name: 'Sweden',
      flag: 'Sweden',
      color: '#d1a493',
    },
    69: {
      name: 'Switzerland',
      flag: 'Switzerland',
      color: '#f24004',
    },
    70: {
      name: 'Thailand',
      flag: 'Thailand',
      color: '#75b688',
    },
    71: {
      name: 'Tunisia',
      flag: 'Tunisia',
      color: '#bbbc94',
    },
    72: {
      name: 'Turkey',
      flag: 'Turkey',
      color: '#bf885d',
    },
    73: {
      name: 'Ukraine',
      flag: 'Ukraine',
      color: '#a2d270',
    },
    74: {
      name: 'United Arab Emirates',
      flag: 'United-Arab-Emirates',
      color: '#5b60c1',
    },
    75: {
      name: 'United Kingdom',
      flag: 'United-Kingdom',
      color: '#261776',
    },
    76: {
      name: 'United States of America',
      flag: 'United-States',
      color: '#5c6482',
    },
    77: {
      name: 'Uruguay',
      flag: 'Uruguay',
      color: '#92cbaf',
    },
    78: {
      name: 'Venezuela',
      flag: 'Venezuela',
      color: '#8cd555',
    },
    79: {
      name: 'Greenland',
      flag: 'Greenland',
      color: '#AB0000',
    },
    80: {
      name: 'Palestine',
      flag: 'Palestine',
      color: '#0a713e',
    },
    81: {
      name: 'Jordan',
      flag: 'Jordan',
      color: '#808000',
    },
    82: {
      name: 'Lebanon',
      flag: 'Lebanon',
      color: '#ac2830',
    },
    83: {
      name: 'Syria',
      flag: 'Syria',
      color: '#4b715e',
    },
    84: {
      name: 'Iraq',
      flag: 'Iraq',
      color: '#f6d648',
    },
  };
};
