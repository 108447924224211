import { ReactNode } from 'react';

export interface GreenButtonProps {
  text: ReactNode;
}

export const GreenButton = ({ text }: GreenButtonProps) => {
  return (
    <button className="w-32 sm:w-40 rounded-md p-2.5 uppercase shadow-lg bg-green-700 hover:bg-green-600 hover:scale-105 transition duration-200 text-sm font-bold text-gray-200 sm:text-sm">
      {text}
    </button>
  );
};
