// @ts-ignore
import Embed from '@editorjs/embed';
// @ts-ignore
import List from '@editorjs/list';
// @ts-ignore
import Table from '@editorjs/table';
const Header = require('@editorjs/header');
const SimpleImage = require('@editorjs/simple-image');
const Quote = require('@editorjs/quote');
const Delimiter = require('@editorjs/delimiter');
const Warning = require('@editorjs/warning');

export const eDitorJSToolsConfig = {
  header: {
    class: Header,
    levels: [1, 2, 3],
  },
  image: SimpleImage,
  quote: Quote,
  embed: {
    class: Embed as any,
    inlineToolbar: true,
  },
  list: {
    class: List as any,
    config: {
      defaultStyle: 'unordered',
    },
  },
  delimiter: Delimiter,
  warning: Warning,
  table: Table,
};

  