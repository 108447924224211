import { Fragment } from 'react';

import { Divider } from '../../../components/Divider';

import { resourceImages } from '../../../helpers/Helpers';
import { fromCDN } from '../../../helpers/Images';
import {useHelper} from "../../../hooks/use-helper";

const food = fromCDN.fetchStaticGameImage('/items/food');
const tankIcon = fromCDN.fetchStaticGameImage('/items/tank');
const houseIcon = fromCDN.fetchStaticGameImage('/items/house');

export const EconomyResources = ({ t, regionsDetail, routeRegions }) => {
    const { helpers } = useHelper()
  return (
    <>
      <h1 className="font-bold m-3 text-yellow-600">{t('GAME_RESOURCES')}</h1>
      <Divider margin="m-5" />
      <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mx-5 gap-4 text-base">
        {Object.values(helpers.Resources).map((item) => {
          let bgColor = 'bg-yellow-100';
          let bg = food;

          if (item.ID > 5 && item.ID < 11) {
            bgColor = 'bg-indigo-100';
            bg = tankIcon;
          }
          if (item.ID > 10 && item.ID < 16) {
            bgColor = 'bg-green-100';
            bg = houseIcon;
          }

          let bonusRate = 0;
          let tradeRegions = '';
          let NonTradeRegions = '';

          Object.values(regionsDetail).map((items) => {
            if (helpers.Regions[items.ID] && helpers.Regions[items.ID]['Resource'][0] === item.ID) {
              if (routeRegions.includes(items.ID)) {
                tradeRegions = items.NAME;
                bonusRate = 20;
              } else {
                bonusRate = 10;
                NonTradeRegions = item.NAME;
              }
            }
          });

          return (
            <Fragment key={item.ID}>
              <div className={`${bgColor} justify-center text-center rounded shadow p-2 text-sm`}>
                <div className="flex justify-between">
                  <img className="w-10 h-10" src={bg} />

                  <div className="flex gap-1">
                    {resourceImages(item.Name, 'h-10 w-10')}
                    <span>{bonusRate}%</span>
                  </div>
                </div>

                <div className="grid grid-cols-1 m-2">
                  <span className="text-gray-800">{t('GAME_REGIONS_WITH_TRADE_ROUTE')}</span>
                  <div>
                    {tradeRegions ? tradeRegions : <span className="text-red-600">{t('GAME_REGIONS_NO_REGION')}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 m-2">
                  <span className="text-gray-800">{t('GAME_REGIONS_WITHOUT_TRADE_ROUTE')}</span>

                  <div>
                    {NonTradeRegions ? (
                      NonTradeRegions
                    ) : (
                      <span className="text-red-600">{t('GAME_REGIONS_NO_REGION')}</span>
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  );
};
