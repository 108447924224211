import { FC, Suspense, useEffect, useState } from 'react';

import { Login } from './Login/Login';
import Footer from '../Footer/Footer';
import { registerTopArticlesOrSetIfExist } from './TopArticles/TopArticles';
import { LandingTopArticles, LandingTopArticlesProps } from './LandingTopArticles';
import TopUsers from './TopUsers/TopUsers';
import { LandingPageTopUsers } from './LandingPageTopUsers';
import { imageAsBackground } from '../../helpers/image-as-background';
import { Loader } from '../../components/Loader';
import { fromCDN } from '../../helpers/Images';

export const LandingPage: FC = () => {
  const [topArticles, setTopArticles] = useState<LandingTopArticlesProps[] | undefined>();
  const [topCitizens, setTopCitizens] = useState();

  useEffect(() => {
    registerTopArticlesOrSetIfExist({
      setTopArticles,
    });
    TopUsers(setTopCitizens);
  }, []);

  return (
    <div className="min-h-screen flex flex-col max-w-12xl mx-auto">
      <div
        className="flex-grow sm:py-0 px-0 sm:px-6 lg:px-8 bg-cover bg-center bg-no-repeat"
        style={{
          ...imageAsBackground(fromCDN.fetchStaticImgImage('/battlefieldscreenbg', 'jpg')),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <img className="h-32 mx-auto" src={fromCDN.fetchStaticImgImage('/logo-l')} alt="logo" />

        <div className="grid grid-rows-3 lg:grid-rows-1 lg:grid-cols-7 lg:gap-y-0 lg:gap-x-5 mt-10">
          <div className="lg:col-span-3">
            <Suspense
              fallback={
                <div
                  className="max-w-md text-center mx-auto h-full grid place-items-center"
                  style={imageAsBackground(fromCDN.fetchStaticImgImage('/table_item'))}
                >
                  <Loader />
                </div>
              }
            >
              <Login />
            </Suspense>
          </div>
          <div className="my-6 lg:mt-0 lg:col-span-2 max-w-md w-full mx-auto">
            <LandingTopArticles topArticles={topArticles} />
          </div>
          <div className="lg:col-span-2 max-w-md w-full mx-auto">
            <LandingPageTopUsers topCitizens={topCitizens} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
