import { SearchCircleIcon } from '@heroicons/react/solid';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { AppModal } from './AppModal';
import { ReactNode, useEffect, useState } from 'react';
import { authApi } from '../api';
import { validate } from '../helpers/Validations';
import { Loader } from './Loader';
import Avatar from './Avatar';
import { Divider } from './Divider';
import { countryFlags } from '../helpers/Helpers';
import { AxiosResponse } from 'axios';
import { SearchReturn } from '../shared-types/types';

export const Search = () => {
  const { t } = useTranslation('common');
  const [modalOpen, setModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchReturn[] | null>();
  const [searchInput, setSearchInput] = useState('');
  const [loader, setLoader] = useState<boolean | ReactNode>(false);

  const getSearchResults = () => {
    setLoader(<Loader className="mx-auto w-10 h-10" />);
    if (!validate.nonEmptyValues([searchInput])) {
      return null;
    }
    authApi
      .post('/api/search', {
        keyword: searchInput,
      })
      .then((resp: AxiosResponse<SearchReturn>) => {
        setLoader(null);
        // @ts-ignore
        setSearchResults(resp.data);
      })
      .catch((err) => {
        setLoader(null);
      });
  };

  const getModalBody = (
    <>
      <div className="p-5">
        <input
          data-testid="searchcitizen"
          className="default-input"
          placeholder={t('GAME_SEARCH_CITIZEN')}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              getSearchResults();
            }
          }}
        />
        <button className="default-save-button" onClick={() => getSearchResults()}>
          {t('GAME_SEARCH')}
        </button>
        {loader && loader}
        {searchResults && (
          <>
            <h1 className="text-gray-100 mx-auto text-center m-3">Results</h1>
            <Divider />
          </>
        )}
        <div className="p-5 grid grid-cols-2 sm:grid sm:grid-cols-4 gap-4 text-center justify-center">
          {searchResults?.map((result) => {
            return (
              <>
                <div
                  onClick={() => {
                    setModalOpen((prevState) => !prevState);
                  }}
                >
                  <p className="text-gray-200">{result.user}</p>
                  <p>{countryFlags(result.CITIZENSHIP, 'mx-auto ')}</p>
                  <Avatar avatarId={result.AVATAR} userId={String(result.id)} className="mx-auto" />
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
  useEffect(() => {}, []);
  return (
    <>
      <div
        data-tip={true}
        data-for="searchbar"
        className="-mt-3 absolute top-8 right-16 bg-gray-200/20 hover:bg-gray-400/20 p-1 rounded-lg mx-auto sm:top-11 sm:grid sm:grid-cols-1 h-8 md:absolute md:top-11 md:right-28 md:w-32 lg:static lg:block lg:mt-1 lg:mr-32 cursor-pointer default-transition"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <SearchCircleIcon className="text-gray-200 w-6 h-6" />
        <ReactTooltip id="searchbar" effect="solid" data-html={true}>
          {t('GAME_SEARCH_CITIZEN')}
        </ReactTooltip>
        <AppModal
          open={modalOpen}
          setOpen={setModalOpen}
          body={getModalBody}
          backgroundStyle={{
            backgroundColor: '#1e293b',
          }}
          shouldHaveCloseButton={false}
        />
        {/*<input className={'default-input'}/>*/}
      </div>
    </>
  );
};
