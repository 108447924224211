import moment from 'moment';

import axios from '../../../api';
import { getFromLocalStorage, useLocalStorageAsCache } from '../../../helpers/LocalStorage';
import { GLOBAL_CONSTANTS } from '../../../constants/enum';

const TOP_CITIZENS = GLOBAL_CONSTANTS.LOCALSTORAGE_KEYS.TOP_USERS;

export default function TopUsers(setTopCitizens) {
  const topCitizens = getFromLocalStorage(TOP_CITIZENS);

  if (moment().unix() <= topCitizens?.expiration) {
    setTopCitizens(topCitizens.data);
    return;
  }

  axios
    .get('/api/topcitizens')
    .then(({ data }) => {
      setTopCitizens(data);
      /* eslint-disable  */
      useLocalStorageAsCache(1440, TOP_CITIZENS, data);
    })
    .catch(() => {
      setTopCitizens(null);
    });
}
