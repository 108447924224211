import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionHeader } from '../../components/SectionHeader';
import { CountryData } from './CountryData';
import { RegionData } from './RegionData';
import {imageAsBackground} from '../../helpers/image-as-background';
import { fromCDN } from '../../helpers/Images';
import { InfoDialog } from './InfoDialog';
import { authApi } from '../../api';
import { Loader } from '../../components/Loader';
import { BASE_URL } from '../../constants/constants';
/* eslint-disable  */
let isMapScriptLoaded = false;

export default function MapView() {
  const { t } = useTranslation('common');

  const [hoveredRegionData, setHoveredRegionData] = useState({});
  const [apiMapDataState, setApiMapDateState] = useState();

  const getApiMapData = () => {
    authApi.get('/api/map/currentmap').then((resp) => {
      setApiMapDateState(resp.data);
    });
  };

  const appendScriptOnce = async () => {
    if (isMapScriptLoaded) {
      return null;
    }
    const script = document.createElement('script');

    script.src = `${BASE_URL}/js/map.js`;

    script.async = true;
    script.defer = true;
    script.onload = () => {
      isMapScriptLoaded = true;
    };
    document.body.appendChild(script);
  };

  const makeMap = async () => {
    try {
      await appendScriptOnce();
    } catch (e) {
      //
    }
    var countryData = CountryData();
    var regionData = RegionData();
    var regionCoordinates = worldMap;
    var map = worldMap;
    var apiData = apiMapDataState;
    var regionPolygon = [];
    var regionNeighbors = [];
    var markers = [];

    function initMap(minvalue = 5, maxvalue = 10, width = 25, height = 25) {
      var customMapType = new google.maps.StyledMapType(
        [
          { stylers: [{ visibility: 'off' }] },
          { featureType: 'geometry.fill', stylers: [{ visibility: 'on' }, { color: '#f2f2f2' }] },
          { featureType: 'water', stylers: [{ visibility: 'on' }, { color: '#d8f2ff' }] },
        ],
        { name: 'Custom Style' },
      );

      var customMapTypeId = 'custom_style';
      var wspolrzedne = new google.maps.LatLng(45.453117295560354, 20.173046874999955);
      var opcjeMapy = {
        center: wspolrzedne,
        disableDefaultUI: true,
        maxZoom: 8,
        minZoom: 3,
        zoom: 2,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControl: false,
        panControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, customMapTypeId],
        },
      };
      map = new google.maps.Map(document.getElementById('map'), opcjeMapy);
      map.mapTypes.set(customMapTypeId, customMapType);
      map.setMapTypeId(customMapTypeId);
      let data = '';
      $.each(regionCoordinates, function (regionID, regionCoor) {
        $.each(regionCoor, function (index, coorArray) {
          var coor = [];
          $.each(coorArray, function (indexE, value) {
            data = value.split(',');
            var latlng = new google.maps.LatLng(data[1], data[0]);
            coor.push(latlng);
          });

          var regionColor = '#d1d1d1';
          if (typeof regionData[regionID] != 'undefined' && typeof apiData[regionID] != 'undefined') {
            if (typeof countryData[apiData[regionID].owner_current_id] != 'undefined') {
              regionColor = countryData[apiData[regionID].owner_current_id].color;
            }
          }
          var gmapsPolygon = new google.maps.Polygon({
            paths: coor,
            strokeColor: regionColor,
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: regionColor,
            fillOpacity: 0.6,
          });

          gmapsPolygon.setMap(map);

          if (regionPolygon[regionID] == null) {
            regionPolygon[regionID] = new Array();
          }
          regionPolygon[regionID].push(gmapsPolygon);

          google.maps.event.addListener(gmapsPolygon, 'mouseover', function (event) {
            this.setOptions({ fillOpacity: 0.9, zIndex: 10 });
            mouseoverNeighbors(regionID);
          });

          google.maps.event.addListener(gmapsPolygon, 'mouseout', function (event) {
            this.setOptions({ fillOpacity: 0.6, zIndex: 10 });
            mouseoutNeighbors();
          });
          addMarker(regionID, coor, width, height);
        });
      });

      function mouseoverNeighbors(region) {
        if (region > 0) {
          setHoveredRegionData({
            region: regionData[region].name,
            resource: apiData[region].resource,
            currentOwnerFlag: countryData[apiData[region].owner_current_id].flag,
            currentOwnerCountry: countryData[apiData[region].owner_current_id].name,
            originalOwnerFlag: countryData[apiData[region].owner_original_id].flag,
            originalOwnerCountry: countryData[apiData[region].owner_original_id].name,
          });
        }

        regionNeighbors = regionData[region].neighbors;
        $.each(regionData[region].neighbors, function (ndex, value) {
          if (typeof regionPolygon[value] != 'undefined') {
            $.each(regionPolygon[value], function (index_, gmapPolygon) {
              gmapPolygon.setOptions({ fillOpacity: 0.3, zIndex: 10 });
            });
          }
        });
      }
      function mouseoutNeighbors() {
        if (regionNeighbors.length > 0) {
          $.each(regionNeighbors, function (ndex, value) {
            if (typeof regionPolygon[value] != 'undefined') {
              $.each(regionPolygon[value], function (index_, gmapPolygon) {
                gmapPolygon.setOptions({ fillOpacity: 0.6, zIndex: 10 });
              });
            }
          });
          regionNeighbors = [];
        }
      }

      var minValue = minvalue;
      var maxValue = maxvalue;
      google.maps.event.addListener(map, 'zoom_changed', function () {
        var zoom = map.getZoom();
        if (zoom > minValue && zoom < maxValue) {
          for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
          }
        } else {
          for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
          }
        }
      });

      function addMarker(region, location, width, height) {
        var bounds = new google.maps.LatLngBounds();
        var i;
        for (i = 0; i < location.length; i++) {
          bounds.extend(location[i]);
        }
        var image = {
          url: '<?php echo $SET_web; ?>public/game/resource/' + apiData[region].resource + '.png',
          scaledSize: new google.maps.Size(width, height),
          //origin: new google.maps.Point(3, 3),
          //anchor: new google.maps.Point(0, 0)
        };
        var infoWindow = new google.maps.InfoWindow();
        var marker = new google.maps.Marker({
          position: bounds.getCenter(),
          map: null,
          icon: image,
        });
        markers.push(marker);
        google.maps.event.addListener(marker, 'click', function () {
          infoWindow.setContent(
            '<h5><img src="<?php echo $SET_web; ?>public/game/flags/shiny/24/' +
              countryData[apiData[region].owner_current_id].flag +
              '.png" style="width: 24px; height: 24px;margin-top: -5px;margin-left: 0px;" alt=""> <a href="<?php echo $SET_www.\'/\'.$game_lang; ?>/country/region/' +
              apiData[region].owner_current_id +
              '/' +
              countryData[apiData[region].owner_current_id].flag +
              '/' +
              region +
              '/' +
              regionData[region].name +
              '">' +
              regionData[region].name +
              '</a></h5><p style="margin-top: 10px;"><img src="<?php echo $SET_web; ?>public/game/resource/' +
              apiData[region].resource +
              '.png" style="width: 24px; height: 24px;margin-top: -5px;margin-left: 0px;" alt=""> Resource: ' +
              apiData[region].resource +
              '</p>',
          );
          infoWindow.open(map, marker);
        });
      }
    }

    const jquery = document.createElement('script');

    jquery.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    jquery.integrity = 'sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=';
    jquery.crossOrigin = 'anonymous';
    jquery.defer = true;

    document.body.appendChild(jquery);
    const script = document.createElement('script');

    script.src =
      'https://maps.googleapis.com/maps/api/js?callback=initMap&libraries=places&key=AIzaSyBWEYsCgD8kolSP5nqBNkjSVvD_in-57LQ';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    // const map = document.createElement("script");
    //
    // map.src = BASE_URL + '/js/map.js';
    // map.async = true;
    // map.defer = true
    // map, 'map ?')
    // document.body.appendChild(map);

    window.initMap = initMap;
  };

  useEffect(() => {
    getApiMapData();
  }, []);

  useEffect(() => {
    makeMap();
  }, [apiMapDataState]);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <div
          className="min-h-screen max-w-13xl mx-auto bg-fixed"
          style={imageAsBackground(fromCDN.fetchStaticImage('t_mapbg', 'svg'))}
        >
          <div className="max-w-screen-2xl mx-auto py-2">
            <SectionHeader title={t('GAME_MENU_MAP')} />
            <div className="px-2 relative">
              <div id="map" className="w-full mt-5 rounded" style={{ height: '800px' }} />
              <InfoDialog hoveredRegionData={hoveredRegionData} t={t} />
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}
