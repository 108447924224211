import { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../../hooks/use-user';
import { OfferProps } from '../Offer';
import { authApi } from '../../../../api';
import { MyOfferCard } from './MyOfferCard';
import ApplicationAlert from '../../../../components/ApplicationAlert';

export const MyOfferList = () => {
  const [offers, setOffers] = useState<OfferProps[]>([]);
  const { user, setUser } = useUser();
  const { t } = useTranslation('common');

  const removeOffer = (offerId: number) => {
    authApi
      .post('/api/business/monetary/monetaryremoveoffer', {
        removeOffer: offerId,
      })
      .then((response) => {
        setOffers(response.data.OFFERS);
        setUser(response.data.USER);
        ApplicationAlert(t(response.data.MESSAGE)).success();
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });
  };

  useEffect(() => {
    authApi.get('/api/business/monetary/getmyoffers').then((response: AxiosResponse<OfferProps[]>) => {
      setOffers(response.data);
    });
  }, [user]);

  return (
    <ul className="border-t-2 border-t-gray-300/40 mt-10 pt-4 animate__animated animate__fadeIn grid grid-cols-2 sm:grid-cols-5 gap-4">
      {offers.map((offer) => (
        <MyOfferCard key={offer.ID} offer={offer} user={user} onRemove={removeOffer} />
      ))}
    </ul>
  );
};
