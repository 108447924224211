import React, { Fragment } from 'react';

import { fromCDN } from '../../../helpers/Images';
import { useHelper } from '../../../hooks/use-helper';

export default function EconomyTax({ economyInfo }) {
  const { helpers } = useHelper();
  let taxView = [];

  for (let x = 1; x < 11; x++) {
    if (helpers.Items[x]) {
      taxView.push(
        <Fragment key={x}>
          <div>
            <img className="mx-auto w-8 h-8" src={fromCDN.fetchStaticGameImage(`/items/${helpers.Items[x].Img}`)} />
          </div>
          <div>{economyInfo['WORKTAX']}%</div>
          <div>{economyInfo['TIMP_' + x]}%</div>
          <div>{economyInfo['TVAT_' + x]}%</div>
        </Fragment>,
      );
    }
  }

  return taxView;
}
