import React, { ReactNode } from 'react';
import { SectionHeader, SectionHeaderProps } from './SectionHeader';
interface AppPageTemplateProps {
  children: ReactNode;
  mainStyle?: React.CSSProperties;
  section: SectionHeaderProps;
}
export const AppPageTemplate = ({ children, mainStyle, section }: AppPageTemplateProps): JSX.Element => {
  return (
    <div className="min-h-screen max-w-13xl mx-auto bg-fixed text-gray-200" style={mainStyle}>
      <div className="max-w-7xl mx-auto py-2  ">
        <SectionHeader {...section} />
        {children}
      </div>
    </div>
  );
};
