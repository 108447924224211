import { lazy, Suspense, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { PublicRoute, PrivateRoute, PublicRouteWithHeaderAndFooter } from './router';
import { NotFound } from './components/NotFound';
import LoaderContext, { cookies } from './constants/constants';
import userContext from './constants/userContext';
import helperContext from './constants/helpersContext';
import { Loader } from './components/Loader';

import EmailConfirmation from './containers/Landing/EmailConfirmation';
import { ForgotPassword } from './containers/Landing/ResetPassword/ForgotPassword';
import { ResetPassword } from './containers/Landing/ResetPassword/ResetPassword';
import Footer from './containers/Footer/Footer';
import Rules from './containers/Footer/Rules';
import PrivacyPolicy from './containers/Footer/PrivacyPolicy';
import TermsOfUse from './containers/Footer/TermsOfUse';
import { Header } from './containers/Header/Header';
import { Article } from './containers/Community/Journalism/Article';
import { FeedBackground } from './assets/Styles/Styles';
import { ScrollToTop } from './helpers/ScrollToTop';
import LatestUpdates from './containers/Footer/LatestUpdates';

import { AboutUs } from './containers/Footer/AboutUs';

import { resources } from './assets/Translations/resources';

import { AuthDashboard } from './router/AuthDashboard';
import { UserProps } from './shared-types/user-type';
import { HelperProps } from './shared-types/types';

import { LandingPage } from './containers/Landing/LandingPage';
import SocketContextProvider from './constants/socket-context';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: cookies.get('mylanguage') ?? 'en', // language to use
  fallbackLng: 'en',
  resources: resources,
});

const Register = lazy(() => import('./containers/Landing/Register/Register'));

// eslint-disable-next-line react/function-component-definition
export default function App() {
  const [loader, setLoader] = useState<boolean>(true);
  const value = { loader, setLoader };

  const [user, setUser] = useState<UserProps | null>(null);
  const userDetails = { user, setUser };

  const [helpers, setHelpers] = useState<HelperProps | null>(null);
  const helperDetails = { helpers, setHelpers };

  return (
    <I18nextProvider i18n={i18next}>
      <SocketContextProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route
              path="/"
              element={
                <PublicRoute value={value}>
                  <Suspense
                    fallback={
                      <main className="min-h-screen" style={FeedBackground()}>
                        <Loader fullScreen={true} />
                      </main>
                    }
                  >
                    <LandingPage />
                  </Suspense>
                </PublicRoute>
              }
            />
            <Route
              path="/register/:referrer"
              element={
                <PublicRoute value={value}>
                  <Suspense
                    fallback={
                      <div className="flex justify-center">
                        <Loader />
                      </div>
                    }
                  >
                    <Register />
                    <Footer />
                  </Suspense>
                </PublicRoute>
              }
            />
            <Route
              path="/confirmation"
              element={
                <PublicRoute value={value}>
                  <Suspense
                    fallback={
                      <div className="flex justify-center">
                        <Loader />
                      </div>
                    }
                  >
                    <EmailConfirmation />
                    <Footer />
                  </Suspense>
                </PublicRoute>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <PublicRoute value={value}>
                  <ForgotPassword />
                  <Footer />
                </PublicRoute>
              }
            />
            <Route
              path="/reset-password/:confirmationCode"
              element={
                <PublicRoute value={value}>
                  <ResetPassword />
                  <Footer />
                </PublicRoute>
              }
            />

            <Route
              path="/no-auth/rules"
              element={
                <PublicRouteWithHeaderAndFooter value={value}>
                  <Rules />
                </PublicRouteWithHeaderAndFooter>
              }
            />
            <Route
              path="/no-auth/privacy-policy"
              element={
                <PublicRouteWithHeaderAndFooter value={value}>
                  <PrivacyPolicy />
                </PublicRouteWithHeaderAndFooter>
              }
            />
            <Route
              path="/no-auth/about-us"
              element={
                <PublicRouteWithHeaderAndFooter value={value}>
                  <AboutUs />
                </PublicRouteWithHeaderAndFooter>
              }
            />
            <Route
              path="/no-auth/tos"
              element={
                <PublicRouteWithHeaderAndFooter value={value}>
                  <TermsOfUse />
                </PublicRouteWithHeaderAndFooter>
              }
            />
            <Route
              path="/no-auth/latest-updates"
              element={
                <PublicRouteWithHeaderAndFooter value={value}>
                  <LatestUpdates />
                </PublicRouteWithHeaderAndFooter>
              }
            />
            <Route
              path="/no-auth/article/:id"
              element={
                <PublicRouteWithHeaderAndFooter value={value}>
                  <Article />
                </PublicRouteWithHeaderAndFooter>
              }
            />
            <Route
              path="/*"
              element={
                <>
                  <LoaderContext.Provider value={value}>
                    {/* @ts-ignore*/}
                    <userContext.Provider value={userDetails}>
                      {/* @ts-ignore*/}
                      <helperContext.Provider value={helperDetails}>
                        {/* @ts-ignore*/}
                        <PrivateRoute>
                          <Header />
                          {loader ? (
                            <>
                              <main className="min-h-screen" style={FeedBackground()}>
                                <Loader fullScreen={true} />
                              </main>
                            </>
                          ) : (
                            <AuthDashboard />
                          )}
                          <Footer />
                        </PrivateRoute>
                      </helperContext.Provider>
                    </userContext.Provider>
                  </LoaderContext.Provider>
                </>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </SocketContextProvider>
    </I18nextProvider>
  );
}
