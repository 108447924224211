import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Divider } from '../../../../components/Divider';
import { Loader } from '../../../../components/Loader';
import { countryFlags } from '../../../../helpers/Helpers';

import { AppModal } from '../../../../components/AppModal';
import ApplicationAlert from '../../../../components/ApplicationAlert';
import { authApi } from '../../../../api';
import { fromCDN } from '../../../../helpers/Images';
import { useHelper } from '../../../../hooks/use-helper';
import { useUser } from '../../../../hooks/use-user';

export default function Travel({ preSelectedCountry = null, preSelectedRegion = null, open, setOpen }) {
  const { helpers } = useHelper();
  const { user, setUser } = useUser();

  const { t: translation } = useTranslation('common');

  const [countries, setCountries] = useState(Object.values(helpers.Countries));
  const [selectedCountry, setSelectedCountry] = useState(preSelectedCountry);
  const [isLoading, setIsLoading] = useState();
  const [regions, setRegions] = useState();
  const [selectedRegion, setSelectedRegion] = useState(preSelectedRegion);

  if (!open) return;

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 60,
      minHeight: 60,
    }),
  };

  const handleChange = (helper) => {
    if (helper.ID !== selectedCountry) {
      getRegions(helper.ID);
      setSelectedRegion(null);
    }

    setCountries(countries);
    setSelectedCountry(helper.ID);
  };

  const handleRegionChange = (regionId) => {
    if (regionId.ID !== selectedRegion) {
      setSelectedRegion(regionId.ID);
    }
  };

  function getRegions(countryId) {
    setIsLoading(true);

    authApi.get(`/api/user/getregions/${countryId}`).then((response) => {
      setRegions(response.data);
      setIsLoading(false);
    });
  }

  const handleTravel = (selectedType) => {
    authApi
      .post('/api/user/travel', {
        type: selectedType,
        regionId: selectedRegion,
      })
      .then((response) => {
        setUser(response.data);
        ApplicationAlert(translation('GAME_SUCCESS')).success();
        return;
      })
      .catch((error) => {
        ApplicationAlert(error.response.data).error();
        return;
      });
  };

  const modalBody = (
    <div className="p-3 md:p-6">
      <div className="text-left">
        <p className="m-3 font-bold text-gray-100">{translation('GAME_TRAVEL')}</p>
        <Divider margin="mx-0" />
        <div className="">
          <div className="flex justify-center py-4 lg:pt-4 pt-8">
            <div className="mr-4 p-3 text-center">
              <span className=" font-bold block tracking-wide text-green-500">
                {translation('GAME_CURRENT_LOCATION')}
              </span>
              <span className="text-sm text-white justify-center flex">
                <span className="mt-0.5 mr-1">{countryFlags(user['COUNTRY'])}</span>
                {helpers.Regions[user['REGION']].Name}
              </span>
            </div>
          </div>
        </div>
        <div className="flex w-2/2 gap-4">
          <div className="w-1/2">
            <Select
              className="basic-select shadow-md travelcountry"
              classNamePrefix="select"
              placeholder={translation('GAME_SELECT_COUNTRY')}
              styles={customStyles}
              onChange={handleChange}
              isSearchable={true}
              getOptionLabel={(countries) => countries.Name}
              getOptionValue={(countries) => countries.ID}
              options={countries}
            />
          </div>
          <div className="w-1/2">
            {isLoading ? (
              <Loader />
            ) : (
              <Select
                className="basic-select shadow-md travelregion"
                classNamePrefix="select"
                styles={customStyles}
                onChange={handleRegionChange}
                isSearchable={true}
                getOptionLabel={(regions) => regions.NAME}
                getOptionValue={(regions) => regions.ID}
                options={regions}
              />
            )}
          </div>
        </div>
        {!selectedRegion && <div className="mt-72" />}
        {selectedRegion && (
          <>
            <div className="m-3 mt-10">
              <div className="flex justify-center py-4 lg:pt-4 pt-8">
                <div className="mr-4 p-3 text-center">
                  <span className=" font-bold block tracking-wide text-blue-500">
                    {translation('GAME_COUNTRY_MOVE')}
                  </span>
                  <span className="text-sm text-white justify-center flex">
                    <span className="mt-0.5 mr-1">{countryFlags(selectedCountry)}</span>
                    {helpers.Countries[selectedCountry].Name}
                  </span>
                </div>
                <div className="lg:mr-4 p-3 text-center">
                  <span className=" font-bold block tracking-wide text-blue-300">
                    {translation('GAME_REGION_MOVE')}
                  </span>
                  <span className="text-sm text-white justify-center flex">
                    <span className="mt-0.5 mr-1">{countryFlags(selectedCountry)}</span>
                    {helpers.Regions[selectedRegion].Name}
                  </span>
                </div>
              </div>

              <div className="flex justify-center py-4 lg:pt-4 pt-8">
                <div className="mr-4 p-3 text-center">
                  <span className="text-sm text-white flex">
                    <span className="mt-0.5 mr-1" />
                    <span className="font-bold">
                      <img src={fromCDN.fetchStaticGameImage('/icons/air')} className="mb-2" />
                      0.3 <img src={fromCDN.fetchStaticGameImage('/icons/gold-icon')} className="inline w-6 h-6 ml-1" />
                    </span>
                  </span>
                  <button
                    data-testid="travelgold"
                    onClick={() => handleTravel(1)}
                    className="inline-flex uppercase justify-center mt-5 bg-green-700 text-white active:bg-green-900 text-sm font-bold transition duration-500 hover:bg-green-500 hover:-translate-y-1 hover:scale-105 ease-in-out p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 "
                  >
                    {translation('GAME_TRAVEL')}
                  </button>
                </div>
                <div className="mr-4 p-3 text-center">
                  <span className="text-sm text-white flex">
                    <span className="mt-0.5 mr-1" />
                    <span className="font-bold">
                      <img src={fromCDN.fetchStaticGameImage('/icons/air')} className="mb-2" />
                      50 <img src={fromCDN.fetchStaticGameImage('/icons/energy')} className="inline w-6 h-6 ml-1" />
                    </span>
                  </span>
                  <button
                    data-testid="travelenergy"
                    onClick={() => handleTravel(2)}
                    className="inline-flex uppercase justify-center mt-5 bg-indigo-700 text-white active:bg-indigo-900 text-sm font-bold transition duration-500 hover:bg-indigo-500 hover:-translate-y-1 hover:scale-105 ease-in-out p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                  >
                    {translation('GAME_TRAVEL')}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

  return (
    <AppModal
      body={modalBody}
      open={open}
      setOpen={setOpen}
      backgroundStyle={{
        backgroundColor: '#1e293b',
      }}
    />
  );
}
