import { Tab } from '@headlessui/react';

import { Medals } from '../Tabs/Medals/Medals';
import CoreAttributes from '../Tabs/CoreAttributes/CoreAttributes';
import DamageStatistics from '../Tabs/DamageStatistics';
import { isAdminAuthorized } from '../../Administration/Administration';

import TabList from './TabList';
import { ProfileTabsProps } from '../../../shared-types/shared-types';
import { GetUserLogs } from '../../Administration/GetUserLogs';

const TABS = [
  { id: 1, text: 'GAME_PROFILE' },
  { id: 2, text: '', plainText: 'Moderation Logs', isHidden: () => isAdminAuthorized() },
];
export const OthersProfileTab = ({ user, translation }: ProfileTabsProps) => {
  return (
    <Tab.Group>
      <TabList tabs={TABS} className="!max-w-xs" />
      <Tab.Panel>
        <Medals user={user} />
        <CoreAttributes user={user} translation={translation} />
        <DamageStatistics user={user} translation={translation} />
      </Tab.Panel>
      <Tab.Panel>{isAdminAuthorized() && <GetUserLogs userId={user.id} />}</Tab.Panel>
    </Tab.Group>
  );
};
