import { MouseEvent, useState } from 'react';
import { CheckIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';

import { useParams } from 'react-router-dom';
import { authApi } from '../../../api';
import { Offer, OFFER_TYPE } from './Tabs/MyOffers';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { useUser } from '../../../hooks/use-user';
import { getMaxInputValue } from '../../../helpers/get-max-input-value';

import { UserProps } from '../../../shared-types/user-type';

export interface SellMonetaryProps {
  tradeIcon: string;
  exchangeIcon: string;
  tradeColor: string;
  exchangeColor: string;
  reverse?: boolean;
  offerType: Offer;
  onClick: () => void;
}

interface PlaceOfferResponse {
  USER: UserProps;
  MESSAGE: string;
}

export const SellMonetary = ({
  tradeIcon,
  exchangeIcon,
  tradeColor,
  exchangeColor,
  reverse,
  offerType,
  onClick,
}: SellMonetaryProps) => {
  const { t } = useTranslation('common');
  const [amount, setAmount] = useState(0);
  const [rate, setRate] = useState(0);
  const { user, setUser } = useUser();
  const params = useParams();
  const stopPropagation = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePlaceOffer = (e: MouseEvent<HTMLButtonElement>) => {
    stopPropagation(e);

    authApi
      .post('/api/business/monetary/monetaryselloffer', {
        offer: offerType,
        amount: amount,
        rate: rate,
        marketCountry: params.countryId,
      })
      .then((response: AxiosResponse<PlaceOfferResponse>) => {
        setUser(response.data.USER);
        ApplicationAlert(t(response.data.MESSAGE)).success();
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });

    setAmount(0);
    setRate(0);
  };

  const maxAmount = OFFER_TYPE.CURRENCY === offerType ? user.GOLD : user.CC;
  const minExchangeRate = OFFER_TYPE.CURRENCY === offerType ? '0.005' : '100';
  const maxExchangeRate = OFFER_TYPE.CURRENCY === offerType ? '0.015' : '100000';

  return (
    <div
      className={`flex justify-between w-full h-full flex-col items-center lg:items-stretch space-y-6 lg:space-y-0 lg:flex-row ${
        reverse ? 'lg:flex-row-reverse' : ''
      }`}
      onClick={stopPropagation}
    >
      <img
        src={tradeIcon}
        alt="money icon"
        className="h-7 w-7 lg:h-12 lg:w-12 self-center cursor-pointer"
        onClick={onClick}
      />
      <div className="flex gap-x-1">
        <div className="flex flex-col justify-between">
          <span className="text-sm text-center capitalize font-semibold">{t('GAME_AMOUNT')}</span>
          <div className="flex gap-x-1">
            <input
              min="1"
              max={maxAmount.toString()}
              value={amount.toString()}
              onChange={(e) => {
                const valueToSet = getMaxInputValue(e, { getDecimal: OFFER_TYPE.CURRENCY === offerType });
                setAmount(valueToSet);
              }}
              type="number"
              className={`w-24 bg-transparent border-b-2 px-px hide-spinner outline-none ${tradeColor}`}
              onClick={stopPropagation}
            />
          </div>
        </div>
        <img src={tradeIcon} alt="money icon" className="h-5 w-5 self-end" />
      </div>

      <div className="flex gap-x-1 -ml-9 lg:ml-0">
        <div className="flex gap-x-1 font-semibold self-end items-center">
          <span>1</span>
          <img src={tradeIcon} alt="money icon" className="h-5 w-5" />
          <ChevronDoubleRightIcon className="h-3 w-3" />
        </div>
        <div className="flex flex-col justify-between">
          <span className="text-sm text-center capitalize font-semibold">{t('GAME_EXCHANGE_RATE')}</span>
          <input
            min={minExchangeRate}
            max={maxExchangeRate}
            value={OFFER_TYPE.CURRENCY === offerType ? rate : rate.toString()}
            onChange={(e) => {
              const valueToSet = getMaxInputValue(e, { getDecimal: OFFER_TYPE.CURRENCY === offerType });

              setRate(valueToSet);
            }}
            type="number"
            className={`w-24 bg-transparent border-b-2 px-px hide-spinner outline-none ${exchangeColor}`}
            onClick={stopPropagation}
          />
        </div>
        <img src={exchangeIcon} alt="money icon" className="h-5 w-5 self-end" />
      </div>

      <button onClick={handlePlaceOffer}>
        <CheckIcon className="text-green-500 h-10 w-10 bg-neutral-500/70 hover:bg-green-600/70 hover:text-white 00 p-2 rounded-lg stroke-2 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]" />
      </button>
    </div>
  );
};
