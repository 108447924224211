import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import React from 'react';

import { fromCDN } from '../../../helpers/Images';
import {imageAsBackground} from '../../../helpers/image-as-background';

export default function CountryMedals({ medalDetails, setMedalDetails }) {
  const { t } = useTranslation('common');

  return (
    <>
      {medalDetails && (medalDetails['M_BRONZE'] > 0 || medalDetails['M_SILVER'] > 0 || medalDetails['M_GOLD'] > 0) ? (
        <div className={'justify-center  m-5'}>
          <div
            className={
              'h-24 sm:h-32 p-3 flex-shrink-0  justify-center text-center rounded-md shadow-lg w-full inline-flex'
            }
            style={imageAsBackground(fromCDN.fetchStaticGameImage('/country/cmedalsbg', 'jpg'))}
          >
            {medalDetails['M_GOLD'] > 0 ? (
              <span data-tip data-for={'golddescription'}>
                <img src={fromCDN.fetchStaticGameImage('/country/1')} />
                <span className={'medalNumbers'}>{medalDetails['M_GOLD']}</span>
              </span>
            ) : (
              ''
            )}

            {medalDetails['M_SILVER'] > 0 ? (
              <span data-tip data-for={'silverdescription'}>
                <img src={fromCDN.fetchStaticGameImage('/country/2')} />
                <span className={'medalNumbers'}>{medalDetails['M_SILVER']}</span>
              </span>
            ) : (
              ''
            )}
            {medalDetails['M_BRONZE'] > 0 ? (
              <span data-tip data-for={'bronzedescription'}>
                <img src={fromCDN.fetchStaticGameImage('/country/3')} />
                <span className={'medalNumbers'}>{medalDetails['M_BRONZE']}</span>
              </span>
            ) : (
              ''
            )}
          </div>

          <ReactTooltip id={'bronzedescription'} effect="solid" data-html={true}>
            {t('GAME_BRONZE_COUNTRY_MEDAL')}
          </ReactTooltip>
          <ReactTooltip id={'silverdescription'} effect="solid" data-html={true}>
            {t('GAME_SILVER_COUNTRY_MEDAL')}
          </ReactTooltip>
          <ReactTooltip id={'golddescription'} effect="solid" data-html={true}>
            {t('GAME_GOLD_COUNTRY_MEDAL')}
          </ReactTooltip>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
