import { useTranslation } from 'react-i18next';

export interface AboutMeProps {
  text: string;
}

export const AboutMe = ({ text }: AboutMeProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <div className="text-left">
        <h3 className="mt-8 p-2 bg-gray-500 hover:bg-gray-400 text-center font-bold cursor-default">
          {t('GAME_ABOUT_ME')}
        </h3>
        <div className="profileAboutMe text-gray-200 text-opacity-90">{text}</div>
      </div>
    </>
  );
};
