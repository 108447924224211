import moment from 'moment';

import { CommentProps } from '../../../shared-types/NewspaperTypes';
import { useUser } from '../../../hooks/use-user';
import Avatar from '../../../components/Avatar';
import { ThrashIcon } from '../../../components/Icons/ThrashIcon';

export interface CommentCardProps {
  comment: CommentProps;
  onDeleteComment: (id: number) => void;
}

export const CommentCard = ({ comment, onDeleteComment }: CommentCardProps) => {
  const handleDelete = () => {
    onDeleteComment(comment.ID);
  };
  const { user } = useUser();

  return (
    <li className="flex pt-3">
      <Avatar avatarId={comment.U_AVATAR} userId={comment.U_ID} className="w-12 h-10 sm:w-14 sm:h-14 mt-1 sm:mt-0" />

      <div className="ml-2 sm:ml-3 mb-3 p-3 rounded-lg bg-gray-700 w-full">
        <div className="font-bold w-full flex justify-between flex-col xs:flex-row">
          {comment.U_NAME}
          <div className="flex justify-center self-end items-center gap-1 font-normal text-xs mt-3 xs:mt-0">
            <time>{moment(comment.DATE).format('LLL')}</time>
            {Number(comment.U_ID) === user.id ? (
              <button
                onClick={handleDelete}
                className="hover:bg-red-500 hover:bg-opacity-40 p-1 rounded-lg transition-all"
              >
                <ThrashIcon className="cursor-pointer text-red-600 h-4 w-4 sm:h-5 sm:w-5" />
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
        <p className="mt-2 pr-4 break-all">{comment.COMMENT}</p>
      </div>
    </li>
  );
};
