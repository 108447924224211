import { useTranslation } from 'react-i18next';

import { SectionHeader } from '../../components/SectionHeader';

import { FeedBackground } from '../../assets/Styles/Styles';
import { PageContainer } from '../../components/PageContainer';
import { ContributorCard } from './ContributorCard';
import { fromCDN } from '../../helpers/Images';

const logo = fromCDN.fetchStaticImgImage('/logo');

const ourTeam = [
  {
    img: logo,
    name: 'OA',
    title: 'Creator / Engineer',
  },
  {
    img: logo,
    name: 'BS',
    title: 'FrontEnd Engineer',
  },
  {
    img: logo,
    name: 'TP',
    title: 'Visuals / Design',
  },
];

const contributors = [
  { role: 'Bug hunting / Content', user: { id: 40 } },
  { role: 'Tester', user: { id: 34 } },
  { role: 'Tester / Content', user: { id: 36 } },
  { role: 'Tester / Content', user: { id: 37 } },
  { role: 'Tester / Content', user: { id: 38 } },
  { role: 'Tester / Content', user: { id: 39 } },
  { role: 'Tester', user: { id: 43 } },
  { role: 'Content / Talisman', user: { id: 44 } },
  { role: 'Tester', user: { id: 45 } },
  { role: 'Tester', user: { id: 46 } },
  { role: 'Tester', user: { id: 48 } },
  { role: 'Tester / Content', user: { id: 49 } },
  { role: 'Tester', user: { id: 50 } },
  { role: 'Tester / Bug hunting', user: { id: 52 } },
];

export const AboutUs = () => {
  const { t } = useTranslation('common');

  return (
    <PageContainer style={FeedBackground()}>
      <SectionHeader title={t('GAME_FOOTER_ABOUT')} titleClass="text-xl" />

      <h2 className="text-gray-200 text-xl text-center mt-5 ">Our Team & Vision</h2>
      <div className="text-white w-full text-center p-4 gap-3 mt-12">
        <div className="text-gray-200 text-sm">
          <p>
            We are bunch of specialists got to know each other from genre and enjoying making games to allow people have
            their fun after a stressful workday.
          </p>
          <p className="text-sm text-gray-200">
            - Our goal/vision is to provide social, strategical role play based massively multiplayer strategy game can
            be accessed by mobile app/browser or a desktop computer with ease.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 content-center text-center md:grid md:grid-cols-3 justify-center p-3  mx-auto gap-4 mb-10 sm:mb-8 gap-x-4">
        {ourTeam.map((people) => {
          return (
            <>
              <div className="gap-6 mt-12 justify-center w-full">
                <span className="text-gray-100  gap-4 text-sm font-bold">{people.name}</span>
                <img src={people.img} alt="logo" className="h-14 mx-auto" />
                <div className="text-gray-200  gap-4 text-sm">
                  <span>{people.title}</span>
                </div>
              </div>
            </>
          );
        })}
      </div>

      <div className="text-white w-full text-center  gap-3 mt-12">
        <h2 className="text-lg mb-3 font-semibold">Special Thanks</h2>
        <div className="text-gray-200">
          <p>
            To the many loyal citizens who have volunteered their time over the years to crew of eDominations or
            participate in the development. Without their help, there's no way we'd be where we are today.
          </p>
          <p>
            We'd also like to thank our supporters, you keep eDominations alive and with your support we're not only
            able to keep eDominations alive, but expand it and grow this family bigger.
          </p>
        </div>
      </div>

      <div className="text-gray-200 text-center mt-8 border-t border-t-slate-400 pt-6 pb-4">
        <h2 className="text-lg font-semibold mb-3">Collaborators</h2>
        <p>Volunteers, contributors. People among you!</p>
      </div>

      <div className="border-t border-t-slate-400 mt-4 pt-8 grid grid-cols-2 sm:grid-cols-6 m-3 gap-3 flex-wrap pb-10 justify-center">
        {contributors.map((contributor, i) => (
          <ContributorCard key={i} contributor={contributor} />
        ))}
      </div>
    </PageContainer>
  );
};
