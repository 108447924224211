import { FC } from 'react';
import { fromCDN } from '../../helpers/Images';

interface HoveredRegionData {
  region: string;
  resource: string;
  currentOwnerFlag: string;
  currentOwnerCountry: string;
  originalOwnerFlag: string;
  originalOwnerCountry: string;
}

interface InfoDialogProps {
  hoveredRegionData: HoveredRegionData;
  t: (key: string) => string;
}

export const InfoDialog: FC<InfoDialogProps> = ({ hoveredRegionData, t }) => {
  if (!Object.keys(hoveredRegionData).length) return null;

  const { region, resource, currentOwnerFlag, currentOwnerCountry, originalOwnerFlag, originalOwnerCountry } =
    hoveredRegionData;

  return (
    <div className="bg-slate-300 w-52 xs:w-72 top-2 right-4 absolute rounded-md shadow-2xl overflow-hidden">
      <div className="flex gap-3 justify-start items-center bg-gradient-to-r from-emerald-700 to-emerald-500 py-2 px-4">
        <img
          src={fromCDN.fetchStaticGameImage(`/flags/flat/64/${currentOwnerFlag}`)}
          alt="Country flag"
          className="h-12"
        />
        <div className="text-white font-bold">
          <h4 className="text-md">{region}</h4>
          <h6>{currentOwnerCountry}</h6>
        </div>
      </div>

      <ul className="py-2 px-4 space-y-2">
        <li className="flex gap-3 justify-start items-center">
          <img
            src={fromCDN.fetchStaticGameImage(`/flags/flat/24/${currentOwnerFlag}`)}
            alt="Country flag"
            className="h-7"
          />
          {t('GAME_OWNED_BY')}: {currentOwnerCountry}
        </li>
        <li className="flex gap-3 justify-start items-center">
          <img
            src={fromCDN.fetchStaticGameImage(`/flags/flat/24/${originalOwnerFlag}`)}
            alt="Country flag"
            className="h-7"
          />
          {t('GAME_RIGHTFUL_OWNER')}: {originalOwnerCountry}
        </li>
        <li className="flex gap-3 justify-start items-center">
          <img src={fromCDN.fetchStaticGameImage(`/resource/${resource}`)} alt="resource" className="h-7" />
          {t('GAME_RESOURCE')}: {resource}
        </li>
      </ul>
    </div>
  );
};
