import { AppModal } from '../../../components/AppModal';
import { useState } from 'react';
import { useHelper } from '../../../hooks/use-helper';
import { countryFlags } from '../../../helpers/Helpers';
interface ResistancePickSideProps {
  battleDetails: Record<any, any>;
  setChoice: (arg: 1 | 2) => void;
}
export const ResistancePickSide = ({ battleDetails, setChoice }: ResistancePickSideProps) => {
  const [open, setOpen] = useState(true);
  const { helpers } = useHelper();

  const body = () => {
    return (
      <div className="grid grid-cols-2 text-gray-200 p-10">
        <div className="text-center  default-transition cursor-pointer">
          <p
            data-testid="aggressor-side"
            onClick={() => {
              setChoice(1);
            }}
          >
            {countryFlags(battleDetails.AGRESOR, 'w-20 h-20 mx-auto')}
            {helpers.Countries[battleDetails.AGRESOR].Name}
          </p>
        </div>
        <div className="text-center default-transition cursor-pointer">
          <p
            data-testid="defender-side"
            onClick={() => {
              setChoice(2);
            }}
          >
            {countryFlags(battleDetails.DEFENDER, 'w-20 h-20 mx-auto')}
            {helpers.Countries[battleDetails.DEFENDER].Name}
          </p>
        </div>
      </div>
    );
  };

  // @ts-ignore
  return (
    <AppModal
      // @ts-ignore
      title=""
      // @ts-ignore
      body={body()}
      open={open}
      // @ts-ignore
      setOpen={setOpen}
      // @ts-ignore
      backgroundStyle={{
        backgroundColor: '#1e293b',
      }}
      shouldHaveCloseButton={false}
    />
  );
};
