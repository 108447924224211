import { Divider } from './Divider';
import { Stars } from '../containers/Util/Icons/star/Stars';
import {TFunction} from "i18next";
import React from "react";
interface ItemsDataTipProps{
    translation: TFunction,
    itemNumber: string,
    name: string,
    quality: number,
    image: React.ReactNode,
    description: string,
    firepower: number,
    firepowerMultiplier: string,
    meta: string
}
export const ItemsDataTip = ({
  translation,
  itemNumber,
  name,
  quality,
  image,
  description,
  firepower,
  firepowerMultiplier,
  meta,
}: ItemsDataTipProps) => {
  let divider: null | React.ReactNode = <Divider margin="m-2" />;
  let firePowerString = translation('GAME_FIRE_POWER');
  if (itemNumber === '14' || itemNumber === '4') {
    firePowerString = translation('GAME_ENERGY_RESTORE');
  }
  if (itemNumber === '8' || itemNumber === '11') {
    firePowerString = translation('GAME_ENERGY');
  }
  if (itemNumber === '15') {
    firePowerString = translation('GAME_FIRE_DAMAGE');
    firepowerMultiplier = '+' + firepowerMultiplier;
  }
  if (itemNumber === '1' || itemNumber === '2' || itemNumber === '3') {
    divider = null;
    firePowerString = '';
  }

  return (
    <div className="block w-48 text-center">
      <span className="justify-center text-center mt-3">{name}</span>
      <div className="flex justify-center text-center mt-3"><Stars totalStars={quality}/></div>
      <p className="justify-center text-center mt-3">{image}</p>
      <p className="justify-center text-center mt-3">{description}</p>
      {divider}
      <p>{firePowerString}</p>
      <p className="text-white bg-yellow-800 rounded-lg m-2">{firepowerMultiplier}</p>
      <p>{meta}</p>
    </div>
  );
}
