import React from 'react';
import { useTranslation } from 'react-i18next';
import { authApi } from '../../../api';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { useUser } from '../../../hooks/use-user';

export default function JoinParty({ partyId, setterForParent }) {
  const { t } = useTranslation('common');
  const { setUser } = useUser();
  const handleJoin = () => {
    authApi
      .post('/api/parties/join/' + partyId)
      .then((resp) => {
        setUser(resp.data.USER);
        setterForParent(resp.data.PARTY);
        return ApplicationAlert(t('GAME_SUCCESS')).success();
      })
      .catch((err) => {});
  };

  return (
    <>
      {Number(partyId) !== 0 && (
        <div className={'float-right mt-0 sm:-mt-4 mr-12'}>
          <button onClick={() => handleJoin()} className={'default-green-button absolute text-gray-800'}>
            {t('GAME_UNIT_JOIN_UNIT')}
          </button>
        </div>
      )}
    </>
  );
}
