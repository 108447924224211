import { AllowedLanguagesReturnTypes } from '../shared-types/shared-types';
import { TFunction } from "i18next";
interface AllowedLanguagesProps{
  t: TFunction
}
export function allowedLanguages(props: AllowedLanguagesProps): AllowedLanguagesReturnTypes[] {

  const { t } = props

  return [
    {
      label: t('GAME_ENGLISH'),
      code: 'en',
    },
    {
      label: t('GAME_GERMAN'),
      code: 'de',
    },
    {
      label: t('GAME_SLOVENIAN'),
      code: 'si',
    },
    {
      label: t('GAME_TURKISH'),
      code: 'tr',
    },
  ];
}
