import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { countryFlags } from '../../../../helpers/Helpers';
import CirclePlusIcon from '../../../Util/Icons/CirclePlusIcon';
import { defaultTransition } from '../../../../constants/constants';

import { fromCDN } from '../../../../helpers/Images';
import {useHelper} from "../../../../hooks/use-helper";

export default function MyMoney({ user,translation }) {
  let navigate = useNavigate();
  const { helpers } = useHelper()
  return (
    <>
      <h3 className={'text-left mt-8 font-bold'}>{translation('GAME_CURRENT_LOCATION_CURRENCY_GOLD')}</h3>
      <div className={'w-2/2 flex mt-3'}>
        <div
          className={
            'grid w-1/2 m-2 placeholder-gray-400 text-gray-200 bg-gradient-to-br from-yellow-300/60 to-yellow-500/60 shadow-inner p-2 rounded-xl'
          }
        >
          <div className={'w-2/2 text-center flex mx-auto'}>
            <img src={fromCDN.fetchStaticGameImage('/icons/gold-icon')} className={'w-8 h-8'} />{' '}
            <span className={'ml-1 mt-2 font-bold'}>{translation('')}</span>
          </div>
          <div className={'w-2/2 text-center font-bold'}>
            <p className={'ml-1'}>{user['GOLD'].toFixed(2)}</p>
            <p
              data-tip
              data-for={'buygold'}
              onClick={() => navigate('/store')}
              className={'mt-2 cursor-pointer ' + defaultTransition}
            >
              <CirclePlusIcon classNames={'mx-auto h-7 w-7 text-green-500 bg-black/40 hover:bg-black/60 rounded p-1'} />
            </p>
          </div>

          <ReactTooltip id={'buygold'} effect="solid" place="bottom" data-html={true}>
            <p className={'font-bold text-center'}>{translation('GAME_BUY_MORE_GOLD')}</p>
          </ReactTooltip>
        </div>

        <div
          className={
            'grid w-1/2 m-2 placeholder-gray-400 text-gray-200 bg-gradient-to-br from-indigo-600/30 to-red-700/30 shadow-inner p-2 rounded-xl'
          }
        >
          <div className={'w-2/2 text-center flex mx-auto mt-3'}>
            <p>{countryFlags(user['COUNTRY'], 'mx-auto mt-0.5')}</p>
            <p className={'ml-1'}>{helpers.Countries[user['COUNTRY']]['Currency']}</p>
          </div>
          <div className={'w-2/2 text-center font-bold'}>
            {user['W_' + helpers.Countries[user['COUNTRY']]['Currency']]}
            <p
              data-tip
              data-for={'buycurrency'}
              onClick={() => navigate('/business/monetary-market/' + user['COUNTRY'] + '/1/1')}
              className={'mt-2 cursor-pointer ' + defaultTransition}
            >
              <CirclePlusIcon classNames={'mx-auto h-7 w-7 text-green-500 bg-black/40 hover:bg-black/60 rounded p-1'} />
            </p>
          </div>
        </div>
      </div>

      <h3 className={'text-left mt-8 font-bold'}>{translation('GAME_ALL_CURRENCY')}</h3>
      <div className={'text-center mt-3 justify-center grid grid-cols-3 sm:grid-cols-5'}>
        {Object.keys(helpers.Countries).map((item, key) => {
          if (user['W_' + helpers.Countries[item]['Currency']] > 0) {
            return (
              <Fragment key={key}>
                <div
                  className={
                    'grid w-2/2 m-2 placeholder-gray-400 text-gray-200 bg-gradient-to-br from-indigo-600/30 to-red-700/30 shadow-inner p-2 rounded-xl'
                  }
                  key={key}
                >
                  <div className={'w-2/2 text-center flex mx-auto'}>
                    <p>{countryFlags(item, 'mx-auto mt-0.5')}</p>
                    <p className={'ml-1'}>{helpers.Countries[item]['Currency']}</p>
                  </div>
                  <div className={'w-2/2 text-center font-bold'}>
                    {user['W_' + helpers.Countries[item]['Currency']]}
                    <p
                      data-tip
                      data-for={'buycurrency'}
                      className={'mt-2 cursor-pointer ' + defaultTransition}
                      onClick={() => navigate('/business/monetary-market/' + item + '/1/1')}
                    >
                      <CirclePlusIcon
                        classNames={'mx-auto h-7 w-7 text-green-500 bg-black/40 hover:bg-black/60 rounded p-1'}
                      />
                    </p>
                  </div>

                  <ReactTooltip id={'buycurrency'} effect="solid" place="bottom" data-html={true}>
                    <p className={'font-bold text-center'}>{translation('GAME_BUY_MORE_CURRENCY')}</p>
                  </ReactTooltip>
                </div>
              </Fragment>
            );
          }
        })}
      </div>
    </>
  );
}
