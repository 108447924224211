import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { authApi } from '../../../../api';
import { PageContainer } from '../../../../components/PageContainer';
import {imageAsBackground} from '../../../../helpers/image-as-background';
import { SectionHeader } from '../../../../components/SectionHeader';
import { fromCDN } from '../../../../helpers/Images';
import PresidentialElectionOpen from './PresidentialElectionsOpen';
import { useHelper } from '../../../../hooks/use-helper';

export default function PresidentialElections() {
  let params = useParams();
  const [countryId, setCountryId] = useState(params.countryId);
  const { helpers } = useHelper();
  const electionType = helpers.Game.Election.Types.Presidency;
  const { t } = useTranslation('common');
  const [selectedMonth, setSelectedMonth] = useState();
  const [electionMonths, setElectionMonths] = useState();

  const getMonths = () => {
    authApi
      .get('/api/elections/' + electionType + '/months')
      .then((resp) => {
        setElectionMonths(resp.data);
      })
      .catch((err) => {});
  };
  const handleMonthPick = (selectedMonth) => {
    setSelectedMonth(selectedMonth);
  };
  useEffect(() => {
    getMonths();
  }, []);

  return (
    <>
      <PageContainer
        style={imageAsBackground(fromCDN.fetchStaticImage('t_electionsbg2', 'svg'))}
        children={
          <>
            <SectionHeader title={t('GAME_COMMUNITY_ELECTIONS_PRESIDENTIAL')} />
            <div className={'text-white p-5 break-words grid grid-cols-3 sm:grid sm:grid-cols-5 gap-4 '}>
              {electionMonths &&
                electionMonths.map((election) => {
                  if (election.TYPE === electionType) {
                    return (
                      <div
                        key={Math.random()}
                        onClick={() => {
                          handleMonthPick(election.ID);
                        }}
                        className={'default-transition bg-gray-700 p-2 cursor-pointer hover:bg-gray-800'}
                      >
                        {t('GAME_MONTH_' + election.MONTH)} - {election.YEAR}
                      </div>
                    );
                  }
                })}
            </div>
            {selectedMonth && <PresidentialElectionOpen electionMonth={selectedMonth} countryId={countryId} />}
          </>
        }
      />
    </>
  );
}
