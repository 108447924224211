import { Popover, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { themeButtonClass } from '../constants/constants';
interface PopoverThemeProps {
  additionalClasses?: string;
  buttonClass?: string;
  buttonColor?: string;
  buttonText?: string;
  content: ReactNode | string;
  dataCy?: string;
}
export const PopoverTheme = ({
  additionalClasses = 'h-32 min-h-32 w-72',
  buttonClass = themeButtonClass,
  buttonColor = ' bg-green-300',
  buttonText = '',
  content = '',
  dataCy = '',
}: PopoverThemeProps) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={
              `
                ${open ? '' : 'text-opacity-90'}
                ` +
              buttonClass +
              buttonColor
            }
            data-testid={dataCy}
          >
            <span>{buttonText}</span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 max-w-sm  px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-3xl ">
              <div
                className={
                  'overflow-hidden rounded-lg  shadow-lg ring-1 bg-gray-200 ring-black ring-opacity-5 h-32 ' +
                  additionalClasses
                }
              >
                <div className="m-5">{content}</div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
