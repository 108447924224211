import React from 'react';
import { fromCDN } from './Images';
import {  GetWhichSidePlayerCanFightProps, PercentProps } from '../shared-types/helper-types';
import { UserProps } from '../shared-types/user-type';
import { BATTLE_SIDES } from '../constants/enum';

export const percent = (x: PercentProps['x'], total: PercentProps['total']): number => {
  if (x === 0) {
    return 0;
  }
  const percentage = (x / total) * 100;
  if (percentage > 100) {
    return 100;
  }
  return percentage;
};



export const getWhichSidePlayerCanFight = ({
  playerLocatedCountry,
  attackers,
  defenders,
  agresor,
  defender,
}: GetWhichSidePlayerCanFightProps): BATTLE_SIDES.ATTACKER | BATTLE_SIDES.DEFENDER => {
  let attackerAllies = attackers.split(',');
  let defenderAllies = defenders.split(',');

  if (agresor === playerLocatedCountry) {
    return BATTLE_SIDES.ATTACKER;
  }
  if (defender === playerLocatedCountry) {
    return BATTLE_SIDES.DEFENDER;
  }

  let side = false;

  attackerAllies.forEach((item, key) => {
    if (parseInt(item) === playerLocatedCountry) {
      side = true;
    }
  });

  if (side) {
    return BATTLE_SIDES.ATTACKER;
  }
  defenderAllies.forEach((item, key) => {
    if (parseInt(item) === playerLocatedCountry) {
      side = true;
    }
  });

  if (side) {
    return BATTLE_SIDES.DEFENDER;
  }

  return BATTLE_SIDES.ATTACKER;
};

export const countryFlags = (
  flagId: UserProps['COUNTRY'],
  additionalClasses = '',
  shouldOverrideDefault = false,
): JSX.Element => {
  if (shouldOverrideDefault) {
    return <img className={additionalClasses} src={fromCDN.fetchStaticGameImage(`/flagscountry/${flagId}`)} />;
  }
  return (
    <img className={'h-5 w-5 ' + additionalClasses} src={fromCDN.fetchStaticGameImage(`/flagscountry/${flagId}`)} />
  );
};

export const resourceImages = (resourceString: string, additionalClasses = ''): JSX.Element => {
  return (
    <img className={`h-5 w-5 ${additionalClasses}`} src={fromCDN.fetchStaticGameImage(`/resource/${resourceString}`)} />
  );
};

export const rankImages = (militaryRank: number, additionalClasses?: string): JSX.Element => {
  return <img className={additionalClasses} src={fromCDN.fetchStaticGameImage(`/ranks/${militaryRank}`)} />;
};

export const numberFormat = (number: number): string => {
  return Intl.NumberFormat().format(number);
};

export const roundIcons = (roundId: number | string, additionalClasses = '') => {
  switch (roundId) {
    case 1:
      roundId = 'wep';
      break;
    case 2:
      roundId = 'tank';
      break;
    case 3:
      roundId = 'air';
      break;

    default:
      roundId = 'wep';
  }
  return <img className={`h-5 w-5 ${additionalClasses}`} src={fromCDN.fetchStaticGameImage(`/battle/${roundId}`)} />;
};

export function storageItems({ user }: { user: UserProps }) {
  let maxQuality = 5;
  let items = {
    1: [0, user['I1_Q1'], 0, 0, 0, 0],
    2: [0, user['I2_Q1'], 0, 0, 0, 0],
    3: [0, user['I3_Q1'], 0, 0, 0, 0],
    4: [0, user['I4_Q1'], user['I4_Q2'], user['I4_Q3'], user['I4_Q4'], user['I4_Q5']],
    5: [0, user['I5_Q1'], user['I5_Q2'], user['I5_Q3'], user['I5_Q4'], user['I5_Q5']],
    6: [0, user['I6_Q1'], user['I6_Q2'], user['I6_Q3'], user['I6_Q4'], user['I6_Q5']],
    7: [0, user['I7_Q1'], user['I7_Q2'], user['I7_Q3'], user['I7_Q4'], user['I7_Q5']],
    8: [0, user['I8_Q1'], user['I8_Q2'], user['I8_Q3'], user['I8_Q4'], user['I8_Q5']],
    9: [0, user['I9_Q1'], user['I9_Q2'], user['I9_Q3'], user['I9_Q4'], user['I9_Q5']],
    10: [0, user['I10_Q1'], user['I10_Q2'], user['I10_Q3'], user['I10_Q4'], user['I10_Q5']],
    11: [0, 0, 0, 0, 0, user['I11_Q5']],
    12: [0, 0, 0, 0, 0, user['I12_Q5']],
    13: [0, user['I13_Q1'], user['I13_Q2'], user['I13_Q3'], user['I13_Q4'], user['I13_Q5']],
    14: [0, user['I14_Q1'], user['I14_Q2'], user['I14_Q3'], user['I14_Q4'], user['I14_Q5']],
    15: [0, user['I15_Q1'], user['I15_Q2'], user['I15_Q3'], user['I15_Q4'], user['I15_Q5']],
    16: [0, 0, 0, 0, 0, user['I16_Q5']],
    17: [0, 0, 0, 0, 0, user['I17_Q5']],
    18: [0, 0, 0, 0, 0, user['I18_Q5']],
    19: [0, user['I19_Q1'], user['I19_Q2'], user['I19_Q3'], user['I19_Q4'], user['I19_Q5']],
  };

  return [items, maxQuality];
}

export function getRoundAvailableItems({ user, roundId }: { user: UserProps; roundId: number }) {
  const availableItems = {
    1: [5, 15, 105, 16],
    2: [5, 6, 12, 15, 105, 106, 17],
    3: [5, 6, 7, 12, 15, 105, 106, 107, 18],
  };
  let myItems = storageItems({ user: user })[0];
  let tmpAvailableItems: any[] = [];

  // @ts-ignore
  availableItems[roundId].forEach((item, key) => {
    // @ts-ignore
    tmpAvailableItems.push({ [item]: myItems[item] });
  });

  // @ts-ignore
  return [tmpAvailableItems, myItems[11]];
}

export const printMarkup = (markup: string) => {
  return {
    __html: markup,
  };
};
