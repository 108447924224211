import { UserProps } from './user-type';
import { ValueOf } from './util';

export type BattlesProps = Record<number, BattlesObjectProps>;

export const BATTLE_STATUS = {
  ONGOING_BATTLE: 0,
  AGGRESSOR_WON: 1,
  DEFENDER_WON: 2,
} as const;

export interface BattlesObjectProps {
  AGRESOR: number;
  ALLIES_A: string;
  ALLIES_D: string;
  AlliesAttackerArr: [];
  AlliesDefenderArr: [];
  DATE: string;
  DEFENDER: number;
  END: string;
  ID: number;
  POWER: number;
  PercentageScores: {
    A: number;
    D: number;
  };
  REGION: number;
  ROUND: number;
  ROUND_A: number;
  ROUND_D: number;
  RoundScores: {
    A: number;
    D: number;
  };
  STARTED_BY: UserProps['id'];
  STATUS: ValueOf<typeof BATTLE_STATUS>;
  VAL1: number;
  VAL2: number;
  VAL3: number;
  WAR: number;
  roundDamages: {
    ATTACKER_PERCENTAGE: number;
    ATT_TOTAL: string;
    DEFENDER_PERCENTAGE: number;
    DEFENDER_TOTAL: number;
  };
}
