import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { avatarClass, BASE_URL, BEARER, TEST_LIVE_URL, themeButtonClass } from '../../../../constants/constants';
import { useTranslation } from 'react-i18next';

export default function AssignMinister({ countryId, setPoliticsDetail, ministerType }) {
  const [ministerUserId, setMinisterUserId] = useState();
  const { t, i18n } = useTranslation('common');
  function handleSetMinisterUserId(input) {
    setMinisterUserId(input);
  }

  async function assignMinister() {
    await axios
      .post(
        BASE_URL + '/api/country/ministry/update',
        {
          countryId: Number(countryId),
          ministerId: Number(ministerUserId),
          ministryType: Number(ministerType),
        },
        {
          headers: { Authorization: 'Bearer ' + BEARER },
        },
      )
      .then((response) => {
        setPoliticsDetail(response.data);
      })
      .catch((error) => {});
  }

  useEffect(() => {}, [ministerUserId]);
  return (
    <>
      <div className={'cursor-pointer default-transition text-center'}>
        <img className={' w-20 h-20  mx-auto ' + avatarClass} src={TEST_LIVE_URL + '/upload/citizen/0.jpg'} />
        <span className={'font-bold'}>
          <input
            className={
              'text-center appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-100 focus:border-green-800 focus:z-10 sm:text-sm'
            }
            type={'text'}
            onChange={(e) => handleSetMinisterUserId(e.target.value)}
            placeholder={t('GAME_COUNTRY_5' + ministerType) + ' ID'}
          />
        </span>
        <span>
          <button className={'bg-green-300 m-2 ' + themeButtonClass} onClick={() => assignMinister()}>
            {t('GAME_ASSIGN_MINISTER')}
          </button>
        </span>
      </div>
    </>
  );
}
