import { useTranslation } from 'react-i18next';
import React, { Fragment, useState } from 'react';

import { storageItems } from '../../../helpers/Helpers';
import { Divider } from '../../../components/Divider';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { Loader } from '../../../components/Loader';
import { authApi } from '../../../api';
import ActiveHouse from './ActiveHouse';
import { useHelper } from '../../../hooks/use-helper';
import { useUser } from '../../../hooks/use-user';

export default function MyActiveHouses() {
  const { user, setUser } = useUser();
  const { t } = useTranslation('common');
  const { helpers } = useHelper();
  const [myStorageItems, setMyStorageItems] = useState(storageItems({ user }));

  const [isLoading, setIsLoading] = useState();

  async function activate(quality) {
    if (myStorageItems[0][8][quality] === 0) {
      ApplicationAlert(t('GAME_DO_NOT_HAVE_HOUSE')).error();
      return;
    }

    setIsLoading(<Loader className={'w-8 h-8'} />);

    await authApi
      .post('/api/activatehouse', { activate: quality })
      .then((response) => {
        setUser(response.data.USER);
        ApplicationAlert(t(response.data.MESSAGE)).success();

        setMyStorageItems(storageItems({ user: response.data.USER }));
        setIsLoading(null);
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
        setIsLoading(null);
      });
  }

  let myRawMaterials = [];
  {
    for (let x = 1; x <= myStorageItems[1]; x++) {
      if (myStorageItems[0][8][x] >= 0) {
        let houseTimeLeft = Math.ceil((user['HOUSE' + x] - user['GameTimeStamp']) / 86400);
        if (houseTimeLeft > 1) {
          houseTimeLeft += ' ' + t('GAME_DAYS_LEFT');
        } else if (user['HOUSE' + x] < user['GameTimeStamp']) {
          houseTimeLeft = (
            <>
              {isLoading ? (
                isLoading
              ) : (
                <button
                  className="bg-green-700 hover:bg-green-600 text-gray-200 active:bg-green-800 text-sm font-bold transition ease-in-out   duration-300 hover:scale-105 py-3 px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                  onClick={() => activate(x)}
                >
                  {t('GAME_ACTIVATE')}
                </button>
              )}
            </>
          );
        } else {
          houseTimeLeft = Math.ceil((user['HOUSE' + x] - user['GameTimeStamp']) / 3600) + ' ' + t('GAME_HOURS_LEFT');
        }

        myRawMaterials.push(
          <Fragment key={x}>
            <ActiveHouse
              x={x}
              t={t}
              storageHouse={myStorageItems[0][8]}
              house={helpers.Items[8]}
              houseTimeLeft={houseTimeLeft}
            />
          </Fragment>,
        );
      }
    }
  }

  return (
    <div className="w-4/4 m-5">
      <p className="m-3 font-bold text-gray-100">{t('GAME_HOUSES')}</p>
      <Divider margin="m-3" />
      <div className="text-center sm:text-center grid grid-cols-2 sm:grid sm:grid-cols-5">{myRawMaterials}</div>
    </div>
  );
}
