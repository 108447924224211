import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { authApi } from '../../../../../api';
import ApplicationAlert from '../../../../../components/ApplicationAlert';
import {
  Employee,
  FetchEmployeeAndOffersResponse,
  JobOffer,
  PlaceOfferResponse,
  PostChangeJobOfferProps,
  PostChangeSalaryProps,
  RemoveOffersResponse,
} from '../types';

function postChangeSalary(employeeProps: PostChangeSalaryProps): Promise<FetchEmployeeAndOffersResponse> {
  return authApi.post(`/api/business/changesalary`, employeeProps);
}

function fetchEmployees(): Promise<FetchEmployeeAndOffersResponse> {
  return authApi.get('/api/business/myemployees');
}

function postRemoveOffer(): Promise<RemoveOffersResponse> {
  return authApi.post('/api/business/removeemployeeoffer', {
    employeeId: 1,
  });
}

function postPlaceJobOffer(body: PostChangeJobOfferProps): Promise<PlaceOfferResponse> {
  return authApi.post(`/api/business/addemployeeoffer`, body);
}

export function useEmployee() {
  const { t } = useTranslation('common');
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState<Employee | null>(null);
  const [jobOffers, setJobOffers] = useState<JobOffer | null>(null);

  useEffect(() => {
    setIsLoading(true);

    fetchEmployees()
      .then(({ data }) => {
        setEmployees(data.EMPLOYEES);
        setJobOffers(data.JOB_OFFERS);
      })
      .catch((error) => {
        console.error('Failed to fetch employees', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleRemoveJobOffer = async () => {
    try {
      const response = await postRemoveOffer();

      setJobOffers(response.data);
      ApplicationAlert(t('GAME_SUCCESS')).success();
    } catch (e: unknown) {
      const error = e as AxiosError<string>;

      console.error(error.response?.data ?? ('GAME_ERROR' as string));
      ApplicationAlert(t(error.response?.data ?? ('GAME_ERROR' as string))).error();
    }
  };

  const handlePlaceJobOffer = async (props: PostChangeJobOfferProps) => {
    try {
      const { data } = await postPlaceJobOffer(props);
      setJobOffers(data);

      ApplicationAlert(t('GAME_SUCCESS')).success();
    } catch (e: unknown) {
      const error = e as AxiosError<string>;

      console.error(error.response?.data ?? ('GAME_ERROR' as string));
      ApplicationAlert(t(error.response?.data ?? ('GAME_ERROR' as string))).error();
    }
  };

  const handleChangeSalary = async (employeeProps: PostChangeSalaryProps) => {
    try {
      const { data } = await postChangeSalary(employeeProps);

      setEmployees(data.EMPLOYEES);
      ApplicationAlert(t('GAME_SUCCESS')).success();
    } catch (e: unknown) {
      const error = e as AxiosError<string>;

      console.error(error.response?.data ?? ('GAME_ERROR' as string));
      ApplicationAlert(t(error.response?.data ?? ('GAME_ERROR' as string))).error();
    }
  };

  return {
    isLoading,
    employees,
    jobOffers,
    handleRemoveJobOffer,
    handlePlaceJobOffer,
    handleChangeSalary,
  };
}
