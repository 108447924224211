import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';

import { Divider } from '../../../../components/Divider';
import { authApi } from '../../../../api';
import { avatarClass } from '../../../../constants/constants';
import { LawsDescriptions } from './Laws/LawsDescriptions';
import { gameTimeZone } from '../../../../helpers/game-time';

import LawPropose from './Laws/LawPropose';
import { useHelper } from '../../../../hooks/use-helper';
import { UserRestrictions } from '../../../../constants/userRestrictions';
import { useUser } from '../../../../hooks/use-user';
import { Loader } from '../../../../components/Loader';
import { AvatarWithDefaultFallback } from '../../../../components/ImageWithFallback';

export default function Administration() {
  let params = useParams();
  let navigate = useNavigate();
  const currentCountry = params.id;

  const { t } = useTranslation('common');
  const { helpers } = useHelper();
  const { user } = useUser();

  const [laws, setLaws] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getLaws = () => {
    setIsLoading(true);

    authApi.get(`api/country/administration/${currentCountry}`).then((response) => {
      setLaws(response.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getLaws();
  }, [currentCountry]);

  return (
    <div id="administration" className="text-center">
      {UserRestrictions(user).isEligibleToCreateLaw(user.CITIZENSHIP, params.id) ? <LawPropose /> : ''}
      <h1 className="m-3 font-bold text-yellow-600">{t('GAME_COUNTRY_LAW_PROPOSALS')}</h1>
      <Divider margin="m-3" />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-3 mx-5 gap-4 text-center justify-center">
          {laws
            ? Object.values(laws).map((item) => {
                return (
                  <div
                    key={item.ID + item.TYPE}
                    className="grid p-3 bg-gray-100 hover:bg-gray-200 shadow-inner rounded-md default-transition"
                  >
                    <h1>{t('GAME_COUNTRY_A_TYPE_' + item.TYPE)}</h1>
                    <span className="absolute right-0 text-tiny text-gray-500">{item.DATE}</span>
                    <div
                      className="cursor-pointer default-transition p-1"
                      onClick={() => navigate('/profile/' + item['U_ID'])}
                    >
                      <AvatarWithDefaultFallback
                        className={`w-8 h-8 mx-auto ${avatarClass}`}
                        avatarId={item['U_AVATAR']}
                      />
                      <span className="font-bold">
                        {t('GAME_COUNTRY_LAW_PROPOSED')} {item['U_NAME']}
                      </span>
                    </div>
                    <p className="p-3">{LawsDescriptions({ law: item, helper:helpers, t })}</p>
                    <div className="flex flex-grid w-3/3 gap-4">
                      <div className="w-2/4">
                        <div className="border border-gray-300 p-3">
                          <p className="break-words text-gray-500">
                            <Countdown date={gameTimeZone().addOneDay({ date: item.DATE })} daysInHours={true} />
                          </p>
                        </div>
                      </div>
                      <div className="text-right w-1/6 ml-6 -mt-1">
                        <span className="absolute ml-9 bg-green-600 rounded-full p-1">{item.VOTE_YES}</span>
                        <button className="default-green-button">{t('GAME_COUNTRY_LAW_VOTE_YES')}</button>
                      </div>
                      <div className="w-1/6 -mt-1 -ml-3 text-right">
                        <span className="absolute ml-9 bg-red-600 rounded-full p-1">{item.VOTE_NO}</span>
                        <button className="default-red-button">{t('GAME_COUNTRY_LAW_VOTE_NO')}</button>
                      </div>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      )}
    </div>
  );
}
