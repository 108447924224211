import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from '../../../api';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { imageAsBackground } from '../../../helpers/image-as-background';

import { fromCDN } from '../../../helpers/Images';
import { Loader } from '../../../components/Loader';

export const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string | null>(null);
  const history = useNavigate();

  function callApi() {
    if (!emailAddress) {
      ApplicationAlert('Please fill the form').error();
      return;
    }
    setIsLoading(true);
    axios
      .post('/api/forgot-password', { email: emailAddress })
      .then((response) => {
        history('/');
        setIsLoading(false);
        ApplicationAlert(response.data).success();
      })
      .catch((error) => {
        setIsLoading(false);
        ApplicationAlert(error.response.data).error();

        return false;
      });
  }
  return (
    <div
      className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      style={imageAsBackground(fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg'))}
    >
      <div
        className="max-w-md w-full space-y-8 p-5 rounded-lg shadow-md"
        style={imageAsBackground(fromCDN.fetchStaticImgImage('/table_item'))}
      >
        <div
          onClick={() => {
            history('/');
          }}
          className="cursor-pointer"
        >
          <img className="mx-auto h-24 w-auto" src={fromCDN.fetchStaticImgImage('/logo-l')} alt="Workflow" />
          <h2 className="mt-6 text-center  font-extrabold text-white">Confirm your e-mail</h2>
        </div>
        {isLoading ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : (
          <div className="mt-8 space-y-6">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="name"
                  type="email"
                  autoComplete="email"
                  required={true}
                  onChange={(e) => {
                    setEmailAddress(e.target.value);
                  }}
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
            </div>
            <div className="text-right">
              <Link to="/" className="font-medium text-white hover:text-indigo-200" data-testid="backtologin">
                Back to login
              </Link>
            </div>

            <button
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={(e) => {
                callApi();
              }}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3" />
              Send password link
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
