export const LatestUpdatesConfig = [
  {
    id: 1,
    date: '2022-11-06',
    writtenBy: 'eDomLegacy',
    title: 'Slow but effective, effective and fun!',
    shortDescription:
      'There are many in place, some of those are increase life quality and some of those are gamechanger',
    longDescription: () => {
      return (
        <>
          <p>
            We had been all the time working on making everything easily understandable, and beautiful at the same time.
          </p>
          <ul className="mx-auto list-disc mt-5 ml-5">
            <li>
              Changed factory mechanism entirely, so the companies are given to you when you create your account. With
              those, you require worker tokens to work and skill points to increase your productivity. Skill points can
              be earned on many different events during the game. Each factory will have 8 hours of cool down so that
              you can do something else in the meantime
            </li>
            <li>
              We have removed travel condition for hitting on any side of the battlefield. This was long going confusion
              for every newbie or even elders sometimes. On each battlefield, all you have to do is to select side to
              win the enemy! we also have thought our competitor players so having yourself located in the region, will
              provide bonuses but that's something that you should choose to do.
            </li>
            <li>
              Most of the features are live feed!. From battlefield to your chat and even your events are live all the
              time. This allows us to present you everything so nicely.
            </li>
          </ul>
          <br />
          <br />
          <br />
          <p>You have said enough, what is coming next ?</p>

          <br />
          <br />
          <br />
          <p>
            We still have a lot more to do and we are very small group to get things done. We do not want to release
            something truly half baked, so we work harder to make it faster and give it to you to play and enjoy.
          </p>
          <ul className="mx-auto list-disc mt-5 ml-5">
            <li>
              There will be battlefield notifications to your email based on your selection and many other
              personalization coming with it, like email me when i get a message and alike.
            </li>
            <li>
              As you know we can not handle the costs without having any income, do accomplish that, we will provide
              some store items but they are not pay to win. You will have small pay to progress items as well as some
              customization.
            </li>
          </ul>
        </>
      );
    },
  },
  {
    id: 1,
    date: '2022-05-14',
    writtenBy: 'eDomLegacy',
    title: 'Just a little bit of exposure what will be in v2',
    shortDescription: 'First we have announced that we will be developing v2, here is what we have',
    longDescription: () => {
      return (
        <>
          <p>
            There are major removals from a modules that had never found its place. In order to build the genre on a
            game changer scale, we have to let some features go.
          </p>
          <ul className="mx-auto list-disc mt-5 ml-5">
            <li>We have removed organizations</li>
            <li>
              We also have removed pay to win store items. There will be no more gold purchases or packages. Thus,
              eDomium also removed.
            </li>
            <li>
              We have removed Alliances. They may come back with greater functionality. As currently it is pretty
              useless.
            </li>
            <li>
              No more 'unlimited strength'. Strength is now capped to 1000. You still can upgrade your building to get
              there as soon as possible. When you miss a log-in thus you miss a workout, your Strength will return back
              to 10. Means your dedication is the key here.
            </li>
            <li>We have also removed military unit levels and alike.</li>
            <li>We have removed donations,contracts and any other system that affected the game flow.</li>
            <li>
              We have removed donations,contracts and any other system that affected the game flow. There will be a
              greater system to provide benefits to your own citizens with a decent systems like bank, army stash and
              many more like this.
            </li>
          </ul>
        </>
      );
    },
  },
];
