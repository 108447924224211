import { createPortal } from 'react-dom';
import { ThrashIcon } from '../../components/Icons/ThrashIcon';

const portalRoot = document.getElementById('portal');

export function Notifications({ children, className, signalToParent }) {
  return createPortal(
    <div
      className={`fixed p-3 rounded-md bg-gray-200 shadow-2xl max-w-xs shadow-black left-11 bottom-11 z-100 ${className}`}
    >
      <div onClick={() => signalToParent()}>
        <ThrashIcon className="absolute right-0.5 top-0.5 mx-auto w-5 h-5 hover:text-red-600 cursor-pointer transition-colors" />
      </div>
      {children}
    </div>,
    portalRoot,
  );
}
