import { SectionHeader } from '../../../components/SectionHeader';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  avatarClass,
  BASE_URL,
  BEARER,
  defaultTransition,
  TEST_LIVE_URL,
  themeButtonClass,
} from '../../../constants/constants';
import AlertIcon from '../../Util/Icons/AlertIcon';
import { Divider } from '../../../components/Divider';
import { CreateUnit } from './CreateUnit';
import { fromCDN } from '../../../helpers/Images';
import { useUser } from '../../../hooks/use-user';
import { getMilitaryUnits } from '../../../api/shared-api';

export const NoMilitaryUnit = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const { t, i18n } = useTranslation('common');
  const [unitList, setUnitList] = useState();
  const [createUnit, setCreateUnit] = useState();

  function handleCreateUnit() {
    setCreateUnit(<CreateUnit setCreateUnit={setCreateUnit} />);
    setUnitList(null);
  }

  useEffect(() => {
    setUnitList(getMilitaryUnits(user.COUNTRY));
  }, []);

  useEffect(() => {
    if (!createUnit) {
      setUnitList(getMilitaryUnits(user.COUNTRY));
    }
  }, [createUnit]);

  return (
    <>
      <div className={'min-h-screen max-w-13xl mx-auto'}>
        <div className="max-w-7xl mx-auto py-2  ">
          <SectionHeader title={t('GAME_MILITARY_UNIT')} />

          <div className={'justify-center text-center m-3 animate__animated animate__fadeIn'}>
            <div
              className="p-4 mb-4 mx-5 text-sm text-gray-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
              role="alert"
            >
              <AlertIcon classNames={'h-5 w-5 mx-auto'} />
              <p className={'font-bold text-indigo-800'}>{t('GAME_MILITARY_NO_UNIT_TITLE')}</p>
              <p>{t('GAME_MILITARY_NO_UNIT')}</p>
            </div>
            {createUnit ? (
              ''
            ) : (
              <button onClick={() => handleCreateUnit()} className={themeButtonClass + ' bg-green-300'}>
                {t('GAME_MILITARY_CREATE_UNIT')}
              </button>
            )}
          </div>
          <Divider />
          {unitList ? (
            <>
              <p className={'font-bold justify-center text-center'}>{t('GAME_MILITARY_JOIN_UNIT')}</p>
              <div className={'gap-x-4 h-full sm:grid sm:grid-cols-2  md:grid md:grid-cols-4  p-3  mx-auto  '}>
                {Object.values(unitList).map((item) => {
                  let muCover = `url(${TEST_LIVE_URL + '/upload/group/cover-' + item.MU_COVER + '.jpg'})`;

                  if (item.MU_COVER === 0) {
                    muCover = `url(${fromCDN.fetchStaticGameImage('/icons/military3', 'jpg')})`;
                  }

                  return (
                    <div
                      key={item.ID}
                      className={
                        'block justify-center text-center m-3  shadow-inner p-2 rounded-lg cursor-pointer ' +
                        defaultTransition
                      }
                      style={{
                        backgroundImage: muCover,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                      onClick={() => navigate('/military-unit/' + item.ID)}
                    >
                      <div className={'absolute font-bold text-lg text-white'}>
                        {t('GAME_MILITARY_MEMBERS_UNIT')} {item.MEMBERS}
                      </div>
                      <div>
                        <img
                          className={'mx-auto h-20 w-20 ' + avatarClass}
                          src={TEST_LIVE_URL + '/upload/group/' + item.AVATAR + '.jpg'}
                          alt={'avatar'}
                        />
                      </div>

                      <div>
                        <p className={'font-bold text-gray-200'}>{item.NAME}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            createUnit
          )}
        </div>
      </div>
    </>
  );
};
