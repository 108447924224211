import React, { ReactNode, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import axios from '../../../api';
import { setBearerToken } from '../../../constants/constants';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { imageAsBackground } from '../../../helpers/image-as-background';

import { validate } from '../../../helpers/Validations';
import { fromCDN } from '../../../helpers/Images';
import { Loader } from '../../../components/Loader';

export const Login = ({ signal = () => {} }) => {
  const { t } = useTranslation('common');
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState<ReactNode | null>(null);
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const callApi = (e: SyntheticEvent) => {
    signal();
    e.preventDefault();

    if (!validate.nonEmptyValues([email, password])) {
      ApplicationAlert('Please fill the forms').error();
    }
    setLoader(<Loader className="h-12 w-12 mt-5" />);
    axios
      .post('/api/login', { email, password })
      .then((response) => {
        setBearerToken(response.data.Bearer);
        setLoader(null);
        navigate('/feed');
        return;
      })
      .catch((error) => {
        if (error.response.data.hasOwnProperty('Reason')) {
          ApplicationAlert(
            `Reason: ${String(error.response.data['Reason'])}${
              error.response.data['Expire']
                ? `  ==>> Ban Will Expire: ${String(error.response.data['Expire'])} in Hours.`
                : ''
            }`,
            error.response.data['Error'],
          ).error();
        } else {
          ApplicationAlert(error.response.data).error();
        }
        setLoader(null);
        return;
      });
  };

  return (
    <>
      <div
        className="max-w-md mx-auto rounded-lg p-8 pb-12"
        style={imageAsBackground(fromCDN.fetchStaticImgImage('/table_item'))}
      >
        <h2 className="text-white text-base font-semibold text-center mt-4 mb-8">{t('GAME_LOGIN_TITLE')}</h2>

        <form onSubmit={callApi}>
          <div>
            <label htmlFor="email-address" className="relative text-gray-400 focus-within:text-gray-600 block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>

              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required={true}
                value={email}
                className="form-input block w-full p-3 rounded-t-lg text-gray-900 pl-14 focus:outline-none border border-gray-300 focus:border-gray-600"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={t('GAME_EMAIL_ADDRESS')}
              />
            </label>

            <div>
              <label htmlFor="email-address" className="relative text-gray-400 focus-within:text-gray-600 block">
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    onClick={() => {
                      setShowPassword(false);
                    }}
                  >
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path
                      fillRule="evenodd"
                      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      setShowPassword(true);
                    }}
                    className="cursor-pointer w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                      clipRule="evenodd"
                    />
                    <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                  </svg>
                )}

                <input
                  id="password"
                  name="password"
                  type={showPassword ? 'input' : 'password'}
                  autoComplete="current-password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  required={true}
                  value={password}
                  className="form-input block w-full p-3 rounded-b-lg text-gray-900 focus:outline-none border border-gray-300 focus:border-gray-600 pl-14"
                  placeholder={t('GAME_PASSWORD')}
                />
              </label>
            </div>
          </div>

          {loader ? (
            loader
          ) : (
            <>
              <div className="flex justify-between my-5">
                <div className="flex items-center gap-2 font-medium">
                  <input id="remember-me" name="remember-me" type="checkbox" className="cursor-pointer" />
                  <label htmlFor="remember-me" className="text-white cursor-pointer">
                    {t('GAME_REMEMBER')}
                  </label>
                </div>

                <Link to="/forgot-password" className="text-indigo-200 hover:text-indigo-400">
                  {t('GAME_FORGOT_PASSWORD')}
                </Link>
              </div>

              <div className="flex flex-col text-white gap-4 text-center">
                <button
                  type="submit"
                  name="submitlogin"
                  className="bg-green-600 hover:bg-green-700 py-2 font-semibold rounded-lg"
                >
                  {t('GAME_LOGIN')}
                </button>
                <Link
                  to="/register/1"
                  className="bg-indigo-600 hover:bg-indigo-700 py-3 font-semibold rounded-lg hover:scale-105 transition-all duration-500 transform"
                >
                  {t('GAME_REGISTER')}
                </Link>
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
};
export default Login;
