import { useEffect, useState, Fragment, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import emojiSn from 'emoji-to-short-name';

import { SectionHeader } from '../../components/SectionHeader';
import { Divider } from '../../components/Divider';
import ApplicationAlert from '../../components/ApplicationAlert';
import { authApi } from '../../api';
import { EmojiPicker } from '../../components/EmojiPIcker';
import { fromCDN } from '../../helpers/Images';
import { useUser } from '../../hooks/use-user';
import { MESSAGE_MAX_LENGTH } from '../../constants/enum';
import { RemainingCharactersHint } from '../../components/RemainingCharactersHint';
import { imageAsBackground } from '../../helpers/image-as-background';

export default function ReadMessages() {
  const params = useParams();
  const navigate = useNavigate();

  const textAreaRef = useRef();

  const { user, setUser } = useUser();

  const { t } = useTranslation('common');

  const [myPrivateMessages, setMyPrivateMessages] = useState('');
  const [message, setMessage] = useState('');
  const [cursorPosition, setCursorPosition] = useState(null);

  function handleMessageSend() {
    if (!message.length) {
      ApplicationAlert(t('GAME_CHAT_LENGTH_SHORT_ERROR')).info();
      return;
    }

    authApi
      .post(`/api/messages/conversation/send/${params.id}`, { message: emojiSn.encode(message) })
      .then((response) => {
        setMyPrivateMessages(response.data);
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });

    setMessage('');
  }

  function handleInsertEmoji(emoji) {
    const ref = textAreaRef.current;
    ref.focus();

    setMessage((prevMessage) => {
      const start = prevMessage.substring(0, ref.selectionStart);
      const end = prevMessage.substring(ref.selectionStart);
      const text = start + emoji.native + end;

      return text;
    });

    setCursorPosition(ref.selectionStart + emoji.native.length);
  }

  function getInbox() {
    authApi.get(`/api/messages/conversation/${params.id}`).then((response) => {
      setMyPrivateMessages(_.sortBy(response.data, 'ID').reverse());
    });
  }

  useEffect(() => {
    getInbox();
    if (user.UNREADMSG > 0) {
      setUser({ ...user, UNREADMSG: (user.UNREADMSG -= 1) });
    }
  }, []);

  useEffect(() => {
    textAreaRef.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  return (
    <div
      className="min-h-screen max-w-13xl mx-auto bg-fixed"
      style={imageAsBackground(fromCDN.fetchStaticImage('t_messagesbg', 'svg'))}
    >
      <div className="max-w-7xl mx-auto py-2">
        <SectionHeader title={t('GAME_MESSAGES')} />
        <div className="m-5">
          <div className="relative mb-1">
            <textarea
              maxLength={MESSAGE_MAX_LENGTH}
              data-testid="writemessage"
              className="h-24 text-md shadow-inner rounded-md block w-full pl-3 pr-7 py-2 bg-indigo-800/20 focus:bg-indigo-900/40 border-gray-300 transition ease-in-out placeholder-gray-400 text-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-600 focus:border-transparent sm:text-sm"
              value={message}
              ref={textAreaRef}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t('GAME_MESSAGE_INPUT_PLACEHOLDER')}
            />
            <EmojiPicker onEmojiSelect={handleInsertEmoji} />
          </div>
          <RemainingCharactersHint text={message} color="text-gray-200" />
          <button
            className="mt-4 bg-green-700 hover:bg-green-500 text-white active:bg-gray-700 text-sm font-bold transition ease-in-out duration-100 hover:scale-105 p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 w-16 h-10"
            onClick={() => handleMessageSend()}
          >
            {t('GAME_SEND')}
          </button>
        </div>
        <Divider margin="mb-8" />

        {myPrivateMessages &&
          Object.values(myPrivateMessages).map((item, i) => {
            let bg = '';
            let isBold = '';
            if (item['TOTAL'] - item['READ'] > 0) {
              bg = (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-green-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                </svg>
              );
              isBold = 'bg-gray-100 p-3 rounded-lg font-bold text-gray-600';
            }

            // const linksWithArticleCard = replaceLinkWithArticleCard(emojiSn.decode(item.MESSAGE));
            // const html = replaceLinkWithBattleCard(linksWithArticleCard);
            const html = emojiSn.decode(item.MESSAGE);
            return (
              <Fragment key={i}>
                <div className="relative flex p-5">
                  <div className="relative rounded-lg h-20 w-20">
                    {item.FROM === user.id ? (
                      <img
                        src={fromCDN.fetchUserAvatar(user.AVATAR)}
                        className="bg-white border shadow-lg rounded hover cursor-pointer light-transition"
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <img
                        src={fromCDN.fetchUserAvatar(item.AVATAR)}
                        className="bg-white border shadow-lg rounded hover cursor-pointer light-transition"
                        onClick={() => navigate('/profile/' + item.U_ID)}
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </div>

                  <div className="flex flex-col w-full bg-red-900/40 pt-2 pb-2 pl-5 pr-4 mb-2 -ml-1 rounded-r-lg shadow-lg">
                    <div className="flex flex-row justify-between">
                      <p className="relative font-bold text-gray-200 whitespace-nowrap truncate overflow-hidden">
                        {item.FROM === user.id ? user.user : item.user}
                        <span className="text-gray-200 font-light rounded-lg ml-2 inline-flex">
                          {item.DATA}
                          {bg}
                        </span>
                      </p>
                    </div>
                    <p
                      onClick={() => navigate('/messages/read/' + item.U_ID)}
                      className={'text-gray-200 text-sm break-all ' + isBold}
                      dangerouslySetInnerHTML={{ __html: html }}
                    />
                  </div>
                </div>
              </Fragment>
            );
          })}
      </div>
    </div>
  );
}
