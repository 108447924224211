import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import { authApi } from '../../../../api';

import { UserProps } from '../../../../shared-types/user-type';
import { Loader } from '../../../../components/Loader';
import { AboutMe } from './AboutMe/AboutMe';
import Achievements, { NewbieMedalsProps, VeteransMedalsProps } from './Achievements/Achievements';
import { SpecialMedal, SpecialMedals } from './SpecialMedals/SpecialMedals';

export interface MedalsProps {
  user: UserProps;
}

interface MedalResponse {
  SpecialMedals: SpecialMedal[];
  VeteransMedal: VeteransMedalsProps[];
  NEWBIEMEDALS: NewbieMedalsProps[];
}

export const Medals = ({ user }: MedalsProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [specialMedals, setSpecialMedals] = useState<SpecialMedal | null>(null);
  const [newbieMedals, setNewbieMedals] = useState<NewbieMedalsProps | null>(null);
  const [veteransMedals, setVeteransMedals] = useState<VeteransMedalsProps | null>(null);

  useEffect(() => {
    setIsLoading(true);

    authApi.get(`/api/user/specialmedals/${user.id}`).then(({ data }: AxiosResponse<MedalResponse>) => {
      if (data.NEWBIEMEDALS) {
        setNewbieMedals(data.NEWBIEMEDALS.length ? data.NEWBIEMEDALS[0] : null);
      }

      if (data.VeteransMedal) {
        setVeteransMedals(data.VeteransMedal.length ? data.VeteransMedal[0] : null);
      }

      if (data.SpecialMedals) {
        setSpecialMedals(data.SpecialMedals.length ? data.SpecialMedals[0] : null);
      }

      setIsLoading(false);
    });
  }, [user.id]);

  return (
    <section className="flex flex-col gap-y-8 text-center sm:text-left">
      <AboutMe text={user.ABOUT} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Achievements user={user} newbieMedals={newbieMedals} veteransMedals={veteransMedals} />
          {specialMedals && <SpecialMedals specialMedals={specialMedals} />}
        </>
      )}
    </section>
  );
};
