import { useRef, useState } from 'react';
import Select, { StylesConfig } from 'react-select';

import { countryFlags } from '../../helpers/Helpers';
import { useHelper } from '../../hooks/use-helper';
import { Country } from '../../shared-types/shared-types';
import { CountryButton } from './CountryButton';
import { Dropdown } from './Dropdown';

interface SelectCountryButtonProps {
  countryId?: string;
  className?: string;
  onChange: (newCountry: Country) => void;
}

interface RenderCountry {
  name: Country['Name'];
  id: Country['ID'];
}

const selectStyles: StylesConfig<Country, false> = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

const renderCountry = ({ name, id }: RenderCountry) => {
  return (
    <div className="flex items-center">
      {countryFlags(id)}
      <span className="ml-1 text-gray-900 text-sm">{name}</span>
    </div>
  );
};

export const SelectCountryButton = ({ countryId, onChange, className }: SelectCountryButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { helpers } = useHelper();
  const countries = useRef(Object.values(helpers.Countries)).current;

  const [value, setValue] = useState<Country>(countries[Number.parseInt(countryId ?? '1') - 1]);

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={onClose}
      target={<CountryButton toggleOpen={toggleOpen} value={value.ID} className={className} />}
    >
      <Select
        autoFocus={true}
        backspaceRemovesValue={false}
        menuIsOpen={true}
        components={{ DropdownIndicator: null }}
        onChange={(newValue) => {
          const newCountryOrDefault = newValue ?? countries[0];
          onChange(newCountryOrDefault);
          setValue(newCountryOrDefault);
          onClose();
        }}
        options={countries}
        placeholder="Search..."
        styles={selectStyles}
        formatOptionLabel={(country) =>
          renderCountry({
            name: country.Name,
            id: country.ID,
          })
        }
        getOptionLabel={(country) => country.Name}
        getOptionValue={(country) => country.ID.toString()}
        value={value}
      />
    </Dropdown>
  );
};
