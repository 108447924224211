import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Divider } from '../../../../components/Divider';

import { fromCDN } from '../../../../helpers/Images';

const ABILITY_TYPE = [
  {
    1: {
      Name: 'MEDIC_S',
    },
    2: {
      Name: 'DEST_S',
    },
    3: {
      Name: 'SNIP_S',
    },
    4: {
      Name: 'ART_S',
    },
  },
];

export default function CoreAttributes({ user, translation }) {
  return (
    <div className="text-center sm:text-left mt-8">
      <p className="p-2 bg-red-400 text-center bg-opacity-30 hover:bg-opacity-50 cursor-default font-bold">
        Military Attributes
      </p>
      <div className={'bg-red-400 bg-opacity-10 px-8 pb-8 pt-4 rounded-b-lg'}>
        <div className="w-2/2 flex">
          <div className="w-1/2 text-center justify-center">
            <div>
              <p className="font-bold p-3">{translation('GAME_STRENGTH')}</p>
              <p>
                <img
                  data-tip
                  data-for="strength"
                  src={fromCDN.fetchStaticGameImage('/icons/big_strength')}
                  className="mx-auto w-10 h-10"
                />
              </p>
              <p className="font-medium p-1">{user.STRENGTH}</p>
            </div>

            <ReactTooltip id="strength" effect="solid" data-html={true}>
              <p className="font-medium text-center">{translation('GAME_STRENGTH_DESCRIPTION')}</p>
            </ReactTooltip>
          </div>

          {user['ABILITY_TYPE'] > 0 && (
            <div className="w-1/2 text-center justify-center">
              <div>
                <p className="font-bold p-3">{translation('GAME_ABILITY_' + user['ABILITY_TYPE'])}</p>
                <div>
                  <img
                    data-tip
                    data-for={'ability'}
                    className="h-10 w-10 mx-auto"
                    src={fromCDN.fetchStaticImage(`/t_abi_${user['ABILITY_TYPE']}`, 'png')}
                  />
                </div>
                <p className="font-medium p-1">{user[ABILITY_TYPE[0][user['ABILITY_TYPE']].Name]}</p>

                <ReactTooltip id="ability" effect="solid" data-html={true}>
                  <p className="font-medium text-center">{translation('GAME_ABILITY_DESCRIPTION')}</p>
                </ReactTooltip>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-2/2 flex justify-center sm:justify-start gap-6 mt-16">
        <div className="w-1/2">
          <div className="w-4/4 flex">
            <div className="w-1/4">
              <img
                data-tip
                data-for="ability"
                className="h-9 w-9 mx-auto mt-11 sm:ml-13 sm:mt-11 md:ml-6 lg:ml-10"
                src={fromCDN.fetchStaticGameImage(`/ranks/${user['MILITARY_RANK']}`)}
              />
            </div>
            <div className="w-3/4 font-bold">
              <p>{translation('GAME_MILITARY_RANK')}</p>
              <p className="font-medium text-blue-400 mt-3">
                {translation('GAME_MILITARY_RANK_' + user['MILITARY_RANK'])}
              </p>
              <div className="font-bold mt-1">
                <div className="progressBarTemplate">
                  <div
                    className="progressBarNumbers bg-blue-500 transition ease delay-50 duration-300"
                    style={{ width: parseInt(user['NEXT_RANK_PERCENT']) + '%' }}
                  >
                    <span className="text-green-500" />
                  </div>
                </div>
              </div>
              <p className="font-medium">
                {user['R_DMG']} / {user['NEXT_RANK']}
              </p>
            </div>
          </div>
        </div>
        <div className="w-1/2 font-bold">
          <p>{translation('GAME_EXPERIENCE_AND_LEVEL')}</p>
          <p className="font-medium text-yellow-500 mt-3">{user['Level']}</p>

          <div className="font-bold mt-1">
            <div className="progressBarTemplate">
              <div
                className="progressBarNumbers bg-yellow-500 transition ease delay-50 duration-300"
                style={{ width: parseInt(user['NEXT_LEVEL_PERCENT']) + '%' }}
              >
                <span className="text-green-500" />
              </div>
            </div>
          </div>
          <p className="font-medium">
            {user['EXPERIENCE']} / {user['NEXT_LEVEL_REQUIREMENT']}
          </p>
        </div>
      </div>
    </div>
  );
}
