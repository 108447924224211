import { Link } from 'react-router-dom';

import {imageAsBackground} from '../../helpers/image-as-background';

import { fromCDN } from '../../helpers/Images';
import { useTranslation } from 'react-i18next';

export const PublicHeader = () => {
  const { t } = useTranslation('common');

  const links = [
    {
      id: 'login',
      label: t('GAME_LOGIN'),
      to: '/',
      className: 'bg-green-600 hover:bg-green-700 p-4 font-semibold rounded-lg',
    },
    {
      id: 'register',
      label: t('GAME_REGISTER'),
      to: '/register/1',
      className:
        'bg-indigo-600 hover:bg-indigo-700 p-4 font-semibold rounded-lg hover:scale-105 transition-all duration-500 transform',
    },
  ];

  return (
    <header
      style={imageAsBackground(fromCDN.fetchStaticImgImage('/header/backgrounds/top_header_background', 'svg'))}
      className="fixed top-0 w-full shadow-sm shadow-gray-700"
    >
      <div className="py-2 max-w-7xl mx-auto px-2 sm:px-6 flex items-center relative h-16">
        <Link to="/" className="sm:absolute">
          <img src={fromCDN.fetchStaticImgImage('/logo')} alt="logo" className="h-14" />
        </Link>

        <nav className="text-gray-200 text-sm font-semibold w-full">
          <ul className="flex items-center justify-center gap-10">
            {links?.map(({ label, to, id, className }) => (
              <Link key={label} className="" to={to}>
                <button id={id} className={className}>
                  {label}
                </button>
              </Link>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};
