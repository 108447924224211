import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/solid';

export interface CardProps {
  to: string;
  'data-testid'?: string;
  imgUrl: string;
  imgClass?: string;
  title: string;
  color: string;
  cardClasses?: string;
  infoButtonClasses?: string;
  infoText: string;
}

export const Card = (props: CardProps) => {
  const {
    to,
    imgUrl,
    title,
    color,
    imgClass ="",
    cardClasses = 'bg-opacity-80 hover:bg-opacity-100',
    infoButtonClasses = 'bg-opacity-70 hover:bg-opacity-100',
    infoText,
  } = props;
  const { t } = useTranslation('common');
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div
      className="text-gray-50 h-96 mb-8 lg:mb-0"
      {...(props['data-testid'] ? { 'data-testid': props['data-testid'] } : {})}
    >
      <h2 className={`${color} text-center font-bold mb-3 p-3 rounded-lg`}>{t(title)}</h2>
      <Link to={to} className={`${color} ${cardClasses} rounded-t-lg h-64 flex justify-center items-center`}>
        <img className={`drop-shadow-[0_8px_6px_rgba(0,0,0,0.4)] ${imgClass}`} src={imgUrl} />
      </Link>

      <button
        className={`${color} ${infoButtonClasses} py-3 rounded-b-lg cursor-pointer w-full`}
        onClick={() => {
          setShowInfo((prevState) => !prevState);
        }}
      >
        <InformationCircleIcon className="mx-auto h-6 w-6" />
        {showInfo ? <p className="p-3 pb-0 text-center">{t(infoText)}</p> : null}
      </button>
    </div>
  );
};
