import { CSSProperties, FC, ReactNode } from 'react';

interface PageContainerProps {
  style: CSSProperties;
  children: ReactNode;
}
export const PageContainer: FC<PageContainerProps> = ({ children, style }) => {
  return (
    <main className="min-h-screen" style={style}>
      <section className="max-w-7xl mx-auto sm:px-6 lg:px-8 pt-2">{children}</section>
    </main>
  );
};
