import { ReactNode } from 'react';

export interface GreenButtonProps {
  text: ReactNode;
}

export const Info = ({ text }: GreenButtonProps) => {
  return (
    <p className="rounded-r-md rounded-b-md py-3 px-4 shadow-lg cursor-default bg-black/40 font-medium text-gray-200">
      {text}
    </p>
  );
};
