import { SectionHeader } from '../../components/SectionHeader';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LatestUpdatesConfig } from './LatestUpdatesConfig';

export default function LatestUpdates() {
  const { t } = useTranslation('common');

  const [latestUpdates, setLatestUpdates] = useState(LatestUpdatesConfig);
  const [longDescription, setLongDescription] = useState(false);

  return (
    <>
      <div className={'min-h-screen max-w-13xl mx-auto'}>
        <div className="max-w-7xl mx-auto py-2  ">
          <SectionHeader title={t('GAME_FOOTER_LATEST_UPDATES')} />
          <main className="mb-auto mx-5">
            <div className="divide-y divide-gray-200 dark:divide-gray-700">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {latestUpdates.map((item) => {
                  return (
                    <li className="py-12" key={item.id}>
                      <article>
                        <div className="space-y-2 xl:grid xl:grid-cols-4 xl:items-baseline xl:space-y-0">
                          <dl>
                            <div className="text-base font-medium leading-6 text-gray-500 dark:text-gray-400">
                              <p>{item.date}</p>
                              <p>Written by: {item.writtenBy}</p>
                            </div>
                          </dl>
                          <div className="space-y-5 xl:col-span-3">
                            <div className="space-y-6">
                              <div>
                                <div className="flex flex-wrap font-bold text-2xl">{item.title}</div>
                              </div>
                              <div className=" max-w-none text-gray-700 dark:text-gray-800 break-words text-md ">
                                {item.shortDescription}
                              </div>
                            </div>
                            <div className="text-base font-medium leading-6 ">
                              <a
                                className="text-primary-500 hover:text-primary-600 hover:text-primary-400 cursor-pointer hover:text-green-400"
                                aria-label='Read "New features in v1"'
                                onClick={() =>
                                  setLongDescription((prevState) => {
                                    if (prevState === item.id) {
                                      return false;
                                    }
                                    return item.id;
                                  })
                                }
                              >
                                Read more &rarr;
                              </a>
                              <br />
                            </div>
                            <div
                              className={
                                'text-primary-500 hover:text-primary-600 dark:hover:text-primary-400 mt-5 text-base'
                              }
                            >
                              {longDescription && longDescription == item.id && item.longDescription()}
                            </div>
                          </div>
                        </div>
                      </article>
                    </li>
                  );
                })}
              </ul>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
