import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';

import { useHelper } from '../../../hooks/use-helper';
import { useUser } from '../../../hooks/use-user';

import { authApi } from '../../../api';

import { SectionHeader } from '../../../components/SectionHeader';
import { Loader } from '../../../components/Loader';
import { CountrySelector } from '../../../components/CountrySelector';
import { fromCDN } from '../../../helpers/Images';
import { NoOffer } from '../NoOffer';
import { MarketplaceTab } from './MarketplaceTab';
import { Country } from '../../../shared-types/shared-types';
import { MarketPlaceOfferProps, MarketParamProps, MarketplaceUpdateOfferHandlerProps } from './types';
import { LIMITED_MARKET_OFFER_ITEM_ID } from '../../../constants/community_enum';
import { OffersContainer } from './OffersContainer';

import { imageAsBackground } from '../../../helpers/image-as-background';

export const Marketplace = () => {
  const { user } = useUser();
  const { helpers } = useHelper();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { countryId, itemType, page, quality } = useParams() as unknown as MarketParamProps;

  const [isLoading, setIsLoading] = useState(false);
  const [offers, setOffers] = useState<MarketPlaceOfferProps[]>([]);

  const handleMarketChange = (market: Country) => {
    navigate(`/business/marketplace/${market.ID}/${itemType}/${quality}/${page}`);
  };

  const updateOfferHandler = ({ offerId, purchasedAmount }: MarketplaceUpdateOfferHandlerProps) => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) => (offer.ID === offerId ? { ...offer, STOCK: offer.STOCK - purchasedAmount } : offer)),
    );
  };

  useEffect(() => {
    setOffers([]);
    setIsLoading(true);

    authApi
      .get(`/api/business/marketplace/${countryId}/${itemType}/${quality}/${page}`)
      .then((response: AxiosResponse<MarketPlaceOfferProps[]>) => {
        setOffers(response.data.filter((offer) => offer.U_ID !== user.id));
      })
      .catch((error) => {
        console.log(error);
        setOffers([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [quality, itemType, countryId]);

  const offersSection = offers.length ? (
    <OffersContainer offers={offers} updateOffer={updateOfferHandler} />
  ) : (
    <NoOffer className="mt-10" />
  );

  return (
    <div
      className="min-h-screen mx-auto bg-fixed"
      style={imageAsBackground(fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg'))}
    >
      <div className="max-w-7xl mx-auto p-2">
        <SectionHeader title={t('GAME_MARKETPLACE')}>
          <CountrySelector countryId={Number.parseInt(countryId)} handleChange={handleMarketChange} />
        </SectionHeader>

        <div
          data-testid="marketplace-products"
          className="sm:text-center grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-7 lg:gap-14 m-5"
        >
          {Object.values(helpers.Items).map((item) => {
            if (item.ID <= LIMITED_MARKET_OFFER_ITEM_ID) {
              return <MarketplaceTab key={item.ID} item={item} />;
            }
          })}
        </div>
        {isLoading ? <Loader /> : offersSection}
      </div>
    </div>
  );
};
