import Select, { SingleValue, StylesConfig } from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';

import { Stars } from '../../Util/Icons/star/Stars';
import { fromCDN } from '../../../helpers/Images';
import {imageAsBackground} from '../../../helpers/image-as-background';
import { validate } from '../../../helpers/Validations';
import { MarketplaceTabProps, MarketParamProps, MarketItemQuality, QualityChangeHandler } from './types';

const qualities: readonly MarketItemQuality[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

const selectStyle: StylesConfig<MarketItemQuality, false> = {
  control: (provided) => ({
    ...provided,
    minHeight: 96,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  singleValue: (provided) => ({
    ...provided,
    height: 96,
    padding: '10px 0',
  }),
};

export const MarketplaceTab = ({ item }: MarketplaceTabProps) => {
  const navigate = useNavigate();
  const { itemType, countryId } = useParams() as unknown as MarketParamProps;

  const changeHandler = ({ id, quality }: QualityChangeHandler) => {
    navigate(`/business/marketplace/${countryId}/${id}/${quality}/1`);
  };

  if (!validate.validNumbers([itemType, countryId])) return null;

  return (
    <div>
      <div
        className={`${
          Number.parseInt(itemType) === item.ID ? 'bg-green-200' : 'bg-gray-100'
        } hover:bg-gray-200 rounded h-24 shadow-inner`}
        style={imageAsBackground(fromCDN.fetchStaticGameImage(`/items/${item.Img}`), {
          backgroundSize: '',
        })}
      >
        <Select
          className="basic-select"
          placeholder=""
          isSearchable={false}
          styles={selectStyle}
          onChange={(quality: SingleValue<MarketItemQuality>) => {
            changeHandler({ quality: quality?.value ?? 1, id: item.ID });
          }}
          components={{
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          formatOptionLabel={(quality: MarketItemQuality) => (
            <div className="flex justify-center cursor-pointer h-full items-end">
              <Stars totalStars={quality.value} className="h-3 w-3 text-yellow-600" />
            </div>
          )}
          options={qualities}
        />
      </div>
    </div>
  );
};
