import React from 'react';
import { HelperProps } from '../shared-types/types';

interface helperContextProps {
  helpers: HelperProps;
  setHelpers: (arg: any) => void;
}

export const helperContext = React.createContext<helperContextProps>({
  // @ts-ignore
  helpers: {},
  setHelpers: () => {},
});

export default helperContext;
