import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from '../../api';
import ApplicationAlert from '../../components/ApplicationAlert';
import {imageAsBackground} from '../../helpers/image-as-background';

import { fromCDN } from '../../helpers/Images';

export default function EmailConfirmation() {
  const [isRedirect, setIsRedirect] = useState(false);

  const [alertModal, setAlertModal] = useState();
  const [confirmationCode, setConfirmationCode] = useState(false);

  const history = useNavigate();

  function callApi() {
    if (confirmationCode === '') {
      setAlertModal(ApplicationAlert('Please enter the code').error());

      return alertModal;
    }

    axios
      .post('/api/activate', { confirmation_code: confirmationCode })
      .then((response) => {
        setIsRedirect(true);

        setAlertModal(ApplicationAlert(response.data).success());

        return alertModal;
      })
      .catch((error) => {
        setAlertModal(
          ApplicationAlert(error.response.data.message ? error.response.data.message : error.response.data).error(),
        );

        return alertModal;
      });
  }

  useEffect(() => {
    if (isRedirect) {
      history('/');
    }
  }, [isRedirect]);

  return (
    <div
      className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 min-h-screen"
      style={imageAsBackground(fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg'))}
    >
      <div className="max-w-md w-full space-y-6 bg-gray-100 p-8 rounded-lg shadow-2xl shadow-black">
        <div className="space-y-4">
          <Link to="/">
            <img
              className="mx-auto h-24 w-auto cursor-pointer"
              src={fromCDN.fetchStaticImgImage('/logo-l')}
              alt="Workflow"
            />
          </Link>
          <p className="text-center font-bold text-base text-gray-900">Check your e-mail</p>
        </div>

        <div className="space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="confirmation" className="sr-only">
                Confirmation code
              </label>
              <input
                id="email-address"
                name="name"
                autoComplete="email"
                required={true}
                onChange={(e) => setConfirmationCode(e.target.value)}
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Confirmation code"
              />
            </div>
          </div>

          <button
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(e) => callApi(e)}
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3" />
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}
