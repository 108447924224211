import { fromCDN } from '../../helpers/Images';

export default function BattlefieldBackground({ round }: { round: number }) {
  switch (round) {
    case 3:
      return fromCDN.fetchStaticGameImage('/battle/airbackground');

    case 1:
      return fromCDN.fetchStaticGameImage('/battle/animatedbattle');
    case 2:
      return fromCDN.fetchStaticGameImage('/battle/animatedbattle');
    default:
      return fromCDN.fetchStaticGameImage('/battle/animatedbattle');
  }
}
