import React, { FC, ReactNode, useState } from 'react';
import { BattlefieldTypesApiProps } from '../../../shared-types/battlefield-types';

interface BattlefieldContextProps {
  citizenDamage: BattlefieldTypesApiProps['CITIZEN'][];
  setCitizenDamage: (args: any) => void;
}

const battlefieldContextInitialProps = {
  citizenDamage: [],
  setCitizenDamage: (args: any) => {},
};

export const BattlefieldContext = React.createContext<BattlefieldContextProps>(battlefieldContextInitialProps);

export const BattleFieldContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [citizenDamage, setCitizenDamage] = useState();
  const val = { citizenDamage, setCitizenDamage };

  // @ts-ignore
  return <BattlefieldContext.Provider value={val}>{children}</BattlefieldContext.Provider>;
};
