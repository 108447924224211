import { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { authApi } from '../../../../api';
import { CURRENCY } from '../../../../constants/community_enum';
import { fromCDN } from '../../../../helpers/Images';
import { Country } from '../../../../shared-types/shared-types';
import { ValueOf } from '../../../../shared-types/util';
import { SelectCountryButton } from '../../../../components/SelectCountryButton/SelectCountryButton';
import { CircleButton } from '../CircleButton';
import { Offer, OfferProps } from '../Offer';
import { useUser } from '../../../../hooks/use-user';
import { Loader } from '../../../../components/Loader';
import { NoOffer } from '../../NoOffer';

const ccImage = fromCDN.fetchStaticImage('/t_cc', 'svg');
const goldImage = fromCDN.fetchStaticImgImage('/header/icons/gold', 'svg');

export const Market = ({ refresh, onRefresh }: { refresh: boolean; onRefresh: () => void }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { countryId } = useParams();
  const { user } = useUser();

  const [currencyType, setCurrencyType] = useState<ValueOf<typeof CURRENCY>>(CURRENCY.COUNTRY);
  const [offers, setOffers] = useState<OfferProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleMarketChange = (market: Country) => {
    navigate(`/business/monetary-market/${market.ID}`);
  };

  const refreshOffers = () => {
    setIsLoading(true);
    authApi
      .get(`/api/business/monetary/${countryId ?? ''}/${currencyType ?? ''}/1`)
      .then((response: AxiosResponse<OfferProps[]>) => {
        setOffers(response.data.filter((offer) => offer.U_ID !== user.id));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshOffers();
  }, [currencyType, countryId, refresh]);

  const renderOffers =
    countryId && offers.length ? (
      <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-5 gap-4 mt-10">
        {offers.map((offer) => (
          <Offer
            key={`${offer.ID}_${offer.DATE}`}
            offer={offer}
            countryId={Number.parseInt(countryId)}
            currencyType={currencyType}
            onPurchaseSuccess={onRefresh}
          />
        ))}
      </div>
    ) : (
      <NoOffer className="mt-10" />
    );

  return (
    <>
      <div className="bg-gradient-to-b from-zinc-700 via-zinc-600 to-zinc-500 border-zinc-700 border-l-4 border-b-2 w-full h-24 rounded-b-lg flex flex-col items-center justify-center">
        <span className="capitalize block py-2 px-4 bg-black/60 rounded-lg font-semibold">
          {t('GAME_CURRENT_MARKET')}
        </span>

        <SelectCountryButton
          onChange={handleMarketChange}
          countryId={countryId}
          className="bg-black/60 hover:bg-black/50 rounded-b-lg px-4 py-2"
        />
      </div>
      <div className="w-full flex justify-center gap-x-4 mt-8">
        <div className="flex-1">
          <CircleButton
            onClick={() => {
              setCurrencyType(CURRENCY.COUNTRY);
            }}
            className="ml-auto border-green-500"
          >
            <img src={ccImage} alt="Currency" className="h-12 w-12 ml-auto" />
          </CircleButton>
        </div>

        <div className="flex-1">
          <CircleButton
            onClick={() => {
              setCurrencyType(CURRENCY.GOLD);
            }}
            className="border-yellow-500"
          >
            <img src={goldImage} alt="Gold" className="h-12 w-12" />
          </CircleButton>
        </div>
      </div>
      {isLoading ? <Loader customPaddingForTheCircle="p-16" /> : renderOffers}
    </>
  );
};
