import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../hooks/use-user';
import { createUnitApi } from '../../../../api/shared-api';
import ApplicationAlert from '../../../../components/ApplicationAlert';
import { useAppTranslation } from '../../../../hooks/use-app-translation';

interface UseCreateUnitProps {
  unitName: string;
}
export const useCreateUnit = (props: UseCreateUnitProps) => {
  let navigate = useNavigate();
  const { setUser } = useUser();
  const { unitName } = props;
  const t = useAppTranslation();

  const handleCreateUnit = async () => {
    try {
      const result = await createUnitApi({ name: unitName });
      setUser(result);
      navigate(`/military-unit/${result ? result.UNIT : 0}`);
    } catch (err) {
      // @ts-ignore
      ApplicationAlert(t(err.response.data)).error();
    }
  };

  return {
    handleCreateUnit,
  };
};
