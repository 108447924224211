import { Notifications } from './Notifications';
import { useEffect, useState } from 'react';
import { GLOBAL_CONSTANTS } from '../../constants/enum';
import {
  forgetLocalStorageCache,
  getFromLocalStorage,
  saveToLocalStorage,
  useLocalStorageAsCache,
} from '../../helpers/LocalStorage';
import moment from 'moment';

export default function NotificationsProvider({
  componentAndType,
  description,
  shouldSaveIntoLocalStorage,
  shouldRemoveFromLocalStorage,
  expirationInMinutes,
  storageKey = GLOBAL_CONSTANTS.LOCALSTORAGE_KEYS.NOTIFICATIONS,
}) {
  const [localStorage, setLocalStorage] = useState(getFromLocalStorage(storageKey) ?? null);
  const [display, setDisplay] = useState(localStorage?.data?.isVisible ?? false);
  const handleCloseButton = () => {
    if (shouldRemoveFromLocalStorage) {
      if (localStorage && !localStorage.isVisible && localStorage.timestamp < moment().unix()) {
        forgetLocalStorageCache(storageKey);
      }
    }

    setDisplay(false);
    localStorage.data.isVisible = false;
    saveToLocalStorage(storageKey, localStorage);
  };

  useEffect(() => {
    if (localStorage?.expiration < moment().unix()) {
      forgetLocalStorageCache(storageKey);
    }

    if (shouldSaveIntoLocalStorage && localStorage === null) {
      /* eslint-disable */
      useLocalStorageAsCache(expirationInMinutes, storageKey, {
        data: {
          description,
        },
        isVisible: true,
      });
      /* eslint-enable */
    }
  }, []);

  useEffect(() => {}, []);
  return (
    <>
      {display && (
        <Notifications
          className="animate__bounceIn mx-auto bg-amber-400 w-full flex justify-center"
          signalToParent={handleCloseButton}
        >
          <div className={'flex flex-col text-center '}>{localStorage?.data?.data?.description ?? description}</div>
        </Notifications>
      )}
    </>
  );
}
