import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { Loader } from '../../../components/Loader';
import { authApi } from '../../../api';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { AxiosResponse } from 'axios';
import { AppPageTemplate } from '../../../components/AppPageTemplate';
import EditorJS from '@editorjs/editorjs';

import {imageAsBackground} from '../../../helpers/image-as-background';
import { fromCDN } from '../../../helpers/Images';

import { eDitorJSToolsConfig } from './eDitorJSToolsConfig';
import { MESSAGE_MAX_LENGTH } from '../../../constants/enum';

export const CreateArticle = () => {
  const { t } = useTranslation('common');
  let navigate = useNavigate();
  const [titleValue, setTitleValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const editor = new EditorJS({
    holder: 'editorjs',
    placeholder: 'Let`s write an awesome story!',
    tools: {
      ...eDitorJSToolsConfig,
    },
  });

  const handleEditorSubmit = (saveData: EditorJS.OutputData) => {
    const trimmedTitle = titleValue.trim();

    if (!trimmedTitle.length) {
      ApplicationAlert(t('GAME_CREATE_ARTICLE_TITLE_ERROR')).error();
      return;
    }

    authApi
      .post('/api/newspaper/create-article', {
        articleTitle: trimmedTitle,
        articleContent: JSON.stringify(saveData),
      })
      .then((res: AxiosResponse<number>) => {
        navigate(`/article/${res.data}`);
      })
      .catch((err) => {
        ApplicationAlert(err.response.data).error();
        setIsLoading(false);
        return;
      });
  };

  const handleSaveEditor = () => {
    editor.save().then((outputData) => {
      handleEditorSubmit(outputData);
    });
  };
  return (
    <div
      className="min-h-screen max-w-13xl mx-auto bg-fixed text-gray-400 placeholder-gray-200"
      style={imageAsBackground(fromCDN.fetchStaticImage('mybuildingsbg', 'svg'))} 
    >
      <AppPageTemplate
        // mainStyle={imageAsBackground(fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg'))}
        section={{
          title: t('GAME_CREATE_ARTICLE'),
        }}
      >
        {isLoading ? (
          <Loader fullScreen={true} />
        ) : (
          <div className="m-5 ">
            <div className="mb-3 ">
              <input
                maxLength={MESSAGE_MAX_LENGTH}
                className="h-12 text-md shadow-inner appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 transition ease-in-out placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent sm:text-sm mb-1"
                placeholder="TITLE"
                onChange={(e) => {
                  setTitleValue(e.target.value);
                }}
                value={titleValue}
              />
              <sup className="ml-1 text-gray-200">{t('GAME_CREATE_ARTICLE_TITLE_CHAR_LIMIT')}</sup>
            </div>
            <div className="content article bg-white/20 rounded-lg p-6 mt-10">
              <div id="editorjs"></div>
            </div>

            <button
              onClick={() => {
                handleSaveEditor();
              }}
              className="w-full md:w-auto mt-4 rounded-md p-3 uppercase shadow-lg font-bold bg-blue-500 hover:bg-blue-600 sm:text-sm text-white default-transition"
            >
              {t('GAME_ARTICLE_PUBLISH')}
            </button>
          </div>
        )}
      </AppPageTemplate>
    </div>
  );
};
