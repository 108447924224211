import { fromCDN } from '../../../helpers/Images';
import { useHelper } from '../../../hooks/use-helper';

const getRewardImage = (imagePath) => fromCDN.fetchStaticGameImage(`/items/${imagePath}`);
const getCurrencyImage = (imagePath) => fromCDN.fetchStaticGameImage(`/flags/currency/${imagePath}`);

export default function RewardAlert({ rewards, t }) {
  const { helpers } = useHelper();

  const renderRewardItem = (type, value) => {
    switch (type) {
      case 'XP':
        return (
          <div
            key="XP"
            className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white"
          >
            <img className="h-12 w-12" src={fromCDN.fetchStaticGameImage('/icons/xp-s')} />
            <p className="block px-3">{t('GAME_EXPERIENCE')}</p>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              +{value}
            </strong>
          </div>
        );
      case 'Energy':
        return (
          <div
            key="Energy"
            className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white"
          >
            <img className="h-12 w-12" src={fromCDN.fetchStaticGameImage('/icons/energy-s')} />
            <p className="block px-3">{t('GAME_ENERGY')}</p>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              -{value}
            </strong>
          </div>
        );
      case 'Gold':
        return (
          <div className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white">
            <img className="h-12 w-12" src={fromCDN.fetchStaticGameImage('/icons/gold-icon')} />
            <p className="block px-3">{t('GAME_GOLD')}</p>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              -{rewards['Gold']}
            </strong>
          </div>
        );
      case 'GG':
        return (
          <div className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white">
            <img className="h-12 w-12" src={fromCDN.fetchStaticGameImage('/icons/gold-icon')} />
            <p className="block px-3">{t('GAME_GOLD')}</p>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              +{rewards['GG']}
            </strong>
          </div>
        );
      case 'Employees':
        return (
          <div className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white">
            <img className="h-12 w-12" src={fromCDN.fetchStaticGameImage('/icons/1emp')} />
            <p className="block px-3">{t('GAME_EMPLOYEES')}</p>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              -{rewards['Employees']}
            </strong>
          </div>
        );
      case 'Employeesplus':
        return (
          <div className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white">
            <img className="h-12 w-12" src={fromCDN.fetchStaticGameImage('/icons/1emp')} />
            <p className="block px-3">{t('GAME_EMPLOYEES')}</p>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              +{rewards['Employees']}
            </strong>
          </div>
        );
      case 'Work':
        return (
          <div className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white">
            <img className="h-12 w-12" src={fromCDN.fetchStaticGameImage('/icons/tools-s')} />
            <p className="block px-3">{t('GAME_WORK_PROGRESS')}</p>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              -{rewards['Work']}
            </strong>
          </div>
        );
      case 'CCR':
        // CC REMOVE //
        return (
          <div className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white">
            <img className="h-12 w-12" src={getCurrencyImage(rewards['CCR'][1])} />
            <p className="block px-3">{rewards['CCR'][1]}</p>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              -{rewards['CCR'][0]}
            </strong>
          </div>
        );
      case 'CC':
        // CC ADD
        return (
          <div className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white">
            <img className="h-12 w-12" src={getCurrencyImage(rewards['CC'][1])} />
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              +{rewards['CC'][0]} {rewards['CC'][1]}
            </strong>
          </div>
        );
      case 'Strength':
        return (
          <div
            key="Strength"
            className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white"
          >
            <img className="h-12 w-12" src={fromCDN.fetchStaticImage('/game/icons/strength', 'png')} />
            <p className="block px-3">{t('GAME_STRENGTH')}</p>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              +{value}
            </strong>
          </div>
        );
      case 'SKILL':
        return (
          <div
            key="SKILL"
            className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white"
          >
            <img
              className="h-12 w-12"
              src={fromCDN.fetchStaticImage(`/game/abiicons/abi_` + Object.keys(value)[0], 'png')}
            />
            <p className="block px-3">{t(`GAME_ABILITY_${Object.keys(value)[0]}`)}</p>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              +{Object.values(value)[1]}
            </strong>
          </div>
        );
      case 'Items':
        return value.map((item, index) => (
          <div
            key={`Item-${index}`}
            className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white"
          >
            <img className="h-12 w-12" src={getRewardImage(helpers.Items[item].Img)} />
            <p className="block px-3">{helpers.Items[item].Name}</p>
            <small>Q{rewards['Quality'][index]}</small>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              +{rewards['Stock'][index]}
            </strong>
          </div>
        ));
      case 'ItemsR':
        return value.map((item, index) => (
          <div
            key={`ItemR-${index}`}
            className="py-1 bg-gradient-to-t from-emerald-500 to-emerald-700 rounded inline-flex flex-col items-center text-sm text-white"
          >
            <img className="h-12 w-12" src={getRewardImage(helpers.Items[item].Img)} />
            <p className="block px-3">{helpers.Items[item].Name}</p>
            <small>Q{rewards['QualityR'][index]}</small>
            <strong className="text-xs bg-gray-600 bg-opacity-90 w-full mt-2 py-1 grid place-items-center">
              -{rewards['StockR'][index]}
            </strong>
          </div>
        ));
      default:
        return null;
    }
  };

  return (
    <div
      className={`grid grid-cols-${Object.keys(rewards).length - 1} sm:grid-cols-${
        Object.keys(rewards).length - 1
      } gap-2`}
    >
      {Object.entries(rewards).map(([type, value]) => renderRewardItem(type, value))}
    </div>
  );
}
