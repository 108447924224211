import React, { useEffect } from 'react';

import { useHelper } from '../../../hooks/use-helper';
import Avatar from '../../../components/Avatar';
import ReactTooltip from 'react-tooltip';

export default function WhoIsOnline() {
  const { helpers } = useHelper();

  useEffect(() => {}, [helpers]);

  return (
    <>
      <div className="text-left max-w-xs text-gray-200 hidden lg:grid lg:grid-cols-12 text-sm -mt-3.5 break-all gap-1">
        {helpers.ONLINE_P_LIST?.length > 0 &&
            helpers.ONLINE_P_LIST?.length < 50 &&
            helpers.ONLINE_P_LIST.map((user, key) => {
            return (
              <div data-tip data-for={'whoonline' + key} key={key}>
                <Avatar
                  avatarId={user.AVATAR}
                  userId={user.U_ID}
                  defaultImageClass={'h-6 w-6 cursor-pointer rounded-lg default-transition'}
                />
                <ReactTooltip id={'whoonline' + key} effect="solid" delayShow={100} delayHide={50} data-html={true}>
                  {user.U_NAME}
                </ReactTooltip>
              </div>
            );
          })}
      </div>
    </>
  );
}
