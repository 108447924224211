export interface LoaderCustomProps {
  className?: string;
  fullScreen?: boolean;
  circleStrokeWidth?: string;
  customPaddingForTheCircle?: string;
}

export const Loader = ({ className, fullScreen, circleStrokeWidth, customPaddingForTheCircle }: LoaderCustomProps) => {
  let iconSize = className ?? 'h-12 w-12';
  let containerClasses = '';
  let outerCircleStroke = circleStrokeWidth ?? '4';
  let customPaddingForCircle = customPaddingForTheCircle ?? 'p-2';

  if (fullScreen) {
    containerClasses = 'fixed left-1/2 -translate-x-1/2 bg-gray-900 bg-opacity-90 w-full h-full top-0 z-50';
    iconSize = 'h-20 w-20';
  }

  return (
    <div className={`${customPaddingForCircle} grid place-items-center my-0 text-neutral-300 ${containerClasses}`}>
      <svg className={`animate-spin ${iconSize}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 66 66">
        <circle cx="33" cy="33" fill="none" r="28" stroke="currentColor" strokeWidth={outerCircleStroke} />
        <circle
          cx="33"
          cy="33"
          fill="none"
          r="28"
          stroke="rgb(29 78 216)"
          strokeDasharray="40, 134"
          strokeDashoffset="325"
          strokeLinecap="round"
          strokeWidth={outerCircleStroke}
        />
      </svg>
    </div>
  );
};
