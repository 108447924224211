import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { getWhichSidePlayerCanFight } from '../../../helpers/Helpers';
import { useUser } from '../../../hooks/use-user';

import { ResistancePickSide } from './ResistancePickSide';
import { AttackButton } from './AttackButton';
import { BattlefieldTypesApiProps } from '../../../shared-types/battlefield-types';
import { BATTLE_SIDES } from '../../../constants/enum';
interface BattleFieldFightProps {
  battleDetails: BattlefieldTypesApiProps['BATTLE'];
  citizenDetails: BattlefieldTypesApiProps['CITIZEN'];
  battleSide: BATTLE_SIDES.ATTACKER | BATTLE_SIDES.DEFENDER;
  shouldUpdate: Dispatch<SetStateAction<boolean>>;
}
export const BattlefieldFight: FC<BattleFieldFightProps> = ({
  battleDetails,
  citizenDetails,
  battleSide,
  shouldUpdate = () => {},
}) => {
  const { user } = useUser();
  const [choice, setChoice] = useState<0 | BATTLE_SIDES.ATTACKER | BATTLE_SIDES.DEFENDER>(0);

  battleSide = getWhichSidePlayerCanFight({
    playerLocatedCountry: user.COUNTRY,
    attackers: battleDetails.ALLIES_A,
    defenders: battleDetails.ALLIES_D,
    agresor: battleDetails.AGRESOR,
    defender: battleDetails.DEFENDER,
  });

  useEffect(() => {}, [battleSide]);

  useEffect(() => {}, [setChoice, choice]);

  if (choice === 0) {
    return <ResistancePickSide battleDetails={battleDetails} setChoice={setChoice} />;
  }

  return choice > 0 ? (
    <AttackButton
      citizenDetails={citizenDetails[choice] as any}
      battleSide={choice}
      battleDetails={battleDetails}
      shouldUpdate={shouldUpdate}
    />
  ) : null;
};
