import { useTranslation } from 'react-i18next';

import { fromCDN } from '../../../../../helpers/Images';
import { USER_NO_MEDAL } from '../Achievements/achievements-constants';
import { Medal } from '../Medal';

export interface SpecialMedal {
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
  6: number;
  7: number;
  8: number;
  SPECIALS: string | null;
}

interface SpecialMedalsProps {
  specialMedals: SpecialMedal;
}

export const SpecialMedals = ({ specialMedals }: SpecialMedalsProps) => {
  const { t } = useTranslation('common');

  const renderedMonthlyMedals = [] as JSX.Element[];
  if (specialMedals.SPECIALS) {
    const spittedSpecialMedals: string[] = specialMedals.SPECIALS.split(',') || [];

    spittedSpecialMedals.forEach((medal, key) => {
      const numberMedal = Number.parseInt(medal);
      renderedMonthlyMedals.push(
        <Medal
          key={key}
          src={fromCDN.fetchStaticGameImage(`/achievements/monthlyspecial/${key}`)}
          tooltipText={t(`GAME_TOP_MONTHLY_${key}`)}
          totalMedals={numberMedal}
          isAchieved={numberMedal !== 0}
        />,
      );
    });
  }

  return (
    <>
      <div className="font-bold" data-testid="top-medals">
        <p className="p-2 text-center bg-orange-400 bg-opacity-30 hover:bg-opacity-50">{t('GAME_TOP_MEDALS')}</p>
        <div className="bg-orange-400 bg-opacity-10 px-8 pb-8 pt-4 rounded-b-lg">
          {Object.keys(specialMedals).map((key) => {
            if (key !== 'SPECIALS') {
              const medal = specialMedals[key as keyof SpecialMedal] as number;

              return (
                <Medal
                  key={key}
                  src={fromCDN.fetchStaticGameImage(`/achievements/topmedal_${key}`)}
                  isAchieved={medal !== USER_NO_MEDAL}
                  totalMedals={medal}
                  tooltipText={
                    <div className="text-center flex flex-col">
                      <span>{t(`GAME_TOP_MEDALS_${key}`)}</span>
                      <span>{t(`GAME_TOP_MEDALS_${key}_1`)}</span>
                    </div>
                  }
                />
              );
            }

            return null;
          })}
        </div>
      </div>

      {renderedMonthlyMedals.length ? (
        <div className="font-bold">
          <p className="p-2 text-center bg-orange-600 bg-opacity-30 hover:bg-opacity-50">{t('GAME_LEGENDS')}</p>
          <div className="bg-orange-600 bg-opacity-10 px-8 pb-8 pt-4 rounded-b-lg">{renderedMonthlyMedals}</div>
        </div>
      ) : null}
    </>
  );
};
