import { RankingsTopThreeDesign } from './RankingsTopThreeDesign';
import Avatar from '../../../components/Avatar';
import { countryFlags } from '../../../helpers/Helpers';
import { TopPLayerByExperience } from '../../../shared-types/types';
import StarIcon from '../../Util/Icons/star/StarIcon';
import { fromCDN } from '../../../helpers/Images';

export const TopPlayersByExperience = (details: TopPLayerByExperience[]) => {
  return (
    <>
      {details && <RankingsTopThreeDesign items={details.slice(0, 3)} />}
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-5 m-3 mt-10 gap-x-4 gap-y-5 mb-10 text-gray-200">
        {details.map((item) => {
          return (
            <div
              className="text-center bg-gradient-to-br from-indigo-700/20 to-red-900/20 rounded-md p-3"
              key={item.U_ID}
            >
              <p className="absolute ml-1 font-semibold">#4</p>
              <Avatar avatarId={item.U_AVATAR} userId={String(item.U_ID)} className="mx-auto" />
              <p className="font-semibold">{item.U_NAME}</p>
              <p>{countryFlags(item.CITIZENSHIP, 'mx-auto')}</p>
              <div>
                <p className="bg-yellow-400/40 rounded-md font-medium mt-4">
                  {item.EXPERIENCE}
                  <StarIcon className="h-4 w-4 ml-1 mb-0.5 text-yellow-300 mx-auto inline drop-shadow-[0_2px_1px_rgba(0,0,0,0.6)]" />
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-2 justify-items-center gap-10 mb-20 text-gray-200">
        <button className="bg-edom-darker-yellow/80 hover:bg-edom-darker-yellow hover:scale-110 transition duration-200 p-3 rounded-lg">
          <img className="h-5 w-5" src={fromCDN.fetchStaticImage('t_arrowleft', 'svg')}></img>
        </button>
        <button className="bg-edom-darker-yellow/80 hover:bg-edom-darker-yellow hover:scale-110 transition duration-200 p-3 rounded-lg">
          <img className="h-5 w-5" src={fromCDN.fetchStaticImage('t_arrowright', 'svg')}></img>
        </button>
      </div>
    </>
  );
};
