import ReactTooltip from 'react-tooltip';

import { Stars } from '../Util/Icons/star/Stars';

export default function VipStars({ gold, tooltip }) {
  if (gold < 1000) return null;

  let vipStars = Math.ceil(gold / 1000);
  vipStars = vipStars > 10 ? 10 : vipStars;

  return (
    <div data-tip data-for="stars" className="flex justify-center mb-3 bg-red-900/80 p-2">
      <Stars totalStars={vipStars} />
      <ReactTooltip id="stars" effect="solid" data-html={true}>
        {tooltip}
      </ReactTooltip>
    </div>
  );
}
