import { FC, useState } from 'react';
import { authApi } from '../../../api';
import { AxiosResponse } from 'axios';
import { UserProps } from '../../../shared-types/user-type';
import ApplicationAlert from '../../../components/ApplicationAlert';

export const PlayerEdit: FC = () => {
  const [playerDetails, setPlayerDetails] = useState<UserProps | null>(null);
  const [inputPlayerId, setInputPlayerId] = useState<string | null>(null);
  const handleGetPlayerDetails = (id: string | null) => {
    if (!id) {
      ApplicationAlert('Set userId').error();
      return;
    }
    setPlayerDetails(null);
    authApi
      .get(`api/admin/player/${id}`)
      .then((resp: AxiosResponse<UserProps>) => {
        setPlayerDetails(resp.data);
      })
      .catch((err) => {
        ApplicationAlert('Something went wrong').error();
      });
  };

  const handleUpdatePlayerDetails = () => {
    authApi
      .post(`api/admin/player/${inputPlayerId ?? 0}/update`, {
        playerDetails,
      })
      .then((resp: AxiosResponse<UserProps>) => {
        setPlayerDetails(resp.data);
        ApplicationAlert('Successfully done').success();
      })
      .catch((err) => {
        ApplicationAlert('Something went wrong').error();
      });
  };

  return (
    <>
      <div className="p-4">
        <div className="w-64 text-gray-900 flex flex-auto">
          <input
            className="default-input"
            placeholder="userId"
            onChange={(e) => {
              setInputPlayerId(e.currentTarget.value);
            }}
          />
          <button
            onClick={() => {
              handleGetPlayerDetails(inputPlayerId);
            }}
            className="default-save-button ml-3"
          >
            Get
          </button>
        </div>
        <div className="p-5">
          <button
            onClick={() => {
              handleUpdatePlayerDetails();
            }}
            className="default-save-button"
          >
            Update
          </button>
        </div>

        <div className="grid grid-cols-3 sm:grid-cols-8 gap-8 mt-3">
          {playerDetails &&
            Object.entries(playerDetails).map((prop) => {
              return (
                <div key={prop[0]}>
                  <p>{prop[0]}</p>
                  <p>
                    <input
                      className="default-input"
                      defaultValue={prop[1]}
                      onChange={(e) => {
                        // @ts-ignore
                        setPlayerDetails((prevState) => {
                          return {
                            ...prevState,
                            [prop[0]]: e.target.value,
                          };
                        });
                      }}
                      placeholder={prop[1]}
                    />
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
