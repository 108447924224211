import { fromCDN } from '../../../helpers/Images';
import { CSSProperties } from 'react';

const topFrame = fromCDN.fetchStaticGameImage('/battle/top');
const barLeft = fromCDN.fetchStaticGameImage('/battle/bar-left');
const barRight = fromCDN.fetchStaticGameImage('/battle/bar-right');
const scoreFrame = fromCDN.fetchStaticGameImage('/battle/bar');
const timeFrame = fromCDN.fetchStaticGameImage('/battle/bar-time');

export const RoundStyle = (): CSSProperties => {
  return {
    backgroundImage: `url(${topFrame})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'inline-block',
    textShadow: '0 1px 0 #000',
    padding: '2px 30px',
    minWidth: '168px',
  };
};

export const AttackerScoreStyle = (): CSSProperties => {
  return {
    backgroundImage: `url(${barLeft})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '25px',
    width: '49px',
    right: '15px',
    marginLeft: '-30px',
  };
};

export const DefenderScoreStyle = (): CSSProperties => {
  return {
    backgroundImage: `url(${barRight})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '25px',
    width: '49px',
    right: '15px',
    marginLeft: '-11px',
  };
};

export const ScoreRateBarFrame = (): CSSProperties => {
  return {
    backgroundImage: `url(${scoreFrame})`,
    zIndex: '-1',
    width: '630px',
    height: '40px',
    borderRadius: '5px',
  };
};

export const ScoreBarAnimation = (): CSSProperties => {
  return {
    padding: '5px',
    overflow: 'hidden',
    borderRadius: '2px',
    boxShadow: '5px 50px 5px rgb(255 255 255 / 10%) inset',
    position: 'relative',
  };
};

export const ScoreBarTimeLeft = (): CSSProperties => {
  return {
    backgroundImage: `url(${timeFrame})`,
    backgroundRepeat: 'no-repeat',
    margin: '0 auto',
    textAlign: 'center',
    cursor: 'default',
    paddingTop: '2px',
    color: 'white',
    font: 'bold 10px/14px “Lucida Sans Unicode”, “Lucida Grande”, sans-serif',
    position: 'relative',
    height: '20px',
    width: '134px',
    textShadow: '0 1px 0 #000000',
  };
};
