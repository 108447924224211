import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHelper } from '../../../../hooks/use-helper';

import { authApi } from '../../../../api';
import { Divider } from '../../../../components/Divider';
import { countryFlags } from '../../../../helpers/Helpers';

export default function Military() {
  let params = useParams();
  const currentCountry = params.id;

  const { t } = useTranslation('common');
  const { helpers } = useHelper();

  const [militaryDetails, setMilitaryDetails] = useState(null);

  const getMilitaryDetails = () => {
    authApi.get(`/api/country/military/${currentCountry}`).then((response) => {
      setMilitaryDetails(response.data);
    });
  };

  useEffect(() => {
    getMilitaryDetails();
  }, [currentCountry]);

  return (
    <div id={'politicsdetails'} className="text-center text-gray-300">
      <h1 className={'text-yellow-600 font-bold'}>{t('GAME_BATTLE_STATISTICS')}</h1>
      <Divider margin={'m-3'} />
      <div className={'grid grid-cols-3 '}>
        {militaryDetails && (
          <>
            <div>
              <p className={'text-green-400'}>{t('GAME_WON')}</p>
              <p className={'font-bold'}>{militaryDetails.BattleStatistics.WON}</p>
            </div>
            <div>
              <p className={'text-red-400'}>{t('GAME_LOST')}</p>
              <p className={'font-bold'}>{militaryDetails.BattleStatistics.WON}</p>
            </div>
            <div>
              <p className={'text-yellow-400'}>{t('GAME_WIN_RATE')}</p>
              <p>{militaryDetails.BattleStatistics.RATE}%</p>
            </div>
          </>
        )}
      </div>
      {militaryDetails?.NaturalEnemy && (
        <div className={'mt-5'}>
          <Divider margin={'m-3'} />
          <div className={'m-3'}>
            <h2 className={'text-red-400 font-bold'}>{t('GAME_COUNTRY_A_TYPE_13')}</h2>
            <p>{militaryDetails.NaturalEnemy.Name}</p>
            {countryFlags(militaryDetails.NaturalEnemy.ID, 'mx-auto w-12 h-12')}
          </div>
        </div>
      )}
      {militaryDetails?.Allies && Object.values(militaryDetails?.Allies).length > 0 && (
        <>
          <Divider margin={'m-3'} />
          <h2 className={'text-green-600 font-bold'}>{t('GAME_ALLIES')}</h2>
          <div className={'grid grid-cols-3 sm:grid-cols-6 '}>
            <div className={'mt-5'}>
              {Object.values(militaryDetails.Allies).map((allies) => {
                return (
                  <div className={'m-3'} key={allies.ID}>
                    <p>{helpers.Countries[allies.ID].Name}</p>
                    {countryFlags(allies.ID, 'mx-auto w-12 h-12')}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
