import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { HeaderNavLink } from './HeaderNavLink';
import NotificationsProvider from '../Notifications/NotificationsProvider';
import { Search } from '../../components/Search';
import { fromCDN } from '../../helpers/Images';
import { useHelper } from '../../hooks/use-helper';

const home = fromCDN.fetchStaticImgImage('/header/icons/home', 'svg');
const myBuildings = fromCDN.fetchStaticImgImage('/header/icons/my_buildings', 'svg');
const battles = fromCDN.fetchStaticImgImage('/header/icons/battles', 'svg');
const businessCenter = fromCDN.fetchStaticImgImage('/header/icons/business_center', 'svg');
const community = fromCDN.fetchStaticImgImage('/header/icons/community', 'svg');
const store = fromCDN.fetchStaticImgImage('/header/icons/store', 'svg');
const map = fromCDN.fetchStaticImgImage('/header/icons/worldmap', 'svg');
const signOut = fromCDN.fetchStaticImgImage('/header/icons/sign_out', 'svg');

const links = [
  { to: '/feed', src: home, text: 'GAME_MENU_FEED' },
  { to: '/buildings', src: myBuildings, text: 'GAME_MENU_BUILDINGS' },
  { to: '/battles', src: battles, text: 'GAME_MENU_BATTLES' },
  { to: '/business', src: businessCenter, text: 'GAME_MENU_BUSINESS' },
  { to: '/community', src: community, text: 'GAME_MENU_COMMUNITY' },
  { to: '/map', src: map, text: 'GAME_MENU_MAP' },
  { to: '/store', src: store, text: 'GAME_MENU_STORE' },
];

export const TopHeader = ({ logout }) => {
  const { t } = useTranslation('common');
  const { helpers } = useHelper();

  return (
    <nav className="border-b border-gray-700">
      <div className="py-2 flex md:items-center gap-4 flex-col md:flex-col-2 lg:flex-row lg:gap-6 max-w-7xl mx-auto px-2 sm:px-6 text-gray-300 text-sm font-semibold">
        <div className="flex justify-evenly items-center">
          <Link to="/feed">
            <img
              src={fromCDN.fetchStaticImgImage('/logo')}
              alt="logo"
              className="sm:mx-5 sm:md-center sm:hover:scale-105 sm:duration-300 h-14 sm:h-auto"
            />
          </Link>

          <button
            onClick={logout}
            className="py-1 pl-2 lg:pl-3 pr-2 absolute right-0 mr-5 hover:rounded-lg hover:bg-red-900 flex md:hidden gap-2 items-center lg:w-32"
          >
            <img src={fromCDN.fetchStaticImgImage('/header/icons/sign_out', 'svg')} alt="Sign out" className="h-5" />
            <span className="hidden lg:block">{t('GAME_SIGN_OUT')}</span>
          </button>
        </div>

        <div className="h-full w-full items-center flex gap-2 justify-center flex-wrap lg:flex-nowrap">
          {links?.map(({ text, src, to }) => (
            <HeaderNavLink key={to} text={t(text)} to={to} src={src} />
          ))}
        </div>

        <Search />

        <button
          name="logout"
          onClick={logout}
          className="p-2 lg:pl-2 lg:pr-2 md:absolute right-12 hover:rounded-lg hover:bg-red-900 hidden md:flex md:mt-5 lg:mt-0 gap-2 items-center"
        >
          <img src={signOut} alt="Sign out" className="md:h-5 lg:h-4" />
          <span className="hidden lg:block">{t('GAME_SIGN_OUT')}</span>
        </button>
      </div>
      <div></div>
      {helpers && helpers?.NOTIFICATIONS && (
        <NotificationsProvider
          description={helpers.NOTIFICATIONS.DESCRIPTION}
          expirationInMinutes={helpers.NOTIFICATIONS.EXPIRY}
          shouldSaveIntoLocalStorage={helpers.NOTIFICATIONS.SHOULDSAVELOCAL}
          shouldRemoveFromLocalStorage={helpers.NOTIFICATIONS.SHOULDREMOVELOCAL}
          storageKey='asd'
        />
      )}
    </nav>
  );
};
