import { AppModal } from '../../../components/AppModal';
import { FC, useState } from 'react';
import { fromCDN } from '../../../helpers/Images';
import { defaultTransition } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from '../../../helpers/HelpersWithTypes';
import { BattlefieldBattleObjectProps } from '../../../shared-types/battlefield-types';
interface TopCountriesProps {
  attackerList: BattlefieldBattleObjectProps['countriesDamagesAttacker'];
  defenderList: BattlefieldBattleObjectProps['countriesDamagesDefender'];
}
export const TopCountries: FC<TopCountriesProps> = ({ attackerList, defenderList }: TopCountriesProps) => {
  const [modalState, setModalState] = useState(false);
  const { t } = useTranslation('common');

  return (
    <>
      <img
        onClick={() => {
          setModalState((prevState) => !prevState);
        }}
        src={fromCDN.fetchStaticGameImage('/battle/vs008')}
        className={'cursor-pointer ' + defaultTransition}
      />
      <AppModal
        open={modalState}
        setOpen={setModalState}
        desktopModalWidth="sm:max-w-3xl"
        shouldHaveCloseButton={false}
        backgroundStyle={{
          backgroundImage: `url(${fromCDN.fetchStaticImage('battlefieldleaderboard', 'png')})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '838px',
          height: '520px',
          position: 'absolute',
          backgroundPosition: 'center',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'block',
          clear: 'both',
        }}
        body={
          <div className="grid grid-cols-2  w-full px-20  mt-12  text-xs  ">
            <div className=" items-center gap-6 justify-between px-6 pr-24 rounded-t-lg border-none text-white">
              <table className="table-fixed min-w-full divide-y divide-gray-200 gap-4 ">
                <thead className="mb-3">
                  <tr className="mb-3">
                    <th className=" text-left text-xs font-medium text-md py-3">{t('GAME_COUNTRY')}</th>
                    <th className=" text-right text-xs font-medium text-md ">{t('GAME_HITS')}</th>
                    <th className=" text-right text-xs font-medium text-md ">{t('GAME_DAMAGE')}</th>
                  </tr>
                </thead>
                <tbody className="p-3">
                  {attackerList.map((item) => {
                    return (
                      <tr className="m-3" key={item.COUNTRY}>
                        <div className="inline-flex text-left text-xs font-medium p-1 inline-block break-all">
                          <img
                            src={fromCDN.fetchStaticGameImage(`/flagscountry/${item.COUNTRY}`)}
                            className="w-8 h-8 mx-auto rounded-md p-1 cursor-pointer default-transition"
                          />
                        </div>
                        <td className=" text-right text-xs font-medium uppercase ">
                          {numberWithCommas(Number(item.T_HIT))}
                        </td>
                        <td className=" text-right text-xs font-medium uppercase ">
                          {numberWithCommas(Number(item.T_DMG))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="ml-16 items-center  justify-between px-4 rounded-t-lg border-none text-white ">
              <table className="table-fixed min-w-full divide-y divide-gray-200 gap-4">
                <thead className="mb-3">
                  <tr className="mb-3">
                    <th className=" text-left text-xs font-medium text-md ">{t('GAME_DAMAGE')}</th>
                    <th className=" text-left text-xs font-medium text-md ">{t('GAME_HITS')}</th>
                    <th className=" text-right text-xs font-medium text-md py-3">{t('GAME_COUNTRY')}</th>
                  </tr>
                </thead>
                <tbody className="p-3">
                  {defenderList.map((item) => {
                    return (
                      <tr className="m-3" key={item.COUNTRY}>
                        <td className="text-left text-xs font-medium uppercase ">
                          {numberWithCommas(Number(item.T_DMG))}
                        </td>
                        <td className=" text-left text-xs font-medium uppercase ">
                          {numberWithCommas(Number(item.T_HIT))}
                        </td>
                        <div className=" text-right text-xs font-medium p-1  inline-block break-all float-right text-end">
                          <img
                            src={fromCDN.fetchStaticGameImage(`/flagscountry/${item.COUNTRY}`)}
                            width={24}
                            height={24}
                            className="  rounded-md  cursor-pointer default-transition mx-auto"
                          />
                        </div>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        }
      />
    </>
  );
};
