import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { countryFlags, resourceImages } from '../../../helpers/Helpers';
import Travel from '../../Profile/Tabs/Travel/Travel';
import CountryMedals from './CountryMedals';
import { Divider } from '../../../components/Divider';
import { authApi } from '../../../api';

import { fromCDN } from '../../../helpers/Images';
import { useHelper } from '../../../hooks/use-helper';
import { StartResistanceWar } from '../../Battles/StartResistanceWar';
import { HOSPITAL_EXPIRATION } from '../../../constants/enum';

export default function Society() {
  let params = useParams();
  let navigate = useNavigate();
  const currentCountry = params.id;

  const { t } = useTranslation('common');

  const { helpers } = useHelper();

  const [societyDetails, setSocietyDetails] = useState();
  const [regionDetails, setRegionDetails] = useState();
  const [medalDetails, setMedalDetails] = useState();
  const [travelModal, setTravelModal] = useState(false);
  const [travelRegion, setTravelRegion] = useState();
  const [getNeighbors, setGetNeighbors] = useState(false);
  const [resistanceWarModal, setResistanceWarModal] = useState(false);
  const [resistanceRegion, setResistanceRegion] = useState({
    regionId: 1,
    conqueror: 1,
  });

  useEffect(() => {
    authApi.get(`/api/country/society/${currentCountry}`).then((response) => {
      setSocietyDetails(response.data.COUNTRY);
      setRegionDetails(response.data.REGIONS);
      setMedalDetails(response.data.MEDALS);
    });
  }, [currentCountry]);

  return (
    <>
      <div>
        {societyDetails && (
          <>
            <CountryMedals medalDetails={medalDetails} setMedalDetails={setMedalDetails} />

            <div className="grid grid-cols-2 sm:grid-cols-5 mx-5 gap-4">
              <div className="p-3 bg-gray-100 shadow-inner rounded-lg">
                <div className="flex justify-center items-center gap-2 font-bold h-full">
                  <img className="h-8 w-10" src={fromCDN.fetchStaticGameImage('/icons/c2')} />
                  <div>
                    <span className="text-gray-700">{t('GAME_SOCIETY_ACTIVE')}</span>
                    <div className="text-indigo-800">
                      <div className="flex gap-1 justify-center items-center">
                        <span>{societyDetails[0]['ACTIVE'] || 0}</span>
                        {countryFlags(currentCountry)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 bg-gray-100 shadow-inner rounded-lg">
                <div className="flex justify-center items-center gap-2 h-full font-bold">
                  <img className="h-8 w-10" src={fromCDN.fetchStaticGameImage('/icons/c3')} />
                  <div className="flex flex-col items-center">
                    <span>{t('GAME_SOCIETY_NEW_CITIZENS')}</span>
                    <span className=" text-indigo-800">{societyDetails[0]['NEW'] || 0}</span>
                  </div>
                </div>
              </div>
              <div className="p-3 bg-gray-100 shadow-inner rounded-lg">
                <div className="flex justify-center items-center gap-2 h-full font-bold">
                  <img className="h-8 w-10" src={fromCDN.fetchStaticGameImage('/icons/c3')} />
                  <div className="flex flex-col gap-1 items-center">
                    <span className="text-gray-700">{t('GAME_SOCIETY_CS_REQUESTS')}</span>
                    <button
                      className="rounded-lg shadow hover:shadow-lg outline-none p-2 bg-gray-800 hover:bg-gray-900 text-white text-sm default-transition"
                      onClick={() => navigate(`/citizenship/${currentCountry}/1/1`)}
                    >
                      {t('GAME_SOCIETY_CS_VIEW')}
                    </button>
                  </div>
                </div>
              </div>
              <div className="p-3 bg-gray-100 shadow-inner rounded-lg">
                <div className="flex justify-center items-center gap-2 h-full font-bold">
                  <img className="h-8 w-10" src={fromCDN.fetchStaticGameImage('/icons/c1')} />
                  <div className="flex flex-col items-center">
                    <span className="text-gray-700">{t('GAME_SOCIETY_AVG_LEVEL')}</span>
                    <span className="text-indigo-800 font-extrabold mt-2">
                      {societyDetails[0]['LEVEL'] ? Number.parseInt(societyDetails[0]['LEVEL']) : 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-3 bg-gray-100 shadow-inner rounded-lg">
                <div className="flex justify-center items-center gap-2 h-full font-bold">
                  <img className="h-8 w-10" src={fromCDN.fetchStaticGameImage('/icons/icon-online')} />
                  <div className="flex flex-col items-center">
                    <span className="text-gray-700">{t('GAME_SOCIETY_ONLINE')}</span>
                    <span className="text-indigo-800 font-extrabold mt-2">{societyDetails[0]['ONLINE'] || 0}</span>
                  </div>
                </div>
              </div>
            </div>

            <Divider />

            <div className="m-5 flex gap-1 items-center">
              <div className="text-gray-200 font-bold grid place-items-center p-2">
                {t('GAME_REGIONS')} ({regionDetails ? regionDetails.length : 0})
              </div>
              <button
                className="rounded-lg bg-yellow-600 p-3"
                onClick={() => setGetNeighbors((prevState) => !prevState)}
              >
                {getNeighbors ? t('GAME_CLOSE_NEIGHBORS') : t('GAME_SHOW_NEIGHBORS')}
              </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-5 p-5 gap-8">
              {regionDetails?.map((item, key) => {
                return (
                  <Fragment key={key}>
                    <div className="bg-gray-100 p-3 text-center shadow-inner rounded-lg hover:bg-gray-200">
                      <div className="block text-center">
                        {countryFlags(item.OWNER, 'mx-auto')}
                        <div className="right-0">
                          <div className="inline-flex">
                            {item.NAME}
                            {item.ORG_OWNER !== item.OWNER && (
                              <span>{countryFlags(item.ORG_OWNER, 'mx-auto ml-2')}</span>
                            )}
                          </div>
                        </div>
                      </div>
                      {item.CAPITAL === parseInt(currentCountry) && (
                        <div className="float-left -mt-12 text-red-600 -ml-2 absolute">{t('GAME_CAPITAL')}</div>
                      )}

                      {item.ORG_OWNER !== item.OWNER && (
                        <>
                          <img
                            data-tip={true}
                            data-for={item.ID + 'resistanceWarDescription'}
                            src={fromCDN.fetchStaticGameImage('/icons/resistance')}
                            className="text-center mx-auto cursor-pointer default-transition -mt-12 text-red-600 -ml-2 "
                            data-testid={`startrw-${item.ID}`}
                            onClick={() => {
                              setResistanceRegion({
                                regionId: item.ID,
                                conqueror: item.OWNER,
                              });
                              setResistanceWarModal((prevState) => !prevState);
                            }}
                          />
                          <ReactTooltip id={item.ID + 'resistanceWarDescription'} effect="solid" data-html={true}>
                            {t('GAME_PROFILE_MEDAL_TEXT_8')}
                          </ReactTooltip>
                          {}
                        </>
                      )}

                      <div data-tip={true} data-for={item.ID + 'resourceDescription'} className="m-3">
                        {resourceImages(
                          helpers.Resources[helpers.Regions[item.ID].Resource[0]].Name,
                          'h-10 w-10 mx-auto',
                        )}
                      </div>

                      <ReactTooltip id={item.ID + 'resourceDescription'} effect="solid" data-html={true}>
                        {helpers.Resources[helpers.Regions[item.ID].Resource[0]].Name}
                      </ReactTooltip>

                      {getNeighbors && (
                        <div className="grid grid-cols-1 m-3">
                          <p className="bg-indigo-300 p-3 rounded shadow-inner m-3">{t('GAME_NEIGHBORS')}</p>
                          {helpers.Regions[item.ID].Neighbors.map((item, key) => {
                            return (
                              <div className="inline-flex m-2 text-left" key={key}>
                                {countryFlags(helpers.Regions[item].Owner, 'mr-1')}
                                {helpers.Countries[helpers.Regions[item].Owner].Name},{helpers.Regions[item].Name}
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {item.DEFENSE > 0 && (
                        <>
                          <Divider margin="m-1" />
                          <div>
                            <div className="inline-flex mt-3">
                              <img
                                className="w-10 h-10 mx-auto  cursor-pointer default-transition"
                                src={fromCDN.fetchStaticGameImage(`/items/${helpers.Items[10]['Img']}`)}
                              />
                              <sup className="mt-2 font-bold">Q{item.DEFENSE}</sup>
                            </div>
                            <div className="-mt-2 inline-flex mr-8">
                              <img src={fromCDN.fetchStaticGameImage('/icons/energy')} /> {item.DEF_ENERGY}
                            </div>
                          </div>
                        </>
                      )}

                      {item.HOSPITAL > 0 && (
                        <>
                          <Divider margin="m-1" />
                          <div data-tip={true} data-for={item.ID + 'hospitalExpires'}>
                            <div className="inline-flex mt-3">
                              <img
                                className="w-10 h-10 mx-auto cursor-pointer default-transition"
                                src={fromCDN.fetchStaticGameImage(`/items/${helpers.Items[9]['Img']}`)}
                              />
                              <sup className="mt-2 font-bold">Q{item.HOSPITAL}</sup>
                            </div>
                            <div className="-mt-2 inline-flex mr-8">
                              <img className="h-5 w-5" src={fromCDN.fetchStaticGameImage('/icons/warning')} />{' '}
                              {Number.parseInt(item.H_AMOUNT + HOSPITAL_EXPIRATION)}
                            </div>
                          </div>

                          <ReactTooltip id={item.ID + 'hospitalExpires'} effect="solid" data-html={true}>
                            {t('GAME_EXPIRES_AT')} {Number.parseInt(item.H_AMOUNT + HOSPITAL_EXPIRATION)}
                          </ReactTooltip>
                        </>
                      )}
                      <button
                        className="rounded-lg shadow hover:shadow-lg outline-none p-2 bg-green-600 hover:bg-green-700 text-white font-bold default-transition"
                        onClick={() => {
                          setTravelRegion(item.ID);
                          setTravelModal((prevState) => !prevState);
                        }}
                      >
                        {t('GAME_TRAVEL')}
                      </button>
                    </div>
                  </Fragment>
                );
              })}
              <Travel
                preSelectedCountry={currentCountry}
                preSelectedRegion={travelRegion}
                open={travelModal}
                setOpen={setTravelModal}
              />
            </div>
          </>
        )}
      </div>

      <StartResistanceWar
        regionId={resistanceRegion.regionId}
        regionOwner={resistanceRegion.conqueror}
        modal={resistanceWarModal}
        setModal={setResistanceWarModal}
      />
    </>
  );
}
