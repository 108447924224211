import StarIcon from './StarIcon';

interface StarsProps {
  totalStars: number;
  className?: string;
}

export const Stars = ({ totalStars, className }: StarsProps) => {
  const starsToRender = Array.from(Array(totalStars));

  return (
    <>
      {starsToRender.map((_, i) => {
        return <StarIcon key={i} className={className} />;
      })}
    </>
  );
};
