import React, { FC, useState } from 'react';
import { rankImages } from '../../../helpers/Helpers';
import StarIcon from '../../Util/Icons/star/StarIcon';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import ReactTooltip from 'react-tooltip';
import { inputButtonClass, lightTransition, themeButtonClass } from '../../../constants/constants';

import { fromCDN } from '../../../helpers/Images';

import { useCreateUnit } from './hooks/use-create-unit';
import { useAppTranslation } from '../../../hooks/use-app-translation';
interface CreateUnitProps {
  setCreateUnit: (p: any) => undefined;
}
export const CreateUnit: FC<CreateUnitProps> = ({ setCreateUnit }) => {
  const t = useAppTranslation();
  const [unitName, setUnitName] = useState('eDominators');
  const { handleCreateUnit } = useCreateUnit({
    unitName,
  });

  return (
    <>
      <div className="justify-center text-center m-3">
        <p className="font-bold ">{t('GAME_UNIT_REQUIREMENTS_DESC')}</p>
        <ArrowLeftIcon
          data-tip={true}
          data-for="returnbacktojoin"
          className={'h-10 w-10 mx-auto cursor-pointer text-green-600 ' + lightTransition}
          onClick={() => {
            setCreateUnit(null);
          }}
        />
        <ReactTooltip id="returnbacktojoin" effect="solid" data-html={true}>
          {t('GAME_BACK_TO_UNIT_LIST')}
        </ReactTooltip>
      </div>
      <div className="justify-center text-center grid grid-cols-3 m-5">
        <div className="bg-yellow-400 p-2 rounded shadow-md">
          <p className="bg-gray-200 p-1 font-bold shadow-inner rounded">{t('GAME_UNIT_REQUIREMENTS_1')}</p>
          <p className="inline-flex mt-2">
            <span className="font-extrabold text-big">100</span>{' '}
            <img
              className="h-5 w-5 mx-auto ml-1"
              src={fromCDN.fetchStaticGameImage('/icons/gold-icon')}
              alt="gold-icon"
            />
          </p>
        </div>

        <div className="bg-indigo-400 p-2 rounded shadow-md">
          <p className="bg-gray-200 p-1 font-bold shadow-inner rounded">{t('GAME_MILITARY_RANK')}</p>
          <p className="inline-flex mt-2">
            <span className="font-extrabold text-big">{rankImages(18)}</span>
          </p>
        </div>
        <div className="bg-gray-400 p-2 rounded shadow-md">
          <p className="bg-gray-200 p-1 font-bold shadow-inner rounded">{t('GAME_EXPERIENCE_AND_LEVEL')}</p>
          <p className="inline-flex mt-2">
            <span className="font-extrabold text-big">1 </span>
            <sup>
              <StarIcon className="h-5 w-5 mx-auto text-red-600" />
            </sup>
          </p>
        </div>
      </div>
      <div className="text-center justify-center">
        <p className="w-48 m-5 mx-auto">
          <input
            className={inputButtonClass + ' w-32'}
            placeholder={t('GAME_UNIT_REQUIREMENTS_2')}
            onChange={(e) => {
              setUnitName(e.target.value);
            }}
          />
        </p>
        <p>
          <button onClick={handleCreateUnit} className={themeButtonClass + ' bg-green-400'}>
            {t('GAME_MILITARY_CREATE_UNIT')}
          </button>
        </p>
      </div>
    </>
  );
};
