import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { TrashIcon } from '@heroicons/react/outline';
import EditorJS from '@editorjs/editorjs';

import { Loader } from '../../../components/Loader';
import { authApi } from '../../../api';
import { countryFlags } from '../../../helpers/Helpers';
import ThumbsUpIcon from '../../Util/Icons/ThumbsUpIcon';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { Divider } from '../../../components/Divider';

import { PostComment } from './PostComment';
import { imageAsBackground } from '../../../helpers/image-as-background';
import { fromCDN } from '../../../helpers/Images';
import { ArticleProps } from '../../../shared-types/NewspaperTypes';
import { CommentCard } from './CommentCard';
import { AppPageTemplate } from '../../../components/AppPageTemplate';
import { eDitorJSToolsConfig } from './eDitorJSToolsConfig';

export const Article = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const [isLoading, setIsLoading] = useState(false);
  const [articleDetails, setArticleDetails] = useState<ArticleProps | null>(null);

  const updateArticleDetails = (updatedDetails: ArticleProps) => {
    setArticleDetails(updatedDetails);
  };

  const voteArticle = () => {
    authApi
      .post(`/api/article/upvote/${params.id ?? 0}`)
      .then(() => {
        setArticleDetails((prevState) => (prevState ? { ...prevState, VOTES: prevState.VOTES + 1 } : null));

        ApplicationAlert(t('GAME_SUCCESS')).success();
      })
      .catch(() => {
        setIsLoading(false);
        ApplicationAlert(t('GAME_ARTICLE_ALREADY_VOTED')).error();
      });
  };

  const deleteComment = (id: number) => {
    authApi
      .get(`/api/article/comment/delete/${id}`)
      .then((response: AxiosResponse<ArticleProps>) => {
        updateArticleDetails(response.data);

        ApplicationAlert(t('GAME_SUCCESS')).success();
      })
      .catch((err) => {
        ApplicationAlert(err.response.data).error();
      });
  };

  const deleteArticle = async () => {
    if (articleDetails?.ID) {
      await authApi.post(`/api/article/delete/${articleDetails.ID}`);
      navigate(`/newspaper/${articleDetails.PAPER}`);
    }
  };

  const renderEditorJsContent = async (data: ArticleProps['ARTICLE']) => {
    if (typeof data === 'string') {
      try {
        new EditorJS({
          holder: 'article-read',
          data: JSON.parse(data),
          readOnly: true,
          tools: {
            ...eDitorJSToolsConfig,
          },
        });
      } catch (err) {
        ApplicationAlert(t('GAME_ARTICLE_BODY_ERROR')).info();
      }
    }
  };
  useEffect(() => {
    setIsLoading(true);
    authApi.get(`/api/article/${params.id ?? 0}`).then((response: AxiosResponse<ArticleProps>) => {
      updateArticleDetails(response.data);
      renderEditorJsContent(response.data.ARTICLE).then((r) => {
        setIsLoading(false);
      });
    });
  }, []);

  const renderedComments = articleDetails?.U_COMMENTS?.length ? (
    <>
      <ul className="list-none divide-y mx-2 sm:mx-5 divide-gray-400">
        {articleDetails.U_COMMENTS.map((comment) => (
          <CommentCard key={comment.ID} comment={comment} onDeleteComment={deleteComment} />
        ))}
      </ul>
      <Divider margin="m-3" />
    </>
  ) : null;

  return (
    <div
      className="min-h-screen max-w-13xl mx-auto bg-fixed"
      style={imageAsBackground(fromCDN.fetchStaticImage('t_messagesbg', 'svg'))}
    >
      <AppPageTemplate
        section={{
          parentChildren: (
            <div className="float-right flex flex-col space-y-2">
              <button className="bg-green-600 p-2 rounded-xl cursor-pointer default-transition" onClick={voteArticle}>
                <span className="block float-right">{articleDetails?.VOTES}</span>
                <ThumbsUpIcon className="h-5 w-5" />
              </button>
              <button
                className="bg-red-600 hover:bg-opacity-80 p-2 rounded-xl cursor-pointer flex justify-center items-center"
                onClick={deleteArticle}
              >
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
          ),
          title: isLoading ? (
            '...'
          ) : (
            <>
              <p>{articleDetails?.TITLE}</p>
              <div className="text-xs mt-2">
                <p className="flex gap-2 align-center">
                  <span>{t('GAME_PUBLISHED_IN')}</span>
                  {articleDetails?.COUNTRY && countryFlags(articleDetails.COUNTRY)}
                </p>
                <p>
                  {t('GAME_PUBLISHED_BY')}
                  <span className="ml-1">{articleDetails?.news_paper_with_user_details.public_user.user}</span>
                </p>
              </div>
            </>
          ),
          titleClass: 'font-bold text-base self-start',
        }}
      >
        <div className="py-2 text-gray-200">
          <div className="m-5 break-all" data-testid="articlebody">
            <div id="article-read" className="text-gray-200 article"></div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="grid grid-cols-1 m-5">
                <h2 className="ml-5 mt-5 font-bold">
                  {t('GAME_COMMENTS')} ({articleDetails?.COMMENTS})
                </h2>
                <Divider margin="m-3" />
                {renderedComments}
              </div>
              {articleDetails && (
                <PostComment articleDetails={articleDetails} setArticleDetails={updateArticleDetails} />
              )}
            </>
          )}
        </div>
      </AppPageTemplate>
    </div>
  );
};
