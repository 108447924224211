import { FormEvent, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { countryFlags } from '../../../../helpers/Helpers';
import { fromCDN } from '../../../../helpers/Images';
import { useHelper } from '../../../../hooks/use-helper';
import { useUser } from '../../../../hooks/use-user';
import { ValueOf } from '../../../../shared-types/util';
import { normalizeUserName } from '../../../../helpers/normalize';
import { Button } from '../../../../components/Buttons/Button';
import { Employee, PostChangeSalaryProps } from './types';

export interface EmployeeCardProps {
  employee: ValueOf<Employee>;
  onSalaryChange: (props: PostChangeSalaryProps) => void;
}

export const EmployeeCard = ({ employee, onSalaryChange }: EmployeeCardProps) => {
  const workedId = useId();
  const { helpers } = useHelper();
  const { user } = useUser();
  const { t } = useTranslation('common');
  const [amount, setAmount] = useState(employee.Salary);

  const workedToday = employee.Work === helpers.Game.Day;
  const workedTodayImage = workedToday ? '/icons/employee_on' : '/icons/employee_off';
  const workedTodayText = workedToday ? 'GAME_EMPLOYEE_WORKED_TODAY' : 'GAME_EMPLOYEE_NOT_WORKED_TODAY';

  const handleChangeSalary = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSalaryChange({ employeeId: employee.ID, newSalary: amount });
  };

  return (
    <div className="bg-edom-dark-yellow/90 p-5 rounded-lg shadow-[inset_0_-1px_5px_rgba(0,0,0,0.6)] flex flex-col gap-y-4 items-center">
      <div className="flex flex-col gap-y-1 text-center">
        <Link to={`/profile/${employee.ID}`}>
          <img
            src={fromCDN.fetchUserAvatar(employee.Avatar)}
            className="h-14 w-14 shadow-lg rounded-lg drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)] default-transition"
          />
        </Link>
        <p>{normalizeUserName(employee.Name)}</p>
      </div>
      <img
        data-tip={true}
        data-for={workedId}
        className="w-6 h-6 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]"
        src={fromCDN.fetchStaticGameImage(workedTodayImage)}
      />
      <ReactTooltip id={workedId} effect="solid" data-html={true} className="text-center">
        {t(workedTodayText)}
        {!workedToday ? (
          <p className="text-red-500">{`${t('GAME_EMPLOYEE_LAST_TIME_WORKED')}${employee.Work}`}</p>
        ) : null}
      </ReactTooltip>
      <div className="bg-black/40 rounded-lg p-2 font-medium w-full text-center">
        <p className="text-gray-200">{t('GAME_CURRENT_SALARY')}</p>

        <div className="flex gap-x-1 w-full justify-center">
          <span className="text-green-200">{employee.Salary}</span>
          <span className="ml-1">{countryFlags(user['CITIZENSHIP'])}</span>
        </div>
      </div>
      <form onSubmit={handleChangeSalary}>
        <div className="flex h-10">
          <input
            type="number"
            className="flex text-center hide-spinner w-full border border-gray-300 placeholder-gray-500 text-gray-900 rounded-l-md focus:outline-none focus:ring-green-100 focus:border-green-800 h-full"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.valueAsNumber);
            }}
            min="1"
          />
          <Button
            type="submit"
            color="green"
            className="w-10 p-1 border bg-green-700 hover:bg-green-800 border-green-700 hover:border-green-800 shadow-inner flex rounded-l-none items-center justify-center h-full"
          >
            <img className="w-5 h-5" src={fromCDN.fetchStaticImage('t_salarygreen', 'svg')} />
          </Button>
        </div>
      </form>

      <Button color="red">{t('GAME_FIRE_EMPLOYEE')}</Button>
    </div>
  );
};
