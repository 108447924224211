import { useContext } from 'react';
import userContext from '../constants/userContext';

export const useUser = () => {
  const { user, setUser } = useContext(userContext);

  return {
    user,
    setUser,
  };
};
