import { useEffect, useCallback, RefObject, MutableRefObject } from 'react';

const isTarget = (ref: RefObject<string>, event: Event) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return ref.current?.contains(event.target);
};

const trueForAny = (array: [], condition: (arg: any) => boolean) => {
  return array.reduce((conditionAlreadyMet, value) => {
    return conditionAlreadyMet || condition(value);
  }, false);
};

export const useClickOutside = (ref: MutableRefObject<undefined>[], onclick?: () => void) => {
  const handleClick = useCallback(
    (click: Event) => {
      if (Array.isArray(ref)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (trueForAny(ref, (ref) => isTarget(ref, click))) {
          return;
        }
      } else if (isTarget(ref, click)) {
        return;
      }

      if (onclick) {
        onclick();
      }
    },

    [onclick, ref],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
};
