import React from 'react';

import { countryFlags } from '../../helpers/Helpers';
import ArrowRight from '../Util/Icons/ArrowRight';

export const ProfileLocation = ({ region, flag, title }) => {
  return (
    <div className="grid grid-cols-4 mx-10">
      <div className="text-sm font-bold">{title}</div>
      <ArrowRight className="h-6 w-6 text-green-500 justify-self-end" />
      <div className="flex items-center gap-1 col-span-2 justify-end">
        <span>{countryFlags(flag)}</span>
        <span>{region.substr(0, 22)}</span>
      </div>
    </div>
  );
};
