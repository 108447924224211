import { useParams } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '../../../components/Loader';
import { Divider } from '../../../components/Divider';
import { countryFlags } from '../../../helpers/Helpers';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { CountrySelector } from '../../../components/CountrySelector';
import { imageAsBackground } from '../../../helpers/image-as-background';
import { fromCDN } from '../../../helpers/Images';
import { useUser } from '../../../hooks/use-user';
import { AppPageTemplate } from '../../../components/AppPageTemplate';
import { NoOffer } from '../NoOffer';
import Avatar from '../../../components/Avatar';
import { useJobMarket } from './use-job-market';
import { GetJobOffersApiReturn } from '../../../api/types';

export const JobMarket = () => {
  const { user, setUser } = useUser();

  const { t } = useTranslation('common');
  let params = useParams();

  const [countryMarketPlace, setCountryMarketPlace] = useState<number>(Number(params.countryid));
  const page = Number(params.page ?? user.COUNTRY);
  const [offers, setOffers] = useState<any | null>();
  const [isLoading, setIsLoading] = useState<ReactNode | null>();

  const { getOffers, applyToJob } = useJobMarket({
    countryId: countryMarketPlace,
    page,
  });

  const getJobs = async () => {
    setOffers(null);
    setIsLoading(<Loader />);
    setOffers(await getOffers());
    setIsLoading(null);
  };
  interface HandleAppyToJob {
    manager: GetJobOffersApiReturn['MANAGER'];
    orgType: GetJobOffersApiReturn['ORGTYPE'];
    salary: GetJobOffersApiReturn['SALARY'];
  }
  const handleApplyToJob = async ({ manager, orgType, salary }: HandleAppyToJob) => {
    try {
      await applyToJob({
        employer: manager,
        orgType,
        salary,
        onSuccess: (response) => {
          setUser(response.data);
        },
      });
      ApplicationAlert(t('GAME_JOB_ACCEPTED')).success();
    } catch (error: any) {
      ApplicationAlert(t(error)).error();
    }
  };

  const handleJobMarketChange = (selectedQuality: any) => {
    setCountryMarketPlace(selectedQuality.ID);
  };

  useEffect(() => {
    getJobs();
  }, [countryMarketPlace]);

  return (
    <AppPageTemplate
      section={{
        title: t('GAME_JOB_MARKET'),
        children: <CountrySelector countryId={params.countryid ?? user.COUNTRY} handleChange={handleJobMarketChange} />,
      }}
      mainStyle={imageAsBackground(fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg'))}
      children={
        <>
          {offers?.length ? (
            <>
              <div>
                <p className="m-5 font-bold text-gray-200">{t('GAME_OFFERS')}</p>
                <Divider margin="m-3" />
              </div>
            </>
          ) : (
            <NoOffer className="mt-10" />
          )}
          <div className="sm:text-center grid grid-cols-3 sm:grid sm:grid-cols-6 gap-4 m-5">
            {offers?.map((item: any, key: any) => {
              let animation = 'animate__animated animate__flipInX';
              if (key % 2 === 0) {
                animation = 'animate__animated animate__flipInY';
              }
              return (
                <div
                  key={key}
                  className={
                    'bg-sky-900/50 hover:bg-sky-900 cursor-pointer justify-center text-center  m-2 shadow-inner rounded-lg ' +
                    animation
                  }
                >
                  <p className="p-3 font-bold">{t('GAME_EMPLOYER')}</p>
                  <div className="mt-5">
                    <Avatar avatarId={item.U_AVATAR} userId={item.U_ID} className="mx-auto" />
                    <span className="font-semibold">{item.U_NAME.substr(0, 20)}</span>
                  </div>
                  <div>
                    <p
                      className="font-bold m-5 p-3 bg-blue-200 rounded-lg text-gray-900"
                      style={{ textShadow: '0 1px 0 #fff' }}
                    >
                      {t('GAME_SALARY')}
                    </p>
                  </div>
                  <div className="font-bold m-5 inline-flex bg-black/40 p-3 rounded-lg text-green-300">
                    <span>{item.SALARY}</span>
                    <span className="ml-1 mt-0.5">{countryFlags(item.COUNTRY, 'mx-auto')}</span>
                  </div>
                  {item.COUNTRY === user.COUNTRY && user.COMPANY === 0 && (
                    <div className="m-5">
                      <button
                        onClick={() => {
                          handleApplyToJob({
                            manager: item.MANAGER,
                            orgType: item.ORGTYPE,
                            salary: item.SALARY,
                          });
                        }}
                        className="bg-green-600 text-white hover:bg-green-800 text-sm font-bold transition ease-in-out   duration-500 hover:scale-105 py-3 px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      >
                        {t('GAME_APPLY_JOB')}
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {isLoading && isLoading}
        </>
      }
    />
  );
};
