import { useTranslation } from 'react-i18next';

import { Divider } from '../../../components/Divider';
import { MarketplaceCard } from './MarketplaceCard';
import { OffersOffersContainerProps } from './types';

export const OffersContainer = ({ offers, updateOffer }: OffersOffersContainerProps) => {
  const { t } = useTranslation('common');

  return (
    <div className="m-5">
      <p className="font-bold text-gray-200">{t('GAME_OFFERS')}</p>
      <Divider margin="my-3" />

      <div className="sm:text-center grid grid-cols-2 sm:grid-cols-5 gap-3 m-5">
        {offers.map((item) => (
          <MarketplaceCard key={item.ID} item={item} updateOffer={updateOffer} />
        ))}
      </div>
    </div>
  );
};
