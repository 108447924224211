import {
  avatarClass,
  BASE_URL,
  BEARER,
  defaultTransition,
  inputButtonClass,
  themeButtonClass,
} from '../../../constants/constants';
import { ArrowCircleLeftIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { Divider } from '../../../components/Divider';
import ChartIcon from '../../Util/Icons/ChartIcon';
import ReactTooltip from 'react-tooltip';
import { countryFlags } from '../../../helpers/Helpers';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ApplicationAlert from '../../../components/ApplicationAlert';
import FileUpload from '../../Util/FileUpload';
import { useHelper } from '../../../hooks/use-helper';
import { fromCDN } from '../../../helpers/Images';

export const UnitEdit = ({
  myUnit,
  setMyUnit = () => {},
  setEditUnit = () => {},
  resolveCoverAvatar = () => {},
  setShouldUpdateEdit = () => {},
}) => {
  let navigate = useNavigate();
  const { helpers } = useHelper();
  const { t } = useTranslation('common');

  const [unitName, setUnitName] = useState(myUnit.NAME);
  const [unitInfo, setUnitInfo] = useState(myUnit.INFO);

  function handleSave() {
    save();
  }

  async function save() {
    await axios
      .post(
        BASE_URL + '/api/military-unit/edit-unit',
        {
          UNIT_ID: myUnit.ID,
          NAME: unitName,
          INFO: unitInfo,
        },
        {
          headers: { Authorization: 'Bearer ' + BEARER },
        },
      )
      .then((response) => {
        setMyUnit(response.data);
        setShouldUpdateEdit(true);
        ApplicationAlert(t('GAME_SUCCESS')).success();
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });
  }

  useEffect(() => {}, [unitName, unitInfo, myUnit, setMyUnit]);
  return (
    <>
      <div className={'animate__animated animate__fadeIn'}>
        <div
          className={'h-32 w-full'}
          style={{
            backgroundImage: resolveCoverAvatar(myUnit.MU_COVER),
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className={'flex'}>
            <img
              className={'absolute w-20 h-20 ml-10 mt-6 ' + avatarClass}
              src={fromCDN.fetchMilitaryUnitAvatar(myUnit.AVATAR)}
            />
          </div>
          <div className={'text-white text-right m-5'}>
            <span data-tip data-for={'rankingdesc'} onClick={() => navigate('/rankings/' + myUnit.COUNTRY + '/5/1')}>
              <ChartIcon
                classNames={
                  'float-right h-10 w-10 m-1 cursor-pointer text-indigo-400 hover:text-indigo-500 ' + defaultTransition
                }
              />
            </span>
            <ReactTooltip id={'rankingdesc'} effect="solid" data-html={true}>
              {t('GAME_COMMUNITY_RANKINGS')}
            </ReactTooltip>
            <h1 className={'font-bold text-lg'}>{myUnit.NAME}</h1>
            <h1 className={'font-bold text-lg'}>{myUnit.INFO}</h1>
            <h1
              className={'font-bold text-lg inline-flex cursor-pointer'}
              onClick={() => navigate('/country/society/' + myUnit.COUNTRY)}
            >
              <span className={'mr-1'}>{t('GAME_LOCATION')}:</span> {countryFlags(myUnit.COUNTRY, 'mx-auto')}
              <span className={'ml-1'}>{helpers.Countries[myUnit.COUNTRY].Name}</span>
            </h1>
          </div>
        </div>
        <div onClick={() => setEditUnit(null)} className={'mt-3 cursor-pointer ' + defaultTransition}>
          <ArrowCircleLeftIcon className={'h-8 w-8 text-blue-600 mx-auto'} />
        </div>
        <Divider margin={'mx-5 my-2'} />

        <div className={'grid grid-cols-1 sm:grid-cols-2'}>
          <div>
            <div className={'justify-center text-center'}>
              <div className={' justify-center m-5'}>
                <p>
                  {t('GAME_UNIT_NAME')}
                  <sup>{t('GAME_UNIT_NAME_LIMIT')}</sup>
                </p>
                <p>
                  <input
                    className={inputButtonClass}
                    placeholder={unitName}
                    defaultValue={unitName}
                    onChange={(e) => setUnitName(e.target.value)}
                  />
                </p>
                <p>
                  <button onClick={() => handleSave()} className={themeButtonClass + ' bg-blue-300 mt-2'}>
                    {t('GAME_SAVE')}
                  </button>
                </p>
              </div>
            </div>
            <div className={'justify-center text-center'}>
              <div className={' justify-center m-5'}>
                <p>
                  {t('GAME_UNIT_INFO')}
                  <sup>{t('GAME_UNIT_INFO_LIMIT')}</sup>
                </p>
                <p>
                  <input
                    className={inputButtonClass}
                    placeholder={unitInfo}
                    defaultValue={unitInfo}
                    onChange={(e) => setUnitInfo(e.target.value)}
                  />
                </p>
                <p>
                  <button onClick={() => handleSave()} className={themeButtonClass + ' bg-blue-300 mt-2'}>
                    {t('GAME_SAVE')}
                  </button>
                </p>
              </div>
            </div>

            <div className={'justify-center text-center'}>
              <div className={' justify-center m-5'}>
                <p>{t('GAME_UNIT_AVATAR_DESC')}</p>
                <p>
                  {' '}
                  <FileUpload
                    url={BASE_URL + '/api/military-unit/edit-unit'}
                    formParams={{ name: 'UNIT_ID', VALUE: myUnit.ID }}
                    fileKey={'AVATAR'}
                    setter={setMyUnit}
                  />
                </p>
              </div>
            </div>

            <div className={'justify-center text-center'}>
              <div className={' justify-center m-5'}>
                <p>{t('GAME_UNIT_COVER_DESC')}</p>
                <p>
                  {' '}
                  <FileUpload
                    url={BASE_URL + '/api/military-unit/edit-unit'}
                    formParams={{ name: 'UNIT_ID', VALUE: myUnit.ID }}
                    fileKey={'MU_COVER'}
                    setter={setMyUnit}
                  />
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};
