import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { fromCDN } from '../../helpers/Images';

export default function BattleFieldCharts({
  chartPosition = 'top',
  labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  dataInput = [],
}) {
  const { t, i18n } = useTranslation('common');

  ChartJS.defaults.color = '#FFFFFF';
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  const plugins = [
    {
      afterDraw: (chart) => {
        var ctx = chart.ctx;
        var xAxis = chart.scales.scales;
        var yAxis = chart.scales.scales;
        chart.config._config.data.datasets[0].data.map((value, index) => {
          var x = xAxis.getPixelForValue(index);
          if (chart.config._config.data.datasets[0].data.length === 3) {
            if (index === 0) {
              x += -15;
            }
            if (index === 2) {
              x += 18;
            }

            var image = new Image();
            image.src = fromCDN.fetchUserAvatar(labels[index].U_AVATAR);
            return ctx?.drawImage(image, x + 215, yAxis.bottom, 35, 35);
          }

          if (chart.config._config.data.datasets[0].data.length === 1) {
            var image = new Image();
            image.src = fromCDN.fetchUserAvatar(labels[index].U_AVATAR);
            return ctx?.drawImage(image, x + 95, yAxis.bottom, 35, 35);
          }
        });
      },
    },
  ];
  const options = {
    responsive: true,
    scaleFontColor: '#FFFFFF',
    scales: {
      x: {
        ticks: {
          callback: function (label, key) {
            return ''; //`\$${this.getLabelForValue(label)}`
          },
        },
      },
      secondXAxis: {
        axis: 'x',
        labels: labels.map((item, key) => {
          return item.U_NAME;
        }),
        grid: {
          drawOnChartArea: true,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              padding: 30,
            },
          },
        ],
      },
    },
  };

  const data = {
    labels: labels.map((item, key) => {
      return item.U_NAME;
    }),
    datasets: [
      {
        label: t('GAME_DAMAGE'),
        data: Object.values(labels).map((item, key) => {
          return dataInput[key] ? dataInput[key]['T_DMG'] : 0;
        }),
        backgroundColor: 'rgb(234 179 8)',
      },
      {
        label: t('GAME_HITS'),
        data: Object.values(labels).map((item, key) => {
          return dataInput[key] ? dataInput[key]['T_HIT'] : 0;
        }),
        backgroundColor: 'rgb(234 279 2)',
      },
    ],
  };

  return <Bar color={'text-white'} options={options} data={data} plugins={plugins} />;
}
