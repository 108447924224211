import ReactTooltip from 'react-tooltip';

import { ItemsDataTip } from '../../../components/ItemsDataTip';
import StorageImage from '../../../components/StorageImage';
import { fromCDN } from '../../../helpers/Images';

export default function ActiveHouse({ x, storageHouse, t, house, houseTimeLeft }) {
  const houseImage = <StorageImage imagePath={house['Img']} className="w-10 h-10 mx-auto" />;
  const houseEnergy = x * 50;
  const storageHouseClass = storageHouse[x] === 0 ? 'text-red-400' : 'text-green-300';

  return (
    <>
      <div
        data-tip
        data-for={x.toString()}
        className="bg-edom-dark-blue/90 m-3 sm:m-5 rounded-lg animate__animated animate__flipInX flex flex-col gap-2 p-2 sm:p-3 text-gray-200 shadow-[inset_0_-1px_5px_rgba(0,0,0,0.6)]"
      >
        {houseImage}
        <p className="font-semibold mt-0">Q{x}</p>
        <p className="font-bold">{house['Name']}</p>
        <p className="bg-indigo-300/40 rounded-lg text-white py-3 sm:py-3">
          {houseEnergy} <img className="inline h-4 w-4" src={fromCDN.fetchStaticImage('/game/icons/energy-s', 'png')}></img>
        </p>
        <div className="bg-indigo-300/40 rounded-lg text-white flex flex-col sm:flex-row justify-center items-center sm:gap-1 py-3">
          <span className={storageHouseClass} data-testid="quantity">
            {storageHouse[x]}
          </span>
          <span>{t('GAME_IN_WAREHOUSE')}</span>
        </div>
        <p className="rounded-lg mt-6">{houseTimeLeft}</p>
      </div>

      <ReactTooltip id={x.toString()} effect="solid" data-html={true}>
        <ItemsDataTip
          translation={t}
          itemNumber={8}
          name={house['Name']}
          quality={x}
          image={houseImage}
          description={t('GAME_ITEMS_8_DESCRIPTION')}
          firepowerMultiplier={houseEnergy}
        />
      </ReactTooltip>
    </>
  );
}
