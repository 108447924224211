import ReactTooltip from 'react-tooltip';
import { ItemsDataTip } from '../../../components/ItemsDataTip';
import React, { Fragment, useEffect, useState } from 'react';
import { Divider } from '../../../components/Divider';
import SellItemsOnMarket from './SellItems/SellItemsOnMarket';
import { AppModal } from '../../../components/AppModal';
import Select from 'react-select';
import { countryFlags, storageItems } from '../../../helpers/Helpers';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { authApi } from '../../../api';
import StorageImage from '../../../components/StorageImage';
import { useUser } from '../../../hooks/use-user';
import {useHelper} from "../../../hooks/use-helper";

export default function StorageDisplay({ myStorageItems,t, setMyStorageItems }) {
  const { helpers } = useHelper()
  const { user, setUser } = useUser();
  const [modalBody, setModalBody] = useState();

  const [modal, setModal] = useState(false);

  const [myLicences, setMyLicences] = useState();
  const [helperCountries] = useState(Object.values(helpers.Countries));
  const [selectCountries, setSelectCountries] = useState();
  const [sellableItems, setSellableItems] = useState();
  const [myListedItems, setMyListedItems] = useState();
  const [alertModal, setAlertModal] = useState();

  let selectedSellingItem = 0;
  let selectedSellingItemQuality = 0;
  let selectedSellingItemPrice = 1;
  let selectedSellingItemQuantity = 1;
  let selectedSellingItemLicence = 0;

  const searchStyle = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  async function getMarketOffers() {
    await authApi
      .get('/api/business/storage/marketlist')
      .then((response) => {
        setMyLicences(response.data.Licences);
        setSellableItems(response.data.SellableItems);
        setMyListedItems(response.data.MarketOffers);
        handleLicencesSearch(response.data.Licences);
      })
      .catch((error) => {});
  }

  function handleLicencesSearch(licences) {
    let selectCountriesfiltered = [];
    Object.values(licences).map((item, key) => {
      if (helperCountries[item - 1]) {
        selectCountriesfiltered.push(helperCountries[item - 1]);
      } else {
        helperCountries.splice(item - 1, 1);
      }
    });
    setSelectCountries(selectCountriesfiltered);
  }

  function handleSelectedItem(value) {
    selectedSellingItem = value;
  }
  function handleSelectedItemQuality(value) {
    selectedSellingItemQuality = value;
  }

  function handlePriceChange(value) {
    selectedSellingItemPrice = value;
  }

  function handleQuantityChange(value) {
    selectedSellingItemQuantity = value;
  }

  function handleMarketChange(id) {
    selectedSellingItemLicence = id.ID;
  }

  function addItemToListing() {
    if (
      Number(selectedSellingItemPrice) <= 0 ||
      Number(selectedSellingItemQuality) <= 0 ||
      Number(selectedSellingItemQuantity) <= 0 ||
      Number(selectedSellingItemLicence) <= 0
    ) {
      ApplicationAlert(t('GAME_FILL_INPUTS')).error();
      return;
    }

    addNewItemsListed();
  }

  async function addNewItemsListed() {
    await authApi
      .post('/api/business/storage/addmarketlistitem', {
        item: Number(selectedSellingItem),
        quantity: Number(selectedSellingItemQuantity),
        quality: Number(selectedSellingItemQuality),
        price: Number(selectedSellingItemPrice),
        licence: Number(selectedSellingItemLicence),
      })
      .then((response) => {
        setUser(response.data.USER);
        setMyListedItems(response.data.MarketOffers);
        setMyStorageItems(storageItems({ user: response.data.USER }));

        ApplicationAlert(t(response.data.MESSAGE)).success();
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });
  }

  useEffect(() => {
    getMarketOffers();
  }, []);

  useEffect(() => {}, [setUser, user]);
  let myItems = [];
  let myRawMaterials = [];
  return (
    <>
      {myStorageItems &&
        Object.keys(myStorageItems[0]).map((storageItems, key) => {
          for (let x = 1; x <= myStorageItems[1]; x++) {
            if (myStorageItems[0][storageItems][x] > 0) {
              if (helpers.Items[storageItems] !== undefined && helpers.Items[storageItems]['Type'] === 1) {
                if (helpers.Items[storageItems]['Damage'] && !helpers.Items[storageItems]['Duration']) {
                  myItems.push(
                    <Fragment key={`${key}${helpers.Items[storageItems].id}`}>
                      <div
                        data-tip={true}
                        data-testid="storage-item"
                        data-for={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        className="storageItems bg-blue-100 cursor-pointer"
                        onClick={() => {
                          if (sellableItems[storageItems - 1]) {
                            setModalBody(
                              <>
                                <div className="justify-start flex">
                                  <div
                                    data-tip={true}
                                    data-for={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                                    className="storageItems bg-blue-100"
                                  >
                                    <StorageImage
                                      className="w-10 h-10 absolute mt-3 ml-5 cursor-pointer default-transition"
                                      imagePath={helpers.Items[storageItems]['Img']}
                                    />
                                    <span className="storageItemsItemNameSpan">
                                      {helpers.Items[storageItems]['Name']}
                                    </span>
                                    <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                                    <input
                                      onChange={(e) => handleQuantityChange(e.target.value)}
                                      defaultValue={myStorageItems[0][storageItems][x]}
                                      type="input"
                                      max={myStorageItems[0][storageItems][x]}
                                      min={1}
                                      className="storageItemsItemQuantitySpan mt-1 cursor-pointer hover:bg-green-700 animate__animated animate__tada"
                                    ></input>
                                  </div>
                                  <div>
                                    <input
                                      placeholder={t('GAME_MARKET_PRICE_DESCRIPTION')}
                                      onChange={(e) => {
                                        handlePriceChange(e.target.value);
                                      }}
                                      type="input"
                                      max={myStorageItems[0][storageItems][x]}
                                      min={1}
                                      className="ml-3 cursor-pointer input--button"
                                    ></input>
                                    <div className="flex ml-3 mt-2">
                                      {myLicences ? (
                                        <Select
                                          className="basic-select "
                                          classNamePrefix="select"
                                          onChange={handleMarketChange}
                                          isSearchable={true}
                                          autosize={true}
                                          styles={searchStyle}
                                          // defaultValue={{value : countryMarketPlace, label : countryMarketPlace}}
                                          formatOptionLabel={(selectCountries) => (
                                            <p className="inline-flex">
                                              {countryFlags(selectCountries.ID)}{' '}
                                              <span className="ml-1">{selectCountries.Name}</span>
                                            </p>
                                          )}
                                          getOptionLabel={(selectCountries) => selectCountries.Name}
                                          getOptionValue={(selectCountries) => selectCountries.ID}
                                          options={selectCountries}
                                        />
                                      ) : (
                                        <div>No licences atm</div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <button
                                  className="mt-3 bg-green-600 text-white hover:bg-green-800 text-sm font-bold transition ease-in-out   duration-500  hover:scale-105  py-3 px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 "
                                  onClick={() => addItemToListing()}
                                >
                                  {t('GAME_ADD')}
                                </button>
                              </>,
                            );
                            handleSelectedItem(storageItems);
                            handleSelectedItemQuality(x);
                            setModal(true);
                          }
                        }}
                      >
                        <StorageImage
                          className="w-10 h-10 absolute mt-3 ml-5 cursor-pointer default-transition"
                          imagePath={helpers.Items[storageItems]['Img']}
                        />
                        <span className="storageItemsItemNameSpan ">{helpers.Items[storageItems]['Name']}</span>
                        <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                        <span className="storageItemsItemQuantitySpan mt-1">{myStorageItems[0][storageItems][x]}</span>
                      </div>
                      <ReactTooltip
                        id={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        effect="solid"
                        data-html={true}
                      >
                        <ItemsDataTip
                          itemNumber={storageItems}
                          translation={t}
                          name={helpers.Items[storageItems]['Name']}
                          quality={x}
                          image={
                            <StorageImage className="w-10 h-10 mx-auto" imagePath={helpers.Items[storageItems]['Img']} />
                          }
                          description={t('GAME_ITEMS_' + storageItems + '_DESCRIPTION')}
                          firepowerMultiplier={'x' + helpers.Items[storageItems]['Damage'][x]}
                        />
                      </ReactTooltip>
                    </Fragment>,
                  );
                }

                if (helpers.Items[storageItems]['Name'] === 'Energy') {
                  myItems.push(
                    <Fragment key={`${key}${helpers.Items[storageItems]['Name']}`}>
                      <div
                        data-tip={true}
                        data-for={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        className="storageItems bg-blue-100"
                      >
                        <StorageImage
                          className="w-10 h-10 absolute mt-3 ml-5 default-transition"
                          imagePath={helpers.Items[storageItems]['Img']}
                        />
                        <span className="storageItemsItemNameSpan ">{helpers.Items[storageItems]['Name']}</span>
                        <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                        <span className="storageItemsItemQuantitySpan mt-1">{myStorageItems[0][storageItems][x]}</span>
                      </div>

                      <ReactTooltip
                        id={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        effect="solid"
                        data-html={true}
                      >
                        <ItemsDataTip
                          itemNumber={storageItems}
                          translation={t}
                          name={helpers.Items[storageItems]['Name']}
                          quality={x}
                          image={
                            <StorageImage className="w-10 h-10 mx-auto" imagePath={helpers.Items[storageItems]['Img']} />
                          }
                          description={t('GAME_ITEMS_' + storageItems + '_DESCRIPTION')}
                          firepowerMultiplier={100}
                        />
                      </ReactTooltip>
                    </Fragment>,
                  );
                }

                if (helpers.Items[storageItems]['Energy']) {
                  myItems.push(
                    <Fragment key={`${key}${helpers.Items[storageItems].id}`}>
                      <div
                        data-tip={true}
                        data-for={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        className="storageItems bg-blue-100"
                      >
                        <StorageImage
                          className="w-10 h-10 absolute mt-3 ml-5 default-transition"
                          imagePath={helpers.Items[storageItems]['Img']}
                        />
                        <span className="storageItemsItemNameSpan ">{helpers.Items[storageItems]['Name']}</span>
                        <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                        <span className="storageItemsItemQuantitySpan mt-1">{myStorageItems[0][storageItems][x]}</span>
                      </div>

                      <ReactTooltip
                        id={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        effect="solid"
                        data-html={true}
                      >
                        <ItemsDataTip
                          itemNumber={storageItems}
                          translation={t}
                          name={helpers.Items[storageItems]['Name']}
                          quality={x}
                          image={
                            <StorageImage className="w-10 h-10 mx-auto" imagePath={helpers.Items[storageItems]['Img']} />
                          }
                          description={t('GAME_ITEMS_' + storageItems + '_DESCRIPTION')}
                          firepowerMultiplier={`${helpers.Items[storageItems]['Energy'][x]}%`}
                        />
                      </ReactTooltip>
                    </Fragment>,
                  );
                }

                if (helpers.Items[storageItems]['Duration']) {
                  myItems.push(
                    <Fragment key={key + x + x}>
                      <div
                        data-tip={true}
                        data-for={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        className="storageItems bg-blue-100"
                      >
                        <StorageImage
                          className="w-10 h-10 absolute mt-3 ml-5 default-transition"
                          imagePath={helpers.Items[storageItems]['Img']}
                        />
                        <span className="storageItemsItemNameSpan ">{helpers.Items[storageItems]['Name']}</span>
                        <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                        <span className="storageItemsItemQuantitySpan mt-1">{myStorageItems[0][storageItems][x]}</span>
                      </div>
                      <ReactTooltip
                        id={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        effect="solid"
                        data-html={true}
                      >
                        <ItemsDataTip
                          itemNumber={storageItems}
                          translation={t}
                          name={helpers.Items[storageItems]['Name']}
                          quality={x}
                          image={
                            <StorageImage className="w-10 h-10 mx-auto" imagePath={helpers.Items[storageItems]['Img']} />
                          }
                          description={t('GAME_ITEMS_' + storageItems + '_DESCRIPTION')}
                          firepowerMultiplier={'x' + helpers.Items[storageItems]['Damage'][x]}
                          meta={helpers.Items[storageItems]['Duration'][x] + ' ' + t('GAME_MINUTES')}
                        />
                      </ReactTooltip>
                    </Fragment>,
                  );
                }

                if (storageItems == 4) {
                  myItems.push(
                    <Fragment key={key + '_' + x}>
                      <div
                        data-tip={true}
                        data-testid="storage-item"
                        data-for={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        className="storageItems bg-blue-100 cursor-pointer"
                        onClick={() => {
                          if (sellableItems[storageItems - 1]) {
                            setModalBody(
                              <>
                                <div className="justify-start flex">
                                  <div
                                    data-tip={true}
                                    data-for={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                                    className="storageItems bg-blue-100"
                                  >
                                    <StorageImage
                                      className="w-10 h-10 absolute mt-3 ml-5 cursor-pointer default-transition"
                                      imagePath={helpers.Items[storageItems]['Img']}
                                    />
                                    <span className="storageItemsItemNameSpan ">
                                      {helpers.Items[storageItems]['Name']}
                                    </span>
                                    <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                                    <input
                                      onChange={(e) => handleQuantityChange(e.target.value)}
                                      defaultValue={myStorageItems[0][storageItems][x]}
                                      type="input"
                                      max={myStorageItems[0][storageItems][x]}
                                      min={1}
                                      className="storageItemsItemQuantitySpan mt-1 cursor-pointer hover:bg-green-700 animate__animated animate__tada"
                                    ></input>
                                  </div>
                                  <div>
                                    <input
                                      placeholder={t('GAME_MARKET_PRICE_DESCRIPTION')}
                                      data-testid="storage-item-price"
                                      onChange={(e) => {
                                        handlePriceChange(e.target.value);
                                      }}
                                      type="input"
                                      max={myStorageItems[0][storageItems][x]}
                                      min={1}
                                      className="ml-3 cursor-pointer input--button"
                                    ></input>
                                    <div className="flex ml-3 mt-2">
                                      {myLicences ? (
                                        <Select
                                          className="basic-select "
                                          classNamePrefix="select"
                                          onChange={handleMarketChange}
                                          isSearchable={true}
                                          autosize={true}
                                          styles={searchStyle}
                                          // defaultValue={{value : countryMarketPlace, label : countryMarketPlace}}
                                          formatOptionLabel={(selectCountries) => (
                                            <p className="inline-flex">
                                              {countryFlags(selectCountries.ID)}{' '}
                                              <span className="ml-1">{selectCountries.Name}</span>
                                            </p>
                                          )}
                                          getOptionLabel={(selectCountries) => selectCountries.Name}
                                          getOptionValue={(selectCountries) => selectCountries.ID}
                                          options={selectCountries}
                                        />
                                      ) : (
                                        <div>No licences atm</div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <button
                                  data-testid="sell-storage-item-button"
                                  className="mt-3 bg-green-600 text-white hover:bg-green-800 text-sm font-bold transition ease-in-out duration-500 hover:scale-105 py-3 px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                  onClick={() => addItemToListing()}
                                >
                                  {t('GAME_ADD')}
                                </button>
                              </>,
                            );
                            handleSelectedItem(storageItems);
                            handleSelectedItemQuality(x);
                            setModal(true);
                          }
                        }}
                      >
                        <StorageImage
                          className="w-10 h-10 absolute mt-3 ml-5 default-transition"
                          imagePath={helpers.Items[storageItems]['Img']}
                        />
                        <span className="storageItemsItemNameSpan ">{helpers.Items[storageItems]['Name']}</span>
                        <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                        <span className="storageItemsItemQuantitySpan mt-1 ">{myStorageItems[0][storageItems][x]}</span>
                      </div>
                      <ReactTooltip
                        id={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        effect="solid"
                        data-html={true}
                      >
                        <ItemsDataTip
                          itemNumber={storageItems}
                          translation={t}
                          name={helpers.Items[storageItems]['Name']}
                          quality={x}
                          image={
                            <StorageImage className="w-10 h-10 mx-auto" imagePath={helpers.Items[storageItems]['Img']} />
                          }
                          description={t('GAME_ITEMS_' + storageItems + '_DESCRIPTION')}
                          firepowerMultiplier={[x * 2] + ' / ' + t('GAME_USE')}
                        />
                      </ReactTooltip>
                    </Fragment>,
                  );
                }

                if (storageItems == 8) {
                  myItems.push(
                    <Fragment key={key + '-' + x}>
                      <div
                        data-tip={true}
                        data-testid="storage-item"
                        data-for={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        className="storageItems bg-blue-100 cursor-pointer"
                        onClick={() => {
                          if (sellableItems[storageItems - 1]) {
                            setModalBody(
                              <>
                                <div className="justify-start flex">
                                  <div
                                    data-tip={true}
                                    data-for={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                                    className="storageItems bg-blue-100"
                                  >
                                    <StorageImage
                                      className="w-10 h-10 absolute mt-3 ml-5 cursor-pointer default-transition"
                                      imagePath={helpers.Items[storageItems]['Img']}
                                    />
                                    <span className="storageItemsItemNameSpan ">
                                      {helpers.Items[storageItems]['Name']}
                                    </span>
                                    <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                                    <input
                                      onChange={(e) => handleQuantityChange(e.target.value)}
                                      defaultValue={myStorageItems[0][storageItems][x]}
                                      type="input"
                                      max={myStorageItems[0][storageItems][x]}
                                      min={1}
                                      className="storageItemsItemQuantitySpan mt-1 cursor-pointer hover:bg-green-700 animate__animated animate__tada"
                                    ></input>
                                  </div>
                                  <div>
                                    <input
                                      placeholder="Price"
                                      onChange={(e) => {
                                        handlePriceChange(e.target.value);
                                      }}
                                      type="input"
                                      max={myStorageItems[0][storageItems][x]}
                                      defaultValue={1}
                                      min={1}
                                      className="ml-3 cursor-pointer input--button"
                                    ></input>
                                    <div className="flex ml-3 mt-2">
                                      {myLicences ? (
                                        <Select
                                          className="basic-select "
                                          classNamePrefix="select"
                                          onChange={handleMarketChange}
                                          isSearchable={true}
                                          autosize={true}
                                          styles={searchStyle}
                                          // defaultValue={{value : countryMarketPlace, label : countryMarketPlace}}
                                          formatOptionLabel={(selectCountries) => (
                                            <p className="inline-flex">
                                              {countryFlags(selectCountries.ID)}{' '}
                                              <span className="ml-1">{selectCountries.Name}</span>
                                            </p>
                                          )}
                                          getOptionLabel={(selectCountries) => selectCountries.Name}
                                          getOptionValue={(selectCountries) => selectCountries.ID}
                                          options={selectCountries}
                                        />
                                      ) : (
                                        <div>No licences atm</div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <button
                                  className="mt-3 bg-green-600 text-white hover:bg-green-800 text-sm font-bold transition ease-in-out   duration-500  hover:scale-105  py-3 px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 "
                                  onClick={() => addItemToListing()}
                                >
                                  {t('GAME_ADD')}
                                </button>
                              </>,
                            );
                            handleSelectedItem(storageItems);
                            handleSelectedItemQuality(x);
                            setModal(true);
                          }
                        }}
                      >
                        <StorageImage
                          className="w-10 h-10 absolute mt-3 ml-5 default-transition"
                          imagePath={helpers.Items[storageItems]['Img']}
                        />
                        <span className="storageItemsItemNameSpan ">{helpers.Items[storageItems]['Name']}</span>
                        <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                        <span className="storageItemsItemQuantitySpan mt-1">{myStorageItems[0][storageItems][x]}</span>
                      </div>
                      <ReactTooltip
                        id={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                        effect="solid"
                        data-html={true}
                      >
                        <ItemsDataTip
                          itemNumber={storageItems}
                          translation={t}
                          name={helpers.Items[storageItems]['Name']}
                          quality={x}
                          image={
                            <StorageImage className="w-10 h-10 mx-auto" imagePath={helpers.Items[storageItems]['Img']} />
                          }
                          description={t('GAME_ITEMS_' + storageItems + '_DESCRIPTION')}
                          firepowerMultiplier={[x * 50]}
                        />
                      </ReactTooltip>
                    </Fragment>,
                  );
                }
              }

              if (helpers.Items[storageItems] !== undefined && helpers.Items[storageItems]['Type'] === 0) {
                myRawMaterials.push(
                  <Fragment key={key + '_' + key}>
                    <div
                      data-tip={true}
                      data-for={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                      className="storageItems bg-yellow-100 cursor-pointer"
                      onClick={() => {
                        if (sellableItems[storageItems - 1]) {
                          setModalBody(
                            <>
                              <div className="justify-start flex">
                                <div
                                  data-tip={true}
                                  data-for={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                                  className="storageItems bg-blue-100"
                                >
                                  <StorageImage
                                    className="w-10 h-10 absolute mt-3 ml-5 cursor-pointer default-transition"
                                    imagePath={helpers.Items[storageItems]['Img']}
                                  />
                                  <span className="storageItemsItemNameSpan ">
                                    {helpers.Items[storageItems]['Name']}
                                  </span>
                                  <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                                  <input
                                    onChange={(e) => handleQuantityChange(e.target.value)}
                                    defaultValue={myStorageItems[0][storageItems][x]}
                                    type="input"
                                    max={myStorageItems[0][storageItems][x]}
                                    min={1}
                                    className="storageItemsItemQuantitySpan mt-1 cursor-pointer hover:bg-green-700 animate__animated animate__tada"
                                  ></input>
                                </div>
                                <div>
                                  <input
                                    placeholder="Price"
                                    onChange={(e) => {
                                      handlePriceChange(e.target.value);
                                    }}
                                    type="input"
                                    max={myStorageItems[0][storageItems][x]}
                                    min={1}
                                    className="ml-3 cursor-pointer input--button"
                                  ></input>
                                  <div className="flex ml-3 mt-2">
                                    {myLicences ? (
                                      <Select
                                        className="basic-select "
                                        classNamePrefix="select"
                                        onChange={handleMarketChange}
                                        isSearchable={true}
                                        autosize={true}
                                        styles={searchStyle}
                                        // defaultValue={{value : countryMarketPlace, label : countryMarketPlace}}
                                        formatOptionLabel={(selectCountries) => (
                                          <p className="inline-flex">
                                            {countryFlags(selectCountries.ID)}{' '}
                                            <span className="ml-1">{selectCountries.Name}</span>
                                          </p>
                                        )}
                                        getOptionLabel={(selectCountries) => selectCountries.Name}
                                        getOptionValue={(selectCountries) => selectCountries.ID}
                                        options={selectCountries}
                                      />
                                    ) : (
                                      <div>No licences atm</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <button
                                className="mt-3 bg-green-600 text-white hover:bg-green-800 text-sm font-bold transition ease-in-out   duration-500  hover:scale-105  py-3 px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 "
                                onClick={() => addItemToListing()}
                              >
                                {t('GAME_ADD')}
                              </button>
                            </>,
                          );
                          handleSelectedItem(storageItems);
                          handleSelectedItemQuality(x);
                          setModal(true);
                        }
                      }}
                    >
                      <StorageImage
                        className="w-10 h-10 absolute mt-3 ml-5 default-transition"
                        imagePath={helpers.Items[storageItems]['Img']}
                      />
                      <span className="storageItemsItemNameSpan ">{helpers.Items[storageItems]['Name']}</span>
                      <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                      <span className="storageItemsItemQuantitySpan mt-1">{myStorageItems[0][storageItems][x]}</span>
                    </div>

                    <ReactTooltip
                      id={myStorageItems[0][storageItems] + myStorageItems[0][storageItems][x]}
                      effect="solid"
                      data-html={true}
                    >
                      <ItemsDataTip
                        translation={t}
                        itemNumber={storageItems}
                        name={helpers.Items[storageItems]['Name']}
                        quality={x}
                        image={
                          <StorageImage className="w-10 h-10 mx-auto" imagePath={helpers.Items[storageItems]['Img']} />
                        }
                        description={t('GAME_ITEMS_' + storageItems + '_DESCRIPTION')}
                      />
                    </ReactTooltip>
                  </Fragment>,
                );
              }
            }
          }
        })}
      <div className="ml-6 mt-3 sm:ml-9">{myItems}</div>

      <div className="ml-6 mt-3 sm:ml-9">
        <h3 className="font-semibold text-white">Raw Materials</h3>
        {myRawMaterials}
      </div>

      <AppModal
        body={
          <div data-testid="selling-modal" className="grid grid-cols-1 m-5 ml-10 gap-4">
            {modalBody}
          </div>
        }
        open={modal}
        setOpen={setModal}
        backgroundStyle={{
          backgroundColor: '#1e293b',
        }}
      />

      <Divider />
      <p className="ml-9 m-5 font-bold text-white">{t('GAME_MY_OFFERS')}</p>
      <div className="ml-6 mt-3 sm:ml-9 mb-8">
        <SellItemsOnMarket
          myStorageItems={myStorageItems}
          setMyStorageItems={setMyStorageItems}
          myLicences={myLicences}
          setMyLicences={setMyLicences}
          myListedItems={myListedItems}
          setMyListedItems={setMyListedItems}
        />
      </div>
    </>
  );
}
