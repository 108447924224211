interface AnimateProps {
  targetElementId: string;
  animation: string;
}
export const animate = ({ targetElementId, animation }: AnimateProps) => {
  const element = document.getElementById(targetElementId);
  if (!element) {
    return null;
  }
  element.classList.add('animate__animated', 'animate__' + animation);

  element.addEventListener('animationend', () => {
    element.classList.remove('animate__animated', 'animate__' + animation);
  });
};
