import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Loader } from '../../components/Loader';
import { imageAsBackground } from '../../helpers/image-as-background';
import ThumbsUpIcon from '../Util/Icons/ThumbsUpIcon';

import { fromCDN } from '../../helpers/Images';

export interface LandingTopArticlesProps {
  DATE: string;
  ID: number;
  PAPER: number;
  TITLE: string;
  VOTES: number;
  news_paper_with_user_details: {
    ID: number;
    USER: number;
    public_user: {
      id: number;
      user: string;
    };
  };
}
export const LandingTopArticles = ({ topArticles }: { topArticles?: LandingTopArticlesProps[] }) => {
  const { t } = useTranslation('common');
  let navigate = useNavigate();
  return (
    <ul className="text-white rounded-t-lg">
      <li
        className="h-14 flex items-center justify-between px-4 rounded-t-lg border-none"
        style={imageAsBackground(fromCDN.fetchStaticImgImage('/article_top_header'))}
      >
        <span className="text-base font-semibold">{t('GAME_TOP_NEWS')}</span>
        <div className="flex align-middle gap-2">
          <img
            src={fromCDN.fetchStaticImgImage('/facebook')}
            className="default-transition cursor-pointer"
            alt="facebook"
          />
          <img
            src={fromCDN.fetchStaticImgImage('/instagram')}
            className="default-transition cursor-pointer"
            alt="instagram"
          />
          <img
            src={fromCDN.fetchStaticImgImage('/twitter')}
            className="default-transition cursor-pointer"
            alt="twitter"
          />
          <img
            src={fromCDN.fetchStaticImgImage('/youtube')}
            className="default-transition cursor-pointer"
            alt="youtube"
          />
          <img
            src={fromCDN.fetchStaticImgImage('/discord')}
            className="default-transition cursor-pointer"
            alt="discord"
          />
        </div>
      </li>

      {!topArticles && (
        <li>
          <Loader />
        </li>
      )}

      {topArticles?.map((article, key) => (
        <li
          key={`${Number(article.ID)}_${key}`}
          className="flex items-center px-6 py-3 w-full h-16  cursor-pointer"
          style={imageAsBackground(fromCDN.fetchStaticImgImage('/article_bg'))}
        >
          <div
            data-testid={`articles_${key}`}
            className="grid grid-cols-2 gap-x-2 default-transition hover:scale-10"
            onClick={() => {
              navigate(`/no-auth/article/${Number(article.ID)}`);
            }}
          >
            <span className="col-span-2 text-base">{article.TITLE.substring(0, 45)}</span>
            <span className="text-yellow-400 text-xs">
              {t('GAME_POSTED_BY')} {article.news_paper_with_user_details.public_user.user}
            </span>
            <span className="text-xs">{article.DATE}</span>
          </div>
          <div className="flex-1 flex gap-2 justify-end">
            <span>{article.VOTES}</span>
            <ThumbsUpIcon className="h-5 w-5" />
          </div>
        </li>
      ))}
    </ul>
  );
};
