import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyProfile } from './MyProfile';
import OthersProfile from './OthersProfile';
import { useUser } from '../../hooks/use-user';

export default function Profile() {
  let params = useParams();
  const [alertModal, setAlertModal] = useState();
  const { user, setUser } = useUser();
  const { t } = useTranslation('common');

  if (user.id === parseInt(params.id)) {
    return (
      <MyProfile user={user} setUser={setUser} translation={t} alertModal={alertModal} setAlertModal={setAlertModal} />
    );
  } else {
    return <OthersProfile translation={t} userId={params.id} />;
  }
}
