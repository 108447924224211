import React, { useState } from 'react';
import { GreenButton } from '../../../components/Buttons/GreenButton';
import { Info } from '../../../components/Info';

import { useTranslation } from 'react-i18next';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { Stars } from '../../Util/Icons/star/Stars';
import { authApi } from '../../../api';
import { fromCDN } from '../../../helpers/Images';
import { useHelper } from '../../../hooks/use-helper';
import { useUser } from '../../../hooks/use-user';

export default function WorkoutUpgrade({ setIsUpgradeRequested = function () {} }) {
  const { user, setUser } = useUser();
  const { t } = useTranslation('common');
  const { helpers } = useHelper();
  const [alertModal, setAlertModal] = useState();

  const tGrounds = [
    {
      ID: 1,
      Name: 'Boot Camp',
      SQL: 'TG1',
      SQLD: 'TG1_D',
      STR: [0, 5, 10, 15, 20],
      XP: [0, 1, 1, 1, 1],
      HP: [0, 10, 10, 10, 10],
      Gold: [0, 0, 0, 0, 0],
      CC: [0, 0, 0, 0, 0],
      Upgrade: [0, 0, 20, 70, 170],
    },
  ];

  async function upgrade(quality) {
    await authApi
      .post('/api/upgradestrength', { upgrade: quality })
      .then((response) => {
        ApplicationAlert(t('GAME_SUCCESSFULLY')).success();
        setUser(response.data[0]);

        if (response.data[0]['TG1'] === 4) {
          setIsUpgradeRequested(null);
        }
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });
  }

  return (
    <>
      {tGrounds &&
        Object.values(tGrounds).map((item, key) => {
          let tgUpgrade = user['TG' + item.ID] + 1;

          if (item['Upgrade'][tgUpgrade]) {
            return (
              <div
                className={
                  'justify-center bg-edom-dark-red/90 border-yellow-700 border-l-2 border-b-2 py-10 px-6 rounded-lg shadow-[inset_0_-4px_20px_rgba(150,120,0,0.6)]'
                }
                key={key}
              >
                <div className="relative flex gap-4">
                  <img
                    src={`${fromCDN.fetchStaticGameImage(`/tg/tg4_q${user.TG1}`)}`}
                    className="relative rounded-lg -top-6 -mb-4 h-20 w-20"
                    loading="lazy"
                  />
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between">
                      <div className="flex items-center gap-1">
                        <span className="text-base font-medium text-gray-200">{t('GAME_BOOTCAMP')}</span>
                        <span className="text-white bg-gray-800 rounded py-px px-2">Q{tgUpgrade}</span>
                        <span
                          className="cursor-pointer default-transition ml-2"
                          onClick={() => setIsUpgradeRequested(null)}
                        >
                          <img
                            className={
                              'h-6 w-6 mx-auto p-1 text-gray-200 bg-red-400/60 hover:bg-red-400/70 rounded animate__animated animate__flipInX'
                            }
                            src={fromCDN.fetchStaticImage('t_x', 'svg')}
                          ></img>
                        </span>
                      </div>

                      <a className="text-gray-500 " href="#">
                        <i className="fa-solid fa-trash"></i>
                      </a>
                    </div>
                    <p className="text-gray-400 text-sm flex items-center">{<Stars totalStars={tgUpgrade} />}</p>
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div className={'mt-5'} onClick={() => upgrade(item.ID)}>
                    <GreenButton text={t('GAME_UPGRADE')} />

                    <div className="flex justify-left font-medium">
                      <div className="text-center">
                        <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                          <img
                            className="h-6 w-6 sm:h-8 sm:w-8 mx-auto"
                            src={fromCDN.fetchStaticImage('/game/icons/strength', 'png')}
                            alt="Workflow"
                          />
                        </span>
                        <span className="sm:text-sm text-gray-200 bg-green-600/70 p-1.5 rounded-lg">
                          +{helpers.TG[1]['STR'][tgUpgrade]}
                        </span>
                      </div>
                      <div className="text-center">
                        <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                          <img
                            className="h-6 w-6 sm:h-8 sm:w-8 mx-auto"
                            src={fromCDN.fetchStaticImage('/game/icons/energy-s', 'png')}
                            alt="Workflow"
                          />
                        </span>
                        <span className="sm:text-sm text-gray-200 bg-red-600/70 p-1.5 rounded-lg">
                          -{helpers.TG[1]['HP'][tgUpgrade]}
                        </span>
                      </div>
                      <div className="text-center">
                        <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                          <img
                            className="h-6 w-6 sm:h-8 sm:w-8 mx-auto"
                            src={fromCDN.fetchStaticImage('/game/icons/gold-icon', 'png')}
                            alt="Workflow"
                          />
                        </span>
                        <span className="sm:text-sm text-gray-200 bg-red-600/70 p-1.5 rounded-lg">
                          {helpers.TG[1]['Upgrade'][tgUpgrade]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={'mt-5'} onClick={() => upgrade(item.ID)}>
                    <img
                      className="w-8 h-8 bg-black/40 rounded-t p-1 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]"
                      src={fromCDN.fetchStaticImage('/game/icons/info', 'svg')}
                    ></img>
                    <Info text={t('GAME_WORKOUTINFOUPG')} />
                  </div>
                </div>
              </div>
            );
          }
        })}
    </>
  );
}
