import React, { useEffect, useState } from 'react';
import { useUser } from '../../../hooks/use-user';
import { useHelper } from '../../../hooks/use-helper';
import { imageAsBackground } from '../../../helpers/image-as-background';
import { fromCDN } from '../../../helpers/Images';
import { SectionHeader } from '../../../components/SectionHeader';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import Unemployed from './Employment/Unemployed';
import Employed from './Employment/Employed';
import { AppModal } from '../../../components/AppModal';
import { Divider } from '../../../components/Divider';
import { Stars } from '../../Util/Icons/star/Stars';
import { useNavigate } from 'react-router-dom';
import { authApi } from '../../../api';
import { animate } from '../../../util/animate';
import { AssignWorkers } from './AssignWorkers';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { gameTimeZone } from '../../../helpers/game-time';
import Countdown from 'react-countdown';
import { CompaniesTutorial } from '../../Tutorial/companies/CompaniesTutorial';

const productionCalculation = ({ assignedWorkers, productionSkills, helper }) => {
  const approximateCalculation = Number(productionSkills * (assignedWorkers * 10));
  let minQuality = 1;
  let maxQuality = 5;
  if (assignedWorkers > helper.Game.CompanyProductionQualityThreshold) {
    minQuality = 3;
  }
  if (
    assignedWorkers > helper.Game.CompanyProductionQualityThreshold &&
    productionSkills > helper.Game.CompanyProductionSkillPointThreshold
  ) {
    minQuality = 4;
  }

  return {
    QualityMin: minQuality,
    QualityMax: maxQuality,
    Quantity: approximateCalculation,
  };
};

const CompaniesNew = () => {
  const { user, setUser } = useUser();
  const { helpers } = useHelper();
  const { t } = useTranslation('common');
  let navigate = useNavigate();

  const [assignedWorkers, setAssignedWorkers] = useState({});
  const [myCompanies, setMyCompanies] = useState();
  const [myTotalWorkers, setMyTotalWorkers] = useState(user.EMPLOYEES);
  const [modal, setModal] = useState(false);

  const [modalBody, setModalBody] = useState(
    user['COMPANY'] === 0 ? (
      <Unemployed t={t} unemployedImage={fromCDN.fetchStaticGameImage('/icons/unemployed')} />
    ) : (
      <Employed
        t={t}
        unemployedImage={fromCDN.fetchStaticGameImage('/icons/unemployed')}
        employerId={user['COMPANY']}
      />
    ),
  );

  const handleAssignWorkers = ({ workerValue, companyId }) => {
    if (workerValue < 0) {
      return;
    }

    setAssignedWorkers((prevState) => {
      return { ...prevState, [companyId]: Number(workerValue) };
    });
    let totalAssigned = 0;
  };

  const getMyCompanies = () => {
    authApi
      .get('/api/user/companies/get')
      .then((response) => {
        setMyCompanies(response.data.Companies);
      })
      .catch((error) => {});
  };

  const workCompanies = () => {
    authApi
      .post('/api/user/companies/work', {
        workObject: assignedWorkers,
      })
      .then((resp) => {
        setMyCompanies(resp.data);
        ApplicationAlert(t('GAME_SUCCESS')).success();
        return;
      })
      .catch((err) => {
        ApplicationAlert(t(err.response.data)).error();
        return;
      });
  };

  const collectProduction = (companyId) => {
    authApi
      .post('/api/user/companies/collect', {
        companyId: companyId,
      })
      .then((resp) => {
        setMyCompanies(resp.data.Companies);
        setUser(resp.data.User);
        ApplicationAlert(t('GAME_SUCCESS')).success();
        return;
      })
      .catch((err) => {
        ApplicationAlert(t(err.response.data)).error();
        return;
      });
  };

  useEffect(() => {
    getMyCompanies();
  }, []);

  useEffect(() => {
    let totalAssigned = 0;
    Object.values(assignedWorkers).map((val) => {
      if (val > 0) {
        totalAssigned += val;
      }
    });

    setMyTotalWorkers(user.EMPLOYEES - totalAssigned);
    animate({
      targetElementId: 'totalWorkers',
      animation: 'tada',
    });
  }, [assignedWorkers]);

  return (
    <>
      <div
        className={'min-h-screen max-w-13xl mx-auto bg-fixed'}
        style={imageAsBackground(fromCDN.fetchStaticImgImage('/mybuildingsbg'))}
      >
        <div className={'static max-w-7xl mx-auto py-2'}>
          <SectionHeader title={t('GAME_COMPANIES')}>
            <CompaniesTutorial />
            <div className="h-8 w-8 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]">
              <img src={fromCDN.fetchStaticGameImage('/v2/Icons/Companies', 'svg')}></img>
            </div>
          </SectionHeader>
          <AppModal
            body={<div className={'grid grid-cols-1 gap-4 m-5 sm:ml-10'}>{modalBody}</div>}
            open={modal}
            setOpen={setModal}
            backgroundStyle={{
              backgroundColor: '#1e293b',
            }}
          />
          <div className={'w-4/4 m-5 '}>
            <div className={'mx-2 mt-10 mb-6 font-bold text-gray-200 grid grid-cols-2 '}>
              <div className={'inline-block p-2'}>
                <div
                  onClick={() => navigate('')}
                  data-tip
                  data-for={'availableworkers'}
                  className={'inline-flex top-0 cursor-pointer'}
                >
                  <img src={fromCDN.fetchStaticGameImage('/icons/0emp')} className={'inline'} />
                  <span id={'totalWorkers'} className="text-indigo-300 text-sm ml-1 pl-2">
                    {myTotalWorkers}
                  </span>
                  <ReactTooltip id={'availableworkers'} effect="solid" data-html={true}>
                    {t('GAME_AVAILABLE_WORKERS')}
                  </ReactTooltip>
                </div>

                <div className="relative pt-2">
                  <div
                    onClick={() => navigate('')}
                    data-tip
                    data-for={'productionskills'}
                    className={'inline-flex top-0 cursor-pointer'}
                  >
                    <img src={fromCDN.fetchStaticImage('t_productionskill', 'svg')} className={'inline'} />
                    <span className="text-green-400 text-sm ml-1 pl-1">{user.ProductionPoints}</span>
                    <ReactTooltip id={'productionskills'} effect="solid" data-html={true}>
                      {t('GAME_PRODUCTION_SKILLS')}
                    </ReactTooltip>
                  </div>
                </div>
                <button
                  className={
                    'bg-green-700 hover:bg-green-600 transition hover:scale-105 p-2.5 mt-4 rounded text-gray-300'
                  }
                  onClick={() => workCompanies()}
                >
                  {t('GAME_START_PRODUCTION')}
                </button>
              </div>
              <div className={'flex flex-col items-end'}>
                <div
                  onClick={() => navigate('/buildings/manage-employees')}
                  data-tip
                  data-for={'manageemployeess'}
                  className={'inline-flex top-0  cursor-pointer'}
                >
                  <span
                    className={
                      'p-3 bg-indigo-300 hover:bg-indigo-200 shadow-inner inline-flex rounded transition duration-200 hover:scale-110'
                    }
                  >
                    <img className={'w-6 h-6'} src={fromCDN.fetchStaticGameImage('/icons/1emp')} />
                  </span>
                  <ReactTooltip id={'manageemployeess'} effect="solid" data-html={true}>
                    {t('GAME_MANAGE_EMPLOYEES_DESCRIPTION')}
                  </ReactTooltip>
                </div>
                <button data-tip data-for="jobDetails" className="mt-4 sm:mt-6 cursor-pointer">
                  <img
                    onClick={() => setModal(true)}
                    className={
                      'inline-flex p-1 bg-black/60 hover:bg-black/40 rounded transition duration-200 hover:scale-110'
                    }
                    src={fromCDN.fetchStaticGameImage('/icons/unemployed')}
                  />
                  <ReactTooltip id="jobDetails" effect="solid" data-html place="left">
                    {t('GAME_EMPLOYER')}
                  </ReactTooltip>
                </button>
              </div>
            </div>
            <Divider margin="mx-0 my-8" />
            <div
              className={
                'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center gap-4 comps drop-shadow-[0_8px_6px_rgba(0,0,0,0.4)]'
              }
            >
              {myCompanies &&
                myCompanies.map((company) => {
                  const productionCalculationView = productionCalculation({
                    assignedWorkers: (assignedWorkers[company.id] || company.assigned_workers) ?? 0,
                    productionSkills: user.ProductionPoints,
                    helper: helpers,
                  });

                  const getCooldown = gameTimeZone().getDifferenceFromGameTimeNormal({ date: company.cooldown });

                  return (
                    <div
                      key={company.id}
                      className="max-w-81 bg-edom-dark-yellow/90 text-gray-200 text-sm  justify-center rounded-lg p-3 cursor-default shadow-[inset_0_-1px_5px_rgba(0,0,0,0.6)]"
                    >
                      {/*<Stars totalStars={5} className={'text-yellow-500 w-5 h-5 mx-auto  inline-flex'}/>*/}
                      <p className={'p-2 text-center font-semibold drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]'}>
                        {t(helpers.Companies[company.type].Name)}
                      </p>
                      {company.type === 1 ? (
                        <img
                          className={'h-14 w-14 mx-auto mb-3 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]'}
                          src={fromCDN.fetchStaticGameImage(`/items/food`)}
                        />
                      ) : (
                        <img
                          className={'h-14 w-14 mx-auto mb-3 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]'}
                          src={fromCDN.fetchStaticGameImage('/companies/c' + helpers.Companies[company.type].Item)}
                        />
                      )}
                      <AssignWorkers company={company} handleAssignWorkers={handleAssignWorkers} />
                      <div
                        className={
                          'text-center justify-center grid shadow-xl bg-black/40 rounded-lg m-3 p-2 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]'
                        }
                      >
                        <p className={'text-orange-300 font-bold'}>{t('GAME_POTENTIAL_QUALITY')}</p>

                        <div className="text-center mt-2 justify-center flex ">
                          <Stars totalStars={productionCalculationView.QualityMin} />
                        </div>
                        <div>
                          <img
                            className={'h-2 w-4 mt-2 opacity-20 mx-auto'}
                            src={fromCDN.fetchStaticImage(`t_arrowdownwhite`, 'svg')}
                          />
                        </div>
                        <div className="text-center mt-2 justify-center flex">
                          <Stars totalStars={productionCalculationView.QualityMax} />
                        </div>
                      </div>

                      <div
                        className={
                          'text-center justify-center grid shadow-xl bg-black/40 rounded-lg m-3 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]'
                        }
                      >
                        <p className={'text-green-400 font-bold p-2'}>{t('GAME_POTENTIAL_QUANTITY')}</p>

                        <div className="text-center justify-center text-gray-200 p-1 inline-flex font-medium text-xl">
                          {productionCalculationView.Quantity}
                          {company.type === 1 ? (
                            <img className={'h-8 w-8 ml-1'} src={fromCDN.fetchStaticGameImage(`/items/food`)} />
                          ) : (
                            <img
                              className={'h-8 w-8 ml-1'}
                              src={fromCDN.fetchStaticGameImage('/companies/c' + helpers.Companies[company.type].Item)}
                            />
                          )}
                        </div>
                      </div>

                      <div className="text-center mt-2 text-sm font-light p-2">
                        {getCooldown > Date.now() && company.is_in_production === 1 ? (
                          <div
                            className={
                              'bg-yellow-700 p-2 font-bold rounded-lg animate-pulse-slow text-gray-200 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]'
                            }
                          >
                            <img
                              src={fromCDN.fetchStaticImgImage('/header/icons/settings', 'svg')}
                              alt="Production"
                              className="h-6 w-6 mr-1 mb-1 inline animate-spin-slow drop-shadow-[0_1px_1px_rgba(0,0,0,0.2)]"
                            />
                            <Countdown date={getCooldown} />
                          </div>
                        ) : (
                          <>
                            {company.is_in_production === 0 ? (
                              <p
                                className={
                                  'bg-red-900 p-3 font-semibold text-sm rounded-lg text-gray-200 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]'
                                }
                              >
                                {t('GAME_COMPANIES_COOLDOWN_OVER')}
                              </p>
                            ) : (
                              <div
                                className={'text-center mt-0.5 mx-auto'}
                                onClick={() => collectProduction(company.id)}
                              >
                                <button
                                  className={
                                    'bg-green-700 hover:bg-green-600 transition hover:scale-105 p-2.5 rounded text-sm font-bold text-gray-200 drop-shadow-[0_5px_2px_rgba(0,0,0,0.3)]'
                                  }
                                >
                                  {t('GAME_COLLECT')}
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompaniesNew;
