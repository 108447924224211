import React, { useEffect, useState, useId } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ProgressBar } from 'react-step-progress-bar';

import ApplicationAlert from '../../components/ApplicationAlert';
import { authApi } from '../../api';

const MAX_FILE_SIZE = 262144;
const ACCEPTABLE_IMAGE_TYPE = 'image/jpeg';

export default function FileUpload({ url, formParams, fileKey = 'file', setter }) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [uploadProgress, setUploadProgress] = useState();
  const [avatar, setAvatar] = useState('');
  const id = useId();

  const { t } = useTranslation('common');

  const files = acceptedFiles.map((item) => item);

  const handleUploadFiles = (file) => {
    const image = file[0];

    if (image && image.type !== ACCEPTABLE_IMAGE_TYPE) {
      ApplicationAlert('Something went wrong. Only .jpg files are allowed').error();
      setUploadProgress(null);
      setAvatar('');
      return;
    }

    if (image.size > MAX_FILE_SIZE) {
      ApplicationAlert('File to big. Maximum file size is 256kb').error();
      return;
    }

    let formData = new FormData();

    formData.append(fileKey, image);
    if (formParams) {
      formData.append(formParams['name'], formParams['VALUE']);
    }

    authApi
      .post(url, formData, {
        onUploadProgress: (e) =>
          setUploadProgress(
            <ProgressBar
              percent={parseInt((e.loaded / e.total) * 100)}
              filledBackground="linear-gradient(to right, rgba(139,255,82,1) 0%, rgba(78,231,64,1) 100%)"
              height={10}
            />,
          ),
      })
      .then((response) => {
        setUploadProgress(null);
        ApplicationAlert('Uploaded!').success();
        setter?.(response.data);
      })
      .catch((error) => {
        ApplicationAlert(`Something went wrong - ${error}`).error();
        setUploadProgress(null);
      });

    setAvatar('');
  };

  useEffect(() => {
    if (files.length > 0) {
      handleUploadFiles(files);
    }
  }, [acceptedFiles]);

  return (
    <>
      {uploadProgress ? (
        uploadProgress
      ) : (
        <section className="container py-4">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input
              {...getInputProps()}
              value={avatar}
              // onChange={handleUploadFiles}
              className="focus:outline-none"
              accept="image/jpeg"
            />
            <div
              data-tip
              data-for={id}
              className="flex justify-center text-green-600 cursor-pointer default-transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                />
              </svg>
            </div>
          </div>
          <ReactTooltip id={id} effect="solid" data-html={true}>
            {t('GAME_ACCEPTED_IMAGE_TYPES')}
          </ReactTooltip>
        </section>
      )}
    </>
  );
}
