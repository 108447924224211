import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';

import { authApi } from '../../../api';
import { Loader } from '../../../components/Loader';
import { useUser } from '../../../hooks/use-user';
import { NewspaperDetails } from '../../../shared-types/NewspaperTypes';
import { imageAsBackground } from '../../../helpers/image-as-background';
import { fromCDN } from '../../../helpers/Images';
import { ArticleCard } from './ArticleCard';
import { AppPageTemplate } from '../../../components/AppPageTemplate';

export const Newspaper = () => {
  const { t } = useTranslation('common');
  const { user } = useUser();
  const params = useParams();
  const navigate = useNavigate();

  const [newspaperDetails, setNewspaperDetails] = useState<NewspaperDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const newsPaper = () => {
    authApi.get(`/api/newspaper/${params.id ?? 0}`).then((response: AxiosResponse<NewspaperDetails>) => {
      setNewspaperDetails(response.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (user.NEWSPAPER === 0) {
      navigate('/newspaper/create/new');
    }
    newsPaper();
  }, []);

  return (
    <AppPageTemplate
      section={{
        title: newspaperDetails ? newspaperDetails.NAME : '...',
        children: (
          <div className="mx-auto sm:mx-0 sm:ml-auto mt-2 sm:mt-0">
            <span className="font-medium">{t('GAME_SUBSCRIBERS')}</span>
            <span className="ml-3 bg-gray-300 font-bold py-1 px-2 rounded-lg text-blue-500">
              {newspaperDetails?.SUBS ?? 0}
            </span>
          </div>
        ),
      }}
      mainStyle={imageAsBackground(fromCDN.fetchStaticImage('t_messagesbg', 'svg'))}
    >
      <div className="grid grid-cols-1 text-center p-3" data-testid="create-article">
        <Link to={`/newspaper/create-article/${newspaperDetails ? newspaperDetails.ID : 1}`}>
          <div className="bg-blue-400 py-3 default-transition cursor-pointer text-neutral-100 uppercase font-medium rounded-lg">
            {t('GAME_CREATE_ARTICLE')}
          </div>
        </Link>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
          {newspaperDetails?.articles.map((article) => (
            <ArticleCard key={article.ID} article={article} />
          ))}
        </div>
      )}
    </AppPageTemplate>
  );
};
