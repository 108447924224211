import React from 'react';

export default function ProfileMemberAndRank({ user, translation }) {
  return (
    <div className="mx-5 my-12 w-2/2 flex text-center gap-4">
      <div className="w-1/2">
        <p className="mb-1 font-bold">{translation('GAME_MEMBER_SINCE')}</p>
        <p className="bg-gray-300 bg-opacity-30 p-2 rounded-lg">{user.BIRTHDAY}</p>
      </div>

      <div className="w-1/2">
        <p className="mb-1 font-bold">{translation('GAME_WORLD_RANK')}</p>
        <p className="bg-gray-300 bg-opacity-30 p-2 rounded-lg">{user.RANK}</p>
      </div>
    </div>
  );
}
