import { useState } from 'react';

import { CircleButton, CircleButtonProps } from './CircleButton';
import { SellMonetary, SellMonetaryProps } from './SellMonetary';

type CircleButtonExpandableProps = SellMonetaryProps & Partial<CircleButtonProps>;

export const CircleButtonExpandable = ({
  children,
  className,
  tradeIcon,
  exchangeIcon,
  tradeColor,
  exchangeColor,
  reverse,
  offerType,
}: Omit<CircleButtonExpandableProps, 'onClick'>) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <CircleButton
      onClick={handleClick}
      className={`lg:h-24 w-24 mx-auto sm:mx-0 duration-300 flex items-center ${
        isOpen ? '!w-full !py-3 px-2 lg:px-8 !cursor-auto !rounded-lg lg:!rounded-full' : ''
      } ${className ?? ''}`}
    >
      {!isOpen ? (
        children
      ) : (
        <SellMonetary
          offerType={offerType}
          tradeIcon={tradeIcon}
          exchangeIcon={exchangeIcon}
          tradeColor={tradeColor}
          exchangeColor={exchangeColor}
          reverse={reverse}
          onClick={handleClick}
        />
      )}
    </CircleButton>
  );
};
