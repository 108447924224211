import { useParams } from 'react-router-dom';

import { Medals } from '../Tabs/Medals/Medals';
import CoreAttributes from '../Tabs/CoreAttributes/CoreAttributes';
import DamageStatistics from '../Tabs/DamageStatistics';
import MyMoney from '../Tabs/Money/MyMoney';
import EditProfile from '../Tabs/EditProfile/EditProfile';
import { Tab } from '@headlessui/react';
import TabList from './TabList';
import { ProfileTabsProps } from '../../../shared-types/shared-types';

const TABS = [
  { id: 1, 'data-testid': 'tabprofile', text: 'GAME_PROFILE' },
  { id: 2, 'data-testid': 'tabmymoney', text: 'GAME_MY_MONEY' },
  { id: 3, 'data-testid': 'tabeditprofile', text: 'GAME_EDIT_PROFILE' },
];

export const ProfileTabs = ({ user, translation }: ProfileTabsProps) => {
  const params = useParams();

  const tabId = Number.parseInt(params.tabId ?? '1') - 1;

  return (
    <Tab.Group defaultIndex={tabId}>
      <TabList tabs={TABS} />
      <Tab.Panel>
        <Medals user={user} />
        <CoreAttributes user={user} translation={translation} />
        <DamageStatistics user={user} translation={translation} />
      </Tab.Panel>
      <Tab.Panel>
        <MyMoney user={user} translation={translation} />
      </Tab.Panel>
      <Tab.Panel>
        <EditProfile />
      </Tab.Panel>
    </Tab.Group>
  );
};
