export const validate = (() => {
  const nonEmptyValues = <T>(values: T[]): boolean => {
    if (!values || !values.length) return false;

    const invalidValues = values.filter((value) => {
      if (!value) return true;

      if (Array.isArray(value)) return value.length === 0;

      if (typeof value === 'object') {
        return Object.keys(value).length === 0;
      }
    });

    return invalidValues.length === 0;
  };

  const email = (value: string): boolean => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);

  const validNumbers = (values: (number | string)[]): boolean => {
    if (!values.length) return false;

    const invalidValues = values.filter((value) => {
      if (typeof value === 'string') {
        return !Number.isSafeInteger(Number.parseInt(value));
      }

      return !Number.isSafeInteger(value);
    });

    return invalidValues.length === 0;
  };

  return {
    nonEmptyValues,
    email,
    validNumbers,
  };
})();
