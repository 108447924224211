import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { avatarClass } from '../../../../constants/constants';
import { animate } from '../../../../util/animate';
import { Divider } from '../../../../components/Divider';
import AssignMinister from './AssignMinister';

import { useUser } from '../../../../hooks/use-user';
import { authApi } from '../../../../api';
import { POLITICS } from '../../../../constants/enum';
import { AvatarWithDefaultFallback } from '../../../../components/ImageWithFallback';

export default function Politics() {
  let params = useParams();
  let navigate = useNavigate();
  const currentCountry = params.id;

  const { t } = useTranslation('common');
  const { user } = useUser();
  const [politicsDetail, setPoliticsDetail] = useState();
  const [emptyMinisterSlots, setEmptyMinisterSlots] = useState();

  function getSocietyDetails() {
    authApi.get(`/api/country/politics/${currentCountry}`).then((response) => {
      setPoliticsDetail(response.data);
      animate({ targetElementId: 'politicsDetails', animation: 'fadeIn' });
    });
  }

  useEffect(() => {
    let tmpMinisterSlots = [];
    let ministrySpots = [1, 2, 3, 4, 5];
    if (politicsDetail) {
      politicsDetail['CP'].forEach((items) => {
        ministrySpots = ministrySpots.filter((f) => {
          return f !== items['MINISTRY'];
        });
      });
    }
    ministrySpots.forEach((spots, keys) => {
      tmpMinisterSlots.push(
        <AssignMinister
          key={keys + 'ministers'}
          setPoliticsDetail={setPoliticsDetail}
          countryId={currentCountry}
          ministerType={spots}
        />,
      );
    });
    setEmptyMinisterSlots(tmpMinisterSlots);
  }, [politicsDetail]);

  useEffect(() => {
    getSocietyDetails();
  }, [currentCountry]);

  return (
    <div id={'politicsDetails'} className="text-center">
      {politicsDetail ? (
        <>
          <h1 className="m-3 font-bold text-gray-300">{t('GAME_PROFILE_MEDAL_NAME_4')}</h1>
          <Divider margin="m-3" />
          <div className="grid grid-cols-1 sm:grid-cols-1 mx-5 gap-4">
            {politicsDetail['CP'] && politicsDetail['CP'][0] ? (
              <div
                className="cursor-pointer default-transition"
                onClick={() => navigate('/profile/' + politicsDetail['CP'][0]['U_ID'])}
              >
                <AvatarWithDefaultFallback
                  className={`w-20 h-20 mx-auto ${avatarClass}`}
                  avatarId={politicsDetail['CP'][0]['U_AVATAR']}
                />
                <span className="font-bold text-neutral-100">{politicsDetail['CP'][0]['U_NAME']}</span>
              </div>
            ) : (
              ''
            )}
          </div>
          <h1 className="m-3 font-bold text-gray-300">{t('GAME_POLITICS_CABINET')}</h1>
          <Divider margin="m-3" />
          <div className="grid grid-cols-3 sm:grid-cols-5 mx-5 gap-4">
            {politicsDetail['CP'] && politicsDetail['CP'].length
              ? politicsDetail['CP'].map((item) => {
                  if (item.POLITICS !== POLITICS.COUNTRY_PRESIDENT) {
                    return (
                      <div
                        key={item['U_ID']}
                        className="cursor-pointer default-transition"
                        onClick={() => navigate('/profile/' + item['U_ID'])}
                      >
                        <AvatarWithDefaultFallback
                          className={`w-20 h-20 mx-auto ${avatarClass}`}
                          avatarId={item['U_AVATAR']}
                        />
                        <span className="font-bold text-gray-300">{item['U_NAME']}</span>
                        <p className="font-bold text-gray-300">{t('GAME_COUNTRY_5' + item['MINISTRY'])}</p>
                      </div>
                    );
                  }
                })
              : ''}

            {politicsDetail &&
            politicsDetail['CP'] &&
            politicsDetail['CP'][0] &&
            politicsDetail['CP'][0]['U_ID'] === user.id
              ? emptyMinisterSlots
              : ''}
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
