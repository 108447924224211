import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { fromCDN } from '../helpers/Images';

type ImageWithFallbackProps = {
  fallback: () => string;
} & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

type AvatarWithDefaultFallbackProps = {
  avatarId: string;
} & Omit<ImageWithFallbackProps, 'fallback'>;

export const ImageWithFallback = ({ fallback, ...otherProps }: ImageWithFallbackProps) => {
  return (
    <img
      {...otherProps}
      onError={(e) => {
        // HACK
        (e.target as any).src = fallback();
      }}
    />
  );
};

export const AvatarWithDefaultFallback = ({ avatarId, ...otherProps }: AvatarWithDefaultFallbackProps) => {
  return (
      
    <ImageWithFallback
      {...otherProps}
      src={fromCDN.fetchUserAvatar(avatarId)}
      fallback={fromCDN.fetchDefaultUserAvatar}
    />
  );
};
