import React from 'react';
import Select from 'react-select';

import { countryFlags } from '../helpers/Helpers';
import { useHelper } from '../hooks/use-helper';
import { Country } from '../shared-types/shared-types';

const countryStyle = {
  control: (base: any) => ({
    ...base,
    width: 250,
    height: 50,
  }),
};

interface CountrySelectorProps {
  countryId: number | string;
  handleChange: (market: Country) => void;
  options?: [];
}
export const CountrySelector = (props: CountrySelectorProps) => {
  const { countryId, handleChange, options, ...rest } = props;

  const { helpers } = useHelper();
  const selectCountries = Object.values(helpers.Countries);

  const renderCountry = (name: string, id: number) => {
    return (
      <div className="flex items-center">
        {countryFlags(id)}
        <span className="ml-1 text-gray-900 text-sm">{name}</span>
      </div>
    );
  };

  return (
    <Select
      className="basic-single inline-block"
      classNamePrefix="select"
      {...rest}
      placeholder={countryId ? renderCountry(selectCountries[Number(countryId) - 1].Name, Number(countryId)) : null}
      /* @ts-ignore */
      onChange={handleChange}
      isSearchable={true}
      styles={countryStyle}
      formatOptionLabel={(country) => renderCountry(country.Name, country.ID)}
      getOptionLabel={(country) => country.Name}
      getOptionValue={(country) => country.ID as unknown as string}
      options={options ?? selectCountries}
    />
  );
};
