import { Tab } from '@headlessui/react';

import { WithChildren } from '../../../../shared-types/shared-types';

export const MonetaryTab = ({ children }: WithChildren) => {
  return (
    <Tab
      className={({ selected }) => {
        const classes =
          'bg-gradient-to-r from-zinc-700 to-zinc-500 border-zinc-600 border-l-4 border-b-2 hover:bg-gradient-to-b hover:from-zinc-600 hover:via-zinc-500 hover:to-zinc-600 w-full py-3 text-base capitalize shadow-2xl outline-none border-b-transparent border-b-zinc-600';

        if (selected) return `${classes} !border-b-yellow-500`;

        return classes;
      }}
    >
      {children}
    </Tab>
  );
};
