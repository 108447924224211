import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authApi } from '../../api';
import LoaderContext, { removeCookie } from '../../constants/constants';
import { MainHeader } from './MainHeader';
import { TopHeader } from './TopHeader';
import { imageAsBackground } from '../../helpers/image-as-background';
import { Accordion } from '../../components/Accordion';
import { fromCDN } from '../../helpers/Images';
import { useHelper } from '../../hooks/use-helper';
import { useUser } from '../../hooks/use-user';
import { GLOBAL_CONSTANTS } from '../../constants/enum';
import { SocketContext } from '../../constants/socket-context';

export const Header = () => {
  const navigate = useNavigate();
  const { socket } = useContext(SocketContext); // Extract socket correctly
  const { setUser } = useUser();
  const { loader, setLoader } = useContext(LoaderContext);
  const { helpers, setHelpers } = useHelper();

  const logout = () => {
    return removeCookie()
      .then(() => navigate('/'))
      .catch(() => navigate('/'));
  };

  useEffect(() => {
    setLoader(true);

    authApi
      .get('/api/user')
      .then((response) => {
        setUser(response.data.USER);
        setHelpers(response.data.HELPERS);
        setLoader(false);
        if (response.data.USER.BANNED) {
          logout();
          navigate('/');
        }
        socket?.emit(GLOBAL_CONSTANTS.SOCKET_HELPER_MESSAGE);
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.data === 'GAME_BANNED_TEMPORARILY') {
          logout();
          navigate('/');
        }
        setLoader(false);
      });

    const handleMessage = (message) => {
      if (message) {
        setHelpers(message);
      }
    };

    if (socket) {
      socket.on(GLOBAL_CONSTANTS.SOCKET_HELPER_MESSAGE, handleMessage);
    }

    return () => {
      if (socket) {
        socket.off(GLOBAL_CONSTANTS.SOCKET_HELPER_MESSAGE, handleMessage);
      }
    };
  }, [socket, setLoader, setUser, setHelpers, navigate]);

  return (
    <header
      style={imageAsBackground(fromCDN.fetchStaticImgImage('/header/backgrounds/main_header_background', 'svg'))}
      className="bg-cover"
    >
      <TopHeader logout={logout} />
      {!loader && <Accordion component={<MainHeader />} shouldSetCookie={true} cookie="mainheaderonoff" />}
    </header>
  );
};
