import api from '../../api';
import { useEffect, useState } from 'react';
import Avatar from '../../components/Avatar';
import { Loader } from '../../components/Loader';
import { forgetLocalStorageCache, getFromLocalStorage, useLocalStorageAsCache } from '../../helpers/LocalStorage';
import moment from 'moment';

export const ContributorCard = ({ contributor }) => {
  const [userDetails, setUserDetails] = useState();
  const [loader, setLoader] = useState(<Loader className="mx-auto w-10 h-20" />);

  const getContributorPublicProfile = () => {
    api
      .get('/api/user/shortprofile/' + contributor.user.id)
      .then((resp) => {
        /* eslint-disable  */
        useLocalStorageAsCache(10, 'about_us' + contributor.user.id, resp.data);
        /* eslint-enable  */
        setUserDetails(resp.data);
        setLoader(false);
      })
      .catch((err) => {
        // do nothing
      });
  };

  useEffect(() => {
    const getContributorProfile = getFromLocalStorage('about_us' + contributor.user.id);
    if (getContributorProfile && getContributorProfile.expiration < moment().unix()) {
      forgetLocalStorageCache('about_us' + contributor.user.id);
      return getContributorPublicProfile();
    }

    if (getContributorProfile) {
      setUserDetails(getContributorProfile.data);
      return setLoader(null);
    }

    return getContributorPublicProfile();
  }, []);
  return (
    <>
      {loader ? (
        loader
      ) : (
        <>
          <div className="p-3 rounded-xl bg-gray-100 overflow-hidden  h-52 w-44 text-center space-y-2">
            <h3 className="bg-cyan-800 text-gray-100 font-semibold py-1">Contributor</h3>
            <Avatar className="mx-auto mt-3" userId={userDetails.id} avatarId={userDetails.AVATAR} />
            <p>{userDetails.user}</p>
            <p className="bg-violet-700 text-white self-center py-1 px-2 font-bold rounded-md">{contributor.role}</p>
          </div>
        </>
      )}
    </>
  );
};
