import React from 'react';

export const LawsProposeDetails = (lawId, setSubmitPropose, setSubmitLawProposeDetails) => {
  const resolveSelectedLaw = (lawId) => {
    setSubmitPropose(false);
    switch (lawId) {
      case 1:
        return (
          <>
            <input
              className="default-input"
              placeholder={'Select currency'}
              onChange={(e) => {
                setSubmitLawProposeDetails((prevState) => {
                  return {
                    ...prevState,
                    id: lawId,
                    currency: e.target.value,
                  };
                });
              }}
              defaultValue={''}
            />
            <input
              className="default-input"
              placeholder="Citizen account ID"
              onChange={(e) => {
                setSubmitLawProposeDetails((prevState) => {
                  return {
                    ...prevState,
                    id: lawId,
                    citizenId: Number(e.target.value),
                  };
                });
              }}
              defaultValue={''}
            />
            <input
              className="default-input"
              placeholder="Amount"
              onChange={(e) => {
                setSubmitLawProposeDetails((prevState) => {
                  return {
                    ...prevState,
                    id: lawId,
                    amount: Number(e.target.value),
                  };
                });
              }}
              defaultValue={''}
            />
            <button onClick={() => setSubmitPropose(true)} className={'default-save-button'}>
              Propose
            </button>
          </>
        );
        break;
      case 5:
        return (
          <>
            <input
              className="default-input"
              placeholder={'Gold to issue 1 = 200Currency'}
              onChange={(e) => {
                setSubmitLawProposeDetails((prevState) => {
                  return {
                    ...prevState,
                    id: lawId,
                    issuecurrencyamount: e.target.value,
                  };
                });
              }}
              defaultValue={''}
            />
            <button onClick={() => setSubmitPropose(true)} className={'default-save-button'}>
              Propose
            </button>
          </>
        );
      case 8:
        return (
          <>
            <input
              className="default-input"
              placeholder={'Country ID'}
              onChange={(e) => {
                setSubmitLawProposeDetails((prevState) => {
                  return {
                    ...prevState,
                    id: lawId,
                    declareWarToCountry: e.target.value,
                  };
                });
              }}
              defaultValue={''}
            />
            <button onClick={() => setSubmitPropose(true)} className={'default-save-button'}>
              Propose
            </button>
          </>
        );
    }
  };

  return resolveSelectedLaw(lawId);
};
