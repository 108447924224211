import { FC, useEffect, useState } from 'react';
import { authApi } from '../../api';
import { UserProps } from '../../shared-types/user-type';
import { AxiosResponse } from 'axios';

interface GetUserLogsApiProps {
  ID: number;
  LOG: string;
  MODERATOR: UserProps['id'];
  PUNISHED_USER: UserProps['id'];
  created_at: string;
  updated_at: string;
}

interface GetUserLogsProps {
  userId: UserProps['id'];
}

export const GetUserLogs: FC<GetUserLogsProps> = ({ userId }: GetUserLogsProps) => {
  const [logs, setLogs] = useState<GetUserLogsApiProps[]>([]);

  useEffect(() => {
    authApi
      .get(`/api/admin/getlogs/${ userId}`)
      .then((resp: AxiosResponse<GetUserLogsApiProps[]>) => {
        setLogs(resp.data);
      })
      .catch((e) => {});
  }, []);

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mt-8 text-center">
      {
        logs?.map((log) => {
          return (
            <div key={log.ID} className="bg-indigo-200 shadow-inner drop-shadow-lg p-3 rounded-lg text-gray-700 font-bold">
              <p>Moderator : {log.MODERATOR}</p>
              <p className="text-yellow-800">LOG : {log.LOG}</p>
              <p>Time : {log.created_at}</p>
            </div>
          );
        })}
    </div>
  );
};
