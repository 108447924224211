import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from '../../../components/Info';

import { GreenButton } from '../../../components/Buttons/GreenButton';
import { DisabledButton } from '../../../components/Buttons/DisabledButton';
import ReclamationIcon from '../../Util/Icons/ReclamationIcon';

import ApplicationAlert from '../../../components/ApplicationAlert';
import RewardAlert from '../../Util/Reward/RewardAlert';
import { Loader } from '../../../components/Loader';
import { fromCDN } from '../../../helpers/Images';

import AbilityFirstTimePick from './AbilityFirstTimePick';
import DoubleUpIcon from '../../Util/Icons/DoubleUpIcon';
import AbilityUpgrade from './AbilityUpgrade';
import { authApi } from '../../../api';
import { Stars } from '../../Util/Icons/star/Stars';
import { useHelper } from '../../../hooks/use-helper';
import { useUser } from '../../../hooks/use-user';

export default function Ability() {
  const { user, setUser } = useUser();
  const { helpers } = useHelper();

  const { t } = useTranslation('common');

  const [isAbilityExplanation, setIsAbilityExplanation] = useState(
    <p className="bg-gray-200 rounded-lg shadow-inner p-3 m-5 animate__animated animate__flipInX">
      {t('GAME_CHOOSE_ABILITY')}
    </p>,
  );

  const [canTrain, setCanTrain] = useState(true);
  const [isAbilityUpgradeRequested, setIsAbilityUpgradeRequested] = useState();
  const abilities = helpers.Ability;
  let tgTrain = user['ABILITY_TYPE'] > 0 ? user[abilities[user['ABILITY_TYPE']]['SQL']] : 0;
  let abilityWorkDay = user['ABILITY_TYPE'] > 0 ? parseInt(user[abilities[user['ABILITY_TYPE']]['SQLD']]) : 0;

  function trainAbility() {
    if (!canTrain) {
      return false;
    }

    authApi
      .post('/api/abilitytrain', { Improve: user['ABILITY_TYPE'] })
      .then((response) => {
        ApplicationAlert(<RewardAlert rewards={response.data} helper={helpers} t={t} />).success();
        setUser(response.data.USER);
        setCanTrain(false);
      })
      .catch((err) => {
        ApplicationAlert(t(err.response.data)).error();
      });
  }

  function handleAbilityDescription(item) {
    setIsAbilityExplanation(null);
    setTimeout(() => {
      setIsAbilityExplanation(<AbilityFirstTimePick item={item} user={user} helper={helpers} t={t} />);
    }, 200);
  }

  useEffect(() => {
    if (abilityWorkDay === helpers.Game.Day) {
      setCanTrain(false);
    }
  }, []);

  return (
    <>
      {isAbilityUpgradeRequested ? (
        isAbilityUpgradeRequested
      ) : (
        <>
          {user['ABILITY_TYPE'] > 0 && (
            <div className="w-2/2 sm:w-2/2 bg-edom-dark-red/90 py-10 px-6 rounded-lg shadow-[inset_0_-1px_5px_rgba(0,0,0,0.6)]">
              <div className="justify-center">
                <div className="relative flex gap-4">
                  <img
                    src={fromCDN.fetchStaticGameImage(`/abiicons/abi_${abilities[user['ABILITY_TYPE']]['ID']}`, 'png')}
                    className="relative rounded-lg -top-6 -mb-4 h-20 w-20 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]"
                    loading="lazy"
                  />
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between">
                      <div className="flex items-center gap-1">
                        <span className="text-base font-medium text-gray-200">
                          {t(`GAME_ABILITY_P_${user['ABILITY_TYPE']}`)}
                        </span>
                        <span className="text-white bg-gray-800 rounded py-px px-2">Q{tgTrain}</span>
                        {user[abilities[user.ABILITY_TYPE]['SQL']] < 4 && (
                          <span
                            className="cursor-pointer default-transition ml-2"
                            onClick={() =>
                              setIsAbilityUpgradeRequested(
                                <AbilityUpgrade
                                  setIsAbilityUpgradeRequested={setIsAbilityUpgradeRequested}
                                  abilities={abilities}
                                />,
                              )
                            }
                          >
                            <DoubleUpIcon className="h-6 w-6 mx-auto text-gray-200 bg-yellow-400/60 hover:bg-yellow-400/70 rounded animate__animated animate__flipInX" />
                          </span>
                        )}
                      </div>

                      <a className="text-gray-500 " href="#">
                        <i className="fa-solid fa-trash"></i>
                      </a>
                    </div>
                    <div className="text-gray-400 text-sm flex items-center gap-1">
                      <Stars totalStars={tgTrain} />
                    </div>
                  </div>
                </div>
                <div className=" grid grid-cols-2">
                  <div className=" justify-center text-left mt-5 mb-2 h-8" onClick={trainAbility}>
                    <Suspense
                      fallback={<Loader className="w-10 h-10" circleStrokeWidth="5" customPaddingForTheCircle="p-0" />}
                    >
                      {canTrain ? (
                        <GreenButton text={t('GAME_WORKOUT')} />
                      ) : (
                        <DisabledButton icon={<ReclamationIcon />} text={t('GAME_TRAINED')} />
                      )}
                    </Suspense>

                    <div className="flex justify-left font-medium">
                      <div className="text-center">
                        <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                          <img
                            className="h-6 w-6 sm:h-8 sm:w-8 mx-auto"
                            src={fromCDN.fetchStaticGameImage(
                              `/abiicons/abi_${abilities[user['ABILITY_TYPE']]['ID']}`,
                              'png',
                            )}
                            alt="Workflow"
                          />
                        </span>
                        <span className="sm:text-sm text-gray-200 bg-green-600/70 p-1.5 rounded-lg">
                          +{helpers.Ability[user.ABILITY_TYPE]['SKILL'][user[helpers.Ability[user.ABILITY_TYPE].SQL]]}
                        </span>
                      </div>
                      <div className="text-center">
                        <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                          <img
                            className="h-6 w-6 sm:h-8 sm:w-8 mx-auto"
                            src={fromCDN.fetchStaticImage('/game/icons/energy-s', 'png')}
                            alt="Workflow"
                          />
                        </span>
                        <span className="sm:text-sm text-gray-200 bg-red-600/70 p-1.5 rounded-lg">
                          -{helpers.TG[1]['HP'][user.TG1]}
                        </span>
                      </div>
                      <div className="text-center">
                        <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                          <img
                            className="h-6 w-6 sm:h-8 sm:w-8 mx-auto"
                            src={fromCDN.fetchStaticImage('/game/icons/gold-icon', 'png')}
                            alt="Workflow"
                          />
                        </span>
                        <span className="sm:text-sm text-gray-200 bg-red-600/70 p-1.5 rounded-lg">
                          -{abilities[user['ABILITY_TYPE']]['Gold'][2]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <img
                      className="w-8 h-8 bg-black/40 rounded-t p-1 drop-shadow-[0_5px_2px_rgba(0,0,0,0.2)]"
                      src={fromCDN.fetchStaticImage('/game/icons/info', 'svg')}
                    ></img>
                    <Info text={t('GAME_ABILITYTRAININFO')} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {user['ABILITY_TYPE'] === 0 && (
            <>
              <div className="w-2/2 sm:w-2/2 mt-10 sm:mt-0 w-4/4 bg-black/40 border-l-4 border-b-2 border-zinc-900 rounded-lg p-4">
                <div className="grid grid-cols-4 text-center justify-items-center relative gap-4">
                  {Object.values(abilities).map((item, key) => {
                    return (
                      <div key={key}>
                        <img
                          src={fromCDN.fetchStaticGameImage(`/abiicons/abi_${item['ID']}`, 'png')}
                          className="rounded-lg mb-4 mt-4 cursor-pointer h-14 w-14 default-transition"
                          loading="lazy"
                          onClick={() => handleAbilityDescription(item)}
                        />
                      </div>
                    );
                  })}
                </div>
                {isAbilityExplanation}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
