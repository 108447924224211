import { useTranslation } from 'react-i18next';

import { countryFlags } from '../../helpers/Helpers';
import { useHelper } from '../../hooks/use-helper';
import { BattlefieldBattleObjectProps } from '../../shared-types/battlefield-types';
import { BATTLE_STATUS } from '../../shared-types/battles-types';

export interface BattleResultsProps {
  battleDetails: BattlefieldBattleObjectProps;
}

export const BattleResults = ({ battleDetails }: BattleResultsProps) => {
  const { t } = useTranslation('common');
  const { helpers } = useHelper();

  const winnerBg = battleDetails.STATUS === BATTLE_STATUS.AGGRESSOR_WON ? 'bg-edom-dark-blue' : 'bg-edom-dark-red';
  const winnerFlag =
    battleDetails.STATUS === BATTLE_STATUS.AGGRESSOR_WON ? battleDetails.AGRESOR : battleDetails.DEFENDER;

  return (
    <div
      data-testid="winner-overlay"
      className={`absolute bottom-0 left-0 w-full text-xl text-neutral-100 flex items-center justify-center gap-x-1 h-40 bg-opacity-90 ${winnerBg}`}
    >
      {countryFlags(winnerFlag, 'w-10 h-10', true)}
      <span>{helpers.Countries[winnerFlag].Name}</span>
      <span>{t('GAME_BATTLE_WINNER')}</span>
    </div>
  );
};
