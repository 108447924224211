import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '../../components/PageContainer';
import { SectionHeader } from '../../components/SectionHeader';
import { ButtonMailTo } from '../../components/Buttons/ButtonMailTo';

export default function Rules() {
  const { t } = useTranslation('common');

  return (
    <PageContainer>
      <SectionHeader title={t('GAME_FOOTER_RULES')} titleClass="text-xl" />

      <div className="text-gray-800 space-y-10 px-4 pt-8 text-base">
        <div>
          <h2 className="uppercase font-semibold mb-5 border-b text-xl border-l-8 border-indigo-900 pl-2">
            General Terms
          </h2>

          <ol className="list-decimal list-inside pl-2 space-y-8">
            <li>
              <h3 className="inline font-semibold text-lg">General Information</h3>
              <div className="pl-3 md:pl-4 space-y-4 mt-4">
                <p>
                  The website accessible on{' '}
                  <Link to="/" className="text-link">
                    www.edominations.com
                  </Link>
                  (the “Website”) allows people to create an account and participate in a variety of activities in a
                  mass multiplayer online social strategy game called eDominations (»Game«).
                </p>
                <p>
                  By accessing the Website and creating your account, you (»User«, »You«) are entering into a contract
                  with IT storitve Ugur Omer Atagun registered under Nr. 8299641000 (»eDominations«, "we", “us” or
                  “our”), the operator of the Website, and are thereby agreeing to these Terms of Service, along with
                  any amendments thereto and any operating rules or policies that may be published from time to time by
                  us and incorporated herein by reference (the "Terms").
                </p>
                <p>
                  In these Terms, the term "Website" includes, but is not limited to, the website, accessible at{' '}
                  <Link to="/" className="text-link">
                    www.edominations.com
                  </Link>
                  , with all related sub-pages, and any upgrades, updates, revisions, new versions or releases thereof,
                  any content contained therein, including any concept, theme, character, text, image, graphics,
                  artwork, sound, animation and Marks (defined below), and any related documentation, but excluding
                  Third Party Content and/or Third Party Sites.
                </p>
                <p>
                  The Terms govern your purchase and use of any products and/or services offered by us on the Website.
                  You may purchase digital items offered on the Website and may only use the Website in accordance with
                  the terms and conditions hereunder. These Terms, the Privacy Policy and any other legal notices
                  published on the Website shall constitute the complete agreement between us.
                </p>
                <p className="font-light text-black">
                  IF YOU DO NOT AGREE TO (OR CANNOT COMPLY WITH) THE TERMS HEREOF AND/OR THE PRIVACY POLICY, THEN DO NOT
                  PROCEED WITH REGISTRATION OF YOUR ACCOUNT AND LEAVE THE WEBSITE.
                </p>
              </div>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Legal Notice</h3>
              <div className="pl-3 md:pl-4 space-y-4 mt-4">
                <p className="font-light text-black">
                  YOU WARRANT AND REPRESENT THAT YOU ARE AT LEAST 18 YEARS OLD, AND AGREE THAT YOU HAVE READ AND
                  UNDERSTAND, AND HAVE THE CAPACITY AND AUTHORITY TO ACCEPT, AGREE TO AND BE BOUND BY THESE TERMS.
                </p>

                <p className="font-light text-black">
                  THESE TERMS MAY BE MODIFIED FROM TIME TO TIME AT OUR SOLE DISCRETION, AND SUCH CHANGES OR
                  MODIFICATIONS ARE EFFECTIVE IMMEDIATELY UPON THE EARLIEST OF:
                </p>

                <ul className="list-disc list-inside px-2 space-y-4">
                  <li className="font-light text-black">
                    OUR EMAIL NOTIFICATION TO YOU ADVISING YOU OF SUCH CHANGES OR MODIFICATIONS,
                  </li>
                  <li className="font-light text-black">
                    YOUR ACCEPTANCE OF THE TERMS AFTER SUCH CHANGES OR MODIFICATIONS HAVE BEEN MADE TO THE TERMS AS
                    INDICATED BY THE "DATE OF LAST REVISION" DATE AT THE BOTTOM OF THE TERMS, OR
                  </li>
                  <li className="font-light text-black">
                    YOUR CONTINUED USE OF THE WEBSITE AND/OR PARTICIPATION IN THE GAME AFTER WE POST THE UPDATED TERMS,
                    AND MAKE THEM ACCESSIBLE ON THE WEBSITE.
                  </li>
                </ul>

                <p className="font-light text-black">
                  WHEN USING PARTICULAR SERVICES, YOU MAY BE SUBJECT TO ADDITIONAL TERMS AND GUIDELINES OR RULES
                  APPLICABLE TO SUCH SERVICES THAT MAY BE POSTED FROM TIME TO TIME ON THE WEBSITE.
                </p>

                <p className="font-light text-black">
                  BY USING THE WEBSITE, YOU ARE CONFIRMING YOUR ADHERENCE TO THESE TERMS AND CONDITIONS AND UNDERTAKE
                  NOT TO USE THE WEBSITE TO PERFORM ANY ILLEGAL ACTIVITIES.
                </p>
              </div>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Description of Services</h3>
              <div className="pl-3 md:pl-4 space-y-4 mt-4">
                <p>
                  On the Website, we provide you with an opportunity to play the Game, interact with other players, and
                  engage in other activities related to the Game.
                </p>
                <p>
                  The Website allows you to purchase various digital items to be used in the Game e.g. gold and other
                  “in game” resources (“In Game Purchases”).
                </p>
              </div>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Your Personal Data</h3>
              <p className="pl-3">
                We are operating under the laws of European Union, and strictly abide by the laws and regulations
                pertaining to the privacy of your personal data, including the General Data Protection Regulation of the
                EU (GDPR). Please find more information about our treatment, collection and processing of your personal
                data in our Privacy Policy, made available at the Website.
              </p>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Website Availability</h3>

              <div className="pl-3 md:pl-4 space-y-4 mt-4">
                <p>
                  Please be aware that the Website may only be available and/or accessible by certain browsers. To be
                  able to access and/or use the Website, or any portion thereof, you must legally obtain all the
                  applicable or required facilities, utilities, software and equipment at your sole risk and expense.
                </p>
                <p>
                  Although the Website may be accessible worldwide, we do not represent that the Website or related
                  materials are available in all languages, or available, appropriate or legally permissible for use in
                  all locations, and accessing the Website from territories where the use thereof is illegal is
                  prohibited. If you choose to access and use the Website, you do so at your initiative and are
                  responsible for compliance with any applicable laws. We reserve the right to limit the availability of
                  the Website, or any portion thereof, to any User, geographical area or jurisdiction, at any time at
                  our sole discretion.
                </p>
                <p>
                  We shall not bear any responsibility and/or liability for any damage or interruptions caused in
                  connection with the use of the Website, by any viruses, worms, trojan horses, time bombs, web bugs,
                  spyware, or any other computer code, files, or programs that may or is intended to damage or hijack
                  the operation of any hardware, software, or telecommunications equipment, or any other actually or
                  potentially harmful, disruptive or invasive code or component.
                </p>
              </div>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Ownership of Intellectual Property Rights</h3>

              <div className="pl-3 md:pl-4 space-y-4 mt-4">
                <p>
                  The Game and Website are protected by applicable copyright, patent and other intellectual property
                  laws and treaties. All rights are reserved.
                </p>
                <p>
                  We grant you a personal, non-transferable, non-exclusive, revocable, limited license to use and access
                  the Services solely as permitted by these Terms. We reserve all rights not expressly granted to you by
                  these Terms.
                </p>
              </div>
            </li>
          </ol>
        </div>

        {/* Special terms */}
        <div>
          <h2 className="uppercase font-semibold mb-5 border-b text-xl border-l-8 border-indigo-900 pl-2">
            Special Terms
          </h2>

          <ol className="list-decimal list-inside pl-2 space-y-8">
            <li>
              <h3 className="inline font-semibold text-lg">Your Account and Account Security</h3>

              <div className="pl-3 md:pl-4 space-y-4 mt-4">
                <p>
                  To enjoy the Game and use certain features of our Services, you may be required to create an account
                  (an "Account") and provide us with an email, username, password, and certain other information about
                  yourself as set forth in the Privacy Policy.
                </p>
                <p>
                  You are allowed to have only two eDomination accounts with which to play the Game. Usage of more than
                  two accounts (also known as multi accounting) will lead to permanent ban. Sharing the same IP address
                  or computer is limited. Only up to two accounts can share same IP connection.
                </p>
                <p>
                  You are solely responsible for the information associated with Your Account and anything that happens
                  related to Your Account. You must maintain the security of your Account and promptly notify us if you
                  discover or suspect that someone has accessed your Account without your permission. We recommend that
                  you use a strong password that is used only with the Services.
                </p>
                <p>
                  Account sitting is generally forbidden, but if you contact Administration providing information of
                  reasons for a need of account sitting and period for it, we may allow it at our sole discretion.
                </p>
                <p>You will not license, sell, or transfer your Account without our prior written approval.</p>
              </div>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">In Game Purchases</h3>

              <div className="pl-3 md:pl-4 space-y-4 mt-4">
                <p>
                  Because our products are digital goods delivered via Internet download we generally DO NOT OFFER
                  REFUNDS ON IN GAME PURCHASES.
                </p>
                <p>
                  Any digital item you buy in relation with the Game is intended to enhance your performance and
                  experience in the Game. Once you use such item you cannot return it, and thus we cannot offer a
                  refund. If you change your mind about your purchase and you have not used the item, however, we will
                  happily issue you a refund upon your request.
                </p>
                <p>
                  We may - at our discretion, also make an exception in case of non-delivery of the product: If your
                  purchase does not appear in your “in game” itinerary due to a technical issue. We may require you to
                  submit proof of transaction such as a screenshot or an invoice for your payment, etc.
                </p>
                <p>
                  We use third-party service providers to process your Payment Information. If you submit your Payment
                  Information, you agree to pay all costs that you incur, and you give us permission to charge you when
                  payment is due for an amount that includes these costs and any applicable taxes and fees.
                </p>
              </div>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Your Content</h3>

              <div className="pl-3 md:pl-4 space-y-4 mt-4">
                <p>
                  The Services may contain information, text, links, graphics, photos, videos, or other materials
                  (“Content”), including Content created with or submitted to the Services by you or through your
                  Account (“Your Content”). We take no responsibility for and we do not expressly or implicitly endorse
                  any of Your Content.
                </p>
                <p>
                  By submitting Your Content, such as newspaper article, article comments, private game messages,
                  account names and avatars, political parties and military units names to the Services, you represent
                  and warrant that you have all rights, power, and authority necessary to grant the rights to Your
                  Content contained within these Terms. Because you alone are responsible for Your Content, you may
                  expose yourself to liability if you post or share Content without all necessary rights.
                </p>
                <p>
                  You retain any ownership rights you have in Your Content, but you grant us the following license to
                  use that Content:
                </p>
                <p>
                  When Your Content is created with or submitted to the Services, you grant us a worldwide,
                  royalty-free, perpetual, irrevocable, non-exclusive, transferable, and sublicensable license to use,
                  copy, modify, adapt, prepare derivative works, distribute, perform, and display Your Content and any
                  name, username, voice, or likeness provided in connection with Your Content in all media formats and
                  channels now known or later developed. This license includes the right for us to make Your Content
                  available for syndication, broadcast, distribution, or publication by other companies, organizations,
                  or individuals who partner with us. You also agree that we may remove metadata associated with Your
                  Content, and you irrevocably waive any claims and assertions of moral rights or attribution with
                  respect to Your Content.
                </p>
                <p>
                  Any ideas, suggestions, and feedback about us or our Services that you provide to us are entirely
                  voluntary, and you agree that we may use such ideas, suggestions, and feedback without compensation or
                  obligation to you.
                </p>
                <p>
                  Although we have no obligation to screen, edit, or monitor Your Content, we may, in our sole
                  discretion, delete or remove Your Content at any time and for any reason, including for violation of
                  these Terms or if you otherwise create liability for us.
                </p>
              </div>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Third-Party Content, Advertisements and Promotions</h3>

              <div className="pl-3 md:pl-4 space-y-4 mt-4">
                <p>
                  The Services may contain links to third-party websites, products, or services, which may be posted by
                  advertisers, our affiliates, our partners, or other users (“Third-Party Content”). Third-Party Content
                  is not under our control, and we are not responsible for any of their websites, products, or services.
                  Your use of Third-Party Content is at your own risk and you should make any investigation you feel
                  necessary before proceeding with any transaction in connection with such Third-Party Content.
                </p>

                <p>
                  The Services may also contain sponsored Third-Party Content or advertisements. The type, degree, and
                  targeting of advertisements are subject to change, and you acknowledge and agree that we may place
                  advertisements in connection with the display of any Content or information on the Services, including
                  Your Content.
                </p>
              </div>
            </li>
          </ol>
        </div>

        {/* Restricted Actions (game rules) */}
        <div>
          <h2 className="uppercase font-semibold mb-5 border-b text-xl border-l-8 border-indigo-900 pl-2">
            Restricted Actions (game rules)
          </h2>

          <ol className="list-decimal list-inside pl-2 space-y-8">
            <li>
              <h3 className="inline font-semibold text-lg">
                Website/Service related:{' '}
                <span className="font-normal">
                  Except as expressly permitted herein, you shall not (and not permit any third party) to:
                </span>
              </h3>

              <ul className="list-disc list-inside pl-3 md:pl-4 space-y-4 mt-4">
                <li>
                  copy, modify, incorporate into or with other software, or create derivative works of the Website, or
                  any part thereof;
                </li>
                <li>
                  decompile, disassemble, decrypt, or otherwise reverse engineer or attempt to reconstruct or discover
                  any source code or underlying ideas or algorithms of the Website, or any portion thereof;
                </li>
                <li>
                  alter, remove or obscure, or use for personal or commercial use any product identification, name,
                  mark, logo, trade name, trademark, service mark, copyright, images, legends, symbols or labels
                  contained in or displayed on or via the Website ("Marks");
                </li>
                <li>
                  disable, circumvent or otherwise interfere with the Website' security-related features or technical
                  restrictions;
                </li>
                <li>
                  collect or harvest any personally identifiable information (such as names or email addresses) from the
                  Website;
                </li>
                <li>
                  use the Website for any commercial purpose or for the benefit of a third party or in any manner not
                  permitted herein;
                </li>
                <li>invade the privacy rights of any Website user or third party;</li>
                <li>
                  use the Website to violate any applicable laws, rules or regulations, for any illegal or unauthorized
                  purpose or in any manner that breaches the terms hereof;
                </li>
                <li>
                  take any action that imposes, or may impose, an unreasonable or disproportionately large load on our
                  platform infrastructure;
                </li>
                <li>bypass any measures we may use to prevent or restrict access to the Website;</li>
                <li>
                  transmit or otherwise make available in connection with the Website any virus, worm, Trojan Horse,
                  time bomb, web bugs, spyware, or any other computer code, files, or programs that may or is intended
                  to damage or hijack the operation of any hardware, software, or telecommunications equipment, or any
                  other actually or potentially harmful, disruptive or invasive code or component;
                </li>
                <li>
                  copy, modify, alter, adapt, make available, translate, port, reverse engineer, decompile, or
                  disassemble any portion of the content made on or through the Website, including any information,
                  videos, text, graphics and/or software programs used in connection with the Website, materials,
                  descriptions and/or data obtained from or through the Website (collectively, "Content"), or publicly
                  display, reproduce, create derivative works from, perform, distribute, or otherwise use such Content,
                  other than as permitted under these Terms; and/or
                </li>
                <li>
                  create a browser or border environment around the Content (no frames or inline linking is allowed),
                  frame or mirror any part of the Website without the prior express written authorization.
                </li>
              </ul>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">
                Content Related:{' '}
                <span className="font-normal">
                  While we generally provide a lot of leeway in what content is acceptable, here are some guidelines for
                  content that is not. Please keep in mind the spirit in which these were written, and know that looking
                  for loopholes is a waste of time.
                </span>
              </h3>

              <div className="pl-3 md:pl-4 space-y-4 mt-4">
                <p className="font-medium">Content is prohibited if it:</p>
                <ul className="list-disc list-inside space-y-4">
                  <li>Is illegal</li>
                  <li>Is pornography</li>
                  <li>Is sexual or suggestive content involving minors</li>
                  <li>Encourages or incites violence</li>
                  <li>Threatens, harasses, or bullies or encourages others to do so</li>
                  <li>Is personal and confidential information</li>
                  <li>Impersonates someone in a misleading or deceptive manner</li>
                  <li>
                    Uses the Game or Services to solicit or facilitate any transaction or gift involving certain goods
                    and services
                  </li>
                  <li>Is spam</li>
                </ul>
              </div>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">
                Game related:{' '}
                <span className="font-normal">
                  Certain actions that could affect Game play and players performance are also prohibited:
                </span>
              </h3>

              <ul className="list-disc list-inside pl-3 md:pl-4 space-y-4 mt-4">
                <li>
                  <span className="font-medium">Shared connection: </span>
                  Shared connection accounts are allowed to have referral connection. Shared connection accounts can
                  participate in the same political parties, military units, vote in elections and etc. all activities
                  which not violate the mentioned restrictions. Attempts of violation of this rule by donations or
                  market operations by assistance of other, not sharing same IP connection account, is strictly
                  forbidden and will lead to punishment also to the accounts providing assistance to such actions.
                  Shared connection however becomes illegal in cases of which there is financial interactions between
                  accounts in game (such as donations, stock and monetary market activities, working for the other
                  account sharing the same IP address, newspaper article endorsement, items transfers via Military Unit
                  options).
                </li>
                <li>
                  <span className="font-medium">Automated Software: </span>
                  Using of bots, scripts and any type of automated software in game activities is strictly forbidden and
                  will lead to permanent ban.
                </li>
                <li>
                  <span className="font-medium">Abusive speech: </span>
                  All type of abuses towards Administration team (including Moderators) as well as other players is
                  prohibited and may result in penalties.
                </li>
                <li>
                  <span className="font-medium">Advertising and Solicitation: </span>
                  Using game options (newspaper articles, private messages, mass shouts and etc.) for advertising of
                  products not connected to the Game (such as other games, products and etc.) is strictly forbidden and
                  could result in a permanent ban.
                </li>
                <li>
                  <span className="font-medium">Exploitation of other players or accounts: </span>
                  Exploiting other players is strictly forbidden for both the exploiter and the exploited accounts, no
                  matter do they share the same IP and are different persons. Administration could find as exploit any
                  of the following: working for minimum wages without any other appropriate payment in gold or items;
                  one-way donation from one to another accounts consisting items or so.
                </li>
                <li>
                  <span className="font-medium">Donations: </span>
                  If a player decide to leave the game it is strictly forbidden for him to donate all his “in Game”
                  valuables to other player, no matter if they share the same IP or not ; players who leave game can
                  only donate their items to a country treasury via direct contributions, and not other players.
                </li>
                <li>
                  <span className="font-medium">Exploiting “in Game” bugs: </span>
                  Exploiting bugs is strictly forbidden and will lead to punishment. If you discover a bug in the Game
                  you should report it to the Administration. If Administration finds any evidence that a player shared
                  a bug with other players in order to provide them with a possibility to exploit it, or has exploited
                  it himself, he may be banned without warning. Reporting of bugs to game administration which lead to
                  resolving of it will be awarded in gold by game administration.
                </li>
                <li>
                  <span className="font-medium">Real-life Item Trading: </span>
                  Exchanging, selling or buying any in-Game content (gold, items, currencies, companies and etc.) for
                  items outside the Game, such as material items or digital goods in other games is strictly forbidden.
                </li>
                <li>
                  <span className="font-medium">Account ownership transfer: </span>
                  Should you choose to cease playing for any reason and decide to transfer your account to another/new
                  owner, you can only do so with the approval of the Administration. Account switching is strictly
                  forbidden.
                </li>
                <li>
                  <span className="font-medium">Account Hacking: </span>
                  Logging into an account which you do not own, without the owners permission, (password break) is
                  considered theft and strictly prohibited.
                </li>
              </ul>
            </li>
          </ol>
        </div>

        {/* Punishments and Enforcement */}
        <div>
          <h2 className="uppercase font-semibold mb-5 border-b text-xl border-l-8 border-indigo-900 pl-2">
            Punishments and Enforcement
          </h2>

          <div className="pl-3 md:pl-4 space-y-6 mt-4">
            <p>
              Should you violate any of the following rules or the terms contained in our Terms of Service, our
              administrators are entitled to use punishments at the level of the Game, Website, and the Administrative
              Action. We have a variety of ways of enforcing our rules, including, but not limited to:
            </p>

            <ol className="list-disc list-inside pl-2 space-y-4">
              <li className="font-medium">Asking you nicely to stop;</li>
              <li className="font-medium">Asking you less nicely;</li>
              <li className="font-medium">Temporary or permanent suspension (“ban”) of accounts;</li>
              <li className="font-medium">Removal of content.</li>
            </ol>

            <p>More specifically, we can resort to any of the following:</p>

            <ul className="list-disc list-inside pl-2 space-y-4">
              <li>
                <span className="font-medium">In Game punishments: </span>
                removing/downgrading of all type of game properties (gold, companies, training grounds).
              </li>
              <li>
                <span className="font-medium">Website/Service punishments: </span>
                Permanent account ban, temporary account ban, official warnings, removing any published content
                (newspaper articles and others).
              </li>
              <li>
                <span className="font-medium">Administrative Action: </span>
                In cases where we suspect a law has been broken, we shall forward the case to responsible authorities
                and institutions.
              </li>
            </ul>
          </div>
        </div>

        {/* Miscellaneous */}
        <div>
          <h2 className="uppercase font-semibold mb-5 border-b text-xl border-l-8 border-indigo-900 pl-2">
            Miscellaneous
          </h2>
          <ol className="list-decimal list-inside pl-2 space-y-6">
            <li>
              <h3 className="inline font-semibold text-lg">Indemnity</h3>

              <p className="pl-3 md:pl-4 mt-4">
                Except to the extent prohibited by law, you agree to defend, indemnify, and hold us, our licensors, our
                third party service providers and our officers, employees, licensors, and agents harmless, including
                costs and attorneys' fees, from any claim or demand made by any third party due to or arising out of{' '}
                <span className="font-medium">(a) your use of the Services</span>,{' '}
                <span className="font-medium">(b) your violation of these Terms</span>,{' '}
                <span className="font-medium">(c) your violation of applicable laws or regulations</span>,{' '}
                <span className="font-medium">or (d) Your Content</span>. We reserve the right to control the defense of
                any matter for which you are required to indemnify us, and you agree to cooperate with our defense of
                these claims.
              </p>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Disclaimers</h3>

              <p className="pl-3 md:pl-4 mt-4 text-black">
                THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, AND NON-INFRINGEMENT. WE, OUR LICENSORS, AND OUR THIRD PARTY SERVICE PROVIDERS DO NOT WARRANT
                THAT THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR FREE. WE DO NOT CONTROL, ENDORSE,
                OR TAKE RESPONSIBILITY FOR ANY CONTENT AVAILABLE ON OR LINKED TO THE SERVICES OR THE ACTIONS OF ANY
                THIRD PARTY OR USER, INCLUDING MODERATORS. WHILE WE ATTEMPT TO MAKE YOUR ACCESS TO AND USE OF OUR
                SERVICES SAFE, WE DO NOT REPRESENT OR WARRANT THAT OUR SERVICES OR SERVERS ARE FREE OF VIRUSES OR OTHER
                HARMFUL COMPONENTS.
              </p>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Limitation of Liability</h3>

              <p className="pl-3 md:pl-4 mt-4 text-black">
                IN NO EVENT AND UNDER NO THEORY OF LIABILITY, INCLUDING CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY,
                WARRANTY, OR OTHERWISE, WILL THE OUR ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, CONSEQUENTIAL,
                EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, OR LOST PROFITS ARISING FROM OR RELATING TO THESE
                TERMS OR THE SERVICES, INCLUDING THOSE ARISING FROM OR RELATING TO CONTENT MADE AVAILABLE ON THE
                SERVICES THAT IS ALLEGED TO BE DEFAMATORY, OFFENSIVE, OR ILLEGAL. ACCESS TO, AND USE OF, THE SERVICES IS
                AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR
                COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM. IN NO EVENT WILL THE AGGREGATE LIABILITY OF THE
                OUR ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS ($100) OR ANY AMOUNT YOU PAID US IN THE
                PREVIOUS SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE LIMITATIONS OF THIS SECTION WILL
                APPLY TO ANY THEORY OF LIABILITY, INCLUDING THOSE BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING
                NEGLIGENCE) OR OTHERWISE, AND EVEN IF THE OUR ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH
                DAMAGE, AND EVEN IF ANY REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. THE
                FOREGOING LIMITATION OF LIABILITY WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE
                JURISDICTION.
              </p>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Governing Law and Venue</h3>

              <div className="pl-3 md:pl-4 mt-4 space-y-4">
                <p>
                  We want you to enjoy the Game, so if you have an issue or dispute, you agree to raise it and try to
                  resolve it with us informally. You can contact us with feedback and concerns here or by emailing us at{' '}
                  <ButtonMailTo mailto="edominationgame@gmail.com" />
                </p>
                <p>
                  Except for the government entities listed below: any claims arising out of or relating to these Terms
                  or the Services will be governed by the laws of the Republic of Slovenia, other than its conflict of
                  laws rules; all disputes related to these Terms or the Services will be brought solely in the state
                  courts located in Ljubljana, Slovenia; and you consent to personal jurisdiction in these courts.
                </p>
              </div>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Termination</h3>

              <div className="pl-3 md:pl-4 mt-4 space-y-4">
                <p>Subject to Termination, these Terms shall continue indefinitely.</p>
                <p>
                  You may unilaterally terminate these Terms at any time and for any reason by deleting your Account and
                  discontinuing your use of all Services. If you stop using the Services without deactivating your
                  Accounts, your Accounts may be deactivated due to prolonged inactivity.
                </p>
                <p>
                  We may suspend or terminate your Accounts, status as a moderator, or ability to access or use the
                  Services at any time for any or no reason, including for violation of these Terms.
                </p>
                <p>
                  The following sections will survive any termination of these Terms or of your Accounts: Your Content,
                  Restrictions, Indemnity, Disclaimers, Limitation of Liability, Governing Law and Venue, Term and
                  Termination, and Miscellaneous.
                </p>
              </div>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Entire agreement, no waiver, and assignment</h3>

              <p className="pl-3 md:pl-4 mt-4 space-y-4">
                These Terms constitute the entire agreement between you and us regarding your access to and use of the
                Services. Our failure to exercise or enforce any right or provision of these Terms will not operate as a
                waiver of such right or provision. If any provision of these Terms is, for any reason, held to be
                illegal, invalid or unenforceable, the rest of the Terms will remain in effect. You may not assign or
                transfer any of your rights or obligations under these Terms without our consent. We may freely assign
                these Terms.
              </p>
            </li>

            <li>
              <h3 className="inline font-semibold text-lg">Contact</h3>

              <div className="pl-3 md:pl-4 mt-4 space-y-4">
                <p>If you have any questions, complaints or claims regarding the Website you may contact us at:</p>
                <p>
                  Email: <ButtonMailTo mailto="edominationgame@gmail.com" />
                </p>
                <p>
                  For the avoidance of doubt any questions, claims or comments regarding Third Party Websites and/or
                  Third Party Content should be directed to the Third Party Provider. You acknowledge and agree that any
                  cause of action that you may have arising out of or related to the Website must commence within one 1
                  year after the cause of action accrues. Otherwise, such cause of action is permanently barred.
                </p>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </PageContainer>
  );
}
