import Avatar from '../../../components/Avatar';
import { countryFlags, rankImages } from '../../../helpers/Helpers';
import { TopPlayersByMilitaryRankProps } from '../../../shared-types/types';
import { fromCDN } from '../../../helpers/Images';

interface TopPlayersByMilitaryViewRankProps {
  details: TopPlayersByMilitaryRankProps[];
}

export const TopPlayersByMilitaryRank = ({ details }: TopPlayersByMilitaryViewRankProps) => {
  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-5 m-3 mt-10 gap-x-4 gap-y-5 mb-10 text-gray-200">
        {details.map((item) => {
          return (
            <div
              className="text-center bg-gradient-to-br from-indigo-700/20 to-red-900/20 rounded-md p-3"
              key={item.U_ID}
            >
              <p className="absolute ml-1 font-semibold">#4</p>
              <Avatar avatarId={item.AVATAR} userId={String(item.U_ID)} className="mx-auto" />
              <p className="text-gray-200 font-medium">{item.U_NAME}</p>
              <p>{countryFlags(item.CITIZENSHIP, 'mx-auto')}</p>
              <div>
                {rankImages(item.MILITARY_RANK, 'mx-auto w-6 h-6 mb-1 mt-2')}
                <p className="bg-blue-400/40 rounded-md font-medium">{item.R_DMG}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-2 justify-items-center gap-10 mb-20 text-gray-200">
        <button className="bg-edom-darker-yellow/80 hover:bg-edom-darker-yellow hover:scale-110 transition duration-200 p-3 rounded-lg">
          <img className="h-5 w-5" src={fromCDN.fetchStaticImage('t_arrowleft', 'svg')}></img>
        </button>
        <button className="bg-edom-darker-yellow/80 hover:bg-edom-darker-yellow hover:scale-110 transition duration-200 p-3 rounded-lg">
          <img className="h-5 w-5" src={fromCDN.fetchStaticImage('t_arrowright', 'svg')}></img>
        </button>
      </div>
    </>
  );
};
