import { fromCDN } from '../../../helpers/Images';
import ReactTooltip from 'react-tooltip';

interface RankingsMenuProps {
  handleSelected: (arg: number) => void;
}

interface BuildRankingsMenuItemsProps {
  id: number;
  img: string;
}

const totalRanks = 5;

export const RankingsMenu = ({ handleSelected }: RankingsMenuProps) => {
  const buildRankingsMenuItems = (): BuildRankingsMenuItemsProps[] => {
    let tmpArray = [];
    for (let x = 1; x < totalRanks; x++) {
      tmpArray.push({
        id: x,
        img: `/t_cat_${x}_big`,
      });
    }
    return tmpArray;
  };

  return (
    <div className="grid grid-cols-3 sm:grid-cols-6 md:grid-cols-10 gap-4 mt-6 mb-16 p-3 rounded-xl bg-gradient-to-b from-gray-100/0 via-gray-200/10 to-gray-200/0 border-x-2 border-edom-darker-yellow">
      {buildRankingsMenuItems().map((item) => {
        return (
          <div
            data-tip={true}
            data-for="x"
            className="inline-flex top-0 cursor-pointer"
            onClick={() => {
              handleSelected(item.id);
            }}
            key={`${item.id}${item.img}`}
          >
            <img
              className="mx-auto hover:scale-110 hover:bg-gradient-to-b hover:from-gray-200/0 hover:via-gray-200/10 hover:to-gray-200/0 hover:border-x-2 hover:border-edom-darker-yellow p-2 rounded-md transition duration-200 cursor-pointer w-20 h-20 drop-shadow-[0_2px_1px_rgba(0,0,0,0.6)]"
              src={fromCDN.fetchStaticImage(item.img, 'svg')}
            />
            <ReactTooltip id="x" effect="solid" place="bottom" data-html={true}>
              {item.id}
            </ReactTooltip>
          </div>
        );
      })}
    </div>
  );
};
