import { ReactNode, useEffect, useState } from 'react';
import { getFromLocalStorage, saveToLocalStorage } from '../helpers/LocalStorage';

interface GeneralAccordionProps {
  component: ReactNode;
  shouldSetCookie?: boolean;
  cookie?: string;
}
export const Accordion = ({ component, shouldSetCookie = false, cookie = '' }: GeneralAccordionProps) => {
  const [open, setOpen] = useState(true);
  const openHandler = () => {
    const localStorageValue = getFromLocalStorage(cookie);

    setOpen((prevState) => !prevState);

    !localStorageValue ? saveToLocalStorage(cookie, true) : saveToLocalStorage(cookie, false);
  };

  useEffect(() => {
    setOpen(!(shouldSetCookie && getFromLocalStorage(cookie)));
  }, [cookie, shouldSetCookie]);
  return (
    <>
      {open && component}
      <button
        data-testid="topaccordionhandler"
        className="absolute right-1  text-white p-1 rounded-b-lg bg-gray-800 z-50"
        onClick={() => {
          openHandler();
        }}
      >
        {open ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 11l7-7 7 7M5 19l7-7 7 7" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
          </svg>
        )}
      </button>
    </>
  );
};
