export const RegionData = () => {
  return {
    1: {
      name: 'Kandahar',
      neighbors: [2, 3, 241, 327],
      border: [],
    },
    2: {
      name: 'Herat',
      neighbors: [1, 3, 4, 241, 242],
      border: [],
    },
    3: {
      name: 'Kabul',
      neighbors: [1, 2, 4, 327, 328],
      border: [],
    },
    4: {
      name: 'Kundus',
      neighbors: [2, 3, 100, 328],
      border: [],
    },
    5: {
      name: 'Southwest Albania',
      neighbors: [6, 7, 193, 199, 258, 571, 572],
      border: [],
    },
    6: {
      name: 'North Albania',
      neighbors: [5, 7, 302, 303, 360, 555, 571],
      border: [],
    },
    7: {
      name: 'Southeast Albania',
      neighbors: [5, 6, 193, 360, 531, 571, 572],
      border: [],
    },
    10: {
      name: 'Oran',
      neighbors: [13, 14, 16, 434, 442, 443],
      border: [],
    },
    11: {
      name: 'South Algeria',
      neighbors: [13, 444, 421],
      border: [],
    },
    13: {
      name: 'Middle Algeria',
      neighbors: [10, 11, 16, 464, 466],
      border: [],
    },
    14: {
      name: 'Algiers',
      neighbors: [10, 16, 260, 443, 467],
      border: [],
    },
    16: {
      name: 'Biskra District',
      neighbors: [10, 13, 14, 466, 467],
      border: [],
    },
    17: {
      name: 'Buenos Aires',
      neighbors: [18, 21, 422, 536],
      border: [],
    },
    18: {
      name: 'Entre Rios',
      neighbors: [17, 21, 516, 526, 536],
      border: [],
    },
    19: {
      name: 'Gran Chaco',
      neighbors: [20, 21, 62, 330, 331, 333, 526],
      border: [],
    },
    20: {
      name: 'Northwest Argentina',
      neighbors: [19, 21, 62, 95, 96, 535],
      border: [],
    },
    21: {
      name: 'Central Argentina',
      neighbors: [17, 18, 19, 20, 22, 93, 94, 95, 526],
      border: [],
    },
    22: {
      name: 'North Patagonia',
      neighbors: [21, 23, 92, 93],
      border: [],
    },
    23: {
      name: 'South Patagonia',
      neighbors: [22, 24, 91, 92],
      border: [],
    },
    24: {
      name: 'Tierra del Fuego',
      neighbors: [23, 91],
      border: [],
    },
    25: {
      name: 'Central Armenia',
      neighbors: [26, 27, 45, 47, 473],
      border: [],
    },
    26: {
      name: 'South Armenia',
      neighbors: [25, 45, 47, 234],
      border: [],
    },
    27: {
      name: 'North Armenia',
      neighbors: [25, 45, 473, 544],
      border: [],
    },
    29: {
      name: 'New South Wales',
      neighbors: [30, 31, 32, 310],
      border: [],
    },
    30: {
      name: 'Victoria',
      neighbors: [29, 32, 35],
      border: [],
    },
    31: {
      name: 'Queensland',
      neighbors: [29, 32, 231],
      border: [],
    },
    32: {
      name: 'Central Australia',
      neighbors: [29, 30, 31, 33, 227],
      border: [],
    },
    33: {
      name: 'Western Australia',
      neighbors: [32, 225, 532],
      border: [],
    },
    35: {
      name: 'Tasmania',
      neighbors: [30, 310],
      border: [],
    },
    36: {
      name: 'Upper Austria',
      neighbors: [39, 40, 135, 136, 189, 207, 412, 413, 419],
      border: [],
    },
    39: {
      name: 'Lower-Austria',
      neighbors: [36, 40, 266, 417, 418, 419, 547],
      border: [],
    },
    40: {
      name: 'Salzburg and Tyrol',
      neighbors: [36, 39, 189, 266, 454, 457],
      border: [],
    },
    43: {
      name: 'Baku and Bilasuvar District',
      neighbors: [45, 233, 234, 381],
      border: [],
    },
    45: {
      name: 'Central Azerbaijan',
      neighbors: [25, 26, 27, 43, 177, 234, 381, 544],
      border: [],
    },
    47: {
      name: 'Nakhchivan',
      neighbors: [25, 26, 234],
      border: [],
    },
    49: {
      name: 'Minsk',
      neighbors: [50, 51, 52, 53],
      border: [],
    },
    50: {
      name: 'Vitebsk',
      neighbors: [49, 51, 53, 278, 281, 283, 381, 388],
      border: [],
    },
    51: {
      name: 'Hrodna',
      neighbors: [49, 50, 52, 281, 282, 343],
      border: [],
    },
    52: {
      name: 'Brest',
      neighbors: [49, 51, 53, 343, 344, 482],
      border: [],
    },
    53: {
      name: 'Homel',
      neighbors: [49, 50, 52, 381, 476, 478, 482],
      border: [],
    },
    54: {
      name: 'Brussels',
      neighbors: [55, 56],
      border: [],
    },
    55: {
      name: 'Flanders',
      neighbors: [54, 56, 163, 304, 305, 495],
      border: [],
    },
    56: {
      name: 'South Belgium',
      neighbors: [54, 55, 163, 186, 286, 287, 305],
      border: [],
    },
    58: {
      name: 'La Paz',
      neighbors: [59, 60, 61, 96, 335, 336, 534, 535],
      border: [],
    },
    59: {
      name: 'North Bolivia',
      neighbors: [58, 60, 73, 75, 335],
      border: [],
    },
    60: {
      name: 'Trinidad',
      neighbors: [58, 59, 61, 73],
      border: [],
    },
    61: {
      name: 'Santa Cruz',
      neighbors: [58, 60, 73, 332, 333, 534],
      border: [],
    },
    62: {
      name: 'Tarija',
      neighbors: [19, 20, 333, 534, 535],
      border: [],
    },
    64: {
      name: 'Herzegovina',
      neighbors: [65, 67, 127, 258, 302, 303],
      border: [],
    },
    65: {
      name: 'Sarajevo',
      neighbors: [64, 66, 67, 302, 406, 407],
      border: [],
    },
    66: {
      name: 'Northeast Bosnia',
      neighbors: [65, 67, 123, 407, 408],
      border: [],
    },
    67: {
      name: 'Banja Luka',
      neighbors: [64, 65, 66, 123, 124, 126, 127, 549],
      border: [],
    },
    68: {
      name: 'Porto Alegre',
      neighbors: [69, 515, 516, 526, 537],
      border: [],
    },
    69: {
      name: 'Sao Paulo',
      neighbors: [68, 70, 73, 330, 526, 540],
      border: [],
    },
    70: {
      name: 'Rio de Janeiro',
      neighbors: [69, 71, 72, 73, 421],
      border: [],
    },
    71: {
      name: 'Fortaleza',
      neighbors: [70, 72, 74, 444],
      border: [],
    },
    72: {
      name: 'Brasilia',
      neighbors: [70, 71, 73, 74],
      border: [],
    },
    73: {
      name: 'Mato Grosso',
      neighbors: [59, 60, 61, 69, 70, 72, 74, 75, 331, 332, 539, 540],
      border: [],
    },
    74: {
      name: 'Amazonia',
      neighbors: [71, 72, 73, 75, 350],
      border: [],
    },
    75: {
      name: 'Northwest Brazil',
      neighbors: [59, 73, 74, 117, 118, 335, 338, 339, 521, 522],
      border: [],
    },
    76: {
      name: 'Sofia',
      neighbors: [77, 78, 362, 404, 545],
      border: [],
    },
    77: {
      name: 'Struma Valley',
      neighbors: [76, 81, 194, 362, 363, 545, 546],
      border: [],
    },
    78: {
      name: 'Western Danube Valley',
      neighbors: [76, 79, 367, 369, 403, 404, 545],
      border: [],
    },
    79: {
      name: 'Eastern Danube Valley',
      neighbors: [78, 80, 367, 368, 545],
      border: [],
    },
    80: {
      name: 'Bulgaria Black Sea Coast',
      neighbors: [79, 81, 368, 469, 477, 545],
      border: [],
    },
    81: {
      name: 'Thracian Valley',
      neighbors: [77, 80, 469, 545, 546],
      border: [],
    },
    82: {
      name: 'Quebec and Newfoundland',
      neighbors: [83, 84, 90, 247, 350, 512, 554],
      border: [],
    },
    83: {
      name: 'Nova Scotia and New Brunswick',
      neighbors: [82, 351, 352, 512],
      border: [],
    },
    84: {
      name: 'Ontario',
      neighbors: [82, 85, 351, 509, 512],
      border: [],
    },
    85: {
      name: 'Central Canada',
      neighbors: [84, 89, 502, 503, 504, 506, 509],
      border: [],
    },
    89: {
      name: 'Nunavut',
      neighbors: [85, 90],
      border: [],
    },
    90: {
      name: 'Canadian Arctic Archipelago',
      neighbors: [82, 89, 552],
      border: [],
    },
    91: {
      name: 'South Chile Archipelago',
      neighbors: [23, 24, 92],
      border: [],
    },
    92: {
      name: 'North Chile Archipelago',
      neighbors: [22, 23, 91, 93],
      border: [],
    },
    93: {
      name: 'Central Chile',
      neighbors: [21, 22, 92, 94],
      border: [],
    },
    94: {
      name: 'Santiago',
      neighbors: [21, 93, 95],
      border: [],
    },
    95: {
      name: 'Copiapo',
      neighbors: [20, 21, 94, 96, 529],
      border: [],
    },
    96: {
      name: 'Antofagasta',
      neighbors: [20, 58, 95, 336, 535],
      border: [],
    },
    97: {
      name: 'Beijing',
      neighbors: [98, 99, 104, 111, 317],
      border: [],
    },
    98: {
      name: 'Manchuria',
      neighbors: [97, 99, 314, 315, 316, 386, 390, 530],
      border: [],
    },
    99: {
      name: 'Inner Mongolia',
      neighbors: [97, 98, 103, 104],
      border: [],
    },
    100: {
      name: 'Xinjiang',
      neighbors: [4, 101, 102, 103, 328, 386],
      border: [],
    },
    101: {
      name: 'Aksai Chin and Shaksgam Valley',
      neighbors: [100, 102, 213, 328],
      border: [],
    },
    102: {
      name: 'Tibet',
      neighbors: [100, 101, 103, 104, 112, 212, 213],
      border: [],
    },
    103: {
      name: 'Gansu and Qinghai',
      neighbors: [99, 100, 102, 104],
      border: [],
    },
    104: {
      name: 'Central China',
      neighbors: [97, 99, 102, 103, 111, 112],
      border: [],
    },
    111: {
      name: 'East China',
      neighbors: [97, 104, 112, 275, 355, 430],
      border: [],
    },
    112: {
      name: 'South China',
      neighbors: [102, 104, 111, 358, 461],
      border: [],
    },
    114: {
      name: 'Bogota',
      neighbors: [115, 116, 118, 119],
      border: [],
    },
    115: {
      name: 'Colombia Pacific Coast',
      neighbors: [114, 116, 119, 120, 143, 145, 146, 298],
      border: [],
    },
    116: {
      name: 'Florencia District',
      neighbors: [114, 115, 117, 118, 145, 339],
      border: [],
    },
    117: {
      name: 'Southeast Columbia',
      neighbors: [75, 116, 118, 339],
      border: [],
    },
    118: {
      name: 'East Columbia',
      neighbors: [75, 114, 116, 117, 119, 520, 522],
      border: [],
    },
    119: {
      name: 'Medellin',
      neighbors: [114, 115, 118, 120, 520],
      border: [],
    },
    120: {
      name: 'Barranquilla',
      neighbors: [115, 119, 131, 518, 520, 528],
      border: [],
    },
    121: {
      name: 'Zagreb',
      neighbors: [122, 418, 549],
      border: [],
    },
    122: {
      name: 'Medimurje',
      neighbors: [121, 123, 205, 207, 418, 419, 549],
      border: [],
    },
    123: {
      name: 'Slavonia',
      neighbors: [66, 67, 122, 204, 205, 408, 549],
      border: [],
    },
    124: {
      name: 'Lika and Gorski Kotar',
      neighbors: [67, 125, 126, 548, 549],
      border: [],
    },
    125: {
      name: 'Istria and Kvarner',
      neighbors: [124, 266, 415, 416, 548],
      border: [],
    },
    126: {
      name: 'North Dalmatia',
      neighbors: [67, 124, 127, 256],
      border: [],
    },
    127: {
      name: 'South Dalmatia',
      neighbors: [64, 67, 126, 258, 303],
      border: [],
    },
    128: {
      name: 'La Habana',
      neighbors: [129, 300, 510],
      border: [],
    },
    129: {
      name: 'Santa Clara',
      neighbors: [128, 130, 510, 528],
      border: [],
    },
    130: {
      name: 'Camaguey',
      neighbors: [129, 131],
      border: [],
    },
    131: {
      name: 'Santiago de Cuba',
      neighbors: [120, 130],
      border: [],
    },
    132: {
      name: 'South Cyprus',
      neighbors: [133, 148, 198, 200, 251],
      border: [],
    },
    133: {
      name: 'North Cyprus',
      neighbors: [132, 474],
      border: [],
    },
    134: {
      name: 'Praha',
      neighbors: [135, 136, 137],
      border: [],
    },
    135: {
      name: 'Moravia',
      neighbors: [36, 134, 136, 137, 345, 347, 412],
      border: [],
    },
    136: {
      name: 'South Bohemia',
      neighbors: [36, 134, 135, 137, 183, 189],
      border: [],
    },
    137: {
      name: 'North Bohemia',
      neighbors: [134, 135, 136, 183, 347],
      border: [],
    },
    140: {
      name: 'Nordjylland',
      neighbors: [141, 319, 446],
      border: [],
    },
    141: {
      name: 'Midtjylland',
      neighbors: [140, 142, 446, 553],
      border: [],
    },
    142: {
      name: 'Syddanmark and Sjaelland',
      neighbors: [141, 182, 348, 446],
      border: [],
    },
    143: {
      name: 'Quito',
      neighbors: [115, 144, 145, 146, 298, 533],
      border: [],
    },
    144: {
      name: 'Guayaquil',
      neighbors: [143, 146, 339, 341, 533],
      border: [],
    },
    145: {
      name: 'Sucumbios',
      neighbors: [115, 116, 143, 339, 533],
      border: [],
    },
    146: {
      name: 'Galapagos Islands',
      neighbors: [115, 143, 144, 298, 341, 502, 529],
      border: [],
    },
    147: {
      name: 'Cairo',
      neighbors: [148, 149, 151, 152],
      border: [],
    },
    148: {
      name: 'Alexandria',
      neighbors: [132, 147, 149, 151, 200],
      border: [],
    },
    149: {
      name: 'Sinai',
      neighbors: [147, 148, 152, 253, 395],
      border: [],
    },
    151: {
      name: 'Matruh and Egypt Desert',
      neighbors: [147, 148, 152, 198, 258, 465, 425],
      border: [],
    },
    152: {
      name: 'Egypt Red Sea Coast',
      neighbors: [147, 149, 151],
      border: [],
    },
    153: {
      name: 'Tallinn and Narva',
      neighbors: [155, 156, 157, 388],
      border: [],
    },
    155: {
      name: 'Tartu',
      neighbors: [153, 156, 280, 388],
      border: [],
    },
    156: {
      name: 'Parnu',
      neighbors: [153, 155, 157, 161, 276, 277, 280, 445],
      border: [],
    },
    157: {
      name: 'Helsinki',
      neighbors: [153, 156, 158, 161, 379, 388],
      border: [],
    },
    158: {
      name: 'Lake District',
      neighbors: [157, 159, 160, 161, 388],
      border: [],
    },
    159: {
      name: 'Finland West Coast',
      neighbors: [158, 160, 161, 449, 451],
      border: [],
    },
    160: {
      name: 'Lapland',
      neighbors: [158, 159, 322, 388, 451],
      border: [],
    },
    161: {
      name: 'Turku',
      neighbors: [156, 157, 158, 159, 445],
      border: [],
    },
    162: {
      name: 'Paris',
      neighbors: [163, 163, 166],
      border: [],
    },
    163: {
      name: 'Northeast France',
      neighbors: [55, 56, 162, 165, 166, 168, 287, 495],
      border: [],
    },
    165: {
      name: 'West France',
      neighbors: [163, 166, 171, 434, 435, 437, 494],
      border: [],
    },
    166: {
      name: 'Central France',
      neighbors: [162, 163, 165, 168, 171],
      border: [],
    },
    168: {
      name: 'East France',
      neighbors: [163, 166, 171, 186, 189, 262, 287, 453, 457],
      border: [],
    },
    171: {
      name: 'France Mediterranean Coast',
      neighbors: [165, 166, 168, 174, 262, 435],
      border: [],
    },
    174: {
      name: 'Corsica',
      neighbors: [171, 256, 260, 262, 443],
      border: [],
    },
    177: {
      name: 'Kakheti',
      neighbors: [45, 178, 381, 543, 544],
      border: [],
    },
    178: {
      name: 'Tbilisi',
      neighbors: [177, 543, 544],
      border: [],
    },
    179: {
      name: 'Abkhazia',
      neighbors: [180, 381, 477, 542],
      border: [],
    },
    180: {
      name: 'West Georgia',
      neighbors: [179, 541, 542, 543, 544],
      border: [],
    },
    181: {
      name: 'Berlin',
      neighbors: [182, 183],
      border: [],
    },
    182: {
      name: 'North Germany',
      neighbors: [142, 181, 183, 186, 306, 307, 347, 348, 446],
      border: [],
    },
    183: {
      name: 'Middle Germany',
      neighbors: [136, 137, 181, 182, 186, 189, 347],
      border: [],
    },
    186: {
      name: 'West Germany',
      neighbors: [56, 168, 182, 183, 189, 286, 287, 305, 306],
      border: [],
    },
    189: {
      name: 'South Germany',
      neighbors: [36, 40, 136, 168, 183, 186, 457],
      border: [],
    },
    192: {
      name: 'Athens',
      neighbors: [193, 197, 199, 200],
      border: [],
    },
    193: {
      name: 'Epirus and Thessaly',
      neighbors: [5, 7, 192, 194, 199, 200, 361, 531],
      border: [],
    },
    194: {
      name: 'Macedonia',
      neighbors: [77, 193, 200, 361, 363, 546],
      border: [],
    },
    197: {
      name: 'Peloponnese',
      neighbors: [192, 198, 199, 200],
      border: [],
    },
    198: {
      name: 'Crete Island',
      neighbors: [132, 151, 197, 200],
      border: [],
    },
    199: {
      name: 'Ionian Islands',
      neighbors: [5, 192, 193, 197, 258],
      border: [],
    },
    200: {
      name: 'Aegean Islands',
      neighbors: [132, 148, 192, 193, 194, 197, 198, 469, 471, 474],
      border: [],
    },
    201: {
      name: 'Budapest',
      neighbors: [202, 204, 207, 412],
      border: [],
    },
    202: {
      name: 'Northeast Hungary',
      neighbors: [201, 204, 373, 374, 410, 412, 482],
      border: [],
    },
    204: {
      name: 'Szeged',
      neighbors: [123, 201, 202, 205, 207, 373, 524, 550],
      border: [],
    },
    205: {
      name: 'Southern Transdanubia',
      neighbors: [122, 123, 204, 207],
      border: [],
    },
    207: {
      name: 'NorthWest Hungary',
      neighbors: [36, 122, 201, 204, 205, 412, 413, 419],
      border: [],
    },
    208: {
      name: 'New Delhi',
      neighbors: [212, 213, 215],
      border: [],
    },
    212: {
      name: 'East India',
      neighbors: [102, 208, 213, 215, 217, 219],
      border: [],
    },
    213: {
      name: 'North India',
      neighbors: [101, 102, 208, 212, 215, 217, 325, 328],
      border: [],
    },
    215: {
      name: 'West India',
      neighbors: [208, 212, 213, 217, 325],
      border: [],
    },
    217: {
      name: 'Central India',
      neighbors: [212, 213, 215, 219],
      border: [],
    },
    219: {
      name: 'South India',
      neighbors: [212, 217, 459],
      border: [],
    },
    224: {
      name: 'Jakarta',
      neighbors: [225],
      border: [],
    },
    225: {
      name: 'Java',
      neighbors: [33, 224, 226, 227, 228, 229],
      border: [],
    },
    226: {
      name: 'Sumatra',
      neighbors: [225, 228, 288, 291, 292, 409, 393],
      border: [],
    },
    227: {
      name: 'Nusatenggara Islands',
      neighbors: [32, 225, 229, 230, 231],
      border: [],
    },
    228: {
      name: 'Kalimantan',
      neighbors: [225, 226, 229, 289, 290],
      border: [],
    },
    229: {
      name: 'Sulawesi',
      neighbors: [225, 227, 228, 230, 290],
      border: [],
    },
    230: {
      name: 'Maluku Islands',
      neighbors: [227, 229, 231],
      border: [],
    },
    231: {
      name: 'Papua',
      neighbors: [31, 227, 230],
      border: [],
    },
    232: {
      name: 'Tehran',
      neighbors: [233, 234, 244],
      border: [],
    },
    233: {
      name: 'Iran Caspian Coast',
      neighbors: [43, 232, 234, 242, 244],
      border: [],
    },
    234: {
      name: 'Northwest Iran',
      neighbors: [26, 43, 45, 47, 232, 233, 236, 237, 244, 473, 475, 568, 569, 570],
      border: [],
    },
    236: {
      name: 'Southwest Iran',
      neighbors: [234, 237, 240, 241, 393, 489, 570],
      border: [],
    },
    237: {
      name: 'Central Iran',
      neighbors: [234, 236, 240, 242, 244],
      border: [],
    },
    240: {
      name: 'Kerman',
      neighbors: [236, 237, 241, 242],
      border: [],
    },
    241: {
      name: 'Sistan and Baluchestan',
      neighbors: [1, 2, 236, 240, 242, 327],
      border: [],
    },
    242: {
      name: 'Khorasan',
      neighbors: [2, 233, 237, 240, 241, 244],
      border: [],
    },
    244: {
      name: 'Semnan',
      neighbors: [232, 233, 234, 237, 242],
      border: [],
    },
    246: {
      name: 'Central Ireland',
      neighbors: [247, 248, 249, 500],
      border: [],
    },
    247: {
      name: 'Southwest Ireland',
      neighbors: [82, 246, 249],
      border: [],
    },
    248: {
      name: 'Donegal',
      neighbors: [246, 498, 500, 553],
      border: [],
    },
    249: {
      name: 'Wexford',
      neighbors: [246, 247, 493],
      border: [],
    },
    251: {
      name: 'North Israel',
      neighbors: [132, 253, 560, 556, 557, 559, 563, 564, 567],
      border: [],
    },
    253: {
      name: 'South Israel',
      neighbors: [149, 251, 255, 561, 560, 558],
      border: [],
    },
    255: {
      name: 'Jerusalem',
      neighbors: [253, 557, 558],
      border: [],
    },
    256: {
      name: 'Rome',
      neighbors: [126, 174, 258, 260, 262, 266],
      border: [],
    },
    258: {
      name: 'South Italy',
      neighbors: [5, 64, 127, 151, 199, 256, 260, 303, 467],
      border: [],
    },
    260: {
      name: 'Sardinia',
      neighbors: [14, 174, 256, 258, 435, 443, 467],
      border: [],
    },
    262: {
      name: 'Northwest Italy',
      neighbors: [168, 171, 174, 256, 266, 453, 454],
      border: [],
    },
    266: {
      name: 'Northeast Italy',
      neighbors: [39, 40, 125, 256, 262, 416, 417, 454],
      border: [],
    },
    268: {
      name: 'Central Japan',
      neighbors: [271, 274, 316, 502],
      border: [],
    },
    271: {
      name: 'Kinki',
      neighbors: [268, 272],
      border: [],
    },
    272: {
      name: 'Chugoku-Shikoku',
      neighbors: [271, 275, 431],
      border: [],
    },
    274: {
      name: 'Hokkaido',
      neighbors: [268, 390],
      border: [],
    },
    275: {
      name: 'Kyushu-Okinawa',
      neighbors: [111, 272, 355, 430, 431, 502],
      border: [],
    },
    276: {
      name: 'Riga',
      neighbors: [156, 277, 278, 280],
      border: [],
    },
    277: {
      name: 'Kurzeme',
      neighbors: [156, 276, 278, 282, 445],
      border: [],
    },
    278: {
      name: 'Zemgale un Latgale',
      neighbors: [50, 276, 277, 280, 282, 283, 388],
      border: [],
    },
    280: {
      name: 'Kidzeme',
      neighbors: [155, 156, 276, 278, 388],
      border: [],
    },
    281: {
      name: 'Vilnius',
      neighbors: [50, 51, 282, 283],
      border: [],
    },
    282: {
      name: 'Suvalkija ir Zemaitija',
      neighbors: [51, 277, 278, 281, 283, 343, 376],
      border: [],
    },
    283: {
      name: 'Aukstaitija',
      neighbors: [50, 278, 281, 282],
      border: [],
    },
    286: {
      name: 'Clervaux',
      neighbors: [56, 186, 287],
      border: [],
    },
    287: {
      name: 'Luxembourg',
      neighbors: [56, 163, 168, 186, 286],
      border: [],
    },
    288: {
      name: 'Kuala Lumpur',
      neighbors: [226, 291, 292],
      border: [],
    },
    289: {
      name: 'Sarawak',
      neighbors: [228, 290, 292],
      border: [],
    },
    290: {
      name: 'Sabah',
      neighbors: [228, 229, 289, 358],
      border: [],
    },
    291: {
      name: 'North Malaysia',
      neighbors: [226, 288, 292, 459],
      border: [],
    },
    292: {
      name: 'Johor',
      neighbors: [226, 288, 289, 291, 409],
      border: [],
    },
    294: {
      name: 'Californias',
      neighbors: [295, 297, 502, 504, 506],
      border: [],
    },
    295: {
      name: 'Sonora and Chihuahua',
      neighbors: [294, 296, 297, 506],
      border: [],
    },
    296: {
      name: 'Olmecas',
      neighbors: [295, 297, 506],
      border: [],
    },
    297: {
      name: 'Central Mexico',
      neighbors: [294, 295, 296, 298, 300],
      border: [],
    },
    298: {
      name: 'Zapotecas',
      neighbors: [115, 143, 146, 297, 300],
      border: [],
    },
    300: {
      name: 'Tulum',
      neighbors: [128, 297, 298, 528],
      border: [],
    },
    302: {
      name: 'North and Central Montenegro',
      neighbors: [6, 64, 65, 303, 406, 555],
      border: [],
    },
    303: {
      name: 'Montenegro Coast',
      neighbors: [6, 64, 127, 258, 302],
      border: [],
    },
    304: {
      name: 'West Netherlands',
      neighbors: [55, 305, 306, 307, 495],
      border: [],
    },
    305: {
      name: 'South Netherlands',
      neighbors: [55, 56, 186, 304, 306],
      border: [],
    },
    306: {
      name: 'Central Netherlands',
      neighbors: [182, 186, 304, 305, 307],
      border: [],
    },
    307: {
      name: 'North Netherlands',
      neighbors: [182, 304, 306],
      border: [],
    },
    308: {
      name: 'Wellington',
      neighbors: [309, 310],
      border: [],
    },
    309: {
      name: 'Auckland',
      neighbors: [308, 337, 502, 529],
      border: [],
    },
    310: {
      name: 'Canterbury',
      neighbors: [29, 35, 308, 311],
      border: [],
    },
    311: {
      name: 'Otago',
      neighbors: [310],
      border: [],
    },
    312: {
      name: 'Pyongyang',
      neighbors: [313, 317],
      border: [],
    },
    313: {
      name: 'Pyongnam',
      neighbors: [312, 314, 315, 317, 530],
      border: [],
    },
    314: {
      name: 'Pyongbuk',
      neighbors: [98, 313, 530],
      border: [],
    },
    315: {
      name: 'Hamgbuk and Ryanggang',
      neighbors: [98, 313, 316, 317, 530],
      border: [],
    },
    316: {
      name: 'Hamgnam',
      neighbors: [98, 268, 315, 390],
      border: [],
    },
    317: {
      name: 'Hwangbuk and Kangwon',
      neighbors: [97, 312, 313, 315, 427, 428],
      border: [],
    },
    318: {
      name: 'Ostlandet',
      neighbors: [319, 320, 321, 449],
      border: [],
    },
    319: {
      name: 'Sorlandet',
      neighbors: [140, 318, 320],
      border: [],
    },
    320: {
      name: 'Vestlandet',
      neighbors: [318, 319, 321, 498],
      border: [],
    },
    321: {
      name: 'Midt-Norge',
      neighbors: [318, 320, 322, 449],
      border: [],
    },
    322: {
      name: 'Nord-Norge',
      neighbors: [160, 321, 323, 388, 449, 451, 551],
      border: [],
    },
    323: {
      name: 'Svalbard Islands',
      neighbors: [322, 551],
      border: [],
    },
    324: {
      name: 'Islamabad',
      neighbors: [325, 328],
      border: [],
    },
    325: {
      name: 'Punjab and Sindh',
      neighbors: [213, 215, 324, 327, 328],
      border: [],
    },
    327: {
      name: 'Balochistan',
      neighbors: [1, 3, 241, 325, 328],
      border: [],
    },
    328: {
      name: 'North West Pakistan',
      neighbors: [3, 4, 100, 101, 213, 324, 325, 327],
      border: [],
    },
    330: {
      name: 'Asuncion',
      neighbors: [19, 69, 331, 526, 538, 540],
      border: [],
    },
    331: {
      name: 'Concepcion',
      neighbors: [19, 73, 330, 332, 333, 538, 539],
      border: [],
    },
    332: {
      name: 'Northeast Chaco',
      neighbors: [61, 73, 331, 333],
      border: [],
    },
    333: {
      name: 'Southwest Chaco',
      neighbors: [19, 61, 62, 331, 332, 534],
      border: [],
    },
    334: {
      name: 'Lima',
      neighbors: [337, 338, 340],
      border: [],
    },
    335: {
      name: 'Cusco',
      neighbors: [58, 59, 75, 336, 337, 338],
      border: [],
    },
    336: {
      name: 'Arequipa',
      neighbors: [58, 96, 335, 337],
      border: [],
    },
    337: {
      name: 'Nasca',
      neighbors: [309, 334, 335, 336, 338, 529],
      border: [],
    },
    338: {
      name: 'Pucallpa District',
      neighbors: [75, 334, 335, 337, 339, 340],
      border: [],
    },
    339: {
      name: 'Iquitos',
      neighbors: [75, 116, 117, 144, 145, 338, 340, 341, 533],
      border: [],
    },
    340: {
      name: 'Trujillo District',
      neighbors: [334, 338, 339, 341],
      border: [],
    },
    341: {
      name: 'Piura District',
      neighbors: [144, 146, 339, 340],
      border: [],
    },
    342: {
      name: 'Warszawa',
      neighbors: [343, 344, 345, 346, 527],
      border: [],
    },
    343: {
      name: 'Masuria',
      neighbors: [51, 52, 282, 342, 344, 346, 348, 376],
      border: [],
    },
    344: {
      name: 'Polesia',
      neighbors: [52, 342, 343, 345, 410, 482],
      border: [],
    },
    345: {
      name: 'Krakow',
      neighbors: [135, 342, 344, 346, 347, 410, 412, 527],
      border: [],
    },
    346: {
      name: 'Central Poland',
      neighbors: [342, 343, 345, 347, 348, 527],
      border: [],
    },
    347: {
      name: 'Silesia',
      neighbors: [135, 137, 182, 183, 345, 346, 348, 527],
      border: [],
    },
    348: {
      name: 'Pomerania',
      neighbors: [142, 182, 343, 346, 347, 376, 446],
      border: [],
    },
    350: {
      name: 'Portugal Atlantic Islands',
      neighbors: [74, 82, 351, 352, 353, 444, 521],
      border: [],
    },
    351: {
      name: 'North Portugal',
      neighbors: [83, 84, 350, 352, 439, 440],
      border: [],
    },
    352: {
      name: 'Central Portugal',
      neighbors: [83, 350, 351, 353, 440],
      border: [],
    },
    353: {
      name: 'South Portugal',
      neighbors: [350, 352, 440, 442, 444],
      border: [],
    },
    355: {
      name: 'Taipei',
      neighbors: [111, 275, 356, 357, 430],
      border: [],
    },
    356: {
      name: 'Hualien and Taitung',
      neighbors: [355, 357, 358],
      border: [],
    },
    357: {
      name: 'Central Taiwan',
      neighbors: [355, 356, 358],
      border: [],
    },
    358: {
      name: 'Tainan',
      neighbors: [112, 290, 356, 357],
      border: [],
    },
    359: {
      name: 'Skopje',
      neighbors: [360, 361, 362, 404, 555],
      border: [],
    },
    360: {
      name: 'Western Macedonia',
      neighbors: [6, 7, 359, 361, 531, 555],
      border: [],
    },
    361: {
      name: 'Pelagonia',
      neighbors: [193, 194, 359, 360, 362, 363, 531],
      border: [],
    },
    362: {
      name: 'Northeastern Macedonia',
      neighbors: [76, 77, 359, 361, 363, 404],
      border: [],
    },
    363: {
      name: 'Southeastern Macedonia',
      neighbors: [77, 194, 361, 362],
      border: [],
    },
    364: {
      name: 'Central Moldova',
      neighbors: [365, 366, 370, 480],
      border: [],
    },
    365: {
      name: 'North Moldova',
      neighbors: [364, 370, 371, 480, 482],
      border: [],
    },
    366: {
      name: 'South Moldova',
      neighbors: [364, 368, 370, 480],
      border: [],
    },
    367: {
      name: 'Bucuresti',
      neighbors: [78, 79, 368, 369, 370, 372],
      border: [],
    },
    368: {
      name: 'Dobrogea',
      neighbors: [79, 80, 366, 367, 370, 472, 477, 480],
      border: [],
    },
    369: {
      name: 'Oltenia',
      neighbors: [78, 367, 372, 403, 524],
      border: [],
    },
    370: {
      name: 'Iasi District',
      neighbors: [364, 365, 366, 367, 368, 371, 372],
      border: [],
    },
    371: {
      name: 'Bucovina',
      neighbors: [365, 370, 372, 374, 482],
      border: [],
    },
    372: {
      name: 'Transylvania',
      neighbors: [367, 369, 370, 371, 373, 374, 524],
      border: [],
    },
    373: {
      name: 'Crisana',
      neighbors: [202, 204, 372, 374, 524],
      border: [],
    },
    374: {
      name: 'Maramures',
      neighbors: [202, 371, 372, 373, 482],
      border: [],
    },
    375: {
      name: 'Moscow',
      neighbors: [381, 388],
      border: [],
    },
    376: {
      name: 'Kaliningrad',
      neighbors: [282, 343, 348],
      border: [],
    },
    379: {
      name: 'Sankt Petersburg',
      neighbors: [157, 388],
      border: [],
    },
    381: {
      name: 'Rostov-on-Don',
      neighbors: [43, 45, 50, 53, 177, 179, 375, 386, 388, 477, 478, 542, 543],
      border: [],
    },
    386: {
      name: 'Siberia',
      neighbors: [98, 100, 381, 388, 390],
      border: [],
    },
    388: {
      name: 'Northwest Russia',
      neighbors: [50, 153, 155, 157, 158, 160, 278, 280, 322, 375, 379, 381, 386],
      border: [],
    },
    390: {
      name: 'Vladivostok',
      neighbors: [98, 274, 316, 386, 503],
      border: [],
    },
    392: {
      name: 'Riyadh',
      neighbors: [393, 394, 395, 399],
      border: [],
    },
    393: {
      name: 'Eastern Province',
      neighbors: [236, 392, 394, 399, 486, 226, 570],
      border: [],
    },
    394: {
      name: 'South Arabia',
      neighbors: [392, 393, 395],
      border: [],
    },
    395: {
      name: 'Western Arabia',
      neighbors: [149, 392, 394, 399],
      border: [],
    },
    399: {
      name: 'Northern Arabia',
      neighbors: [392, 393, 395, 561, 560, 559, 570, 569],
      border: [],
    },
    402: {
      name: 'Belgrade',
      neighbors: [403, 405, 407, 408],
      border: [],
    },
    403: {
      name: 'Eastern Serbia',
      neighbors: [78, 369, 402, 404, 405, 408, 524],
      border: [],
    },
    404: {
      name: 'Southern Serbia',
      neighbors: [76, 78, 359, 362, 403, 405, 406, 555],
      border: [],
    },
    405: {
      name: 'Rasina and Toplica',
      neighbors: [402, 403, 404, 407, 555],
      border: [],
    },
    406: {
      name: 'Raska',
      neighbors: [65, 302, 405, 407, 555],
      border: [],
    },
    407: {
      name: 'Podrinje and Posavina',
      neighbors: [65, 66, 402, 405, 406, 408],
      border: [],
    },
    408: {
      name: 'Vojvodina',
      neighbors: [66, 123, 402, 403, 407, 524, 550],
      border: [],
    },
    409: {
      name: 'Singapore',
      neighbors: [226, 292],
      border: [],
    },
    410: {
      name: 'Kosice',
      neighbors: [202, 344, 345, 412, 482],
      border: [],
    },
    412: {
      name: 'Central Slovakia',
      neighbors: [36, 135, 201, 202, 207, 345, 410, 413],
      border: [],
    },
    413: {
      name: 'Bratislava',
      neighbors: [36, 207, 412],
      border: [],
    },
    414: {
      name: 'Ljubljana',
      neighbors: [417, 548],
      border: [],
    },
    415: {
      name: 'Notranjska',
      neighbors: [125, 416, 417, 548],
      border: [],
    },
    416: {
      name: 'Primorska',
      neighbors: [125, 266, 415, 417],
      border: [],
    },
    417: {
      name: 'Gorenjska',
      neighbors: [39, 266, 414, 415, 416, 418, 548],
      border: [],
    },
    418: {
      name: 'Stajerska',
      neighbors: [39, 121, 122, 417, 419, 547, 548, 549],
      border: [],
    },
    419: {
      name: 'Prekmurje',
      neighbors: [36, 39, 122, 207, 418],
      border: [],
    },
    420: {
      name: 'Pretoria and Johannesburg',
      neighbors: [424, 425],
      border: [],
    },
    421: {
      name: 'Northern Cape',
      neighbors: [70, 422, 423, 424, 11],
      border: [],
    },
    422: {
      name: 'Cape Town',
      neighbors: [17, 421, 423, 537],
      border: [],
    },
    423: {
      name: 'Eastern Cape',
      neighbors: [421, 422, 424, 532],
      border: [],
    },
    424: {
      name: 'Free State',
      neighbors: [420, 421, 423, 425, 532],
      border: [],
    },
    425: {
      name: 'Limpopo and Mpumalanga',
      neighbors: [420, 424, 532, 151],
      border: [],
    },
    427: {
      name: 'Gyeonggi-do',
      neighbors: [317, 428, 429],
      border: [],
    },
    428: {
      name: 'Gangwon-do',
      neighbors: [317, 427, 429, 431],
      border: [],
    },
    429: {
      name: 'Chungcheongnam-do',
      neighbors: [427, 428, 430, 431],
      border: [],
    },
    430: {
      name: 'Jeollanam-do',
      neighbors: [111, 275, 355, 429, 431],
      border: [],
    },
    431: {
      name: 'Gyeongsangnam-do',
      neighbors: [272, 275, 428, 429, 430],
      border: [],
    },
    433: {
      name: 'Madrid',
      neighbors: [434, 440, 442],
      border: [],
    },
    434: {
      name: 'Valencia and Aragon',
      neighbors: [10, 165, 433, 435, 437, 440, 442, 443],
      border: [],
    },
    435: {
      name: 'Catalonia',
      neighbors: [165, 171, 260, 434, 443],
      border: [],
    },
    437: {
      name: 'Cantabrian Cornice',
      neighbors: [165, 434, 439, 440],
      border: [],
    },
    439: {
      name: 'Galicia',
      neighbors: [351, 437, 440],
      border: [],
    },
    440: {
      name: 'Castilla-Leon and Extremadura',
      neighbors: [351, 352, 353, 433, 434, 437, 439, 442],
      border: [],
    },
    442: {
      name: 'Andalucia',
      neighbors: [10, 353, 433, 434, 440, 444],
      border: [],
    },
    443: {
      name: 'Balearic Islands',
      neighbors: [10, 14, 174, 260, 434, 435],
      border: [],
    },
    444: {
      name: 'Canary Islands',
      neighbors: [11, 71, 350, 353, 442, 521],
      border: [],
    },
    445: {
      name: 'Stockholm samt Gothland Island',
      neighbors: [156, 161, 277, 446, 449],
      border: [],
    },
    446: {
      name: 'Sydvast',
      neighbors: [140, 141, 142, 182, 348, 445, 449],
      border: [],
    },
    449: {
      name: 'Central Sweden',
      neighbors: [159, 318, 321, 322, 445, 446, 451],
      border: [],
    },
    451: {
      name: 'Norrbotten',
      neighbors: [159, 160, 322, 449],
      border: [],
    },
    453: {
      name: 'Bern und WestSchweiz',
      neighbors: [168, 262, 454, 457],
      border: [],
    },
    454: {
      name: 'Graubunden and Ticino',
      neighbors: [40, 262, 266, 453, 457],
      border: [],
    },
    457: {
      name: 'Zurich',
      neighbors: [40, 168, 189, 453, 454],
      border: [],
    },
    459: {
      name: 'Southern Thailand',
      neighbors: [291, 460, 219],
      border: [],
    },
    460: {
      name: 'Western Thailand',
      neighbors: [459, 461, 462, 463],
      border: [],
    },
    461: {
      name: 'Northern Thailand',
      neighbors: [112, 460, 462],
      border: [],
    },
    462: {
      name: 'Isan',
      neighbors: [460, 461, 463],
      border: [],
    },
    463: {
      name: 'Central Thailand',
      neighbors: [460, 462],
      border: [],
    },
    464: {
      name: 'South Tunisia',
      neighbors: [13, 465, 466],
      border: [],
    },
    465: {
      name: 'Sfax',
      neighbors: [151, 464, 466, 467, 571],
      border: [],
    },
    466: {
      name: 'Gafsa',
      neighbors: [13, 16, 464, 465, 467],
      border: [],
    },
    467: {
      name: 'Tunisia',
      neighbors: [14, 16, 258, 260, 465, 466],
      border: [],
    },
    468: {
      name: 'Ankara',
      neighbors: [472, 473, 474, 525],
      border: [],
    },
    469: {
      name: 'Edirne District',
      neighbors: [80, 81, 200, 470, 471, 573],
      border: [],
    },
    470: {
      name: 'Istanbul',
      neighbors: [469, 525],
      border: [],
    },
    471: {
      name: 'Turkey Aegean Coast',
      neighbors: [200, 469, 474, 525],
      border: [],
    },
    472: {
      name: 'Turkey Black Sea Coast',
      neighbors: [368, 468, 473, 477, 525, 541],
      border: [],
    },
    473: {
      name: 'Eastern Anatolia',
      neighbors: [25, 27, 234, 468, 472, 474, 475, 541, 544],
      border: [],
    },
    474: {
      name: 'Turkey Meditarean Coast',
      neighbors: [133, 200, 468, 471, 473, 475, 525, 565],
      border: [],
    },
    475: {
      name: 'Southeastern Anatolia',
      neighbors: [234, 473, 474, 565, 568],
      border: [],
    },
    476: {
      name: 'Dnipro',
      neighbors: [53, 478, 479, 480, 482],
      border: [],
    },
    477: {
      name: 'Crimea',
      neighbors: [80, 179, 368, 381, 472, 479, 480],
      border: [],
    },
    478: {
      name: 'Northeast Ukraine',
      neighbors: [53, 381, 476, 479],
      border: [],
    },
    479: {
      name: 'Zaporozhia',
      neighbors: [476, 477, 478, 480],
      border: [],
    },
    480: {
      name: 'Southwest Ukraine',
      neighbors: [364, 365, 366, 368, 476, 477, 479, 482],
      border: [],
    },
    482: {
      name: 'Northwest Ukraine',
      neighbors: [52, 53, 202, 344, 365, 371, 374, 410, 476, 480],
      border: [],
    },
    486: {
      name: 'Abu Dhabi',
      neighbors: [393, 488],
      border: [],
    },
    488: {
      name: 'Central Emirates',
      neighbors: [486, 489],
      border: [],
    },
    489: {
      name: 'North Emirates',
      neighbors: [236, 488],
      border: [],
    },
    490: {
      name: 'London',
      neighbors: [494, 495],
      border: [],
    },
    493: {
      name: 'Wales',
      neighbors: [249, 494, 500],
      border: [],
    },
    494: {
      name: 'West England',
      neighbors: [165, 490, 493, 495, 498],
      border: [],
    },
    495: {
      name: 'East England',
      neighbors: [55, 163, 304, 490, 494, 498],
      border: [],
    },
    498: {
      name: 'Scotland',
      neighbors: [248, 320, 494, 495, 500, 553],
      border: [],
    },
    500: {
      name: 'Northern Ireland',
      neighbors: [246, 248, 493, 498],
      border: [],
    },
    502: {
      name: 'Hawaii Islands',
      neighbors: [85, 146, 268, 275, 294, 309, 503, 504, 529],
      border: [],
    },
    503: {
      name: 'Alaska',
      neighbors: [85, 390, 502],
      border: [],
    },
    504: {
      name: 'USA Pacific Coast',
      neighbors: [85, 294, 502, 506],
      border: [],
    },
    506: {
      name: 'Central States',
      neighbors: [85, 294, 295, 296, 504, 508, 509],
      border: [],
    },
    508: {
      name: 'Mid South States',
      neighbors: [506, 509, 510, 512],
      border: [],
    },
    509: {
      name: 'Mid West States',
      neighbors: [84, 85, 506, 508, 512],
      border: [],
    },
    510: {
      name: 'Southeast States',
      neighbors: [128, 129, 508, 512],
      border: [],
    },
    512: {
      name: 'Northeast States',
      neighbors: [82, 83, 84, 508, 509, 510],
      border: [],
    },
    514: {
      name: 'Montevideo',
      neighbors: [515, 516, 536, 537],
      border: [],
    },
    515: {
      name: 'Melo',
      neighbors: [68, 514, 516, 537],
      border: [],
    },
    516: {
      name: 'Tacuarembo',
      neighbors: [18, 68, 514, 515, 526, 536],
      border: [],
    },
    517: {
      name: 'Caracas',
      neighbors: [518, 520, 521, 522],
      border: [],
    },
    518: {
      name: 'Maracaibo',
      neighbors: [120, 517, 520],
      border: [],
    },
    520: {
      name: 'Barinas District',
      neighbors: [118, 119, 120, 517, 518, 522],
      border: [],
    },
    521: {
      name: 'Northeast Venezuela',
      neighbors: [75, 350, 444, 517, 522],
      border: [],
    },
    522: {
      name: 'Southeast Venezuela',
      neighbors: [75, 118, 517, 520, 521],
      border: [],
    },
    524: {
      name: 'Banat',
      neighbors: [204, 369, 372, 373, 403, 408],
      border: [],
    },
    525: {
      name: 'Central Turkey',
      neighbors: [468, 470, 471, 472, 474],
      border: [],
    },
    526: {
      name: 'Corrientes and Missiones',
      neighbors: [18, 19, 21, 68, 69, 330, 516],
      border: [],
    },
    527: {
      name: 'Lodz',
      neighbors: [342, 345, 346, 347],
      border: [],
    },
    528: {
      name: 'San Andres',
      neighbors: [120, 129, 300],
      border: [],
    },
    529: {
      name: 'Rapa Nui',
      neighbors: [95, 146, 309, 337, 502],
      border: [],
    },
    530: {
      name: 'Chagang',
      neighbors: [98, 313, 314, 315],
      border: [],
    },
    531: {
      name: 'Ohrid',
      neighbors: [7, 193, 360, 361],
      border: [],
    },
    532: {
      name: 'Kwazulu Natal',
      neighbors: [33, 423, 424, 425],
      border: [],
    },
    533: {
      name: 'Oriente',
      neighbors: [143, 144, 145, 339],
      border: [],
    },
    534: {
      name: 'Chuquisaca',
      neighbors: [58, 61, 62, 333, 535],
      border: [],
    },
    535: {
      name: 'Potosi',
      neighbors: [20, 58, 62, 96, 534],
      border: [],
    },
    536: {
      name: 'Rio De La Plata',
      neighbors: [17, 18, 514, 516],
      border: [],
    },
    537: {
      name: 'Atlantic Coast Of Uruguay',
      neighbors: [68, 422, 514, 515],
      border: [],
    },
    538: {
      name: 'San Pedro',
      neighbors: [330, 331, 539, 540],
      border: [],
    },
    539: {
      name: 'Amambay',
      neighbors: [73, 331, 538, 540],
      border: [],
    },
    540: {
      name: 'Canindeyu',
      neighbors: [69, 73, 330, 538, 539],
      border: [],
    },
    541: {
      name: 'Adjara',
      neighbors: [180, 472, 473, 544],
      border: [],
    },
    542: {
      name: 'Svaneti',
      neighbors: [179, 180, 381, 543],
      border: [],
    },
    543: {
      name: 'Samachablo',
      neighbors: [177, 178, 180, 381, 542, 544],
      border: [],
    },
    544: {
      name: 'Kartli',
      neighbors: [27, 45, 177, 178, 180, 473, 541, 543],
      border: [],
    },
    545: {
      name: 'Valley of Roses',
      neighbors: [76, 77, 78, 79, 80, 81],
      border: [],
    },
    546: {
      name: 'Rhodopes',
      neighbors: [77, 81, 194, 573],
      border: [],
    },
    547: {
      name: 'Koroska',
      neighbors: [39, 418],
      border: [],
    },
    548: {
      name: 'Dolenjska',
      neighbors: [124, 125, 414, 415, 417, 418, 549],
      border: [],
    },
    549: {
      name: 'Central Croatia',
      neighbors: [67, 121, 122, 123, 124, 418, 548],
      border: [],
    },
    550: {
      name: 'Backa and Banat',
      neighbors: [123, 204, 402, 403, 524, 550, 408],
      border: [],
    },
    551: {
      name: 'North East Greenland',
      neighbors: [552, 553, 554, 323, 322],
      border: [],
    },
    552: {
      name: 'North West Greenland',
      neighbors: [551, 553, 554, 90],
      border: [],
    },
    553: {
      name: 'South East Greenland',
      neighbors: [551, 552, 554, 498, 248, 141],
      border: [],
    },
    554: {
      name: 'South West Greenland',
      neighbors: [551, 552, 553, 82],
      border: [],
    },
    555: {
      name: 'Kosovo',
      neighbors: [6, 302, 404, 405, 406, 359, 360],
      border: [],
    },
    556: {
      name: 'North Palestine',
      neighbors: [251, 556, 560, 557],
      border: [],
    },
    557: {
      name: 'East Jerusalem',
      neighbors: [556, 558, 251, 253, 255, 560],
      border: [],
    },
    558: {
      name: 'South Palestine',
      neighbors: [557, 255, 253, 560],
      border: [],
    },
    559: {
      name: 'Al Mafraq',
      neighbors: [251, 399, 560, 567, 569],
      border: [],
    },
    560: {
      name: 'Amman',
      neighbors: [557, 253, 561, 556, 560, 558, 251, 399, 559],
      border: [],
    },
    561: {
      name: 'Maan',
      neighbors: [253, 253, 399, 560],
      border: [],
    },
    562: {
      name: 'Gaza',
      neighbors: [253, 149],
      border: [],
    },
    563: {
      name: 'Beirut',
      neighbors: [565, 251, 564],
      border: [],
    },
    564: {
      name: 'East Lebanon',
      neighbors: [567, 565, 251, 563],
      border: [],
    },
    565: {
      name: 'Hama',
      neighbors: [563, 564, 567, 566, 474, 475, 568],
      border: [],
    },
    566: {
      name: 'East Syria',
      neighbors: [565, 567, 568, 569],
      border: [],
    },
    567: {
      name: 'Damascus',
      neighbors: [251, 564, 559, 565, 566],
      border: [],
    },
    568: {
      name: 'Erbil',
      neighbors: [565, 475, 568, 566, 234, 569],
      border: [],
    },
    569: {
      name: 'Bagdad',
      neighbors: [570, 234, 568, 566, 559, 399],
      border: [],
    },
    570: {
      name: 'Basrah',
      neighbors: [569, 234, 236, 393, 399],
      border: [],
    },
    571: {
      name: 'Tirana',
      neighbors: [5, 6, 7, 572, 465],
      border: [],
    },
    572: {
      name: 'Elbasan',
      neighbors: [5, 7, 571],
      border: [],
    },
    573: {
      name: 'Thrace',
      neighbors: [469, 194, 546],
      border: [],
    },
  };
};
