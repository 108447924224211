// @ts-nocheck
import { LastHitsProps } from '../../../shared-types/shared-types';
import React, { FC, useEffect, useState } from 'react';
import { fromCDN } from '../../../helpers/Images';
import { Avatar } from '../../../components/Avatar';
import { numberWithCommas } from '../../../helpers/HelpersWithTypes';
import { GenerateRandomNumber } from '../../../helpers/generate-random-number';
import { resolveHitFlow } from './resolve-hit-flow';
import { useBattlefield } from '../hooks/use-battlefield';
import { useUser } from '../../../hooks/use-user';

const gifIcons = {
  min: 1,
  max: 7,
};

const maxPresentationOfGifs = 10;

const getByDataId = ({ userId, damage, isAttacker }) => {
  const div = document.querySelector(`[lasthit-id="${userId as string}${isAttacker as string}"]`);

  if (div) {
    const div = document.querySelector(`[lasthit-damage-id="${userId as string}${isAttacker as string}"]`);
    div.textContent = numberWithCommas(damage);
    div.className = 'animate__animated animate__fadeIn';
    return true;
  }

  return false;
};
/* eslint-disable  */
// @ts-ignore
export const LastHits: FC<LastHitsProps[]> = ({ lastHits, isAttacker = true, round, citizenDetails }) => {
  // eslint-disable-next-line react/hook-use-state
  const [lastHitsFlow, setLastHitsFLow] = useState([]);
  // eslint-disable-next-line react/hook-use-state
  const [rerenderSignal, setRerenderSignal] = useState(false);
  const { citizenDamage } = useBattlefield();
  const { user } = useUser();
  const sideLabel = isAttacker ? 'att' : 'def';

  function getKeyByValue(object, value) {
    let val = false;
    object.map((values) => {
      Object.keys(values).find((key) => {
        if (values[key] === value) {
          val = true;
        }
      });
    });
    return val;
  }

  let tmpObject = [];
  let lastId = 0;
  let counterState = 0;

  function mergeObjectsInUnique(array) {
    array.forEach((value) => {
      if (!getKeyByValue(tmpObject, value.U_ID)) {
        if (tmpObject.length > 7) {
          tmpObject.splice(0, 1);
        }
        tmpObject.push(value);
      }
      tmpObject.forEach((tmpValue) => {
        if (lastId < value.ID) {
          if (value.U_ID === tmpValue.U_ID) {
            if (value.U_ID === user.id) {
              tmpValue.DMG = numberWithCommas(citizenDamage[isAttacker ? 1 : 2][0].T_DMG);
            } else {
              tmpValue.DMG += value.DMG;
            }
          }
        } else {
          tmpValue.DMG = numberWithCommas(tmpValue.DMG + value.DMG);
        }

        lastId = tmpValue.ID;
      });
    });
    return tmpObject;
  }

  const attackerBattleFieldPresentation = (orderState: number, hitDetails: LastHitsProps['lastHits'], round) => {
    const hitFlow = resolveHitFlow({
      side: isAttacker ? 1 : 2,
      round: round,
    });

    const animation = hitFlow.animation;
    const divClasses = hitFlow.class;
    const fightImages = hitFlow.image;

    return (
      <div
        key={hitDetails.U_ID}
        id={'id-' + hitDetails.U_ID + ',animate'}
        className={divClasses + counterState + ' ' + animation + ' text-center justify-center'}
      >
        <div className="text-gray-50 inline-block text-center justify-center">
          <div lasthit-id={hitDetails.U_ID + sideLabel}>
            <Avatar
              avatarId={hitDetails.U_AVATAR}
              userId={String(hitDetails.U_ID)}
              className="h-6 w-6 cursor-pointer rounded-md transition transform hover:scale-105  mx-auto  -mb-5 bg-yellow-500 shadow-lg shadow-yellow-500/50 "
              defaultLinkClass=""
              defaultImageClass=""
            />
            <div
              style={{
                backgroundImage: `url(${fromCDN.fetchStaticImage('hitmodal1')})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <p className=" left-12 text-gray-300 pt-5 truncate">{`${hitDetails.U_NAME}`.substring(0, 11)}</p>
              <p className=" top-2 mt-1 left-12 break-words whitespace-nowrap drop-shadow-2xl text-yellow-400 font-medium rounded-xl animate__animated animate__tada animate__delay-1s">
                <span lasthit-damage-id={hitDetails.U_ID + sideLabel}>{numberWithCommas(hitDetails.DMG)}</span>
              </p>
            </div>
            <img
              className="mx-auto block -mt-10 ml-5"
              key={orderState + hitDetails.ID}
              src={fromCDN.fetchStaticGameImage(
                fightImages + Number(GenerateRandomNumber(gifIcons.min, gifIcons.max)),
                'gif',
              )}
            />
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const mergedHits = mergeObjectsInUnique(lastHits);

    const timer = setInterval(() => {
      const updateHits = () => {
        // @ts-ignore
        if (
          !getByDataId({
            userId: mergedHits[counterState].U_ID,
            damage: mergedHits[counterState].DMG,
            isAttacker: sideLabel,
          })
        ) {
          lastHitsFlow.push(attackerBattleFieldPresentation(counterState, mergedHits[counterState], round));
        }
        counterState++;
        setRerenderSignal((prevState) => !prevState);
        setLastHitsFLow((prevState) => lastHitsFlow);
      };

      if (mergedHits.length <= counterState || counterState >= maxPresentationOfGifs) {
        return;
      }

      updateHits();
    }, 500);

    return () => {
      clearInterval(timer); // Return a function to clear the timer so that it will stop being called on unmount
    };
  }, [lastHits]);
  useEffect(() => {}, [rerenderSignal]);
  return <div>{lastHitsFlow}</div>;
};
/* eslint  2 */ // --> ON
