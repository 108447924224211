import axios from 'axios';
import Cookies from 'universal-cookie';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const TOKEN_KEY = 'bearertoken';

const api = axios.create({ baseURL: BASE_URL });

const cookies = new Cookies();
const BEARER = cookies.get(TOKEN_KEY) as string;

export const authApi = axios.create({ baseURL: BASE_URL });
authApi.defaults.headers.common.Authorization = `Bearer ${BEARER}`;
export const setAuthToken = (token: string) => {
  authApi.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default api;
