import { ChangeEvent } from 'react';

interface GetMaxInputValueProps {
  getDecimal?: boolean;
}

export const getMaxInputValue = (
  e: ChangeEvent<HTMLInputElement>,
  { getDecimal = false } = {} as GetMaxInputValueProps,
) => {
  const { value, max } = e.target;
  if (getDecimal) {
    return Math.min(Number.parseFloat(value.length ? value : '0'), Number.parseFloat(max));
  }

  return Math.min(Number.parseInt(value.length ? value : '0'), Number.parseInt(max));
};
