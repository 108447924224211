import { authApi } from '../../../api';
import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

interface SettingsApi {
  Events: [
    {
      id: number;
      event_name: string;
      from_day: number;
      to_day: number;
      is_active: number;
    },
  ];
}

interface HandleEventChangesProps {
  eventId: number;
  details: {
    fromDay?: number;
    toDay?: number;
    isActive?: number;
  };
}

const Settings = () => {
  const [settingsApi, setSettingsApi] = useState<null | SettingsApi>();
  const [eventDetails, setEventDetails] = useState<HandleEventChangesProps>({
    eventId: 0,
    details: {
      fromDay: 1,
      toDay: 1,
      isActive: 0,
    },
  });
  const getSettings = () => {
    authApi.get('/api/admin/settings').then((resp: AxiosResponse<SettingsApi>) => {
      setSettingsApi(resp.data);
    });
  };

  const changeSettings = (params: object) => {
    authApi
      .post('api/admin/settings/change/event', params)
      .then((resp: AxiosResponse<SettingsApi['Events']>) => {
        setSettingsApi({
          Events: resp.data,
        });
      })
      .catch((err) => {});
  };

  const handleEventChanges = ({ eventId, details }: HandleEventChangesProps) => {
    setEventDetails((prevState) => {
      return {
        eventId: eventId,
        details: {
          ...prevState.details,
          ...details,
        },
      };
    });
  };

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {}, [eventDetails, settingsApi]);

  const isActiveClass = (active: number): string => {
    return active === 1 ? 'bg-gradient-to-r from-green-500 to-green-700' : 'bg-gradient-to-r from-red-500 to-red-700';
  };
  return (
    <>
      <div className="text-gray-900">
        <div className="grid grid-cols-1 sm:grid-cols-3 pt-3">
          {settingsApi?.Events.map((event) => {
            return (
              <div key={event.id} className={'p-3 rounded-lg ' + isActiveClass(event.is_active)}>
                <p className="p-3 font-bold text-black ">{event.event_name}</p>
                <div className="p-3 text-center text-gray-900 font-bold">
                  <p>Current status</p>
                  <p>{event.is_active === 1 ? 'Active' : 'Inactive'}</p>
                  <p>From Day: {event.from_day}</p>
                  <p>To Day: {event.to_day}</p>
                </div>
                <input
                  className="default-input  p-3"
                  onChange={(e) => {
                    handleEventChanges({
                      eventId: event.id,
                      details: {
                        fromDay: Number(e.target.value),
                      },
                    });
                  }}
                  placeholder="From Day"
                />
                <input
                  className="default-input  p-3"
                  onChange={(e) => {
                    handleEventChanges({
                      eventId: event.id,
                      details: {
                        toDay: Number(e.target.value),
                      },
                    });
                  }}
                  placeholder="To Day"
                />
                <input
                  className="default-input  p-3"
                  onChange={(e) => {
                    handleEventChanges({
                      eventId: event.id,
                      details: {
                        isActive: Number(e.target.value),
                      },
                    });
                  }}
                  placeholder="Should be active ? "
                  type="number"
                  min={1}
                  max={2}
                />
                <button
                  className="default-save-button"
                  onClick={() => {
                    changeSettings(eventDetails);
                  }}
                >
                  Change settings
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Settings;
