import moment from 'moment';

import axios from '../../../api';
import { getFromLocalStorage, useLocalStorageAsCache } from '../../../helpers/LocalStorage';
import { GLOBAL_CONSTANTS } from '../../../constants/enum';
import { Dispatch, SetStateAction } from 'react';
import { LandingTopArticlesProps } from '../LandingTopArticles';

const CACHE_EXPIRATION = GLOBAL_CONSTANTS.CACHE_EXPIRATION;
const TOP_ARTICLES = GLOBAL_CONSTANTS.LOCALSTORAGE_KEYS.TOP_ARTICLES;
export interface RegisterOrFetchTopArticlesProps {
  setTopArticles: Dispatch<SetStateAction<LandingTopArticlesProps[] | undefined>>;
}

interface RegisterTopArticlesProps {
  data: LandingTopArticlesProps[];
  expiration: number;
}
export const registerTopArticlesOrSetIfExist = ({ setTopArticles }: RegisterOrFetchTopArticlesProps) => {
  const topArticles: RegisterTopArticlesProps | null = getFromLocalStorage(TOP_ARTICLES);

  if (topArticles && moment().unix() <= topArticles.expiration) {
    setTopArticles(topArticles.data);
    return;
  }
  setTopArticles(undefined);

  axios
    .get('/api/toparticles')
    .then(({ data }) => {
      setTopArticles(data);
      /* eslint-disable  */
      useLocalStorageAsCache(CACHE_EXPIRATION, TOP_ARTICLES, data);
    })
    .catch(() => {
      setTopArticles(undefined);
    });
};
