import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../hooks/use-user';

export default function Unemployed({ unemployedImage, t }) {
  const { user } = useUser();
  let navigate = useNavigate();

  return (
    <div
      className={
        ' w-4/4 text-center justify-center p-3 m-5 transition ease-in-out  duration-200 hover:scale-105 text-white'
      }
    >
      <div className={''}>
        <img className={'mx-auto'} src={unemployedImage} />
        <p>{t('GAME_UNEMPLOYED')}</p>

        <p className={'my-5 text-gray-50'} onClick={() => navigate('/business/jobs/' + user.COUNTRY + '/1')}>
          <button className="py-2 px-3 bg-green-600 hover:bg-green-800 rounded-lg light-transition">
            {t('GAME_FIND_JOB')}
          </button>
        </p>
      </div>
    </div>
  );
}
