import React from 'react';
import { authApi } from '../api';
import { Loader } from './Loader';

interface PagionationProps {
  items: {
    path: string;
    current_page: number;
    last_page: number;
  };
  setItems: (arg: any) => void;
  setLoader?: (arg: any) => void;
}
export const Pagination = ({ items, setItems, setLoader }: PagionationProps) => {
  const changePage = (requestedPage: string | number) => {
    if (requestedPage > items.last_page || requestedPage < 1) {
      return;
    }

    authApi
      .get(`${items.path}?page=${requestedPage}`)
      .then((response) => {
        setItems(response.data);
        if (setLoader) {
          setLoader(<Loader className="mx-auto" />);
        }
      })
      .catch((error) => {});
  };

  const getPagination = () => {
    let view = [];

    for (let i = 1; i <= items.last_page; i++) {
      if (items.current_page === i) {
        view.push([
          <a
            key={i}
            aria-current="page"
            onClick={() => {
              changePage(i);
            }}
            className="cursor-pointer z-10 bg-gray-800  hover:bg-gray-700 text-gray-100 relative inline-flex items-center px-4 py-2  text-sm font-medium"
          >
            {i}
          </a>,
        ]);
      } else {
        view.push([
          <a
            key={i}
            aria-current="page"
            onClick={() => {
              changePage(i);
            }}
            className="cursor-pointer z-10 bg-gray-800  hover:bg-gray-700 text-gray-100 relative inline-flex items-center px-4 py-2  text-sm font-medium"
          >
            {i}
          </a>,
        ]);
      }
    }
    view.splice(items.current_page + 3, items.current_page + items.last_page);

    return view;
  };
  return (
    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <a
            onClick={() => {
              changePage(items.current_page - 1);
            }}
            className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-800 bg-yellow-700 text-sm font-medium text-gray-200 hover:bg-yellow-600"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </a>
          {getPagination()}

          <a
            onClick={() => {
              changePage(items.current_page + 1);
            }}
            className="relative inline-flex cursor-pointer items-center px-2 py-2 rounded-r-md border border-gray-800 bg-yellow-700 text-sm font-medium text-gray-200 hover:bg-yellow-600"
          >
            <span className="sr-only">Next</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </nav>
      </div>
    </div>
  );
};
