import React, { Dispatch, FC, Fragment, useEffect, useState } from 'react';
import { BattlefieldTypesApiProps } from '../../../shared-types/battlefield-types';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../hooks/use-user';
import { useHelper } from '../../../hooks/use-helper';
import { useBattlefield } from '../hooks/use-battlefield';
import { getRoundAvailableItems, numberFormat } from '../../../helpers/Helpers';
import { Loader } from '../../../components/Loader';
import StorageImage from '../../../components/StorageImage';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { ToastNotifications } from '../../../components/ToastNotifications';
import {
  battlefieldAttackerWeaponsStyle,
  battlefieldDefenderWeaponsStyle,
  energyBarAttackerStyle,
  energyBarDefenderStyle,
  HitButtonAttackerFrame,
  HitButtonDefenderFrame,
  HitButtonFightAttackerImage,
  HitButtonFightDefenderImage,
} from '../Styles/BattlefieldStyles';
import { AppModal } from '../../../components/AppModal';
import { FightSummary } from './FightSummary';

import { BATTLE_SIDES, GLOBAL_CONSTANTS } from '../../../constants/enum';
import { authApi } from '../../../api';
import { parseInt } from 'lodash';
import { consumeEnergyBar } from '../../../api/shared-api';
import { animate } from '../../../util/animate';
interface AttackButtonProps {
  citizenDetails: BattlefieldTypesApiProps['CITIZEN'];
  shouldUpdate: Dispatch<React.SetStateAction<boolean>>;
  battleDetails: BattlefieldTypesApiProps['BATTLE'];
  battleSide: BATTLE_SIDES.ATTACKER | BATTLE_SIDES.DEFENDER;
}
export const AttackButton: FC<AttackButtonProps> = ({ citizenDetails, shouldUpdate, battleDetails, battleSide }) => {
  const [damageDetails, setDamageDetails] = useState(citizenDetails);
  const { t } = useTranslation('common');
  const { user, setUser } = useUser();
  const { helpers } = useHelper();
  const [isLoading, setIsLoading] = useState<JSX.Element | null>(null);
  const [rewardModal, setRewardModal] = useState<JSX.Element | null>(null);
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedItemQuality, setSelectedItemQuality] = useState(5);
  const [weaponsModal, setWeaponsModal] = useState(false);
  const [toastNotification, setToastNotification] = useState<JSX.Element | null>(null);
  const { citizenDamage, setCitizenDamage } = useBattlefield();
  const [roundBasedAvailableItems, setRoundBasedAvailableItems] = useState(
    getRoundAvailableItems({
      user: user,
      roundId: battleDetails.ROUND,
    }),
  );

  const [weaponsModalBody, setWeaponsModalBody] = useState<any[]>([]);

  function handleFight() {
    setIsLoading(() => <Loader className="w-6 h-6 text-white" />);
    if (!isLoading) {
      fight();
    }
  }

  function getAvailableItemsView() {
    let tmpItemsList: any[] = [];
    roundBasedAvailableItems[0].forEach((item: {}, key: string | number) => {
      if (roundBasedAvailableItems[0][key][Object.keys(item)[0]]) {
        for (let x = 1; x < 6; x++) {
          if (roundBasedAvailableItems[0][key][Object.keys(item)[0]][x] > 0) {
            tmpItemsList.push(
              <Fragment key={`${key}_${x}`}>
                <div
                  className="storageItems bg-blue-100 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();

                    setSelectedItem(Number(Object.keys(item)[0]));
                    setSelectedItemQuality(x);
                    setWeaponsModal(false);
                  }}
                >
                  <StorageImage
                    className="w-10 h-10 absolute mt-3 ml-5 cursor-pointer default-transition"
                    imagePath={helpers.Items[Number(Object.keys(item)[0])].Img}
                  />
                  <span className="storageItemsItemNameSpan">{helpers.Items[Number(Object.keys(item)[0])].Name}</span>
                  <span className="storageItemsItemQualitySpan mt-1">Q{x}</span>
                  <span className="storageItemsItemQuantitySpan mt-1">
                    {/*
// @ts-ignore */}
                    {roundBasedAvailableItems[0][key][Object.keys(item)][x]}
                  </span>
                </div>
              </Fragment>,
            );
          }
        }
      }
    });

    return tmpItemsList;
  }

  function fight() {
    authApi
      .post('/api/battle/fight', {
        itemType: selectedItem ?? 0,
        itemQuality: selectedItemQuality ?? 5,
        battleSide: battleSide,
        battleId: battleDetails['ID'],
      })
      .then((response) => {
        setUser(response.data.USER);
        // @ts-ignore
        let tmpArr: BattlefieldTypesApiProps['CITIZEN'] = [
          {
            // @ts-ignore
            T_DMG: parseInt(damageDetails[0].T_DMG) + parseInt(response.data.DMG),
            // @ts-ignore
            T_HIT: damageDetails[0].T_HIT,
          },
        ];
        setCitizenDamage(() => {
          // @ts-ignore
          citizenDamage[1][0].T_DMG = Number(parseInt(citizenDamage[1][0].T_DMG) + parseInt(response.data.DMG));
          return citizenDamage;
        });

        setDamageDetails(tmpArr);
        shouldUpdate((prevState) => !prevState);
        setRewardModal(
          <FightSummary
            damage={response.data.DMG}
            rankPoints={response.data.R_DMG}
            experiencePoints={response.data.EXPERIENCE}
          />,
        );

        animate({
          targetElementId: GLOBAL_CONSTANTS.EXPERIENCE_BAR_ID,
          animation: 'tada',
        });
        setTimeout(() => {
          setRewardModal(null);
          setIsLoading(null);
        }, GLOBAL_CONSTANTS.FIGHT_LOADING_INTERVAL);
      })
      .catch((e) => {
        console.error(e);
        ApplicationAlert(t(e.response.data)).error();
        setIsLoading(null);
        rewardModal;
        return;
      });
  }

  async function handleEnergyBarLoad() {
    if (roundBasedAvailableItems[1][5] === 0) {
      ApplicationAlert(t('GAME_NOT_ENOUGH_ENERGY_BARS')).error();
      return;
    }
    await consumeEnergyBar({ itemType: 11, itemQuality: 5 })
      .then((r) => {
        setUser(r.data);
        setToastNotification(
          ToastNotifications(t('GAME_SUCCESSFULLY_LOADED_ENERGY'))
            .style('absolute top-96 mt-24 left-1/3 ml-14 z-10')
            .success(),
        );
        setTimeout(() => {
          setToastNotification(null);
        }, 2000);
        return;
      })
      .catch((e) => {
        ApplicationAlert(t(e.response.data)).error();

        setIsLoading(null);
        return;
      });
  }
  useEffect(() => {
    setWeaponsModalBody(getAvailableItemsView());
  }, []);

  useEffect(() => {
    setRoundBasedAvailableItems(getRoundAvailableItems({ user: user, roundId: battleDetails.ROUND }));
  }, [damageDetails, selectedItem, user]);

  return (
    <>
      {toastNotification}
      <div
        className="absolute bottom-0 left-1/2 -ml-48 text-white"
        style={battleSide === BATTLE_SIDES.ATTACKER ? HitButtonAttackerFrame() : HitButtonDefenderFrame()}
      >
        <div
          className="text-center justify-center text-gray-100 mt-3 ml-3 font-extrabold"
          style={{ textShadow: '0 1px 1px rgb(0 0 0 / 60%), 0 0 10px rgb(0 0 0 / 50%)' }}
        >
          {/*
// @ts-ignore */}
          {numberFormat(damageDetails[0].T_DMG)}
        </div>
        <button
          data-testid="attackerfight"
          className="text-center cursor-pointer light-transition"
          style={battleSide === BATTLE_SIDES.ATTACKER ? HitButtonFightAttackerImage() : HitButtonFightDefenderImage()}
          onClick={() => {
            handleFight();
          }}
        >
          {isLoading ? (
            <div className="">{isLoading}</div>
          ) : (
            <span className="absolute top-6 text-center uppercase left-14 font-bold" style={{ fontSize: '16px' }}>
              {t('GAME_BATTLES_FIGHT')}
            </span>
          )}
        </button>
        <div
          className="absolute block -right-9 w-24 h-16 top-6 text-center"
          style={
            battleSide === BATTLE_SIDES.ATTACKER ? battlefieldAttackerWeaponsStyle() : battlefieldDefenderWeaponsStyle()
          }
        >
          <span
            onClick={() => {
              setWeaponsModal((prevState) => !prevState);
            }}
          >
            <AppModal
              open={weaponsModal}
              setOpen={setWeaponsModal}
              body={
                <>
                  <div data-testid="weaponsmodal" className="grid grid-cols-4 sm:grid-cols-6 gap-1 m-3 sm:ml-3">
                    {weaponsModalBody}
                  </div>
                </>
              }
              shouldHaveCloseButton={false}
              backgroundStyle={{ backgroundColor: '#1e293b' }}
            />
            <StorageImage
              testId="bf-select-weapon"
              className="w-12 h-12 absolute mt-2.5 ml-1.5 cursor-pointer default-transition"
              imagePath={selectedItem > 0 ? helpers.Items[selectedItem].Img : 'barehand'}
            />
            <span className="w-10 h-10 text-center absolute mt-9 font-bold -ml-8 pointer-events-none">
              {selectedItem > 0
                ? roundBasedAvailableItems[0].map((item: any, key: string | number) => {
                    if (roundBasedAvailableItems[0][key][selectedItem]) {
                      if (roundBasedAvailableItems[0][key][selectedItem][selectedItemQuality] <= 0) {
                        setSelectedItem(0);
                      }
                      return roundBasedAvailableItems[0][key][selectedItem][selectedItemQuality];
                    }
                  })
                : 0}
            </span>
          </span>
        </div>
        <div
          className="absolute block bottom-4 left-2 w-24 h-16 top-6"
          style={battleSide === BATTLE_SIDES.ATTACKER ? energyBarAttackerStyle() : energyBarDefenderStyle()}
        >
          <div>
            <button onClick={handleEnergyBarLoad} data-testid="energy-bars" className="w-10 h-10 absolute">
              <StorageImage
                className="mt-3 ml-2.5 cursor-pointer default-transition"
                imagePath={helpers.Items[11 as number]['Img']}
              />
            </button>
            <span className="w-10 h-10 absolute mt-9 font-bold ml-5 pointer-events-none">
              {roundBasedAvailableItems[1][5]}
            </span>
          </div>
        </div>
      </div>
      {rewardModal}
    </>
  );
};
