import AlertIcon from './Icons/AlertIcon';
import React from 'react';

export default function DoesNotExist({ t, title, description = '' }) {
  return (
    <div
      className="p-4 mb-4 mx-5 text-sm text-gray-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
      role="alert"
    >
      <AlertIcon classNames={'h-5 w-5 mx-auto'} />
      <p className={'font-bold text-indigo-800'}>{t(title)}</p>
      <p>{t(description)}</p>
    </div>
  );
}
