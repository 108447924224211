import React from 'react';
import { UserProps } from '../shared-types/user-type';

interface UserContextProps {
  user: UserProps;
  setUser: (args: any) => void;
}

export const userContext = React.createContext<UserContextProps>({
  user: {} as UserProps,
  setUser: (arg:UserProps) => {},
});

export default userContext;
