import React from 'react';
import BattleFieldCharts from './BattleFieldCharts';
import { BattlefieldBattleObjectProps } from '../../shared-types/battlefield-types';
interface BattlefieldTopListProps {
  topList: BattlefieldBattleObjectProps['topAttackers'] & BattlefieldBattleObjectProps['topDefenders'];
}
export const BattlefieldTopList = ({ topList }: BattlefieldTopListProps) => {
  let chartsLabel = [];

  for (let y = 0; y < topList.length; y++) {
    chartsLabel.push(topList[y]);
  }
  return (
    <>
      <div className="bg-gray-800 rounded-lg  absolute shadow-xl w-1/3 left-1/2 -ml-56 top-40 animate__animated animate__fadeIn z-20">
        <BattleFieldCharts
          // @ts-ignore
          labels={chartsLabel}
          dataInput={topList}
        />
      </div>
    </>
  );
};
