import { ReactNode } from 'react';
import { Blanket } from './Blanket';

import { Menu } from './Menu';

interface DropdownProps {
  children: ReactNode;
  isOpen: boolean;
  target: ReactNode;
  onClose: () => void;
}

export const Dropdown = ({ children, isOpen, target, onClose }: DropdownProps) => (
  <div className="relative text-white">
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
