import React, { useState } from 'react';
import { Loader } from '../../../components/Loader';
import axios from 'axios';
import { BASE_URL, BEARER } from '../../../constants/constants';
import ApplicationAlert from '../../../components/ApplicationAlert';
import RewardAlert from '../../Util/Reward/RewardAlert';
import { useTranslation } from 'react-i18next';
import { fromCDN } from '../../../helpers/Images';
import { useHelper } from '../../../hooks/use-helper';
import { useUser } from '../../../hooks/use-user';

export default function AbilityFirstTimePick({ item }) {
  const { user, setUser } = useUser();
  const { t } = useTranslation('common');
  const { helpers } = useHelper();
  const [isLoading, setIsLoading] = useState();

  async function firstTimePickAbility(id) {
    setIsLoading(<Loader shouldFullScreen={false} width={40} height={40} />);

    await axios
      .post(
        BASE_URL + '/api/upgradeability',
        { upgrade: id },
        {
          headers: { Authorization: 'Bearer ' + BEARER },
        },
      )
      .then((response) => {
        ApplicationAlert(<RewardAlert rewards={response.data} helper={helpers} t={t} title={''} />).success();
        setUser(response.data[0]);

        setIsLoading(null);
      })
      .catch((error) => {
        setIsLoading(null);
      });
  }

  return (
    <>
      {isLoading ? (
        isLoading
      ) : (
        <>
          <p
            className={
              'bg-gray-200/20 text-gray-200 rounded-lg shadow-inner p-3 m-5 animate__animated animate__flipInX'
            }
          >
            {t('GAME_ABILITY_' + item['ID'] + '_DESCRIPTION')}
          </p>
          <div className="flex justify-center">
            <div className=" text-center">
              <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                <img
                  className="h-8 w-8 mx-auto"
                  src={fromCDN.fetchStaticImage(`/game/abiicons/abi_${item['ID']}`, 'png')}
                  alt="Workflow"
                />
              </span>
              <span className="sm:text-sm text-gray-200 bg-green-600/70 p-1.5 rounded-lg">
                +{helpers.TG[1]['STR'][user.TG1]}
              </span>
            </div>
            <div className=" text-center">
              <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                <img className="h-8 w-8 mx-auto" src={fromCDN.fetchStaticImage('/game/icons/energy-s', 'png')} alt="Workflow" />
              </span>
              <span className="sm:text-sm text-gray-200 bg-red-600/70 p-1.5 rounded-lg">
                -{helpers.TG[1]['HP'][user.TG1]}
              </span>
            </div>
            <div className=" text-center">
              <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                <img className="h-8 w-8 mx-auto" src={fromCDN.fetchStaticImage('/game/icons/gold-icon', 'png')} alt="Workflow" />
              </span>
              <span className="sm:text-sm text-gray-200 bg-red-600/70 p-1.5 rounded-lg">-{item['Upgrade'][1]}</span>
            </div>
          </div>
          <div className={'flex justify-center mt-5'}>
            <button
              className={
                'uppercase bg-green-700 text-white hover:bg-green-600 text-sm font-bold transition ease-in-out duration-200 hover:scale-105 py-3 px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 '
              }
              onClick={() => firstTimePickAbility(item.ID)}
            >
              {t('GAME_CHOOSE')}
            </button>
          </div>
        </>
      )}
    </>
  );
}
