import { PlusIcon } from '@heroicons/react/solid';

import { fromCDN } from '../../../../helpers/Images';
import { ValueOf } from '../../../../shared-types/util';
import { CircleButtonExpandable } from '../CircleButtonExpandable';
import { MyOfferList } from './MyOfferList';

//fromCDN.fetchStaticGameImage(`/flagscountry/${offer.OFFER}`)

const ccImage = fromCDN.fetchStaticImage('/t_cc', 'svg');
const goldImage = fromCDN.fetchStaticImgImage('/header/icons/gold', 'svg');

export const OFFER_TYPE = {
  GOLD: 1,
  CURRENCY: 2,
} as const;

export type Offer = ValueOf<typeof OFFER_TYPE>;

export const MyOffers = () => {
  return (
    <>
      <div className="w-full flex justify-center flex-col sm:flex-row gap-4 mt-8">
        <div className="flex-1">
          <CircleButtonExpandable
            offerType={OFFER_TYPE.GOLD}
            exchangeIcon={ccImage}
            tradeIcon={goldImage}
            className="sm:ml-auto border-yellow-500 flex-row-reverse"
            tradeColor="border-yellow-500"
            exchangeColor="border-green-500"
            reverse={true}
          >
            <PlusIcon className="h-12 w-12 text-yellow-500 stroke-2" />
          </CircleButtonExpandable>
        </div>

        <div className="flex-1">
          <CircleButtonExpandable
            offerType={OFFER_TYPE.CURRENCY}
            exchangeIcon={goldImage}
            tradeIcon={ccImage}
            className="border-green-500"
            tradeColor="border-green-500"
            exchangeColor="border-yellow-500"
          >
            <PlusIcon className="h-12 w-12 text-green-500 stroke-2" />
          </CircleButtonExpandable>
        </div>
      </div>
      <MyOfferList />
    </>
  );
};
