import { useParams } from 'react-router-dom';
import { authApi } from '../../../../api';
import { useEffect, useState } from 'react';
import { PageContainer } from '../../../../components/PageContainer';

import {imageAsBackground} from '../../../../helpers/image-as-background';
import { SectionHeader } from '../../../../components/SectionHeader';
import { useTranslation } from 'react-i18next';
import ElectionsPickDate from './ElectionsPickDate';
import { fromCDN } from '../../../../helpers/Images';
import { useHelper } from '../../../../hooks/use-helper';

export default function PartyElections() {
  let params = useParams();
  const { helpers } = useHelper();

  const electionType = helpers.Game.Election.Types.Party;
  const { t } = useTranslation('common');

  const [electionMonths, setElectionMonths] = useState();
  const [countryId, setCountryId] = useState(params.countryId);

  const getMonths = () => {
    authApi
      .get('/api/elections/' + electionType + '/months')
      .then((resp) => {
        setElectionMonths(resp.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getMonths();
  }, []);

  return (
    <>
      <PageContainer
        style={imageAsBackground(fromCDN.fetchStaticImage('t_electionsbg2', 'svg'))}
        childrenAdditionalClassName={'p-5'}
        children={
          <>
            <SectionHeader title={t('GAME_COMMUNITY_ELECTIONS_PARTY')} />
            {electionMonths && (
              <ElectionsPickDate electionMonths={electionMonths} electionType={electionType} countryId={countryId} />
            )}
          </>
        }
      />
    </>
  );
}
