import moment from 'moment';

type LocalStorageKey = string;

export const saveToLocalStorage = <T>(key: LocalStorageKey, data: T) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getFromLocalStorage = <T>(key: LocalStorageKey): T | null => {
  const item = localStorage.getItem(key);

  if (item) {
    return JSON.parse(item);
  }

  return null;
};

export const useLocalStorageAsCache = <T>(expirationInMinutes: number, key: LocalStorageKey, data: T) => {
  const localStorageData = {
    data,
    expiration: moment().add(expirationInMinutes, 'minutes').unix(),
  };

  saveToLocalStorage(key, localStorageData);
};

export const forgetLocalStorageCache = (key: LocalStorageKey) => {
  const res = getFromLocalStorage(key)
  if (res){
    localStorage.removeItem(key)
  }
};
