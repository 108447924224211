import { authApi } from '../../../api';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ApplicationAlert from '../../../components/ApplicationAlert';

export default function CancelNominateCongressMan({ partyId, currentCandidate = 0, candidateId, trigger }) {
  const { t } = useTranslation('common');

  const handleCancelNomination = () => {
    authApi
      .get('api/parties/' + partyId + '/cancelnominate/' + candidateId + '/congress')
      .then((resp) => {
        trigger(resp.data['CONGRESS_CANDIDATES']);
        return ApplicationAlert(t('GAME_SUCCESS')).success();
      })
      .catch((err) => {
        return ApplicationAlert(t(err.response.data)).error();
      });
  };

  return (
    <>
      <span
        onClick={() => handleCancelNomination()}
        data-tip
        data-for={'cancelNominatedCongressCandidate'}
        className={''}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-red-600 hover:text-red-600 cursor-pointer default-transition mx-auto"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
          />
        </svg>
      </span>
      <ReactTooltip id={'cancelNominatedCongressCandidate'} effect="solid" data-html={true}>
        {t('GAME_CANCEL_NOMINATE_COUNTRY_CONGRESS')}
      </ReactTooltip>
    </>
  );
}
