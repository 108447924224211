import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { countryFlags, storageItems } from '../../../../helpers/Helpers';
import StorageImage from '../../../../components/StorageImage';
import { authApi } from '../../../../api';
import { useHelper } from '../../../../hooks/use-helper';
import { useUser } from '../../../../hooks/use-user';

export default function SellItemsOnMarket({ setMyStorageItems, myListedItems, setMyListedItems }) {
  const { t } = useTranslation('common');
  const { setUser } = useUser();
  const { helpers } = useHelper();

  function removeItemFromMarket(id) {
    authApi.post('/api/business/storage/removemarketlistitem', { id }).then((response) => {
      setUser(response.data.USER);
      setMyListedItems(response.data.MarketOffers);
      setMyStorageItems(storageItems({ user: response.data.USER }));
    });
  }

  return (
    <>
      <div className="flex flex-wrap">
        {myListedItems?.length > 0 ? (
          myListedItems.map((item) => {
            return (
              <div className="marketSaleItems mt-3 h-full flex flex-col gap-2" key={item.ID}>
                <div className="marketSaleItem bg-blue-100">
                  <StorageImage className="w-10 h-10 absolute mt-3 ml-5" imagePath={helpers.Items[item.TYPE]['Img']} />
                  <span className="storageItemsItemNameSpan">{helpers.Items[item.TYPE]['Name']}</span>
                  <span className="storageItemsItemQualitySpan mt-1">Q{item.QUALITY}</span>
                  <span className="storageItemsItemQuantitySpan mt-1">{item.STOCK}</span>
                </div>
                <div className="market-price-wrapper flex flex-col w-full text-white gap-1">
                  <div
                    data-tip={true}
                    data-for="marketPriceDescription"
                    className="flex justify-between w-full bg-neutral-700 bg-opacity-70"
                  >
                    <span className="ml-1">{item.PRICE}</span>
                    {countryFlags(item.COUNTRY)}
                  </div>
                  <div
                    data-tip={true}
                    data-for="marketTaxDescription"
                    className="flex justify-between w-full bg-neutral-700 bg-opacity-70"
                  >
                    <span className="ml-1">{item.TAX}</span>
                    {countryFlags(item.COUNTRY)}
                  </div>
                  <button
                    className="cursor-pointer transition transform ease-in-out hover:scale-105 duration-300 bg-red-600 hover:bg-red-800 p-0 flex items-baseline justify-center"
                    id={item.ID}
                    onClick={(e) => removeItemFromMarket(e.target.id)}
                  >
                    {t('GAME_REMOVE')}
                  </button>
                  <ReactTooltip id="marketPriceDescription" effect="solid" data-html={true}>
                    {t('GAME_MARKET_PRICE_DESCRIPTION')}
                  </ReactTooltip>
                  <ReactTooltip id="marketTaxDescription" effect="solid" data-html={true}>
                    {t('GAME_MARKET_TAX_DESCRIPTION')}
                  </ReactTooltip>
                </div>
              </div>
            );
          })
        ) : (
          <div className="justify-center text-center p-3 bg-gray-300 shadow-inner text-gray-900 rounded-lg w-full mr-8">
            {t('GAME_NO_OFFERS')}
          </div>
        )}
      </div>
    </>
  );
}
