import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { authApi } from '../../../api';
import { CURRENCY } from '../../../constants/community_enum';
import { getMaxInputValue } from '../../../helpers/get-max-input-value';

import { countryFlags } from '../../../helpers/Helpers';
import { fromCDN } from '../../../helpers/Images';
import { useUser } from '../../../hooks/use-user';
import { ValueOf } from '../../../shared-types/util';

import ApplicationAlert from '../../../components/ApplicationAlert';
import Avatar from '../../../components/Avatar';
import { normalizeUserName } from '../../../helpers/normalize';

export interface OfferProps {
  ID: number;
  USER: number;
  OFFER: number;
  FOR: number;
  MONEY: number;
  RATE: number;
  DATE: string;
  U_ID: number;
  U_NAME: string;
  U_AVATAR: string;
}

interface SingleOfferProps {
  offer: OfferProps;
  countryId: number;
  currencyType: ValueOf<typeof CURRENCY>;
  onPurchaseSuccess: () => void; // Add the prop to type definition
}

const goldImage = fromCDN.fetchStaticImgImage('/header/icons/gold', 'svg');

export const Offer = ({ offer, countryId, currencyType, onPurchaseSuccess }: SingleOfferProps) => {
  const { U_AVATAR, U_NAME, MONEY, RATE, ID, OFFER } = offer;
  const { t } = useTranslation('common');
  const { setUser } = useUser();

  const [purchaseAmount, setPurchaseAmount] = useState(0);

  const purchase = ({ event, ID, RATE }: { event: React.FormEvent; ID: number; RATE: number }) => {
    event.preventDefault();

    if (!purchaseAmount) {
      ApplicationAlert(t('GAME_SET_QUANTITY')).error();
      return;
    }

    authApi
      .post('/api/business/monetary/monetarypurchase', {
        offer: ID,
        amount: purchaseAmount,
        rate: RATE,
        marketCountry: countryId,
        marketCc: currencyType,
      })
      .then((response) => {
        setUser(response.data.USER);
        ApplicationAlert(t(response.data.MESSAGE)).success();
        onPurchaseSuccess();
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });
  };

  const sellingIcon = OFFER === CURRENCY.GOLD ? <img className="h-5 w-5" src={goldImage} /> : countryFlags(countryId);
  const buyingIcon =
    OFFER === CURRENCY.GOLD ? countryFlags(countryId) : <img className="h-5 w-5 mx-auto" src={goldImage} />;

  const price = purchaseAmount * RATE;
  const priceToRender = Number.isSafeInteger(price) ? price.toFixed(0) : price.toPrecision(2);

  return (
    <div className="bg-edom-dark-yellow/90 rounded-lg animate__animated animate__fadeIn px-3 py-5 flex flex-col gap-y-2 justify-start text-center shadow-[inset_0_-1px_5px_rgba(0,0,0,0.6)]">
      <div>
        <Avatar avatarId={U_AVATAR} userId={String(offer.USER)} className="mx-auto" />
        <span className="font-semibold text-white">{normalizeUserName(U_NAME)}</span>
      </div>
      <div className="bg-black/50 p-3 rounded-lg">
        <div className="flex justify-center text-gray-200 gap-x-1">
          <span>{MONEY}</span>
          <span>{sellingIcon}</span>
        </div>
      </div>
      <div className="bg-black/50 p-2 rounded-lg">
        <span className="font-medium">{t('EXCHANGE_RATE')}</span>
        <div className="flex justify-center mt-2 text-green-400 gap-x-1">
          <span>1</span>
          <span>{sellingIcon}</span>
        </div>
        <img
          src={fromCDN.fetchStaticImage('t_arrowdownwhite', 'svg')}
          className="h-3 w-3 opacity-20 mx-auto -mb-0.5 mt-1 rotate-180"
        />
        <div className="flex justify-center text-red-400 gap-x-1 pt-1">
          <span>{RATE}</span>
          <span>{buyingIcon}</span>
          <div className="flex text-white"></div>
        </div>
      </div>
      <form
        className="flex flex-col gap-y-2 items-center"
        onSubmit={(event) => {
          purchase({
            ID,
            RATE,
            event,
          });
        }}
      >
        <input
          type="number"
          min="0.01"
          step="0.01"
          max={MONEY.toString()}
          value={purchaseAmount.toString()}
          onChange={(e) => {
            const valueToSet = getMaxInputValue(e, { getDecimal: true });
            setPurchaseAmount(valueToSet);
          }}
          className="w-full p-3 bg-gray-200/20 focus:bg-gray-200/30 text-white rounded-lg focus:outline-none shadow-[inset_0_-1px_5px_rgba(0,0,0,0.6)]"
          placeholder={t('GAME_QUANTITY')}
        />

        <button
          disabled={purchaseAmount === 0}
          type="submit"
          className="bg-green-600 hover:bg-green-500 text-white text-sm font-semibold transition ease-in-out duration-200 enabled:hover:scale-105 py-2 px-3 rounded outline-none focus:outline-none mt-2 -mb-1 cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-800 disabled:transition-none"
        >
          {t('GAME_PURCHASE')}
        </button>
      </form>
      {0 < purchaseAmount ? (
        <div className="flex flex-col items-center border-t-slate-900/25">
          <div className="flex text-green-400 gap-x-1">
            <span>+ {purchaseAmount}</span>
            <span>{sellingIcon}</span>
          </div>
          <div className="flex text-red-400 gap-x-1">
            <span>- {priceToRender}</span>
            <span>{buyingIcon}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};
