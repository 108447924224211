import { useEffect, useState } from 'react';
import { useHelper } from './use-helper';
import { gameTimeZone } from '../helpers/game-time';
export const showLiveTime = (): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { helpers } = useHelper();
  const locale = 'en';
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [today, setToday] = useState(new Date(helpers.Game.Date)); // Save the current date to be able to trigger an update

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.// console.log()
      setToday(
        new Date(
          gameTimeZone().getDifferenceFromGameTimeWhatTheFuck({
            date: new Date(),
          }),
        ),
      );
    }, 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  const day = today.toLocaleDateString(locale, { weekday: 'long' });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })}\n\n`;

  const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {}, [today]);
  return `${date}${time}`;
};
