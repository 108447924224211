import Countdown from 'react-countdown';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { countryFlags, resourceImages, roundIcons } from '../../helpers/Helpers';
import { gameTimeZone} from "../../helpers/game-time";
import { useHelper } from '../../hooks/use-helper';
import { fromCDN } from '../../helpers/Images';
import { BattlesObjectProps } from '../../shared-types/battles-types';
import { authApi } from '../../api';

interface BattleCardProps {
  item: BattlesObjectProps;
  isUnitCaptain: {
    unitId: number;
  };
}
export const BattleCard = ({ item, isUnitCaptain }: BattleCardProps) => {
  const { helpers } = useHelper();
  const { t } = useTranslation('common');
  let navigate = useNavigate();

  const cardBgDependsOnRound = (round: number): string => {
    switch (round) {
      case 1:
        return 't_urbancard';
      case 2:
        return 't_landcard';
      case 3:
        return 't_aircard';
      default:
        return 't_aircard';
    }
  };

  function setBattleOrder(unit: number, battle: number) {
    authApi
      .post('/api/military-unit/battleorder', {
        unitId: unit,
        battleId: battle,
      })
      .then(() => {
        navigate(`/military-unit/${unit}`);
      });
  }

  return (
    <div
      style={{
        backgroundImage: `url(${fromCDN.fetchStaticImage(cardBgDependsOnRound(item.ROUND), 'png')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      className="rounded-b-md text-center image-opacity-50"
    >
      <div>
        <p className="mx-auto bg-indigo-700 bg-opacity-90 text-white text-center font-bold p-1.5 mb-1">
          {helpers.Regions[item.REGION].Name}{' '}
          {resourceImages(
              helpers.Resources[helpers.Regions[item.REGION].Resource[0]].Name,
            'absolute -mt-6 h-6 w-6 mx-auto',
          )}
        </p>

        <div className="text-center justify-center lg:pt-4 pt-8 text-gray-800">
          <Countdown
            className="bg-emerald-700 bg-opacity-90 text-white font-bold rounded-md p-1.5"
            date={gameTimeZone().getDifferenceFromGameTime({ date: item.DATE })}
            daysInHours={true}
          />
        </div>
        <div className="flex justify-center py-4 lg:pt-4 lg:mr-0 -mt-9 mr-4">
          <div className="m-4 p-3 -mt-3 text-center">
            <span className="bg-blue-500 p-1 rounded-sm text-gray-100 font-bold">
              {helpers.Countries[item.AGRESOR].Name}
            </span>
            <span className="font-bold block uppercase tracking-wide text-gray-700">
              {countryFlags(item.AGRESOR, 'mx-auto h-12 w-12', true)}
            </span>
            <span className="bg-blue-500 bg-opacity-90 text-white p-1.5 font-bold rounded-sm">
              {item.RoundScores.A}
            </span>
          </div>
          <div className="mr-5 md:mr-4 p-3 text-center mt-7">
            <span className=" font-bold block -mt-5 tracking-wide text-gray-700">
              {roundIcons(item.ROUND, 'mx-auto h-12 w-12')}
            </span>
          </div>

          <div className="lg:mr-4 p-3 -mt-3 text-center">
            <span className="bg-red-500 bg-opacity-90 p-1 rounded-sm text-gray-100 font-bold">
              {helpers.Countries[item.DEFENDER].Name}
            </span>
            <span className=" font-bold block uppercase tracking-wide text-gray-700">
              {countryFlags(item.DEFENDER, 'mx-auto h-12 w-12', true)}
            </span>
            <span className="text-white font-bold rounded-sm bg-red-500 bg-opacity-90 p-1.5 ">
              {item.RoundScores.D}
            </span>
          </div>
        </div>
        <div className="battleProgressBarParent -mt-6">
          <div className="battleProgressBarParent-1">
            <div
              className="battleVersusBar transition ease delay-50 duration-300"
              style={{ width: `${item.PercentageScores.A}%`, float: 'left' }}
            />
            <div className="battleVersusBarAttackerRate z-10">{item.PercentageScores.A}%</div>
            <div className="battleVersusrBarDefenderRate z-10">{item.PercentageScores.D}%</div>

            <div className="battleVersusBar battleVersusBar2 color3">
              {/* Drawing the bubbles */}
              <span />
            </div>
          </div>
          <div className="battleProgressBarParent-1" style={{ width: `${item.PercentageScores.D}%` }} />
        </div>

        <div className="sm:p-3 py-1 flex gap-x-1 justify-center text-center text-sm font-bold text-white">
          <Link
            className="block bg-green-700 hover:bg-green-600 transition ease-in-out duration-300 hover:scale-105 p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none"
            to={`/battlefield/${item.ID}/1`}
          >
            {t('GAME_BATTLES_FIGHT')}
          </Link>
          {/* eslint-disable */}
          {isUnitCaptain && isUnitCaptain.unitId ? (
            <button
              className="bg-yellow-600 transition ease-in-out duration-300 hover:scale-105 p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none"
              onClick={() => {
                setBattleOrder(isUnitCaptain.unitId, item.ID);
              }}
            >
              {t('GAME_UNIT_SET_ORDER_BATTLE')}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
