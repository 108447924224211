import { TutorialInformationProps } from '../types/types';
import { fromCDN } from '../../../helpers/Images';

export const companiesConfig: TutorialInformationProps = {
  headerExplanation: (
    <p className="font-medium mb-6 text-orange-300">
      Having the ability to produce your own items, work a job or even manage your own employees definitely has its
      advantages. Here are some key components to get you started and acquainted with the process:
    </p>
  ),
  sections: [
    <p key={1}>
      <img className="w-5 h-5 mr-1 inline" src={fromCDN.fetchStaticGameImage('/icons/0emp')} />
      <p className="font-medium text-indigo-400 inline">Employing/Available Workers</p> - When you employ a player, they
      can work for you twice a day. This means that once per day, you can get 2
      <img className="w-3 h-3 ml-0.5 inline" src={fromCDN.fetchStaticGameImage('/icons/0emp')} />{' '}
      <p className="underline decoration-indigo-400 inline">available workers</p> per 1 of your employee. Employees
      require a <img className="w-3 h-3 ml-0.5 inline" src={fromCDN.fetchStaticImage('t_salarygreen', 'svg')} />{' '}
      <p className="underline decoration-green-300 inline">salary</p>.{' '}
    </p>,
    <p key={2}>
      <img className="w-6 h-6 mr-1 inline" src={fromCDN.fetchStaticGameImage('/icons/unemployed')} />
      <p className="font-medium text-red-400 inline">Employer</p> - Just like you, other players can employ as well.
      This is a stable source of income that lets you work twice per day for another player. Working takes{' '}
      <img className="w-3 h-3 ml-0.5 inline" src={fromCDN.fetchStaticImage('/game/icons/energy-s', 'png')} />{' '}
      <p className="underline decoration-green-200 inline">energy</p> but you are rewarded with a{' '}
      <img className="w-3 h-3 ml-0.5 inline" src={fromCDN.fetchStaticImage('t_salarygreen', 'svg')} />{' '}
      <p className="underline decoration-green-300 inline">salary</p>. You can find an employer on the{' '}
      <img className="w-3 h-3 ml-0.5 inline" src={fromCDN.fetchStaticGameImage('/icons/Jobs', 'svg')} />{' '}
      <p className="underline decoration-blue-700 inline">job market</p>.
    </p>,

    <p key={3}>
      <img className="w-5 h-5 mr-1 inline" src={fromCDN.fetchStaticImage('t_productionskill', 'svg')} />
      <p className="font-medium text-green-400 inline">Production Skills</p> - These skills get reset every day. You get{' '}
      <img className="w-3 h-3 ml-0.5 inline" src={fromCDN.fetchStaticImage('t_productionskill', 'svg')} />{' '}
      <p className="underline decoration-green-400 inline">production skills</p> by doing daily tasks throughout the
      game such as shooting in battles, completing missions, events etc. The
      <img className="w-3 h-3 ml-1 -mr-0.5 inline" src={fromCDN.fetchStaticImage('t_star', 'svg')} />{' '}
      <p className="underline decoration-yellow-400 inline">potential quality output</p> of a factory relies on how many{' '}
      <img className="w-3 h-3 ml-1 inline" src={fromCDN.fetchStaticImage('t_productionskill', 'svg')} />{' '}
      <p className="underline decoration-green-400 inline">production skills</p> you have at the initial start of{' '}
      <img
        className="w-3 h-3 ml-0.5 -mr-0.5 inline"
        src="https://eu2.contabostorage.com/d04380f507ee4b7990214d34aadf868a:edomlegacy/static//img/header/icons/settings.svg"
      />{' '}
      <p className="underline decoration-orange-400 inline">production</p> in a particular factory.{' '}
    </p>,
    <p key={4} className="mb-1">
      <img
        className="w-5 h-5 mr-1 inline"
        src="https://eu2.contabostorage.com/d04380f507ee4b7990214d34aadf868a:edomlegacy/static//img/header/icons/settings.svg"
      />
      <p className="font-medium text-orange-400 inline">Production</p> - Production takes 5 aspects into account:{' '}
      <img className="w-3 h-3 ml-0.5 inline" src={fromCDN.fetchStaticGameImage('/icons/0emp')} />{' '}
      <p className="underline decoration-indigo-400 inline">available workers</p>,{' '}
      <img className="w-3 h-3 ml-0.5 inline" src={fromCDN.fetchStaticImage('t_productionskill', 'svg')} />{' '}
      <p className="underline decoration-green-300 inline">production skills</p>,{' '}
      <img className="w-3 h-3 ml-0.5 inline" src={fromCDN.fetchStaticImage('t_food', 'svg')} />{' '}
      <p className="underline decoration-green-200 inline">potential quantity output</p>,{' '}
      <img className="w-3 h-3 ml-0.5 -mr-0.5 inline" src={fromCDN.fetchStaticImage('t_star', 'svg')} />{' '}
      <p className="underline decoration-yellow-400 inline">potential quality output</p> and the time it takes to
      produce the desired items. the first 2 correlate with the second 2, which essentially means:
    </p>,
  ],
  extraNotes: (
    <>
      <p className="mt-6">
        <p className="font-medium inline">more</p>{' '}
        <img className="w-3 h-3 -mr-0.5 inline" src={fromCDN.fetchStaticGameImage('/icons/0emp')} />{' '}
        <p className="underline decoration-indigo-400 inline">available workers assigned</p> ={' '}
        <p className="font-medium inline">higher</p>{' '}
        <img className="w-3 h-3 -mr-0.5 inline" src={fromCDN.fetchStaticImage('t_food', 'svg')} />{' '}
        <p className="underline decoration-green-200 inline">potential quantity output</p>
      </p>
      <p className="">
        <p className="font-medium inline">more</p>{' '}
        <img className="w-3 h-3 -mr-0.5 inline" src={fromCDN.fetchStaticImage('t_productionskill', 'svg')} />{' '}
        <p className="underline decoration-green-300 inline">production skills</p> at start of production ={' '}
        <p className="font-medium inline">higher</p>{' '}
        <img className="w-3 h-3 -mr-0.5 inline" src={fromCDN.fetchStaticImage('t_star', 'svg')} />{' '}
        <p className="underline decoration-yellow-400 inline">potential quality output</p>
      </p>
      <p className="mt-6">
        When you assign <img className="w-3 h-3 ml-0.5 inline" src={fromCDN.fetchStaticGameImage('/icons/0emp')} />{' '}
        <p className="underline decoration-indigo-400 inline">available workers</p> to a factory, your{' '}
        <img className="w-3 h-3 -mr-0.5 inline" src={fromCDN.fetchStaticImage('t_food', 'svg')} />{' '}
        <p className="underline decoration-green-200 inline">potential quantity output</p> and{' '}
        <img className="w-3 h-3 ml-0.5 -mr-0.5 inline" src={fromCDN.fetchStaticImage('t_star', 'svg')} />{' '}
        <p className="underline decoration-yellow-400 inline">potential quality output</p> is calculated and you can
        start your desired item production by pressing{' '}
        <p className="p-0.5 m-0.5 bg-green-600 rounded-sm inline font-medium">Start production</p>. Every factory takes
        8 hours to produce, after which the items can be claimed from the factory as a final product. These items are
        then deposited into your
        <img
          className="w-3 h-3 ml-0.5 -mr-0.5 inline"
          src={fromCDN.fetchStaticGameImage('/v2/Icons/Storage', 'svg')}
        />{' '}
        <p className="underline decoration-teal-200 inline">storage</p>.
      </p>
    </>
  ),
};
