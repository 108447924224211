import { countryFlags } from '../../../helpers/Helpers';
import { fromCDN } from '../../../helpers/Images';
import { TopCountriesByActivePopulationProps } from '../../../shared-types/types';
import {useHelper} from "../../../hooks/use-helper";
interface TopCountriesByActivePopulationComponentProps {
  details: TopCountriesByActivePopulationProps[];
}
export const TopCountriesByActivePopulation = ({ details }: TopCountriesByActivePopulationComponentProps) => {
    const {helpers } = useHelper()
  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 m-3 gap-x-4 gap-y-5 mb-10">
        {details.map((item, key) => {
          return (
            <div
              className="text-center bg-gradient-to-br from-indigo-700/20 to-red-900/20 rounded-md p-3"
              key={`${item.CITIZENSHIP}${key}`}
            >
              <p className="absolute ml-1 text-gray-200 font-semibold">#4</p>
              <p>{countryFlags(item.CITIZENSHIP, 'w-16 h-16 mx-auto cursor-pointer default-transition')}</p>
              <p className="text-gray-200 -mt-2 font-bold text-lg">{helpers.Countries[item.CITIZENSHIP].Name}</p>
              <img className="mx-auto mt-2" src={fromCDN.fetchStaticImage('t_friendsyellow', 'svg')}></img>
              <p className="text-yellow-400 font-semibold bg-gray-200/20 mt-2 rounded-md text-sm">{item.TOTAL}</p>
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-2 justify-items-center gap-10 mb-20 text-gray-200">
        <button className="bg-edom-darker-yellow/80 hover:bg-edom-darker-yellow hover:scale-110 transition duration-200 p-3 rounded-lg">
          <img className="h-5 w-5" src={fromCDN.fetchStaticImage('t_arrowleft', 'svg')}></img>
        </button>
        <button className="bg-edom-darker-yellow/80 hover:bg-edom-darker-yellow hover:scale-110 transition duration-200 p-3 rounded-lg">
          <img className="h-5 w-5" src={fromCDN.fetchStaticImage('t_arrowright', 'svg')}></img>
        </button>
      </div>
    </>
  );
};
