import { CSSProperties } from 'react';
import { Card, CardProps } from './Card';

export interface CardsContainerProps {
  cards: CardProps[];
  style?: CSSProperties;
  containerClasses?: string;
  innerContainerClasses?: string;
}

export const CardsContainer = ({
  cards,
  style,
  containerClasses = 'min-h-screen bg-fixed',
  innerContainerClasses = 'max-w-7xl grid sm:grid-cols-3 mx-auto py-12 gap-x-10 gap-y-8 sm:gap-y-16 px-2 2xl:px-0 drop-shadow-[0_8px_6px_rgba(0,0,0,0.4)]',
}: CardsContainerProps) => {
  return (
    <div className={containerClasses} style={style}>
      <div className={innerContainerClasses}>
        {cards.map((card) => (
          <Card key={card.to} {...card} />
        ))}
      </div>
    </div>
  );
};
