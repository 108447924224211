import { fromCDN } from '../../helpers/Images';
import { useUser } from '../../hooks/use-user';
import { CardsContainer } from '../../components/Card/CardsContainer';

import { imageAsBackground } from '../../helpers/image-as-background';

export const BusinessCenter = () => {
  const { user } = useUser();
  const cards = [
    {
      to: `/business/marketplace/${user.COUNTRY}/0/0/1`,
      imgUrl: fromCDN.fetchStaticGameImage('/icons/Market', 'svg'),
      title: 'GAME_MARKETPLACE',
      'data-testid': 'marketplace',
      color: 'bg-edom-dark-red',
      infoText: 'GAME_BUSINESS_MENU_MARKETPLACE_DESCRIPTION',
    },
    {
      to: `/business/monetary-market/${user.COUNTRY}`,
      imgUrl: fromCDN.fetchStaticGameImage('/icons/Monetary', 'svg'),
      title: 'GAME_MONETARY_MARKET',
      'data-testid': 'monetary-market',
      color: 'bg-edom-dark-yellow',
      infoText: 'GAME_BUSINESS_MENU_MONETARYMARKET_DESCRIPTION',
    },
    {
      to: `/business/jobs/${user.COUNTRY}/1`,
      imgUrl: fromCDN.fetchStaticGameImage('/icons/Jobs', 'svg'),
      title: 'GAME_JOB_MARKET',
      'data-testid': 'jobs',
      color: 'bg-edom-dark-blue',
      infoText: 'GAME_BUSINESS_MENU_JOBS_DESCRIPTION',
    },
  ];

  return (
    <CardsContainer cards={cards} style={imageAsBackground(fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg'))} />
  );
};
