import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  avatarClass,
  BASE_URL,
  BEARER,
  defaultTransition,
  TEST_LIVE_URL,
  themeButtonClass,
} from '../../../constants/constants';
import AlertIcon from '../../Util/Icons/AlertIcon';
import { Divider } from '../../../components/Divider';
import axios from 'axios';
import CreateParty from './CreateParty';
import { fromCDN } from '../../../helpers/Images';
import { useUser } from '../../../hooks/use-user';

export default function NoParty() {
  let navigate = useNavigate();
  const { user, setUser } = useUser();
  const { t } = useTranslation('common');
  const [unitList, setUnitList] = useState();
  const [createUnit, setCreateUnit] = useState();

  async function getMilitaryUnits() {
    await axios
      .get(BASE_URL + '/api/parties/' + user.CITIZENSHIP, {
        headers: { Authorization: 'Bearer ' + BEARER },
      })
      .then((response) => {
        setUnitList(response.data);
      })
      .catch((error) => {});
  }

  function handleCreateUnit() {
    setCreateUnit(<CreateParty setCreateUnit={setCreateUnit} />);
    setUnitList(null);
  }

  useEffect(() => {
    getMilitaryUnits();
  }, []);

  useEffect(() => {
    if (!createUnit) {
      getMilitaryUnits();
    }
  }, [createUnit]);

  return (
    <>
      <div className="justify-center text-center m-3 animate__animated animate__fadeIn">
        <div
          className="p-4 mb-4 mx-5 text-sm text-gray-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
          role="alert"
        >
          <AlertIcon classNames="h-5 w-5 mx-auto" />
          <p className="font-bold text-indigo-800">{t('GAME_PARTY_NO_PARTY_TITLE')}</p>
          <p>{t('GAME_PARTY_NO_PARTY')}</p>
        </div>
        {createUnit ? (
          ''
        ) : (
          <button onClick={() => handleCreateUnit()} className={themeButtonClass + ' bg-green-300 '}>
            {t('GAME_PARTY_CREATE_PARTY')}
          </button>
        )}
      </div>
      <Divider />
      {unitList ? (
        <>
          <p className="font-bold justify-center text-center text-white">{t('GAME_MILITARY_JOIN_UNIT')}</p>
          <div className="gap-x-4 h-full sm:grid sm:grid-cols-2  md:grid md:grid-cols-4  p-3  mx-auto  ">
            {Object.values(unitList).map((item, key) => {
              let muCover = `url(${TEST_LIVE_URL + '/upload/party/' + item.COVER + '.jpg'})`;

              if (item.MU_COVER === 0) {
                muCover = `url(${fromCDN.fetchStaticGameImage('/icons/politicsnew')}`;
              }
              return (
                <>
                  <div
                    className={
                      'block justify-center text-center m-3  shadow-inner p-2 rounded-lg cursor-pointer ' +
                      defaultTransition
                    }
                    style={{
                      backgroundImage: muCover,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                    }}
                    onClick={() => navigate('/party/' + item.ID)}
                  >
                    <div className=" font-bold text-lg text-green-300">
                      {t('GAME_MILITARY_MEMBERS_UNIT')} {item.MEMBERS}
                    </div>
                    <div>
                      <img
                        className={'mx-auto h-20 w-20 ' + avatarClass}
                        src={TEST_LIVE_URL + '/upload/group/' + item.AVATAR + '.jpg'}
                        alt="avatar"
                      />
                    </div>

                    <div>
                      <p className="font-bold text-gray-200">{item.NAME}</p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </>
      ) : (
        createUnit
      )}
    </>
  );
}
