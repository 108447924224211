import React, { Fragment, ReactNode, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

interface AppModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  body?: string | ReactNode;
  button?: ReactNode;
  backgroundStyle?: React.CSSProperties;
  shouldHaveCloseButton?: boolean;
  desktopModalWidth?: string;
}

export const AppModal = ({
  open = false,
  setOpen = function () {},
  body = null,
  button = null,
  backgroundStyle = undefined,
  shouldHaveCloseButton = true,
  desktopModalWidth = 'sm:max-w-lg',
}: AppModalProps) => {
  const cancelButtonRef = useRef(null);
  const { t } = useTranslation('common');

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-100 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        data-testid="appmodal"
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              style={backgroundStyle}
              className="inline-block min-w-full sm:min-w-[22%] align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle "
            >
              {body}
              <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {button}
                {shouldHaveCloseButton && (
                  <button
                    type="button"
                    data-testid="appmodaldefaultclose"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 bg-gray-200 shadow-sm px-4 py-2 bg-gray-200text-base font-medium text-gray-700 hover:bg-white duration-200 hover:scale-105 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setOpen(false);
                    }}
                    ref={cancelButtonRef}
                  >
                    {t('GAME_CLOSE_MODAL')}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
