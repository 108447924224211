export const ACHIEVEMENTS = [
  2, 3, 4, 5, 6, 8, 9, 10, 13, 14, 18, 19, 23, 20, 21, 22, 24, 25, 98, 99, 100, 101, 102, 103, 26,
] as const;

export const ACHIEVEMENT_COLUMN_NAMES = [
  'M_WORKER',
  'M_CONGRESS',
  'M_PRESIDENT',
  'M_MEDIA',
  'M_BHERO',
  'M_RHERO',
  'M_SSOLIDER',
  'M_SOCIETY',
  'M_TRUEPATRIOT',
  'M_TRUEALLY',
  'M_TR',
  'M_CH',
  'M_VP',
  'M_WF1',
  'M_WF2',
  'M_WF3',
  'M_S1',
  'M_S2',
  'M_BB',
  'M_BT',
  'M_AM',
  'M_BBC1',
  'M_BBC2',
  'M_BBC3',
  'M_JUDGE',
] as const;

export const SPECIAL_AWARDS = {
  1: 'Best Admin ;)',
  21460: 'Best Fighter',
  1248: 'Best Trader',
  5677: 'Best Politician',
  370: 'Best Politician',
  60737: 'Best Journalist',
  89705: 'Funniest Citizen',
} as const;

export const USER_NO_MEDAL = 0;
export const DEFAULT_MEDALS = 13;
