export const GLOBAL_CONSTANTS = {
  LOCALSTORAGE_KEYS: {
    NOTIFICATIONS: 'notifications',
    CHAT: 'chat',
    TOP_USERS: 'top_users',
    TOP_ARTICLES: 'top_articles',
    WEEKLY_LEADERBOARD: 'weeklyleaderboard',
  },
  TOTAL_CONGRESSMAN_RATE: 20,
  ELECTION_DAYS_IN_MONTH: {
    PARTY: 25,
    CONGRESS: 15,
    PRESIDENCY: 5,
  },
  FIGHT_LOADING_INTERVAL: 1800,
  LAW_ALLOWED_ROLE: 2,
  ADMIN_ID: 1,
  CACHE_EXPIRATION: 360,
  FAST_CACHE_EXPIRATION: 5,
  SHOULD_CACHE_CHAT: false,
  SOCKET_CHAT_MESSAGE: 'update_chat',
  SOCKET_CHAT_TYPING: 'send_chat_typing',
  SOCKET_HELPER_MESSAGE: 'update_helper',
  SOCKET_BATTLEFIELD_UPDATE: 'update_battlefield',
  SOCKET_WHO_IS_ONLINE: 'update_who_is_online',
  BATTLE_REFRESH_INTERVAL: 18000,
  ADMIN_KEY: 'admin',
  MODERATOR_KEY: 'moderator',
  EXPERIENCE_BAR_ID: 'animatexp',
  TUTORIAL_KEY: 'welcometutorial',
};

export const MESSAGE_MAX_LENGTH = 255;
export const HOSPITAL_EXPIRATION = 35;
export const MAX_NEWSPAPER_TITLE_LENGTH = 25;

export const POLITICS = {
  CONGRESS: 1,
  COUNTRY_PRESIDENT: 2,
} as const;

export const RANKINGS_PROPS = {
  TYPE: 1,
  FROM: 0,
  TO: 50,
  COUNTRY_ID: 0,
};

export enum BATTLE_SIDES {
  ATTACKER = 1,
  DEFENDER = 2,
}

export const CHAT_VOTE = {
  LIKE: 1,
  DISLIKE: 2,
};

export enum ADMIN_AUTHORIZATION {
  USER = 0,
  MODERATOR = 1,
  ADMIN = 2,
}
