import ReactTooltip from 'react-tooltip';
import { useId, ReactNode, FC } from 'react';

export interface DisabledButtonProps {
  icon: ReactNode;
  text: string;
}

export const DisabledButton: FC<DisabledButtonProps> = ({ icon, text }: DisabledButtonProps) => {
  const id = useId();

  return (
    <>
      <button
        className="w-32 sm:w-40 rounded-md p-2 disabled uppercase shadow-lg bg-red-400/60 hover:bg-red-400/80 text-base font-bold text-gray-200 sm:text-sm cursor-not-allowed"
        data-tip={true}
        data-for={id}
      >
        {icon}
      </button>

      <ReactTooltip id={id} effect="solid">
        <span>{text}</span>
      </ReactTooltip>
    </>
  );
};
