import { Divider } from '../../../components/Divider';
import React, { useEffect, useState } from 'react';
import { countryFlags } from '../../../helpers/Helpers';

import {imageAsBackground} from '../../../helpers/image-as-background';
import { fromCDN } from '../../../helpers/Images';

export default function DamageStatistics({ user, translation }) {
  const [truePatriotDamage, setTruePatriotDamage] = useState(user['TP_DMG']);
  const [truePatriotMedals, setTruePatriotMedals] = useState(user['M_TRUEPATRIOT']);
  const [truePatriotPercentage, setTruePatriotPercentage] = useState();
  const [truePatriotNextMedal, setTruePatriotNextMedal] = useState();

  const calculateTruePatriot = () => {
    let userTpDamage = truePatriotDamage;
    let tpDamage = 0;
    let tp_s = 0;

    for (let i = 0; i <= truePatriotMedals; i++) {
      tpDamage = tpDamage + 80000 + 80000 * i;
      tp_s = tp_s + 80000 + 80000 * (i - 1);
    }
    setTruePatriotNextMedal(tpDamage);
    let truePatriotPercentages = ((truePatriotDamage - tp_s) / (tpDamage - tp_s)) * 100;
    setTruePatriotPercentage(truePatriotPercentages);
  };

  const [trueAllyDamage, setTrueAllyDamage] = useState(user['TA_DMG']);
  const [trueAllyMedals, setAllyMedals] = useState(user['M_TRUEALLY']);
  const [trueAllyPercentage, setTrueAllyPercentage] = useState();
  const [trueAllyNextMedal, setTrueAllyNextMedal] = useState();

  const calculateTrueAlly = () => {
    let userTpDamage = trueAllyDamage;
    let tpDamage = 0;
    let tp_s = 0;

    for (let i = 0; i <= trueAllyMedals; i++) {
      tpDamage = tpDamage + 80000 + 80000 * i;
      tp_s = tp_s + 80000 + 80000 * (i - 1);
    }
    setTrueAllyNextMedal(tpDamage);
    let trueAllyPercentages = ((trueAllyDamage - tp_s) / (tpDamage - tp_s)) * 100;
    setTrueAllyPercentage(trueAllyPercentages);
  };

  const [trueRevolutionaryDamage, setTrueRevolutionaryDamage] = useState(user['TR_DMG']);
  const [trueRevolutionaryMedals, setRevolutionaryMedals] = useState(user['M_TR']);
  const [trueRevolutionaryPercentage, setTrueRevolutionaryPercentage] = useState();
  const [trueRevolutionaryNextMedal, setTrueRevolutionaryNextMedal] = useState();

  const calculateTrueRevolutionary = () => {
    let userTpDamage = trueRevolutionaryDamage;
    let tpDamage = 0;
    let tp_s = 0;

    for (let i = 0; i <= trueRevolutionaryMedals; i++) {
      tpDamage = tpDamage + 80000 + 80000 * i;
      tp_s = tp_s + 80000 + 80000 * (i - 1);
    }
    setTrueRevolutionaryNextMedal(tpDamage);
    let trueRevolutionaryPercentages = ((trueRevolutionaryDamage - tp_s) / (tpDamage - tp_s)) * 100;
    setTrueRevolutionaryPercentage(trueRevolutionaryPercentages);
  };

  useEffect(() => {
    calculateTruePatriot();
    calculateTrueAlly();
    calculateTrueRevolutionary();
  }, [user]);
  return (
    <>
      <div className={'text-center mt-24'}>
        <p className={'mb-3 font-bold text-indigo-300'}>{translation('GAME_PROFILE_MEDAL_NAME_13')}</p>
        <div
          style={imageAsBackground(fromCDN.fetchStaticImage('t_truepatriotbg', 'jpg'))}
          className="max-w-81 text-gray-200 justify-center shadow-md rounded-lg p-3"
        >
          <p className={'p-2 font-bold drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
            {translation('GAME_DAMAGE_PROFILE')}{' '}
            <span className={'font-medium ml-2 drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
              {Intl.NumberFormat().format(truePatriotDamage)}{' '}
            </span>
            <span className={''}>{countryFlags(user.COUNTRY, 'ml-2 inline mx-auto')}</span>
          </p>
          <div className={'font-bold p-2 drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
            <div className="progressBarTemplate">
              <div
                className="progressBarNumbers bg-blue-600 transition ease delay-50  duration-300"
                style={{ width: parseInt(truePatriotPercentage) + '%' }}
              >
                <span className="text-blue-500 pl-1"> - </span>
              </div>
            </div>
          </div>
          <p className={'font-bold -mt-2 text-gray-200 drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
            {Intl.NumberFormat().format(truePatriotDamage)} / {Intl.NumberFormat().format(truePatriotNextMedal)}
          </p>
        </div>
      </div>

      <div className={'text-center mt-10'}>
        <p className={'mb-3 font-bold text-green-300'}>{translation('GAME_PROFILE_MEDAL_NAME_14')}</p>
        <div
          style={imageAsBackground(fromCDN.fetchStaticImage('t_trueallybg', 'jpg'))}
          className="max-w-81 text-gray-200 justify-center shadow-md rounded-lg p-3"
        >
          <p className={'p-2 font-bold drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
            {translation('GAME_DAMAGE_PROFILE')}{' '}
            <span className={'font-semibold ml-2 drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
              {Intl.NumberFormat().format(trueAllyDamage)}
            </span>
            <img className="h-7 w-9 ml-1 mb-1 mx-auto inline" src={fromCDN.fetchStaticImage('t_trueally', 'png')} />
          </p>
          <div className={'font-bold p-2 drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
            <div className="progressBarTemplate">
              <div
                className="progressBarNumbers bg-blue-600 transition ease delay-50  duration-300"
                style={{ width: parseInt(trueAllyPercentage) + '%' }}
              >
                <span className="text-green-500 pl-1"> - </span>
              </div>
            </div>
          </div>
          <p className={'font-bold -mt-2 text-gray-200 drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
            {Intl.NumberFormat().format(trueAllyDamage)} / {Intl.NumberFormat().format(trueAllyNextMedal)}
          </p>
        </div>
      </div>

      <div className={'text-center mt-10'}>
        <p className={'m-3 font-bold text-red-300'}>{translation('GAME_PROFILE_MEDAL_NAME_18')}</p>
        <div
          style={imageAsBackground(fromCDN.fetchStaticImage('t_truerevolutionarybg', 'jpg'))}
          className="max-w-81 text-gray-200 justify-center shadow-md rounded-lg p-3"
        >
          <p className={'p-2 font-bold drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
            {translation('GAME_DAMAGE_PROFILE')}{' '}
            <span className={'font-semibold ml-2 drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
              {Intl.NumberFormat().format(trueRevolutionaryDamage)}{' '}
            </span>
            <img
              className="h-6 w-7 ml-1 mb-1 mx-auto inline"
              src={fromCDN.fetchStaticImage('t_truerevolutionary', 'png')}
            />
          </p>
          <div className={'font-bold p-2 drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
            <div className="progressBarTemplate">
              <div
                className="progressBarNumbers bg-blue-600 drop-shadow-[0_3px_3px_rgba(0,0,0,0.6)] transition ease delay-50  duration-300"
                style={{ width: parseInt(trueRevolutionaryPercentage) + '%' }}
              >
                <span className="text-red-500 pl-1"> - </span>
              </div>
            </div>
          </div>
          <p className={'font-bold  -mt-2 text-gray-200 drop-shadow-[0_3px_3px_rgba(0,0,0,0.4)]'}>
            {Intl.NumberFormat().format(trueRevolutionaryDamage)} /{' '}
            {Intl.NumberFormat().format(trueRevolutionaryNextMedal)}
          </p>
        </div>
      </div>
    </>
  );
}
