import { AppModal } from '../../components/AppModal';
import { useTranslation } from 'react-i18next';
import { useHelper } from '../../hooks/use-helper';
import { countryFlags } from '../../helpers/Helpers';
import { fromCDN } from '../../helpers/Images';
import { authApi } from '../../api';
import ApplicationAlert from '../../components/ApplicationAlert';
import React from 'react';
interface StartResistanceWarProps {
  regionId: number;
  regionOwner: number;
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const StartResistanceWar = ({ regionId, regionOwner, setModal, modal }: StartResistanceWarProps) => {
  const { t } = useTranslation('common');
  const { helpers } = useHelper();

  const handleStartResistanceWar = (type: 'GOLD' | 'CC') => {
    authApi
      .post('/api/resistance/start', {
        regionId: regionId,
        type: type,
      })
      .then((resp) => {
        setModal((prevState) => !prevState);
        ApplicationAlert(t(resp.data)).success();
        return;
      })
      .catch((e) => {
        ApplicationAlert(t(e.response.data)).error();
        return;
      });
  };
  return (
    <>
      <AppModal
        body={
          <>
            <div className="text-gray-200 text-center justify-center p-3" data-testid="start-resistance-war">
              <p>{t('GAME_PROFILE_MEDAL_TEXT_8')}</p>
              <span className="pt-5">
                {helpers.Regions[regionId].Name} {countryFlags(helpers.Regions[regionId].Owner, ' mx-auto')}
              </span>

              <div className="flex justify-center">
                <div className="flex justify-center">
                  <div className="lg:mr-4 p-3 text-center">
                    <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                      {countryFlags(regionOwner, 'h-8 w-8 mx-auto')}
                    </span>
                    <span className="text-sm text-gray-900 bg-yellow-200 p-2 rounded-lg">
                      {helpers.Game.ResistanceWarCurrencyCost} {helpers.Countries[regionOwner].Currency}{' '}
                    </span>
                    <p className="mt-4 text-gray-800">
                      <button
                        onClick={() => {
                          handleStartResistanceWar('CC');
                        }}
                        className=" default-save-button"
                      >
                        {t('GAME_START')}
                      </button>
                    </p>
                  </div>
                </div>

                <div className="lg:mr-4 p-3 text-center">
                  <span className=" font-bold block uppercase tracking-wide text-gray-700 m-3">
                    <img
                      className="h-8 w-8 mx-auto"
                      src={fromCDN.fetchStaticGameImage('/icons/gold-icon')}
                      alt="Workflow"
                    />
                  </span>
                  <span className="text-sm text-gray-900 bg-yellow-200 p-2 rounded-lg">
                    {helpers.Game.ResistanceWarGoldCost}
                  </span>
                  <p className="mt-4 text-gray-800">
                    <button
                      onClick={() => {
                        handleStartResistanceWar('GOLD');
                      }}
                      className="default-save-button"
                    >
                      Start
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </>
        }
        setOpen={setModal}
        open={modal}
        backgroundStyle={{ backgroundColor: '#1e293b' }}
      />
    </>
  );
};
