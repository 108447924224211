import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { JobOffer, PostChangeJobOfferProps } from './types';
import { Button } from '../../../../components/Buttons/Button';

export interface EmploymentOfferProps {
  jobOffers: JobOffer | null;
  onPlaceJobOffer: (props: PostChangeJobOfferProps) => void;
  onRemoveJobOffer: () => void;
}

export const EmploymentOffer = ({ jobOffers, onPlaceJobOffer, onRemoveJobOffer }: EmploymentOfferProps) => {
  const { t } = useTranslation('common');
  const [amount, setAmount] = useState(0);
  const [salary, setSalary] = useState(0);

  useEffect(() => {
    setAmount(jobOffers?.AMOUNT ?? 0);
    setSalary(jobOffers?.SALARY ?? 0);
  }, [jobOffers]);

  const hasOffers = !isEmpty(jobOffers);
  const buttonText = hasOffers ? 'GAME_REMOVE_JOB_OFFERS' : 'GAME_PLACE_JOB_OFFER';
  const buttonStyles = hasOffers ? 'bg-red-700 enabled:hover:bg-red-800' : 'bg-blue-700 hover:bg-blue-800';
  const onClick = hasOffers
    ? onRemoveJobOffer
    : () => {
        onPlaceJobOffer({ amount, salary });
      };

  return (
    <div className="m-5 mt-10 flex gap-x-4 flex-col sm:flex-row items-center sm:items-end gap-y-4">
      <label className="flex flex-col gap-y-2 font-bold text-center">
        {t('GAME_ACTIVE_OFFERS')}
        <input
          className="outline-none focus:ring ring-orange-900 p-2 pr-0 rounded-lg text-gray-900 font-bold w-48 disabled:text-gray-50"
          value={amount !== 0 ? amount.toString() : jobOffers?.AMOUNT ?? 0}
          onChange={(e) => {
            setAmount(e.target.valueAsNumber);
          }}
          disabled={hasOffers}
          type="number"
          min="1"
        />
      </label>

      <label className="flex flex-col gap-y-2 font-bold text-center">
        {t('GAME_SALARY')}
        <input
          className="outline-none focus:ring ring-orange-900 p-2 pr-0 rounded-lg text-gray-900 font-bold w-48 disabled:text-gray-50"
          value={salary !== 0 ? salary.toString() : jobOffers?.SALARY ?? 0}
          onChange={(e) => {
            setSalary(e.target.valueAsNumber);
          }}
          disabled={hasOffers}
          type="number"
          min="1"
        />
      </label>
      <Button className={buttonStyles} onClick={onClick}>
        {t(buttonText)}
      </Button>
    </div>
  );
};
