import { useTranslation } from 'react-i18next';

import { MESSAGE_MAX_LENGTH } from '../constants/enum';

interface RemainingCharactersHintProps {
  text: string;
  className?: string;
}

export const RemainingCharactersHint = ({ text, className }: RemainingCharactersHintProps) => {
  const remainingCharacters = MESSAGE_MAX_LENGTH - text.length;
  const { t } = useTranslation('common');

  return (
    <p className={`${className ?? 'text-gray-200'} text-left`}>
      <span className={`${remainingCharacters === 0 ? 'text-red-600' : 'text-indigo-500'} font-medium`}>
        {remainingCharacters}{' '}
      </span>
      <span>{t('GAME_ABOUT_CHAR_LIMIT')}</span>
    </p>
  );
};
