type ExtensionType = 'png' | 'svg' | 'jpg' | 'gif';
export type StaticImageProps = (path: string, extension?: ExtensionType) => string;

interface FromCDNReturnTypes {
  fetchMilitaryUnitAvatar: (avatarId: string) => string;
  fetchMilitaryUnitCover: (avatarId: string) => string;
  fetchUserAvatar: (userId: string) => string;
  fetchPartyAvatar: (partyId: string) => string;
  fetchStaticImage: StaticImageProps;
  fetchStaticGameImage: StaticImageProps;
  fetchStaticImgImage: StaticImageProps;
  fetchDefaultUserAvatar: () => string;
}

export const fromCDN: FromCDNReturnTypes = (() => {
  const CDN_URL = process.env.REACT_APP_CDN_SERVER ?? '';


  const CDN_URL_STATIC = `${CDN_URL}/statics`;
  const CDN_URL_GAME = `${CDN_URL_STATIC}/game`;
  const CDN_URL_IMG = `${CDN_URL_STATIC}/img`;

  const fetchMilitaryUnitCover = (avatarId: string) => {
    return `${CDN_URL}/unit/cover/${avatarId}.jpg`;
  };
  const fetchMilitaryUnitAvatar = (avatarId: string) => {
    return `${CDN_URL}/unit/avatars/${avatarId}.jpg`;
  };

  const fetchUserAvatar = (userId: string) => {
    return `${CDN_URL}/upload/avatars/${userId}.jpg`;
  };

  const fetchPartyAvatar = (partyId: string) => {
    return `${CDN_URL}/party/avatars/${partyId}.jpg`;
  };

  const fetchDefaultUserAvatar = () => `${CDN_URL}/public/avatars/1.png`;

  const fetchStaticImage = (path: string, extension = 'png') => {
    if (!path || typeof path !== 'string') {
      throw new Error('"path" invalid or not provided');
    }

    return `${CDN_URL_STATIC}${path}.${extension}`;
  };

  const fetchStaticGameImage: StaticImageProps = (path, extension = 'png') => {
    if (!path || typeof path !== 'string') {
      throw new Error('"path" invalid or not provided');
    }

    return `${CDN_URL_GAME}${path}.${extension}`;
  };

  const fetchStaticImgImage: StaticImageProps = (path, extension = 'png') => {
    if (!path || typeof path !== 'string') {
      throw new Error('"path" invalid or not provided');
    }
    return `${CDN_URL_IMG}${path}.${extension}`;
  };

  return {
    fetchMilitaryUnitAvatar,
    fetchMilitaryUnitCover,
    fetchStaticImage,
    fetchUserAvatar,
    fetchStaticGameImage,
    fetchStaticImgImage,
    fetchPartyAvatar,
    fetchDefaultUserAvatar,
  };
})();
