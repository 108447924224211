import React, { FC } from 'react';

import { fromCDN } from '../helpers/Images';

export interface StorageImageProps {
  imagePath: string;
  className: string;
  testId?: string;
}

export const StorageImage: FC<StorageImageProps> = ({ imagePath, className, testId = 'storage-item' }) => {
  return <img className={className} src={fromCDN.fetchStaticGameImage(`/items/${imagePath}`)} data-testid={testId} />;
};

export default StorageImage;
