import React, { useState, useId, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from 'react-router-dom';

import { APP_PUBLISH_URL, APP_URL } from '../../constants/constants';
import { AppModal } from '../../components/AppModal';
import { fromCDN } from '../../helpers/Images';

export default function ProfileFriends({ user, translation, friends }) {
  let navigate = useNavigate();

  const [modal, setModal] = useState();
  const [modalBody, setModalBody] = useState();
  const id = useId();

  if (friends && friends.length <= 5) {
    return (
      <>
        <h3 className="mx-5 mt-12 font-bold text-center">{translation('GAME_INVITE_FRIEND')}</h3>
        <div
          data-tip={true}
          data-for={id}
          className="font-bold text-gray-900 m-5 text-center bg-gray-100 shadow-lg rounded-lg p-3"
        >
          {APP_PUBLISH_URL + '/register/' + user['id']}
        </div>

        <ReactTooltip id={id} effect="solid" data-html={true}>
          {translation('GAME_INVITE_FRIENDS_TOOLTIP')}
        </ReactTooltip>

        <h3 className="m-5 justify-center font-bold text-center">{translation('GAME_FRIENDS')}</h3>
        <div className="flex flex-wrap m-5 ml-10  gap-4">
          {friends?.map((item, key) => {
            return (
              <Fragment key={key}>
                <div onClick={() => navigate('/profile/' + item.U_ID)}>
                  <img
                    className="h-10 w-10 mx-auto shadow-lg cursor-pointer default-transition"
                    src={fromCDN.fetchUserAvatar(item.U_AVATAR)}
                    alt="avatar"
                  />
                </div>
              </Fragment>
            );
          })}
        </div>
      </>
    );
  }

  const onViewFriends = () => {
    setModalBody(
      friends?.map((item, key) => {
        return (
          <div className="text-center" key={key}>
            <img
              onClick={() => {
                navigate(`/profile/${item.U_ID}`);
                setModal(false);
              }}
              className="h-10 w-10 mx-auto shadow-lg cursor-pointer default-transition"
              src={fromCDN.fetchUserAvatar(item.U_AVATAR)}
              alt="avatar"
            />
            <span className="text-gray-200 text-tiny text-center">{item.U_NAME}</span>
            <p
              onClick={() => {
                navigate(`/profile/${item.U_ID}`);
                setModal(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mx-auto text-yellow-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
              </svg>
            </p>
          </div>
        );
      }),
    );

    setModal(true);
  };

  return (
    <>
      <h3 className="mx-5 mt-9 font-bold text-center">{translation('GAME_INVITE_FRIEND')}</h3>
      <div
        data-tip={true}
        data-for="invite-friends"
        className="font-bold text-gray-200 mx-5 mt-3 text-center bg-yellow-400/40 hover:bg-yellow-400/60 cursor-pointer shadow-lg rounded-lg p-3"
      >
        {APP_PUBLISH_URL + '/register/' + user['id']}
      </div>

      <ReactTooltip id="invite-friends" effect="solid" data-html={true}>
        {translation('GAME_INVITE_FRIENDS_TOOLTIP')}
      </ReactTooltip>
      <h3 className="mx-5 mt-8 justify-center font-bold text-center">
        {translation('GAME_FRIENDS')}
        <img
          className="h-6 w-6 ml-1 mb-1 mx-auto inline opacity-100"
          src={fromCDN.fetchStaticImage('t_friendsyellow', 'svg')}
        />
      </h3>
      <div className="flex flex-wrap m-5 ml-10  gap-4">
        {friends?.map((item, key) => {
          if (key < 6) {
            return (
              <div onClick={() => navigate('/profile/' + item.U_ID)} key={key}>
                <img
                  className="h-10 w-10 sm:h-10 sm:w-10 mx-auto shadow-lg cursor-pointer default-transition"
                  src={fromCDN.fetchUserAvatar(item.U_AVATAR)}
                  alt="avatar"
                />
              </div>
            );
          }
        })}

        {5 < friends?.length && (
          <button
            className="justify-center text-center p-2 bg-gray-200 font-bold cursor-pointer rounded-lg hover:bg-gray-400"
            onClick={onViewFriends}
          >
            View All
          </button>
        )}
      </div>

      <AppModal
        body={<div className="grid grid-cols-4 gap-4 m-5 ml-10">{modalBody}</div>}
        open={modal}
        setOpen={setModal}
        backgroundStyle={{
          backgroundColor: '#1e293b',
        }}
      />
    </>
  );
}
