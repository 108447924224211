import { avatarClass, BASE_URL, BEARER, defaultTransition } from '../../../constants/constants';
import React from 'react';
import { countryFlags } from '../../../helpers/Helpers';
import { useNavigate } from 'react-router-dom';
import { BanIcon, ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/solid';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { fromCDN } from '../../../helpers/Images';
import { useUser } from '../../../hooks/use-user';

export default function UnitMembers({ item, unit, shouldUpdateMembers, setShouldUpdateMembers = () => {} }) {
  const { user } = useUser();
  let navigate = useNavigate();
  const { t, i18n } = useTranslation('common');

  async function kickMember(memberId) {
    await axios
      .post(
        BASE_URL + '/api/military-unit/kick-member',
        {
          unitId: unit.ID,
          memberId: memberId,
        },
        {
          headers: { Authorization: 'Bearer ' + BEARER },
        },
      )
      .then((response) => {
        setShouldUpdateMembers(response.data);
      })
      .catch((error) => {});
  }

  async function promoteMember(memberId) {
    await axios
      .post(
        BASE_URL + '/api/military-unit/promote-member',
        {
          unitId: unit.ID,
          memberId: memberId,
        },
        {
          headers: { Authorization: 'Bearer ' + BEARER },
        },
      )
      .then((response) => {
        setShouldUpdateMembers(response.data);
      })
      .catch((error) => {});
  }

  async function demoteMember(memberId) {
    await axios
      .post(
        BASE_URL + '/api/military-unit/demote-member',
        {
          unitId: unit.ID,
          memberId: memberId,
        },
        {
          headers: { Authorization: 'Bearer ' + BEARER },
        },
      )
      .then((response) => {
        setShouldUpdateMembers(response.data);
      })
      .catch((error) => {});
  }

  return (
    <>
      <div className={' justify-center text-center cursor-pointer '}>
        <img
          className={'mx-auto w-12 h-12 ' + avatarClass}
          onClick={() => navigate('/profile/' + item.id)}
          src={fromCDN.fetchUserAvatar(item.AVATAR)}
        />
        {countryFlags(item.CITIZENSHIP, 'mx-auto')}
        <p>{item.user}</p>
        {unit.CAPTAIN.id === item.id || unit.CAPTAIN2.id === item.id || unit.CAPTAIN3.id === item.id ? (
          <>
            <p className={'bg-yellow-700 p-1 rounded'}>Officer</p>
          </>
        ) : (
          <>
            <p className={'bg-gray-300 p-1 text-gray-800 rounded'}>Member</p>
          </>
        )}
        {unit.OWNER === user.id ? (
          <>
            {unit.CAPTAIN.id === item.id || unit.CAPTAIN2.id === item.id || unit.CAPTAIN3.id === item.id ? (
              <>
                <ChevronDoubleDownIcon
                  onClick={() => demoteMember(item.id)}
                  data-tip
                  data-for={'demotecaptain'}
                  className={'h-5 w-5 mx-auto text-yellow-500 m-2 ' + defaultTransition}
                />
                <ReactTooltip id={'demotecaptain'} effect="solid" data-html={true}>
                  {t('GAME_UNIT_DEMOTE_CAPTAIN')}
                </ReactTooltip>
              </>
            ) : (
              <>
                <ChevronDoubleUpIcon
                  onClick={() => promoteMember(item.id)}
                  data-tip
                  data-for={'promotecaptains'}
                  className={'h-5 w-5 mx-auto text-blue-500 m-2 ' + defaultTransition}
                />
                <ReactTooltip id={'promotecaptains'} effect="solid" data-html={true}>
                  {t('GAME_UNIT_PROMOTE_CAPTAIN')}
                </ReactTooltip>
              </>
            )}

            <BanIcon
              onClick={() => kickMember(item.id)}
              data-tip
              data-for={'kickuser'}
              className={'text-red-600 h-5 w-5 mx-auto ' + defaultTransition}
            />
            <ReactTooltip id={'kickuser'} effect="solid" data-html={true}>
              {t('GAME_UNIT_KICK_MEMBER')}
            </ReactTooltip>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
