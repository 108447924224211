import { authApi } from '../../../../api';
import { useEffect, useState } from 'react';
import Avatar from '../../../../components/Avatar';
import { useTranslation } from 'react-i18next';

import { countryFlags } from '../../../../helpers/Helpers';
import { Divider } from '../../../../components/Divider';
import PartyAvatar from '../../../../components/PartyAvatar';
import ApplicationAlert from '../../../../components/ApplicationAlert';
import { useHelper } from '../../../../hooks/use-helper';

export default function CongressElectionOpen({ electionMonth, countryId }) {
  const [electionList, setElectionList] = useState();
  const [totalVotes, setTotalVotes] = useState();
  const { helpers } = useHelper();
  const { t } = useTranslation('common');
  const getElections = () => {
    authApi
      .get('/api/elections/' + countryId + '/congress/' + electionMonth)
      .then((response) => {
        setElectionList(response.data.CANDIDATES);
        setTotalVotes(response.data.TOTAL_VOTES);
      })
      .catch((err) => {});
  };
  const handleVote = (vote) => {
    authApi
      .post('/api/elections/' + countryId + '/voteelection', {
        vote: vote,
        electionMonth: electionMonth,
      })
      .then((resp) => {
        ApplicationAlert(t(resp.data)).success();
        getElections();
        return;
      })
      .catch((e) => {
        ApplicationAlert(t(e.response.data)).error();
        return;
      });
  };

  useEffect(() => {
    getElections();
  }, []);
  return (
    <>
      <div className={'text-white p-5 break-words grid grid-cols-2 sm:grid sm:grid-cols-5 gap-4 '}>
        {electionList &&
          electionList.map((candidate) => {
            return (
              <div className="bg-gradient-to-t from-emerald-500 to-emerald-700 rounded-lg p-3 text-center ">
                <p className="text-gray-800 font-semibold text-xs">{t('GAME_PARTY_PRESIDENT')}</p>
                <Avatar avatarId={candidate.L_USERAVATAR} className={'mx-auto w-12 h-12'} userId={candidate.L_ID} />
                <p className={'text-gray-800 font-bold antialiased '}>{candidate.L_USERNAME}</p>
                <p>{candidate.P_NAME}</p>
                <PartyAvatar avatarId={candidate.P_AVATAR} partyId={candidate.P_ID} className={'mx-auto'} />
                <p>{countryFlags(candidate.P_COUNTRY, 'mx-auto')}</p>
                <Divider margin={'m-3'} />
                <p>
                  {t('GAME_TOTAL_VOTES')} : {candidate.L_VOTES}
                </p>
                {helpers.Game.Election.Dates.Congress != helpers.Game.DayOfMonth ? (
                  <>
                    <p className="bg-gray-500 rounded-lg p-3 mt-2 text-red-100">{t('GAME_ELECTION_IS_OVER')}</p>
                  </>
                ) : (
                  <button className="default-green-button uppercase" onClick={() => handleVote(candidate.P_ID)}>
                    {t('GAME_VOTE')}
                  </button>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
}
