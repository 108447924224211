
import moment from "moment-timezone";
import {GameTimeZoneReturnProps} from "../shared-types/helper-types";

export const gameTimeZone = (): GameTimeZoneReturnProps => {
    const timeZone = moment();
    timeZone.tz("America/Los_Angeles", false).format("YYYY-MM-DD HH:mm:ss");

    return {
        getDifferenceFromGameTime: ({ date }) => {
            const gameMoment = moment(timeZone);
            const dateMoment = moment(date, "YYYY-MM-DD HH:mm:ss").add(17, "hours");
            const diff = moment(dateMoment).diff(gameMoment, "milliseconds");
            return Date.now() + diff;
        },
        getDifferenceFromGameTimeNormal: ({ date }) => {
            const gameMoment = moment(timeZone);
            const dateMoment = moment(date, "YYYY-MM-DD HH:mm:ss").add(9, "hours");
            const diff = moment(dateMoment).diff(gameMoment, "milliseconds");
            return Date.now() + diff;
        },

        getDifferenceFromGameTimeWhatTheFuck: ({ date }) => {
            const gameMoment = moment(timeZone);
            const dateMoment = moment(date, "YYYY-MM-DD HH:mm:ss").subtract(
                9,
                "hours"
            );
            const diff = moment(dateMoment).diff(gameMoment, "milliseconds");
            return Date.now() + diff;
        },
        addOneDay: ({ date }) => {
            const gameMoment = moment(timeZone);
            const dateMoment = moment(date, "YYYY-MM-DD HH:mm:ss")
                .add(1, "day")
                .add("9", "hours");
            const diff = moment(dateMoment).diff(gameMoment, "milliseconds");
            return Date.now() + diff;
        },
        getHowManyDaysLeftToElections: ({ electionDay }) => {
            const daysLeft = moment(timeZone).date() - electionDay;

            return Math.abs(daysLeft);
        },
    };
};
