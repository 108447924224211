import { FC, useState } from 'react';
import { isAdminAuthorized } from './Administration';
import { FeedBackground } from '../../assets/Styles/Styles';
import Settings from './Containers/Settings';
import { PlayerEdit } from './Containers/PlayerEdit';
/* eslint-disable  */
interface SettingsComponentNotationProps {
  Events: JSX.Element;
  Player: JSX.Element;
}

const settingsComponentsNotation: SettingsComponentNotationProps = {
  Events: <Settings />,
  Player: <PlayerEdit />,
};

const AdministrationPanel: FC = () => {
  if (!isAdminAuthorized()) {
    return <></>;
  }

  const [currentView, setCurrentView] = useState<JSX.Element | null>();

  return (
    <>
      <main className="min-h-screen" style={FeedBackground()}>
        <div className="min-w-full mx-auto sm:px-6 lg:px-8 text-gray-300">
          <div>
            <article className=" md:flex-nowrap  mx-auto max-w-xxl gap-4">
              <section>
                <nav className="flex items-center justify-between flex-wrap bg-indigo-500 p-6 rounded-lg">
                  <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <span className="font-semibold text-xl tracking-tight">Settings</span>
                  </div>

                  <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                    <div className="text-sm lg:flex-grow">
                      {Object.entries(settingsComponentsNotation).map((item, key) => {
                        return (
                          <>
                            <a
                              key={item[0]}
                              onClick={() => {
                                setCurrentView(
                                  settingsComponentsNotation[item[0] as keyof SettingsComponentNotationProps],
                                );
                              }}
                              className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 cursor-pointer"
                            >
                              {item[0]}
                            </a>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </nav>
                {currentView}
              </section>
            </article>
          </div>
        </div>
      </main>
    </>
  );
};

export default AdministrationPanel;
