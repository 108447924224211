export interface FightImagesProps {
  round: number;
  side: number;
}

export const resolveHitFlow = ({ round, side }: FightImagesProps) => {
  let divClasses = '';
  let fightImages = '';
  let animation = ''; // round 3 default

  if (round === 1) {
    if (side === 1) {
      fightImages = '/battle/soldierattacker';
      animation = 'animate__animated animate__fadeIn';
      divClasses = 'round1AttackerFirstFighter';
    }
    if (side === 2) {
      fightImages = '/battle/soldierdefender';
      animation = 'animate__animated animate__fadeIn';
      divClasses = 'round1DefenderFirstFighter';
    }
  }
  if (round === 2) {
    if (side === 1) {
      //tankattacker12
      fightImages = '/battle/tankattacker';
      animation = 'animate__animated animate__fadeIn';
      divClasses = 'round1AttackerFirstFighter';
    }
    if (side === 2) {
      fightImages = '/battle/tankdefender';
      animation = 'animate__animated animate__fadeIn';
      divClasses = 'round1DefenderFirstFighter';
    }
  }
  if (round === 3) {
    if (side === 1) {
      divClasses = 'lastAttackerFirstFighter';
      fightImages = '/battle/attackerair';
      animation = 'animate__animated animate__fadeInLeft';
    }
    if (side === 2) {
      fightImages = '/battle/defenderair';
      animation = 'animate__animated animate__fadeInRight';
      divClasses = 'lastDefenderFirstFighter';
    }
  }
  return {
    class: divClasses,
    image: fightImages,
    animation: animation,
  };
};
