import { useTranslation } from 'react-i18next';

import { Loader } from '../../components/Loader';
import { countryFlags } from '../../helpers/Helpers';

import { imageAsBackground } from '../../helpers/image-as-background';
import StarIcon from '../Util/Icons/star/StarIcon';
import { fromCDN } from '../../helpers/Images';
import { AvatarWithDefaultFallback } from '../../components/ImageWithFallback';
import { normalizeUserName } from '../../helpers/normalize';
import { UserProps } from '../../shared-types/user-type';
interface LandingTopArticlesProps {
  AVATAR: UserProps['AVATAR'];
  CITIZENSHIP: UserProps['CITIZENSHIP'];
  EXPERIENCE: UserProps['EXPERIENCE'];
  id: UserProps['id'];
  user: UserProps['user'];
}
export const LandingPageTopUsers = ({ topCitizens }: { topCitizens?: LandingTopArticlesProps[] }) => {
  const { t } = useTranslation('common');

  return (
    <ol className="text-white">
      <li
        className="h-14 flex items-center px-4 rounded-t-lg"
        style={imageAsBackground(fromCDN.fetchStaticImgImage('/citizens_header'))}
      >
        <span className="text-base font-semibold">{t('GAME_TOP_CITIZENS')}</span>
      </li>

      {!topCitizens && (
        <li>
          <Loader />
        </li>
      )}

      {topCitizens?.map((citizen) => (
        <li key={citizen.id}>
          <div
            style={imageAsBackground(fromCDN.fetchStaticImgImage('/table_item'))}
            className="flex justify-start items-center px-6 py-3 gap-4"
          >
            <AvatarWithDefaultFallback className="h-10 w-10" avatarId={citizen.AVATAR} />
            <div className="grid grid-cols-2 w-full">
              <span>{normalizeUserName(citizen.user)}</span>
              <span className="justify-self-end">{citizen.EXPERIENCE}</span>
              {countryFlags(citizen.CITIZENSHIP, 'h-5 w-5')}
              <StarIcon className="justify-self-end h-5 w-5 text-white" />
            </div>
          </div>
        </li>
      ))}
    </ol>
  );
};
