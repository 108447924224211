import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { authApi } from '../../../api';

import { Divider } from '../../../components/Divider';

import Charts from '../../Util/Charts';
import EconomyTax from './EconomyTax';
import { PopoverTheme } from '../../../components/PopoverTheme';
import Donate from './Donate';

import { countryFlags } from '../../../helpers/Helpers';
import { Taxes } from './Taxes';

import { EconomyResources } from './EconomyResources';
import { fromCDN } from '../../../helpers/Images';
import { useHelper } from '../../../hooks/use-helper';
import { CountryResource } from './CountryResource';
import { animate } from '../../../util/animate.ts';

export default function Economy() {
  const params = useParams();
  const currentCountry = params.id;

  const { t } = useTranslation('common');
  const { helpers } = useHelper();

  const [economyDetails, setEconomyDetails] = useState();
  const [taxDetails, setTaxDetails] = useState();
  const [countryBonuses, setCountryBonuses] = useState([0, 0, 0, 0]);
  const [regionsDetail, setRegionsDetail] = useState();
  const [routeRegions, setRouteRegions] = useState();
  const [taxViews, setTaxViews] = useState();

  let chartsLabel = [];

  for (let y = 0; y < 7; y++) {
    chartsLabel.push(helpers.Game.Day - y);
  }

  function getSocietyDetails() {
    authApi.get(`/api/country/economy/${currentCountry}`).then((response) => {
      setRegionsDetail(response.data.REGIONS);
      setTaxDetails(response.data.TAX);
      setCountryBonuses(response.data.BONUSES);
      setRouteRegions(response.data.ROUTES);
      setEconomyDetails(response.data.ECONOMY);
      setTaxViews(<EconomyTax economyInfo={response.data.ECONOMY} />);

      animate({ targetElementId: 'economyDetails', animation: 'fadeIn' });
    });
  }

  useEffect(() => {
    getSocietyDetails();
  }, [currentCountry]);

  return (
    <div id="economyDetails">
      {economyDetails && (
        <>
          <h1 className="m-3 font-bold text-yellow-600">{t('GAME_TREASURY')}</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 mx-5 gap-4">
            <div className="grid justify-center p-3 text-center bg-gray-100 shadow-inner rounded-md">
              <div className="font-bold  text-gray-700 flex">
                <div className=" text-gray-700 font-extrabold" style={{ fontSize: '1rem' }}>
                  {t('GAME_GOLD')}
                  <div className="text-green-800 font-extrabold mt-2" style={{ fontSize: '1.5rem' }}>
                    <div className="flex gap-1">
                      {economyDetails.GOLD.toFixed(2)}
                      <img className="h-6 w-6 -mt-1" src={fromCDN.fetchStaticGameImage('/icons/gold-icon')} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative sm:absolute float-right ">
                <PopoverTheme
                  buttonText={t('GAME_DONATE')}
                  dataCy={'goldpopover'}
                  content={
                    <Donate
                      donationSection={'country'}
                      donationType={'gold'}
                      donationCountry={currentCountry}
                      setter={setEconomyDetails}
                    />
                  }
                />
              </div>
            </div>

            <div className="grid justify-center p-3 text-center bg-gray-100 shadow-inner rounded-md">
              <div className="font-bold  text-gray-700 flex">
                <div className=" text-gray-700 font-extrabold" style={{ fontSize: '1rem' }}>
                  {helpers.Countries[currentCountry].Currency}
                  <div className=" text-green-800 font-extrabold mt-2" style={{ fontSize: '1.5rem' }}>
                    <div className="flex gap-1">
                      {economyDetails.CC.toFixed(2)}
                      {countryFlags(currentCountry)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative sm:absolute float-right">
                <PopoverTheme
                  buttonText={t('GAME_DONATE')}
                  dataCy={'currencypopover'}
                  content={
                    <Donate
                      donationSection={'country'}
                      donationType={'currency'}
                      donationCountry={currentCountry}
                      setter={setEconomyDetails}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <Divider />
          <div className="grid grid-cols-1 sm:grid-cols-2 mx-5 gap-4">
            <div data-testid={'economychart'}>
              {/* FIXME - this is showing some error */}
              <Charts labels={chartsLabel} dataInput={taxDetails[currentCountry]} />
            </div>
            <div>
              {countryBonuses.length && (
                <>
                  <h1 className="font-bold m-3 text-yellow-600">{t('GAME_BONUSES')}</h1>
                  <Divider margin="mx-5 my-1" />

                  <div className="grid grid-cols-3 mx-5 gap-4 text-center justify-center">
                    <CountryResource src={fromCDN.fetchStaticGameImage('/items/food')} bonuses={countryBonuses[1]} />
                    <CountryResource src={fromCDN.fetchStaticGameImage('/items/tank')} bonuses={countryBonuses[2]} />
                    <CountryResource src={fromCDN.fetchStaticGameImage('/items/house')} bonuses={countryBonuses[3]} />
                  </div>
                </>
              )}
            </div>
          </div>

          <EconomyResources t={t} regionsDetail={regionsDetail} routeRegions={routeRegions} />

          <Taxes
            t={t}
            country={helpers.Countries[currentCountry]}
            countryFlags={countryFlags(currentCountry, 'mx-auto')}
            economyDetails={economyDetails}
            taxViews={taxViews}
          />
        </>
      )}
    </div>
  );
}
