import { CSSProperties } from 'react';

export const imageAsBackground = (imageUrl: string, additional?: CSSProperties): CSSProperties => {
  const additionalProps = additional ?? {};

  return {
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ...additionalProps,
  };
}
