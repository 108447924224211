import { FC } from 'react';
import { Link } from 'react-router-dom';

import { fromCDN } from '../helpers/Images';

export interface AvatarProps {
  avatarId: string;
  partyId: string;
  className?: string;
  defaultImageClass?: string;
  defaultLinkClass?: string;
}

export const Avatar: FC<AvatarProps> = ({
  avatarId,
  partyId,
  className = '',
  defaultImageClass = 'h-12 w-12 cursor-pointer rounded-lg default-transition',
  defaultLinkClass = 'shadow-xl z-10 drop-shadow-xl shadow-gray-900',
}) => {
  return (
    <Link to={`/party/${partyId}`} className={defaultLinkClass}>
      <img className={defaultImageClass + ' ' + className} src={fromCDN.fetchPartyAvatar(avatarId)} alt="avatar" />
    </Link>
  );
};

export default Avatar;
