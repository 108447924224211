import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL, BEARER } from '../../../../constants/constants';
import { Loader } from '../../../../components/Loader';
import { countryFlags } from '../../../../helpers/Helpers';
import ApplicationAlert from '../../../../components/ApplicationAlert';
import RewardAlert from '../../../Util/Reward/RewardAlert';
import { fromCDN } from '../../../../helpers/Images';
import { useHelper } from '../../../../hooks/use-helper';
import { useUser } from '../../../../hooks/use-user';
import { Button } from '../../../../components/Buttons/Button';
import { normalizeUserName } from '../../../../helpers/normalize';
import { AvatarWithDefaultFallback } from '../../../../components/ImageWithFallback';

export default function Employed({ unemployedImage, t, employerId }) {
  const [employer, setEmployer] = useState();

  const [rewardModal, setRewardModal] = useState();
  const [isLoading, setIsLoading] = useState(<Loader width={100} height={100} shouldFullScreen={false} />);
  const { user, setUser } = useUser();
  const { helpers } = useHelper();

  async function getMyEmployer() {
    await axios
      .get(BASE_URL + '/api/user/shortprofile/' + employerId)
      .then((response) => {
        setEmployer(response.data);
        setIsLoading(null);
      })
      .catch((error) => {});
  }

  async function work() {
    await axios
      .post(
        BASE_URL + '/api/user/work',
        {},
        {
          headers: { Authorization: 'Bearer ' + BEARER },
        },
      )
      .then((response) => {
        setUser(response.data.USER);

        ApplicationAlert(<RewardAlert rewards={response.data.REWARDS} t={t} helper={helpers} />).success();

        setIsLoading(null);
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });
  }

  async function resign() {
    await axios
      .get(BASE_URL + '/api/business/jobresign', {
        headers: { Authorization: 'Bearer ' + BEARER },
      })
      .then((response) => {
        setUser(response.data.USER);

        ApplicationAlert(t(response.data.MESSAGE)).success();
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });
  }

  function handleResignWork() {
    resign();
  }

  function handleWork() {
    work();
  }

  useEffect(() => {
    // get public profile
    getMyEmployer();
  }, []);
  return (
    <>
      {isLoading}{' '}
      {employer ? (
        <>
          <div className={' text-center justify-center transition ease-in-out  duration-200  text-white'}>
            <div className={'p-5'}>
              <div>
                <span className="p-2 bg-gray-300 rounded bg-opacity-30">{normalizeUserName(employer.user)}</span>
                <AvatarWithDefaultFallback
                  className="h-24 w-24 mx-auto shadow-md rounded-md mt-3"
                  avatarId={employer.AVATAR}
                />
              </div>

              <Button color="red" onClick={handleResignWork} className="mb-6 mt-2">
                {t('GAME_RESIGN_JOB')}
              </Button>
              <div className="flex justify-center py-4 lg:pt-4 pt-8 jobCardTheme">
                <div className="mr-4 p-3 text-center">
                  <span className=" font-bold block  tracking-wide">
                    <p>
                      {t('GAME_GROSS_SALARY')}{' '}
                      <img className={'inline w-4 h-4'} src={fromCDN.fetchStaticImage('t_salarygreen', 'svg')} />
                    </p>
                  </span>
                  <span className="text-sm text-green-400 inline-flex">
                    <span>+{user['CO_SALARY']}</span>{' '}
                    <span className={'mt-0.5 ml-1'}> {countryFlags(employer['CITIZENSHIP'])}</span>
                  </span>
                </div>
                <div className="lg:mr-4 p-3 text-center">
                  <span className=" font-bold block  tracking-wide ">
                    <p>
                      {t('GAME_WORK_TAX')}{' '}
                      <img className={'inline w-4 h-4'} src={fromCDN.fetchStaticImage('t_salarygreentax', 'svg')} />
                    </p>
                  </span>
                  <span className=" text-red-400 inline-flex">
                    <span>-{(user['CO_SALARY'] / 100) * user['WORKTAX']}</span>{' '}
                    <span className={'mt-0.5 ml-1'}> {countryFlags(employer['CITIZENSHIP'])}</span>
                  </span>
                </div>
              </div>
              <div className=" text-center mt-10">
                <span className=" font-bold block uppercase tracking-wide text-gray-700">
                  <p className={'mb-2'} onClick={() => handleWork()}>
                    <Button color="green">{t('GAME_WORK')}</Button>
                  </p>
                  <img className="h-6 w-6 mx-auto" src={fromCDN.fetchStaticImage('/game/icons/energy-s', 'png')} alt="Workflow" />
                </span>
                <span className="text-sm mr-1 text-red-400">-10</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
}
