import { Navigate } from 'react-router-dom';

import LoaderContext, { BEARER } from '../constants/constants';
import Footer from '../containers/Footer/Footer';
import { PublicHeader } from '../containers/Header/PublicHeader';
import { ReactNode} from 'react';

export const PrivateRoute = ({ children }: { children: ReactNode }) => {
  return BEARER ? children : <Navigate to="/" />;
};

export const PublicRoute = ({ children, value }: { children: ReactNode; value: any }) => {
  if (BEARER) return <Navigate to="/feed" />;

  return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>;
};

export const PublicRouteWithHeaderAndFooter = ({ children, value }: { children: ReactNode; value: any }) => {
  if (BEARER) return <Navigate to="/feed" />;

  return (
    <LoaderContext.Provider value={value}>
      <PublicHeader />
      <div className="mt-16">{children}</div>
      <Footer />
    </LoaderContext.Provider>
  );
};
