import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import emojiSn from 'emoji-to-short-name';
import _ from 'lodash';

import { SectionHeader } from '../../components/SectionHeader';
import { Divider } from '../../components/Divider';
import { authApi } from '../../api';
import { fromCDN } from '../../helpers/Images';
import { imageAsBackground } from '../../helpers/image-as-background';

export default function Messages() {
  let params = useParams();
  let navigate = useNavigate();

  const [myMessages, setMyMessages] = useState();

  const { t } = useTranslation('common');

  useEffect(() => {
    authApi.get(`/api/messages/${params.id}`).then((response) => {
      setMyMessages(_.sortBy(response.data, 'ID').reverse());
    });
  }, []);

  return (
    <>
      <div
        className="min-h-screen max-w-13xl mx-auto bg-fixed"
        style={imageAsBackground(fromCDN.fetchStaticImage('t_messagesbg', 'svg'))}
      >
        <div className="max-w-7xl mx-auto py-2 " data-testid="messagessection">
          <SectionHeader title={t('GAME_MESSAGES')} />

          <div className="grid grid-cols-1 m-5 gap-8">
            {myMessages &&
              Object.values(myMessages).map((item) => {
                let bg = '';
                let isBold = '';
                if (item['TOTAL'] - item['READ'] > 0) {
                  bg = (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-green-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                    </svg>
                  );
                  isBold = 'bg-gray-100 p-3 rounded-lg font-bold text-gray-600';
                }

                return (
                  <Fragment key={item.ID}>
                    <div className="relative flex px-7 pt-6 pb-11 gap-4 cursor-pointer light-transition bg-gradient-to-b from-indigo-900/40 to-red-900/40 hover:bg-gradient-to-br hover:from-indigo-900/80 hover:to-red-900/80 rounded-lg">
                      <div className="relative rounded-lg h-20 w-20">
                        <img
                          src={fromCDN.fetchUserAvatar(item.AVATAR)}
                          className="bg-white border shadow-lg rounded hover cursor-pointer light-transition"
                          onClick={() => navigate('/profile/' + item.U_ID)}
                          alt=""
                          loading="lazy"
                        />
                        <p className="text-center text-gray-200 font-bold break-words">{item.user.substr(0, 15)}</p>
                      </div>

                      <div
                        onClick={() => navigate('/messages/read/' + item.U_ID)}
                        data-testid={'message_' + item.U_ID}
                        className="flex flex-col w-full"
                      >
                        <div className="flex flex-row justify-between">
                          <p className="relative font-bold text-yellow-400 whitespace-nowrap truncate overflow-hidden">
                            {item.SUBJECT}
                            <span className="text-gray-200 ml-2 font-light rounded-lg inline-flex">
                              {item.DATA}
                              {bg}
                            </span>
                          </p>
                        </div>
                        <p
                          className={'text-gray-200 break-all mt-2 text-sm ' + isBold}
                          dangerouslySetInnerHTML={{ __html: emojiSn.decode(item.MESSAGE) }}
                        ></p>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
