import { ChevronDownIcon } from '@heroicons/react/solid';
import { countryFlags } from '../../helpers/Helpers';

interface CountryButtonProps {
  toggleOpen: () => void;
  value: number;
  className?: string;
}

export const CountryButton = ({ toggleOpen, value, className }: CountryButtonProps) => {
  return (
    <button onClick={toggleOpen} className={`flex gap-x-2 items-center ${className ?? ''}`}>
      {countryFlags(value, 'h-6 w-6', true)}
      <ChevronDownIcon className="h-6 w-6" />
    </button>
  );
};
