import { useTranslation } from 'react-i18next';
import { authApi } from '../../../../api';
import React, { useEffect, useState } from 'react';
import { avatarClass, defaultTransition, TEST_LIVE_URL } from '../../../../constants/constants';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import Avatar from '../../../../components/Avatar';
import ApplicationAlert from '../../../../components/ApplicationAlert';
import { useHelper } from '../../../../hooks/use-helper';

export default function ElectionsPickDate({ electionMonths, electionType, countryId }) {
  const { t } = useTranslation('common');
  const [parties, setParties] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [electionresults, setElectionResults] = useState();
  const { helpers } = useHelper();

  const getParties = () => {
    authApi
      .get('/api/elections/' + countryId + '/party/getparties')
      .then((response) => {
        setParties(response.data);
      })
      .catch((err) => {});
  };

  const handleMonthPick = (selectedMonth) => {
    setSelectedMonth(selectedMonth);
    return getParties();
  };

  const handleVote = (vote) => {
    authApi
      .post('/api/elections/' + countryId + '/voteelection', {
        vote: vote,
        electionMonth: selectedMonth,
      })
      .then((resp) => {
        ApplicationAlert(t(resp.data)).success();
        handleGetElectionResults();
        return;
      })
      .catch((e) => {
        ApplicationAlert(t(e.response.data)).error();
        return;
      });
  };

  const handleGetElectionResults = (selectedParty) => {
    authApi
      .get('/api/elections/' + countryId + '/partyelections/' + selectedParty + '/' + selectedMonth)
      .then((response) => {
        setElectionResults(response.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {}, [selectedMonth]);
  return (
    <>
      <div className={'p-5 text-center'}>
        {parties ? (
          <>
            <ArrowLeftIcon
              onClick={() => {
                setParties(null);
                setElectionResults(null);
              }}
              className={'text-green-500 cursor-pointer default-transition h-10 w-10 mx-auto'}
            />
          </>
        ) : (
          <h1 className={'text-gray-100  mx-auto bg-gray-800 p-3 rounded break-words'}>{t('GAME_PICK_DATE')}</h1>
        )}
      </div>
      {electionresults && (
        <>
          <div className={'text-gray-200 mx-auto grid grid-cols-2'}>
            <div className={'mx-auto text-center'}>
              {' '}
              {t('GAME_TOTAL_CANDIDATES')}:{' '}
              <span className={'text-green-300 p-2 bg-green-800 rounded-full'}>{electionresults[1].TOTAL}</span>{' '}
            </div>
            <div className={'mx-auto text-center'}>
              {' '}
              {t('GAME_TOTAL_VOTES')}:{' '}
              <span className={'text-green-300 p-2 bg-green-800 rounded-full'}>{electionresults[1].T_VOTES}</span>{' '}
            </div>
          </div>
        </>
      )}
      <div className={'text-white p-5 break-words grid grid-cols-3 sm:grid sm:grid-cols-5 gap-4 '}>
        {electionresults ? (
          <>
            {electionresults[0].map((electionCandidates, index) => {
              let classNameForwinner = index === 0 ? 'mx-auto shadow-lg shadow-cyan-500/100' : 'mx-auto';
              return (
                <>
                  <div className={'jobCardTheme p-5 text-center'}>
                    <Avatar
                      avatarId={electionCandidates.U_AVATAR}
                      userId={electionCandidates.U_ID}
                      className={classNameForwinner}
                    />
                    <div className={'mx-auto text-center p-2'}>
                      {t('GAME_TOTAL_VOTES')}
                      <p className={'text-green-300 p-2 bg-green-800 rounded-full'}>{electionCandidates['L_VOTES']}</p>
                    </div>
                    {helpers.Game.Election.Dates.Party === helpers.Game.DayOfMonth ? (
                      <button
                        className="default-save-button uppercase"
                        onClick={() => handleVote(electionCandidates.L_ID)}
                      >
                        {t('GAME_VOTE')}
                      </button>
                    ) : (
                      <>
                        <p className="bg-gray-500 rounded-lg p-3 mt-2 text-red-100">{t('GAME_ELECTION_IS_OVER')}</p>
                      </>
                    )}
                  </div>
                </>
              );
            })}
          </>
        ) : (
          <>
            {parties
              ? parties.map((party) => {
                  return (
                    <div
                      className={
                        'block justify-center text-center m-3  shadow-inner p-2 rounded-lg cursor-pointer ' +
                        defaultTransition
                      }
                      onClick={() => handleGetElectionResults(party.ID)}
                    >
                      <div className={' font-bold text-md text-green-600'}>
                        {t('GAME_MILITARY_MEMBERS_UNIT')} {party.MEMBERS}
                      </div>
                      <div>
                        <img
                          className={'mx-auto h-20 w-20 ' + avatarClass}
                          src={TEST_LIVE_URL + '/upload/group/' + party.AVATAR + '.jpg'}
                          alt={'avatar'}
                        />
                      </div>

                      <div>
                        <p className={'font-bold text-gray-200'}>{party.NAME}</p>
                      </div>
                    </div>
                  );
                })
              : electionMonths &&
                electionMonths.map((election) => {
                  if (election.TYPE === electionType) {
                    return (
                      <div
                        key={Math.random()}
                        onClick={() => {
                          handleMonthPick(election.ID);
                        }}
                        className={'default-transition bg-gray-700 p-2 cursor-pointer hover:bg-gray-800'}
                      >
                        {t('GAME_MONTH_' + election.MONTH)} - {election.YEAR}
                      </div>
                    );
                  }
                })}
          </>
        )}
      </div>
    </>
  );
}
