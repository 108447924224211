import { useNavigate } from 'react-router-dom';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { SectionHeader } from '../../../components/SectionHeader';
import { rankImages } from '../../../helpers/Helpers';
import { authApi } from '../../../api';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { fromCDN } from '../../../helpers/Images';
import { useUser } from '../../../hooks/use-user';
import {imageAsBackground} from '../../../helpers/image-as-background';
import { UserProps } from '../../../shared-types/user-type';
import { MAX_NEWSPAPER_TITLE_LENGTH } from '../../../constants/enum';
import { Button } from '../../../components/Buttons/Button';

const MINIMUM_LEVEL = 9;
const MINIMUM_RANK = 9;

export const CreateNewspaper = () => {
  let navigate = useNavigate();
  const { t } = useTranslation('common');
  const { user, setUser } = useUser();

  const [newspaperName, setNewspaperName] = useState('');

  const handleCreateNewspaper = (e: FormEvent) => {
    e.preventDefault();

    if (!newspaperName.length) {
      ApplicationAlert(t('GAME_CREATE_NEWSPAPER_NAME_LENGTH')).error();
      return;
    }

    if (user.Level < MINIMUM_LEVEL) {
      ApplicationAlert(t('GAME_NEWSPAPER_NOT_ENOUGH_LEVEL')).error();
      return;
    }

    if (user.RANK < MINIMUM_RANK) {
      ApplicationAlert(t('GAME_NEWSPAPER_NOT_ENOUGH_RANK')).error();
      return;
    }

    authApi
      .post('/api/newspaper/create/new', { newspaperName })
      .then((response) => {
        navigate(`/newspaper/${response.data[0] as number}`);
        setUser(response.data[1] as UserProps);
      })
      .catch((e: unknown) => {
        const error = e as AxiosError<string>;

        console.error(error.response?.data ?? ('GAME_ERROR' as string));
        ApplicationAlert(t(error.response?.data ?? ('GAME_ERROR' as string))).error();
      });
  };

  useEffect(() => {
    if (user.NEWSPAPER > 0) {
      navigate('/');
    }
  }, []);

  return (
    <div
      className="min-h-screen max-w-13xl mx-auto bg-fixed"
      style={imageAsBackground(fromCDN.fetchStaticImgImage('/mybuildingsbg'))}
    >
      <div className="max-w-7xl mx-auto py-2">
        <SectionHeader title={t('GAME_CREATE_NEWSPAPER')} />

        <div className="xs:w-1/2 bg-gradient-to-r from-zinc-700 to-zinc-500 mx-3 text-center p-3 mt-3 xs:mx-auto">
          <span className="text-neutral-100">{t('GAME_CREATE_NEWSPAPER_NAME')}</span>
          <form onSubmit={handleCreateNewspaper} className="mt-2">
            <input
              type="text"
              maxLength={MAX_NEWSPAPER_TITLE_LENGTH}
              value={newspaperName}
              onChange={(e) => {
                setNewspaperName(e.target.value);
              }}
              className="default-input"
            />
            <Button color="blue" type="submit" className="mt-2">
              {t('GAME_CREATE_NEWSPAPER')}
            </Button>
          </form>
          <div className="w-full flex justify-evenly mt-6 text-white">
            <div>
              {t('GAME_MINIMUM_RANK')}
              {rankImages(MINIMUM_RANK, 'mx-auto')}
            </div>
            <div>
              {t('GAME_MINIMUM_LEVEL')}
              <br />
              <span className="text-2xl">{MINIMUM_LEVEL}</span>
            </div>
            <div>
              {t('GAME_GOLD')}
              <img className="mx-auto w-8 h-8" src={fromCDN.fetchStaticGameImage('/icons/gold-icon')} />
              <span>-2</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
