import { TopPLayerByExperience } from '../../../shared-types/types';
import Avatar from '../../../components/Avatar';
import { countryFlags } from '../../../helpers/Helpers';
import StarIcon from '../../Util/Icons/star/StarIcon';
import { fromCDN } from '../../../helpers/Images';

interface RankingsTopThreeDesignProps {
  items: TopPLayerByExperience[];
}
export const RankingsTopThreeDesign = ({ items }: RankingsTopThreeDesignProps) => {
  let counter = 0;
  return (
    <div className="grid grid-cols-3 text-gray-200 bg-gradient-to-br from-edom-darker-yellow/80 to-edom-darker-yellow/60 border-2 border-edom-darker-yellow py-6 px-1 sm:px-6 rounded-lg">
      {items.map((item) => {
        counter++;
        return (
          <div className="text-center" key={`${item.U_NAME}${item.EXPERIENCE}`}>
            <span className="bg-yellow-400/20">
              {counter === 1 && (
                <>
                  <img
                    className="w-7 h-7 mx-auto drop-shadow-[0_2px_1px_rgba(0,0,0,0.6)]"
                    src={fromCDN.fetchStaticImage('t_rank1', 'svg')}
                    alt=""
                  />
                </>
              )}
            </span>

            <span className="bg-gray-400/20">
              {counter === 2 && (
                <>
                  <img
                    className="w-7 h-7 mx-auto drop-shadow-[0_2px_1px_rgba(0,0,0,0.6)]"
                    src={fromCDN.fetchStaticImage('t_rank2', 'svg')}
                    alt=""
                  />
                </>
              )}
            </span>

            <span className="bg-orange-400/20">
              {counter === 3 && (
                <>
                  <img
                    className="w-7 h-7 mx-auto drop-shadow-[0_2px_1px_rgba(0,0,0,0.6)]"
                    src={fromCDN.fetchStaticImage('t_rank3', 'svg')}
                    alt=""
                  />
                </>
              )}
            </span>

            <Avatar avatarId={item.U_AVATAR} userId={String(item.U_ID)} className="mx-auto mt-2" />
            <p className="font-semibold">{item.U_NAME}</p>
            <p>{countryFlags(item.CITIZENSHIP, 'mx-auto')}</p>
            <div>
              <p className="bg-yellow-400/40 rounded-md sm:mx-auto mx-1 mt-4 sm:w-44 font-medium">
                {item.EXPERIENCE}
                <StarIcon className="h-4 w-4 ml-1 mb-0.5 text-yellow-300 mx-auto inline drop-shadow-[0_2px_1px_rgba(0,0,0,0.6)]" />
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
