import React from 'react';

interface ButtonMailToProps{
  mailto: string,
  label?: string
}
export const ButtonMailTo = ({ mailto, label }: ButtonMailToProps) => {
  return (
    <a
      href={`mailto:${mailto}`}
      className="bg-blue-200 bg-opacity-40 hover:bg-opacity-70 inline-block border-b border-gray-500 px-px transition-all duration-300"
    >
      {label ? label : mailto}
    </a>
  );
}
