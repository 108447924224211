import React from 'react';

import { Divider } from '../../../components/Divider';
import { fromCDN } from '../../../helpers/Images';

export default function EnergyCenters({ user, t }) {
  let energyCenters = {
    1: { ID: 1, Name: 'Field Hospital 50', Img: 'ec1', Energy: 50 },
    2: { ID: 2, Name: 'Field Hospital 100', Img: 'ec2', Energy: 100 },
    3: { ID: 3, Name: 'Field Hospital 150', Img: 'ec3', Energy: 150 },
    4: { ID: 4, Name: 'Field Hospital 200', Img: 'ec4', Energy: 200 },
    5: { ID: 5, Name: 'Field Hospital 250', Img: 'ec5', Energy: 250 },
  };

  let myEnergyCenters = [];

  for (let x = 1; x <= 5; x++) {
    if (user['EC' + x]) {
      let animation = 'animate__fadeInUp';
      if (x % 2 === 0) {
        animation = 'animate__fadeInDown';
      }
      myEnergyCenters.push(
        <>
          <div
            className={
              'grid bg-blue-100 justify-center text-center gap-x-4 m-5 shadow-inner rounded-lg animate__animated ' +
              animation
            }
          >
            <img className="w-16 h-12 mx-auto mt-3" src={fromCDN.fetchStaticGameImage(`/ec/ec${x}`)} />
            <p className="m-3">{energyCenters[x]['Name']}</p>
            <p className="m-3 bg-gray-600 p-3 rounded-lg text-white">
              +{energyCenters[x]['Energy']} {t('GAME_ENERGY')}
            </p>
          </div>
        </>,
      );
    }
  }
  return (
    <>
      <div className="w-4/4 m-5">
        <p className="m-3 font-bold text-gray-100">
          {t('GAME_ENERGY_CENTER')} <sup className="tracking-wider">{t('GAME_ENERGY_CENTER_PERMA')}</sup>
        </p>
        <Divider margin="m-3" />
        <div className="grid grid-cols-2 sm:grid sm:grid-cols-5 justify-center text-center">
          {myEnergyCenters.length ? (
            myEnergyCenters
          ) : (
            <div className="justify-center text-center p-3 bg-gray-300 shadow-inner rounded-lg">
              Buy from the store to be finished later
            </div>
          )}
        </div>
      </div>
    </>
  );
}
