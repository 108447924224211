import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { animate } from '../../util/animate';
import { BattlefieldStyles } from './Styles/BattlefieldStyles';
import { BattleFieldCountryIndicators } from './BattlefieldCountryIndicators';
import { BattlefieldAttackerTopThree } from './BattlefieldAttackerTopThree';
import { BattlefieldProgressAndScoreBar } from './BattlefieldProgressAndScoreBar';
import { fromCDN } from '../../helpers/Images';
import { LastHits } from './LastHits/LastHits';
import { Loader } from '../../components/Loader';
import { GLOBAL_CONSTANTS } from '../../constants/enum';
import { SocketContext } from '../../constants/socket-context'; // Ensure this is the correct path
import { useBattlefield } from './hooks/use-battlefield';
import { BattlefieldTypesApiProps } from '../../shared-types/battlefield-types';
import { TopCountries } from './TopCountries/Topcountries';
import { BattlefieldDefenderTopThree } from './BattlefieldDefenderTopThree';
import { BattlefieldFight } from './BattlefieldFight/BattlefieldFight';
import { BATTLE_STATUS } from '../../shared-types/battles-types';
import { BattleResults } from './BattleResults';
import { authApi } from '../../api';

export const BattleField = () => {
  const params = useParams();
  const [battleDetails, setBattleDetails] = useState<BattlefieldTypesApiProps['BATTLE']>();
  const [lastHitsAttacker, setLastHitsAttacker] = useState<BattlefieldTypesApiProps['LAST_HITS_ATT']>();
  const [lastHitsDefender, setLastHitsDefender] = useState<BattlefieldTypesApiProps['LAST_HITS_DEF']>();
  const [battleFieldStyle, setBattleFieldStyle] = useState({});
  const [aggressorAllies, setAggressorAllies] = useState<string[]>([]);
  const [defenderAllies, setDefenderAllies] = useState<string[]>([]);
  const [citizenDetails, setCitizenDetails] = useState<BattlefieldTypesApiProps['CITIZEN']>();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [showTopList, setShowTopList] = useState<boolean | JSX.Element>(false);
  const loader = <Loader fullScreen={true} />;
  const { setCitizenDamage } = useBattlefield();
  const { socket } = useContext(SocketContext); // Get socket from context directly

  function getBattle() {
    authApi.get(`/api/battledetails/${params.id ?? 0}`).then((response: AxiosResponse<BattlefieldTypesApiProps>) => {
      if (response.data.BATTLE.ALLIES_A !== '') {
        setAggressorAllies(response.data.BATTLE.ALLIES_A.split(','));
      } else {
        setAggressorAllies([]);
      }
      if (response.data.BATTLE.ALLIES_D !== '') {
        setDefenderAllies(response.data.BATTLE.ALLIES_D.split(','));
      } else {
        setDefenderAllies([]);
      }
      setBattleFieldStyle(() => BattlefieldStyles({ round: response.data.BATTLE.ROUND }));
      setCitizenDetails(response.data.CITIZEN);
      setCitizenDamage(response.data.CITIZEN);
      setBattleDetails(response.data.BATTLE);
      setLastHitsAttacker(response.data.LAST_HITS_ATT);
      setLastHitsDefender(response.data.LAST_HITS_DEF);
    });
  }

  const handleAnimation = (divId: string, animationKey: string) => {
    animate({ targetElementId: divId, animation: animationKey });
  };

  useEffect(() => {
    getBattle();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.emit(GLOBAL_CONSTANTS.SOCKET_BATTLEFIELD_UPDATE, params.id);
    }
  }, [socket, shouldUpdate]); // Added socket to dependency array

  useEffect(() => {
    if (socket && battleDetails) {
      const handleBattleUpdate = (message: BattlefieldTypesApiProps) => {
        if (message) {
          setBattleDetails(message.BATTLE);
          if (message.LAST_HITS_ATT) setLastHitsAttacker(message.LAST_HITS_ATT);
          if (message.LAST_HITS_DEF) setLastHitsDefender(message.LAST_HITS_DEF);
        }
      };

      socket.on(GLOBAL_CONSTANTS.SOCKET_BATTLEFIELD_UPDATE, handleBattleUpdate);

      // Clean up the event listener on component unmount or dependencies change
      return () => {
        socket.off(GLOBAL_CONSTANTS.SOCKET_BATTLEFIELD_UPDATE, handleBattleUpdate);
      };
    }
  }, [socket, battleDetails]);

  const fightButtonOrResults =
    battleDetails && battleDetails.STATUS === BATTLE_STATUS.ONGOING_BATTLE ? (
      <BattlefieldFight
        battleDetails={battleDetails}
        // @ts-ignore
        citizenDetails={citizenDetails}
        battleSide={params.side as any}
        shouldUpdate={setShouldUpdate}
      />
    ) : battleDetails ? (
      <BattleResults battleDetails={battleDetails} />
    ) : null;

  return (
    <div
      className="min-h-screen max-w-13xl mx-auto overflow-auto sm:overflow-hidden"
      style={{
        backgroundImage: `url(${fromCDN.fetchStaticImgImage('/battlefieldscreenbg', 'jpg')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="max-w-7xl mx-auto py-0 sm:py-2 text-tiny">
        {battleDetails ? (
          <div className="shadow-lg rounded relative" style={battleFieldStyle}>
            <div className="relative">
              <BattleFieldCountryIndicators
                agressorAllies={aggressorAllies}
                defenderAllies={defenderAllies}
                battleDetails={battleDetails}
              />

              <BattlefieldAttackerTopThree
                battleDetails={battleDetails}
                setShowTopList={setShowTopList}
                handleAnimation={handleAnimation}
              />

              <BattlefieldDefenderTopThree
                battleDetails={battleDetails}
                setShowTopList={setShowTopList}
                handleAnimation={handleAnimation}
              />

              {showTopList}
              <BattlefieldProgressAndScoreBar battleDetails={battleDetails} />

              <div>
                {lastHitsAttacker && (
                  <LastHits
                    // @ts-ignore
                    lastHits={lastHitsAttacker}
                    round={battleDetails.ROUND}
                    citizenDetails={citizenDetails}
                  />
                )}
                {lastHitsDefender && (
                  <LastHits
                    // @ts-ignore
                    lastHits={lastHitsDefender}
                    round={battleDetails.ROUND}
                    isAttacker={false}
                    citizenDetails={citizenDetails}
                  />
                )}
              </div>
              <div className="absolute flex " style={{ bottom: '-650px', right: '0px' }}>
                <TopCountries
                  attackerList={battleDetails.countriesDamagesAttacker}
                  defenderList={battleDetails.countriesDamagesDefender}
                />
              </div>
            </div>
            {fightButtonOrResults}
          </div>
        ) : (
          loader
        )}
      </div>
    </div>
  );
};
