import { useTranslation } from 'react-i18next';

import { fromCDN } from '../../../helpers/Images';
import { FC } from 'react';
interface AssignWorkersProps {
  company: any;
  handleAssignWorkers: (arg: any) => void;
}
export const AssignWorkers: FC<AssignWorkersProps> = ({ handleAssignWorkers, company }) => {
  const { t } = useTranslation('common');

  return (
    <div className="w-20 mx-auto cursor-default drop-shadow-[0_5px_2px_rgba(0,0,0,0.4)]">
      {company.is_in_production === 1 ? (
        <div className="text-center">
          <p>{t('GAME_ASSIGNED_WORKERS')}</p>
          <p className="font-bold text-gray-200 text-lg mt-1.5">{company.assigned_workers}</p>
          <img
            className="w-6 h-6 employee text-yellow-900 mx-auto mt-3"
            src={fromCDN.fetchStaticGameImage('/icons/1emp')}
          />
        </div>
      ) : (
        <>
          <p className="text-center "> {t('GAME_ASSIGN_WORKERS')}</p>
          <input
            onChange={(e) => {
              handleAssignWorkers({
                companyId: company.id,
                workerValue: e.target.value,
              });
            }}
            min={0}
            className="outline-none default-input w-10 p-3 text-center justify-center "
            type="number"
          />
          <img
            className="w-6 h-5.5 employee cursor-pointer text-yellow-900 mx-auto mt-2.5"
            src={fromCDN.fetchStaticGameImage('/icons/0emp')}
          />
        </>
      )}
    </div>
  );
};
