import { fromCDN } from '../../../helpers/Images';
import { useUser } from '../../../hooks/use-user';

export const FightSummary = ({ damage = 10, rankPoints = 10, experiencePoints = 1 }) => {
  const { user } = useUser();
  return (
    <>
      <div
        data-testid="fight-summary"
        className="z-50 w-80 absolute animate__animated animate__fadeIn animate__animated animate__fast text-center justify-center drop-shadow-2xl rounded-2xl opacity-75 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
        style={{
          backgroundImage: `url(${fromCDN.fetchStaticImage('hitmodalframe', 'png')})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        {user.ABILITY_TYPE ? (
          <img
            className="absolute h-10 w-10 mx-auto -mt-44"
            src={fromCDN.fetchStaticGameImage(`/abiicons/abi_${user.ABILITY_TYPE}`)}
          />
        ) : null}
        <img src={fromCDN.fetchStaticGameImage('/battle/bar-time')} className="absolute mx-auto -mt-24 mb-2" />
        <p className="absolute -mt-24 mb-2 text-yellow-500 animate__animated animate__bounceIn font-bold animate__animated  animate__slow ">
          {experiencePoints}x
        </p>
        <div className="m-5">
          <div
            style={{
              backgroundImage: `url(${fromCDN.fetchStaticImage('hitmodaldamageframe', 'png')})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              height: '3em',
            }}
            className="relative mt-16 text-center text-lg text-yellow-500  justify-center"
          >
            <p className=" text-gray-100  mx-auto z-10">{damage}</p>
            <img className="absolute mt-9" src={fromCDN.fetchStaticImage('bmodaldmg', 'png')} />
          </div>

          <div className="grid grid-cols-2 gap-8  text-center text-lg text-yellow-500  justify-center -mt-2">
            <div
              style={{
                backgroundImage: `url(${fromCDN.fetchStaticImage('hitmodalrankshadow', 'png')})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                height: '5em',
              }}
              className="text-center text-sm text-yellow-500 justify-center"
            >
              <p className="text-gray-100 mx-auto z-10">{experiencePoints}</p>
              <img className="absolute mt-9" src={fromCDN.fetchStaticImage('bmodalxp', 'png')} />
            </div>

            <div
              className="text-center text-sm text-yellow-500 justify-center"
              style={{
                backgroundImage: `url(${fromCDN.fetchStaticImage('hitmodalrankshadow', 'png')})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                height: '5em',
              }}
            >
              <p className="text-gray-100  m-3 ">{rankPoints}</p>
              <img className="absolute mt-9" src={fromCDN.fetchStaticImage('bmodalrank', 'png')} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
