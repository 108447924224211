import { fromCDN } from '../../../helpers/Images';
import BattlefieldBackground from '../BattlefieldBackground';
import { CSSProperties } from 'react';
const attackerFrame = fromCDN.fetchStaticGameImage('/battle/attackerflag');
const defenderFrame = fromCDN.fetchStaticGameImage('/battle/defenderflag');
const attackerTopListChild1 = fromCDN.fetchStaticGameImage('/battle/battlebhattacker');
const attackerTopListChild2 = fromCDN.fetchStaticGameImage('/battle/attackertopbutton2');
const defenderTopListChild1 = fromCDN.fetchStaticGameImage('/battle/battlebhdefender');
const defenderTopListChild2 = fromCDN.fetchStaticGameImage('/battle/defendertopbutton2');
const HeroRibbon = fromCDN.fetchStaticGameImage('/battle/hero-top');
const TopListMedal = fromCDN.fetchStaticGameImage('/battle/hero-top-1');
const hitButtonAttacker = fromCDN.fetchStaticGameImage('/battle/eD019');
const hitButtonDefender = fromCDN.fetchStaticGameImage('/battle/eD018');
const hitAttackerFightImage = fromCDN.fetchStaticGameImage('/battle/vs021', 'gif');
const hitDefenderFightImage = fromCDN.fetchStaticGameImage('/battle/vs022', 'gif');
const energyBarAttackerImage = fromCDN.fetchStaticGameImage('/battle/vs019');
const energyBarDefenderImage = fromCDN.fetchStaticGameImage('/battle/vs020');

export const BattlefieldStyles = ({ round }: { round: number }) => {
  return {
    backgroundImage: `url(${BattlefieldBackground({ round: round })})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '1280px',
    maxWidth: '1280px',
    height: '720px',
  };
};

export const AttackerParentStyle = (): CSSProperties => {
  return {
    backgroundImage: `url(${attackerFrame})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
};

export const DefenderParentStyle = (): CSSProperties => {
  return {
    backgroundImage: `url(${defenderFrame})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
};
export const AttackerFirstChildStyle = (): CSSProperties => {
  return {
    textShadow: '0 1px 0 rgb(0 0 0 / 30%)',
  };
};

export const AttackerTopPlacementParent = (): CSSProperties => {
  return {
    // backgroundImage : `url(${attackerTopListFrame})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
};

export const AttackerRibbon = (): CSSProperties => {
  return {
    left: '34px',
    backgroundImage: `url(${HeroRibbon})`,
    backgroundSize: 'cover',
    display: 'block',
    height: '31px',
    position: 'absolute',
    top: '38px',
    width: '39px',
    zIndex: '1',
  };
};

export const AttackerTopPlacementChild1 = (): CSSProperties => {
  return {
    backgroundImage: `url(${attackerTopListChild1})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    listStyle: 'outside none none',
    margin: '0',
    padding: '0',
    position: 'absolute',
    width: '108px',
    height: '106px',
    top: '-1px',
    left: '14px',
  };
};

export const AttackerTopPlacementChildMedal = (): CSSProperties => {
  return {
    backgroundImage: `url(${TopListMedal})`,
    backgroundRepeat: 'no-repeat',
    right: '19px',
    height: '32px',
    width: '29px',
    bottom: '47px',
    position: 'absolute',
  };
};

export const AttackerExtendTopList = (): CSSProperties => {
  return {
    backgroundImage: `url(${attackerTopListChild2})`,
    backgroundRepeat: 'no-repeat',
    height: '40px',
    width: '120px',
    display: 'block',
    margin: '0 auto',
    position: 'absolute',
    bottom: '-69px',
    left: '19px',
  };
};

export const DefenderTopPlacementParent = (): CSSProperties => {
  return {
    // backgroundImage : `url(${attackerTopListFrame})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
};

export const DefenderTopPlacementChild1 = (): CSSProperties => {
  return {
    backgroundImage: `url(${defenderTopListChild1})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    listStyle: 'outside none none',
    margin: '0',
    padding: '0',
    position: 'absolute',
    width: '108px',
    right: '11px',
    height: '106px',
    top: '-1px',
  };
};

export const DefenderExtendTopList = (): CSSProperties => {
  return {
    backgroundImage: `url(${defenderTopListChild2})`,
    backgroundRepeat: 'no-repeat',
    height: '40px',
    width: '120px',
    display: 'block',
    margin: '0 auto',
    position: 'absolute',
    bottom: '-69px',
    left: '11px',
  };
};

export const HitButtonAttackerFrame = (): CSSProperties => {
  return {
    backgroundImage: `url(${hitButtonAttacker})`,
    backgroundRepeat: 'no-repeat',
    top: '597px',
    height: '123px',
    width: '364px',
  };
};

export const HitButtonFightAttackerImage = (): CSSProperties => {
  return {
    background: `url(${hitAttackerFightImage}) no-repeat scroll 0 -80px transparent`,
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    width: '166px',
    height: '75px',
    top: '27px',
    left: '102px',
    float: 'left',
    textShadow: '1px 1px 1px #000',
  };
};
export const HitButtonFightDefenderImage = (): CSSProperties => {
  return {
    background: `url(${hitDefenderFightImage}) no-repeat scroll 0 -80px transparent`,
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    width: '166px',
    height: '75px',
    top: '27px',
    left: '102px',
    float: 'left',
    textShadow: '1px 1px 1px #000',
  };
};

export const HitButtonDefenderFrame = (): CSSProperties => {
  return {
    backgroundImage: `url(${hitButtonDefender})`,
    backgroundRepeat: 'no-repeat',
    top: '597px',
    height: '123px',
    width: '364px',
  };
};

export const battlefieldAttackerWeaponsStyle = (): CSSProperties => {
  return {
    background: `url(${energyBarAttackerImage}) no-repeat scroll 0 -63px transparent`,
    float: 'right',
  };
};

export const battlefieldDefenderWeaponsStyle = (): CSSProperties => {
  return {
    background: `url(${energyBarDefenderImage}) no-repeat scroll 0 -63px transparent`,
    float: 'right',
  };
};

export const energyBarAttackerStyle = (): CSSProperties => {
  return {
    background: `url(${energyBarAttackerImage}) no-repeat scroll 0 -63px transparent`,
    float: 'left',
  };
};

export const energyBarDefenderStyle = (): CSSProperties => {
  return {
    background: `url(${energyBarDefenderImage}) no-repeat scroll 0 -63px transparent`,
    float: 'left',
  };
};
