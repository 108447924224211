import { Tab } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import { fromCDN } from '../../../helpers/Images';
import { imageAsBackground } from '../../../helpers/image-as-background';
import { PageContainer } from '../../../components/PageContainer';
import { Market } from './Tabs/Market';
import { MonetaryTab } from './Tabs/MonetaryTab';
import { MyOffers } from './Tabs/MyOffers';
import { useState } from 'react';

const tabPanels = [Market, MyOffers];

export const Monetary = () => {
  const { t } = useTranslation('common');
  const [refreshMarket, setRefreshMarket] = useState(false);

  const handleRefreshMarket = () => {
    setRefreshMarket((prev) => !prev);
  };

  return (
    <PageContainer style={imageAsBackground(fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg'))}>
      <div className="mt-8 max-w-6xl mx-auto text-neutral-50 px-2 sm:px-0">
        <Tab.Group>
          <Tab.List className="flex gap-x-0.5">
            <MonetaryTab>{t('GAME_MARKET')}</MonetaryTab>
            <MonetaryTab>{t('GAME_MY_OFFERS')}</MonetaryTab>
          </Tab.List>
          <Tab.Panels>
            {tabPanels.map((Panel) => (
              <Tab.Panel key={Panel.name}>
                <Panel refresh={refreshMarket} onRefresh={handleRefreshMarket} />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </PageContainer>
  );
};
