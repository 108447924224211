import { WithChildren } from '../../../shared-types/shared-types';

export type CircleButtonProps = {
  onClick: () => void;
  className?: string;
} & WithChildren;

export const CircleButton = ({ children, className, onClick }: CircleButtonProps) => {
  return (
    <div
      className={`p-6 rounded-full bg-gradient-to-r from-zinc-800 to-zinc-600 hover:bg-gradient-to-r hover:from-zinc-800 hover:to-zinc-500 border-2 cursor-pointer block w-fit ${
        className ?? ''
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
