import { useTranslation } from 'react-i18next';
import React from 'react';
import { Link } from 'react-router-dom';

import { SectionHeader } from '../../components/SectionHeader';

import { PageContainer } from '../../components/PageContainer';

export default function TermsOfUse() {
  const { t } = useTranslation('common');

  return (
    <PageContainer>
      <SectionHeader title={t('GAME_FOOTER_TERMS')} titleClass="text-xl" />

      {/*<div className="w-full mt-8">*/}
      {/*  <a>*/}
      {/*    <img src={fromCDN.fetchStaticGameImage('/flags/flat/32/Slovenia')} alt="Slovenian flag" className="mx-auto" />*/}
      {/*  </a>*/}
      {/*  <a className="hover:text-gray-600 text-lg font-semibold cursor-pointer flex items-center justify-center mt-2">*/}
      {/*    <DownloadDocument className="inline-block h-6 w-6 mr-1" />*/}
      {/*    Download*/}
      {/*  </a>*/}
      {/*</div>*/}

      <ol className="text-gray-800 space-y-6 list-decimal list-inside px-4 py-8 text-base">
        <li>
          <span className="font-semibold text-lg">Terms</span>
          <p className="pl-5">
            By creating your account and logging in the game at edomLegacy,{' '}
            <Link to="/" className="text-blue-500 hover:text-blue-700 hover:underline">
              www.edomlegacy.com
            </Link>
            , you are agreeing to be bound by these web site Privacy Policy and Terms and Conditions of Use, all
            applicable laws and regulations, and agree that you are responsible for compliance with any applicable local
            laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The
            materials contained in this web site are protected by applicable copyright and trademark law. By playing the
            game you agree to all condition mentioned in the following document.
          </p>
        </li>

        <li>
          <span className="font-semibold text-xl">Use License</span>
          <ol className="list-inside space-y-3 mt-4 list--character pl-10">
            <li className="space-y-3">
              <span className="pl-1 mt-4 break--indent">
                Permission is granted to temporarily download one copy of the materials (information or software) on
                edomLegacy web site for personal, non-commercial transitory viewing only. This is the grant of a
                license, not a transfer of title, and under this license you may not:
              </span>

              <ul className="list-disc list-inside pl-2">
                <li>modify or copy the materials;</li>
                <li>
                  use the materials for any commercial purpose, or for any public display (commercial or
                  non-commercial);
                </li>
                <li>attempt to decompile or reverse engineer any software contained on edomLegacy web site;</li>
                <li>remove any copyright or other proprietary notations from the materials;</li>
                <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
              </ul>
            </li>

            <li>
              This license shall automatically terminate if you violate any of these restrictions and may be terminated
              by edomLegacy at any time. Upon terminating your viewing of these materials or upon the termination of
              this license, you must destroy any downloaded materials in your possession whether in electronic or
              printed format.
            </li>
          </ol>
        </li>

        <li>
          <span className="font-semibold text-xl">Disclaimer</span>
          <p className="pl-5 mt-4">
            The materials on edomLegacy web site are provided "as is". edomLegacy makes no warranties, expressed or
            implied, and hereby disclaims and negates all other warranties, including without limitation, implied
            warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights. Further, edomLegacy does not warrant or make any
            representations concerning the accuracy, likely results, or reliability of the use of the materials on its
            Internet web site or otherwise relating to such materials or on any sites linked to this site.
          </p>
        </li>

        <li>
          <span className="font-semibold text-xl">Limitations</span>
          <p className="pl-5 mt-4">
            In no event shall edomLegacy or its suppliers be liable for any damages (including, without limitation,
            damages for loss of data or profit, or due to business interruption), arising out of the use or inability to
            use the materials on edomLegacy Internet site, even if edomLegacy or an edomLegacy authorized representative
            has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not
            allow limitations on implied warranties, or limitations of liability for consequential or incidental
            damages, these limitations may not apply to you.
          </p>
        </li>

        <li>
          <span className="font-semibold text-xl">Revisions and Errata</span>
          <p className="pl-5 mt-4">
            The materials appearing on edomLegacy web site could include technical, typographical, or photographic
            errors. edomLegacy does not warrant that any of the materials on its web site are accurate, complete, or
            current. edomLegacy may make changes to the materials contained on its web site at any time without notice.
            edomLegacy does not, however, make any commitment to update the materials.
          </p>
        </li>

        <li>
          <span className="font-semibold text-xl">Links</span>
          <p className="pl-5 mt-4">
            edomLegacy has not reviewed all of the sites linked to its Internet web site and is not responsible for the
            contents of any such linked site. The inclusion of any link does not imply endorsement by edomLegacy of the
            site. Use of any such linked web site is at the user's own decision and risk.
          </p>
        </li>

        <li>
          <span className="font-semibold text-xl">Site Terms of Use Modifications</span>
          <p className="pl-5 mt-4">
            edomLegacy may revise these terms of use for its web site at any time without notice. By using this web site
            you are agreeing to be bound by the then current version of these Terms and Conditions of Use, and also
            periodically to check for any changes which took place.
          </p>
        </li>

        <li>
          <span className="font-semibold text-xl">Governing Law</span>
          <p className="pl-5 mt-4">
            Any claim relating to edomLegacy web site shall be governed by the laws of Slovenia without regard to its
            conflict of law provisions. Payment service
          </p>
        </li>

        <li>
          <span className="font-semibold text-xl">Payment service</span>
          <ol className="pl-10 mt-4 list--character list-inside space-y-3">
            <li className="pl-1">
              Subject to this Agreement, you are granted a limited license right to use the Game's virtual currency,
              Gold, which is available for purchase or free distribution at our discretion.
            </li>
            <li className="pl-1">
              You acknowledge that you are not entitled to claim refunds from us for edomLegacy Gold purchased by you,
              other than where we have exercised our right to terminate your user account and termination was not caused
              by your breach of this Agreement, the game rules or of law.
            </li>
            <li className="pl-1">
              For the avoidance of doubt you are never entitled to a refund from us for edomLegacy Gold given to you by
              us for free or given to you by another Citizen.
            </li>
            <li className="pl-1">
              You may use edomLegacy Gold to create game companies, upgrade game companies, buy game market licenses,
              declare wars, attack regions, sign alliances, start resistance wars, create game organizations, create
              game parties, create game newspapers, buy crate boxes, buy roll the dice tokens, issue money laws,
              exchange for local currency, purchase in-game producible items and any other features that may be added in
              future.
            </li>
            <li className="pl-1">
              You acknowledge that we may, at any time and at our sole discretion, add new services for additional fees
              and charges, or amend fees and charges for existing services and may determine that any fees and charges
              are payable by the amount of edomLegacy Gold specified by us or for an amount specified by us.
            </li>
            <li className="pl-1">
              In order to buy edomLegacy Gold and/or any other store items, you must access the “Store” section of the
              Game, choose the Gold or any other package you wish to buy, choose a payment method and acknowledge your
              acceptance of this Agreement by you already have done since you accepted on registration. According to the
              chosen method of payment, you will then be transferred to the Website of the relevant payment provider,
              from which point you must follow the instructions given by that payment provider. You agree and
              acknowledge that the terms and conditions imposed by the relevant payment provider in respect of your
              transaction form a separate contract to this Agreement. We are not responsible and expressly exclude our
              liability for any loss or damage suffered by you arising from the use of the websites of any payment
              providers in connection with edomLegacy. Please read the terms and conditions of any third party website
              carefully.
            </li>
            <li className="pl-1">
              edomLegacy is a free to play massive multiplayer online game. Playing the game itself does not require any
              type of payments - you can play and use every possible option of the game for free and players from over
              18 years old age can join the game.
            </li>
            <li className="pl-1">
              edomLegacy provides options for payments in order for the players to gain additional gold by purchasing it
              for real life money. Such purchases are not demanded and players can make it only on their own will.
              edomLegacy don't refund back any purchases.
            </li>
            <li className="pl-1">
              By the rules of edomLegacy the players are not allowed to sell things that belong to them in the game -
              such as accounts or things from it (gold, currency and etc). That is strictly forbidden and violating it -
              both by selling or purchasing to/from other players account or items with real money - can result in
              permanent ban.
            </li>
          </ol>
        </li>
      </ol>
    </PageContainer>
  );
}
