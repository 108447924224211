import { BASE_URL, BEARER, inputButtonClass, themeButtonClass } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import axios from 'axios';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { useUser } from '../../../hooks/use-user';

export default function Donate({
  donationType,
  donationSection,
  donationCountry = null,
  donationPlayer = null,
  setter,
}) {
  const { t, i18n } = useTranslation('common');
  const [quantity, setQuantity] = useState();
  const [alertModal, setAlertModal] = useState();
  const { setUser } = useUser();
  async function handleDonation() {
    let params = resolveDonationTypeAndSection();

    let postData = new FormData();

    Object.keys(params[1]).map((item, key) => {
      postData.append(item, params[1][item]);
    });
    await axios
      .post(BASE_URL + '/api' + params[0].link, postData, {
        headers: { Authorization: 'Bearer ' + BEARER },
      })
      .then((response) => {
        setter(response.data.ECONOMY);
        setUser(response.data.USER);
        setAlertModal(ApplicationAlert(t('GAME_SUCCESS')).success());
        return alertModal;
      })
      .catch((error) => {
        setAlertModal(ApplicationAlert(t(error.response.data)).error());
        return alertModal;
      });
  }

  function resolveDonationTypeAndSection() {
    if (donationType === 'currency' && donationSection === 'country') {
      return [
        { link: '/country/economy/donatemoney' },
        {
          countryId: donationCountry,
          amount: quantity,
        },
      ];
    }
    if (donationType === 'gold' && donationSection === 'country') {
      return [
        { link: '/country/economy/donategold' },
        {
          countryId: donationCountry,
          amount: quantity,
        },
      ];
    }
  }

  return (
    <>
      <div data-testid={'donatopen' + donationType}>
        <input
          data-testid={'donatequantity' + donationType}
          className={inputButtonClass}
          placeholder={t('GAME_QUANTITY')}
          onChange={(e) => setQuantity(e.target.value)}
        />

        <button
          data-testid={'donatbutton' + donationType}
          className={themeButtonClass + ' bg-green-300 mt-2'}
          onClick={() => handleDonation()}
        >
          {t('GAME_DONATE')}
        </button>
      </div>
    </>
  );
}
