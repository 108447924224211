import { useContext, useEffect } from 'react';
import helperContext from '../constants/helpersContext';

export const useHelper = () => {
  const { helpers, setHelpers } = useContext(helperContext);

  useEffect(() => {}, [helpers]);
  return {
    helpers,
    setHelpers,
  };
};
