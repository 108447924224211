import { isAdminAuthorized } from './Administration';
import { AppModal } from '../../components/AppModal';
import { FC, useState } from 'react';
import { CogIcon } from '@heroicons/react/solid';
import { Divider } from '../../components/Divider';
import { authApi } from '../../api';
import ApplicationAlert from '../../components/ApplicationAlert';
import { useTranslation } from 'react-i18next';
import { UserProps } from '../../shared-types/user-type';
import { useUser } from '../../hooks/use-user';
interface MuteOrBanUserProps {
  userId: UserProps['id'];
  className?: string;
  shoutIds?: number[] | null;
  callBack?: () => void;
}
/* eslint-disable  */
export const MuteOrBanUser: FC<MuteOrBanUserProps> = ({
  userId,
  className = 'w-5 h-5 cursor-pointer',
  shoutIds = null,
  callBack = () => {},
}: MuteOrBanUserProps) => {
  if (!isAdminAuthorized()) {
    return null;
  }

  const [modal, setModal] = useState(false);
  const [muteHours, setMuteHours] = useState<number>(1);
  const [shouldDeleteAllShouts, setShouldDeleteAllShouts] = useState(false);
  const [reason, setReason] = useState('');
  const [banType, setBanType] = useState(0);
  const { user } = useUser();
  const { t } = useTranslation('common');
  const handleMute = () => {
    authApi
      .post('/api/admin/mute', {
        userId: userId,
        hours: Number(muteHours),
        reason: reason,
        shoutId: shoutIds,
        shouldDeleteAllShouts: shouldDeleteAllShouts,
      })
      .then((resp) => {
        ApplicationAlert(t(resp.data)).success();
        callBack();
        return;
      })
      .catch((err) => {
        ApplicationAlert(t(err.response.data)).error();
        return;
      });
  };

  const handleBan = () => {
    authApi
      .post('/api/admin/ban', {
        userId: userId,
        hours: Number(muteHours),
        reason: reason,
        isPerma: banType,
      })
      .then((resp) => {
        ApplicationAlert(t(resp.data)).success();
        callBack();
        return;
      })
      .catch((err) => {
        ApplicationAlert(t(err.response.data)).error();
        return;
      });
  };

  return (
    <>
      <CogIcon
        className={className}
        onClick={() => {
          setModal((prevState) => !prevState);
        }}
      />
      <AppModal
        shouldHaveCloseButton={true}
        open={modal}
        setOpen={setModal}
        backgroundStyle={{
          backgroundColor: '#1e293b',
        }}
        body={
          <div className="justify-center text-center mx-auto p-3 text-gray-200">
            <p>
              Hello moderator <span className="font-bold text-yellow-500">{user.user}</span>
            </p>

            <Divider margin="m-3" />
            <div className="text-left font-bold text-indigo-300 text-sm p-3">
              <p>Mute user</p>
              <input
                className="default-input"
                type="number"
                onChange={(e) => {
                  setMuteHours(Number(e.target.value));
                }}
                placeholder="Number in hours"
              />
              <input
                className="default-input mt-2"
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                placeholder="Reason"
              />
              <p className="mt-2">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  onClick={() => {
                    setShouldDeleteAllShouts((prevState) => !prevState);
                  }}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ml-2 text-sm font-medium text-gray-200 dark:text-gray-300 -mt-"
                >
                  Delete all shouts
                </label>
              </p>
              <button
                onClick={() => {
                  handleMute();
                }}
                className="default-red-button text-white"
              >
                Approve
              </button>
            </div>

            <Divider margin="m-3" />
            <div className="text-left text-sm text-red-500 font-bold p-3">
              <p>Ban user</p>

              <label htmlFor="countries" className="block mb-2 text-sm font-medium text-orange-300 dark:text-gray-400">
                Select Ban Type
              </label>
              <select
                onChange={(e) => {
                  setBanType(Number(e.target.value));
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-800 focus:border-gray-800 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value={0} selected={true}>
                  Temporary
                </option>
                <option value={1}>Permanent</option>
              </select>

              {banType === 0 && (
                <input
                  className="default-input mt-2"
                  type="number"
                  onChange={(e) => {
                    setMuteHours(Number(e.target.value));
                  }}
                  placeholder="Number in hours"
                />
              )}
              <input
                className="default-input mt-2"
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                placeholder="Reason"
              />
              <button
                onClick={() => {
                  handleBan();
                }}
                className="default-red-button text-white"
              >
                Approve
              </button>
            </div>
          </div>
        }
      />
    </>
  );
};
