import { useId } from 'react';
import ReactTooltip from 'react-tooltip';

export interface MedalProps {
  src: string;
  totalMedals: number;
  tooltipText: string | JSX.Element;
  isAchieved?: boolean;
}

export const Medal = ({ src, tooltipText, totalMedals, isAchieved }: MedalProps) => {
  const id = useId();

  return (
    <>
      <div className="inline-block text-center mr-3 mt-3 relative w-14 h-14">
        <img
          data-tip={true}
          data-for={id}
          src={src}
          {...(!isAchieved ? { className: 'grayscale' } : {})}
          alt="medal"
          draggable={false}
        />
        <span className="medal-numbers">{totalMedals}</span>
      </div>
      <ReactTooltip id={id} effect="solid" data-html={true}>
        <div className="text-center">{tooltipText}</div>
      </ReactTooltip>
    </>
  );
};
