import { useNavigate, useParams } from 'react-router-dom';
import {imageAsBackground} from '../../../helpers/image-as-background';
import { SectionHeader } from '../../../components/SectionHeader';
import { CountrySelector } from '../../../components/CountrySelector';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fromCDN } from '../../../helpers/Images';

export default function Elections() {
  let params = useParams();
  let navigate = useNavigate();
  const { t } = useTranslation('common');

  const [electionCountry, setElectionCountry] = useState(params.countryId);

  const handleCountryChange = (countryId) => {
    setElectionCountry(countryId.ID);
    navigate('/elections/' + countryId.ID);
  };

  useEffect(() => {}, []);

  let myCountryHeaderColor = {
    backgroundColor: '#3f1717',
  };
  let myunitHeaderColor = {
    backgroundColor: '#173b3d',
  };

  let partyHeaderColor = {
    backgroundColor: '#161523',
  };

  let myCountryBg = {
    backgroundImage: `url(${fromCDN.fetchStaticImage('t_presidentialelections', 'svg')})`,
    backgroundPosition: 'center',
    backgroundSize: '',
    backgroundRepeat: 'no-repeat',
    lineHeight: '1.25rem',
    backgroundColor: '#3f1717',
  };

  let myunityBg = {
    backgroundImage: `url(${fromCDN.fetchStaticImage('t_congresselections', 'svg')})`,
    backgroundPosition: 'center',
    backgroundSize: '',
    backgroundRepeat: 'no-repeat',
    lineHeight: '1.25rem',
    backgroundColor: '#173b3d',
  };

  let myPartyBg = {
    backgroundImage: `url(${fromCDN.fetchStaticImage('t_partyelections', 'svg')})`,
    backgroundPosition: 'center',
    backgroundSize: '',
    backgroundRepeat: 'no-repeat',
    lineHeight: '1.25rem',
    backgroundColor: '#161523',
  };
  return (
    <>
      <div
        className={'min-h-screen max-w-13xl mx-auto bg-fixed'}
        style={imageAsBackground(fromCDN.fetchStaticImage('t_electionsbg2', 'svg'))}
      >
        <div className="max-w-7xl mx-auto py-2 justify-center items-center text-center drop-shadow-[0_8px_6px_rgba(0,0,0,0.4)]">
          <SectionHeader title={t('GAME_COMMUNITY_ELECTIONS')}>
            <CountrySelector countryId={params.countryId} handleChange={handleCountryChange} />
          </SectionHeader>
          <div
            className={
              'h-full sm:grid sm:grid-cols-1  md:grid md:grid-cols-3  p-3  mx-auto gap-4 mb-10 sm:mb-8 gap-x-4'
            }
          >
            <div
              className={'min-h-full h-64 '}
              onClick={() => navigate('/elections/' + electionCountry + '/presidential')}
            >
              <h1
                className=" text-center font-bold text-white mb-3 bg-blue-200 p-3 rounded-lg "
                style={myCountryHeaderColor}
              >
                {t('GAME_COMMUNITY_ELECTIONS_PRESIDENTIAL')}{' '}
              </h1>
              <div
                style={myCountryBg}
                className={
                  'min-h-full transition duration-500 rounded-md shadow-lg  transform  hover:scale-105 ease-in-out relative  min-w-0 break-words bg-gray-50 w-full mb-6 shadow-lg rounded cursor-pointer hover:bg-gray-200 '
                }
              ></div>
            </div>
            <div
              className={'min-h-full h-64 sm:mt-0 mt-20'}
              onClick={() => {
                navigate('/elections/' + electionCountry + '/congress');
              }}
            >
              <h1
                className=" text-center font-bold text-white mb-3 bg-blue-200 p-3 rounded-lg"
                style={myunitHeaderColor}
              >
                {t('GAME_COMMUNITY_ELECTIONS_CONGRESS')}{' '}
              </h1>
              <div
                style={myunityBg}
                className={
                  'min-h-full transition duration-500 rounded-md shadow-lg  transform  hover:scale-105 ease-in-out relative  min-w-0 break-words bg-gray-50 w-full mb-6 shadow-lg rounded cursor-pointer hover:bg-gray-200 '
                }
              ></div>
            </div>
            <div
              className={'min-h-full h-64 sm:mt-0 mt-20'}
              onClick={() => navigate('/elections/' + electionCountry + '/party')}
            >
              <h1
                className=" text-center font-bold text-white mb-3 bg-blue-200 p-3 rounded-lg"
                style={partyHeaderColor}
              >
                {t('GAME_COMMUNITY_ELECTIONS_PARTY')}{' '}
              </h1>
              <div
                style={myPartyBg}
                className={
                  'min-h-full transition duration-500 rounded-md shadow-lg  transform  hover:scale-105 ease-in-out relative  min-w-0 break-words bg-gray-50 w-full mb-6 shadow-lg rounded cursor-pointer hover:bg-gray-200 '
                }
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
