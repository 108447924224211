import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { lawsIdsInUse } from '../../../../../constants/constants';
import { useTranslation } from 'react-i18next';
import { authApi } from '../../../../../api';
import { LawsProposeDetails } from './LawsProposeDetails';

export default function LawPropose() {
  const { t } = useTranslation('common');
  const [lawInputs, setLawInputs] = useState(null);
  const [shouldOpenSelect, setShouldOpenSelect] = useState(false);
  const [submitLawProposeDetails, setSubmitLawProposeDetails] = useState({ id: 0 });
  const [submitPropose, setSubmitPropose] = useState(false);

  const getLaws = () => {
    return lawsIdsInUse.map((law) => {
      return {
        ID: law,
        Name: t('GAME_COUNTRY_A_TYPE_' + law),
      };
    });
  };

  const handleLawChange = (id) => {
    setLawInputs(LawsProposeDetails(id.ID, setSubmitPropose, setSubmitLawProposeDetails));
  };

  const submitLawPropose = () => {
    authApi
      .post('api/country/laws/proposelaw', submitLawProposeDetails)
      .then((response) => {})
      .catch((err) => {});
  };

  useEffect(() => {}, [submitLawProposeDetails, setSubmitLawProposeDetails]);

  useEffect(() => {
    if (submitPropose) {
      submitLawPropose();
    }
  }, [submitPropose]);

  return (
    <>
      <div className={'grid grid-cols-2 gap-2 p-3'}>
        <div className={'p-5 text-center'}>
          <button
            onClick={() =>
              setShouldOpenSelect((prevState) => {
                if (prevState) {
                  setLawInputs(null);
                }
                return !prevState;
              })
            }
            className={
              'mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
            }
          >
            Propose a law
          </button>
          {shouldOpenSelect && (
            <div className={'m-5'}>
              <Select
                className="basic-select cursor-pointer"
                classNamePrefix="select"
                placeholder=""
                isSearchable={false}
                onChange={handleLawChange}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                getOptionLabel={(getLaws) => getLaws.Name}
                getOptionValue={(getLaws) => getLaws.ID}
                options={getLaws()}
              />
            </div>
          )}
        </div>
        <div>{shouldOpenSelect ? lawInputs : ''}</div>
      </div>
    </>
  );
}
