import React, { useEffect, useState } from 'react';

import FireIcon from '../Util/Icons/FireIcon';
import VipStars from './VipStars';
import ProfileMemberAndRank from './ProfileMemberAndRank';
import ProfilePoliticsUnitNewsPaper from './ProfilePoliticsUnitNewsPaper';
import { OthersProfileTab } from './OthersProfile/OthersProfileTab';
import { Loader } from '../../components/Loader';
import { ProfileLocation } from './ProfileLocation';
import { authApi } from '../../api';
import { imageAsBackground } from '../../helpers/image-as-background';

import { Link } from 'react-router-dom';
import { fromCDN } from '../../helpers/Images';
import { useHelper } from '../../hooks/use-helper';
import { MuteOrBanUser } from '../Administration/MuteOrBanUser';
import { AvatarWithDefaultFallback } from '../../components/ImageWithFallback';


export default function OthersProfile({ translation, userId }) {
  const { helpers } = useHelper();

  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(<Loader fullScreen={true} className="mx-auto" />);

  useEffect(() => {
    authApi.get(`/api/user/profiledetails/${userId}`).then((response) => {
      setUser(response.data.USER);
      setIsLoading(null);
    });
  }, [userId]);

  return (
    <div
      className="min-h-screen max-w-13xl mx-auto pt-6"
      style={imageAsBackground(fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg'))}
    >
      <div className="max-w-7xl mx-auto py-2 text-gray-200">
        <div className="max-w-7xl mx-auto pb-10">
          <div className="max-w-7xl mx-auto py-2">
            <article className="flex flex-wrap md:flex-nowrap mx-auto max-w-xxl gap-4">
              {isLoading ? (
                isLoading
              ) : (
                <>
                  <div className="relative bg-gradient-to-br from-indigo-500/20 to-red-600/20 w-full md:w-1/3 h-auto rounded-lg pt-4">
                    <div className="relative w-2/2 flex px-4 pb-2">
                      <div className="relative">
                      <AvatarWithDefaultFallback
                          className={`h-45 w-45 shadow-xl rounded-lg drop-shadow-[0_2px_1px_rgba(0,0,0,0.6)] ${
                            user.Banned === 2 || user.Banned === 1 ? 'filter grayscale opacity-90' : ''
                          }`}
                          avatarId={user.AVATAR}
                        />
                        {/* PERMA BANNED Banner */}
                        {user.Banned == 1 && (
                          <img
                            src={fromCDN.fetchStaticGameImage('/ban/tempbanned', 'png')}
                            alt="Perma Banned"
                            className="absolute top-1/2 left-1/2 w-3/4 h-3/4 object-cover transform -translate-x-1/2 -translate-y-1/2 rotate-[-45deg] z-20 opacity-70"
                            style={{ pointerEvents: 'none' }}
                          />
                        )}
                         {/* TEMP BANNED Banner eğer banlıysa */}
                         {user.Banned == 2 && (
                          <img
                          src={fromCDN.fetchStaticGameImage('/ban/permabanned', 'png')}
                            alt="Perma Banned"
                            className="absolute top-1/2 left-1/2 w-3/4 h-3/4 object-cover transform -translate-x-1/2 -translate-y-1/2 rotate-[-45deg] z-20 opacity-70"
                            style={{ pointerEvents: 'none' }}
                          />
                        )}
                        {/* Level Badge */}
                        <span className="bg-green-600 text-white pt-1 px-3 pb-1 text-base rounded-b-lg absolute bottom-0 left-0 transform translate-y-1/2 shadow-lg">
                          Lvl {user.CURRENT_LEVEL}
                        </span>
                      </div>

                      <div className="w-1/2 m-3 flex flex-col gap-4 items-center">
                        <h3 className="text-center mt- gap-2 items-center text-gray-200 font-bold flex">
                          {user.ctime > parseInt(Date.now() / 1000) - 900 && (
                            <span className="text-gray-100 rounded-lg bg-gradient-to-br from-green-300 to-green-500 absolute -ml-1 mb-8 font-semibold p-1.5 drop-shadow-[0_2px_1px_rgba(0,0,0,0.6)]"></span>
                          )}
                          <span className="p-2 bg-gray-300 bg-opacity-30 rounded-lg shadow-inner">{user.user}</span>
                        </h3>

                        <div
                          className="text-gray-50 py-1 px-5 rounded-lg flex justify-center items-center"
                          style={imageAsBackground(fromCDN.fetchStaticGameImage('/profile/button'))}
                        >
                          <FireIcon className="h-5 w-5 text-yellow-500" />
                          {user.FIRE_POWER.toLocaleString('us', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                        <Link
                          to={`/messages/read/${user.id}`}
                          className="text-gray-100 bg-cyan-900 font-semibold px-5 py-1 shadow-lg rounded-lg light-transition"
                        >
                          Message
                        </Link>

                        <MuteOrBanUser userId={user.id} />
                      </div>
                    </div>

                    <VipStars gold={user.BUY_GOLD} tooltip={translation('GAME_PREMIUM')} />

                    <div className="space-y-3 bg-black/40 py-8">
                      <ProfileLocation
                        title={translation('GAME_LOCATION')}
                        flag={user.COUNTRY}
                        region={helpers.Regions[user.REGION].Name}
                      />
                      <ProfileLocation
                        title={translation('GAME_CITIZENSHIP')}
                        flag={user.CITIZENSHIP}
                        region={helpers.Countries[user.CITIZENSHIP].Name}
                      />
                    </div>

                    <ProfileMemberAndRank user={user} translation={translation} />
                    <ProfilePoliticsUnitNewsPaper user={user} translation={translation} />
                  </div>
                  <div className="w-full md:w-2/3 h-auto px-2 justify-center text-center">
                    <OthersProfileTab user={user} translation={translation} />
                  </div>
                </>
              )}
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}
