import { Divider } from '../../../components/Divider';

import { fromCDN } from '../../../helpers/Images';

export const Taxes = ({ t, countryFlag, country, economyDetails, taxViews }) => {
  return (
    <div>
      <h1 className="font-bold mt-5 mb-2 sm:m-3 text-center sm:text-left text-yellow-600">{t('GAME_TAXES')}</h1>
      <div className="grid sm:grid-cols-3 mx-5 gap-4 text-center">
        <div className="bg-gray-100 rounded py-2 shadow">
          <img className="mx-auto" src={fromCDN.fetchStaticGameImage('/icons/unemployed')} />
          {t('GAME_MINIMUM_SALARY')}
          <div className="mt-2">
            <span className="font-bold">{economyDetails.SALARY} </span>
            {country.Currency}
            {countryFlag}
          </div>
        </div>

        <div className="bg-gray-100 rounded py-2 shadow">
          <img className="mx-auto" src={fromCDN.fetchStaticGameImage('/icons/unemployed')} />
          {t('GAME_CITIZENS_TAX')}
          <div className="mt-2">
            <span className="font-bold mr-1">{economyDetails.MWT1} </span>
            {country.Currency}
            {countryFlag}
          </div>
        </div>

        <div className="bg-gray-100 rounded py-2 shadow">
          <img className="mx-auto" src={fromCDN.fetchStaticGameImage('/icons/unemployed')} />
          {t('GAME_FOREIGNERS_TAX')}
          <div className="mt-2">
            <span className="font-bold">{economyDetails.MWT2} </span>
            <span>
              {country.Currency}
              {countryFlag}
            </span>
          </div>
        </div>
      </div>
      <Divider margin="m-5" />

      <div className="grid grid-cols-4 mx-5 gap-4 bg-gray-100 rounded-lg text-center justify-center pb-5">
        <div></div>
        <div className="font-bold">{t('GAME_WORK_TAX')}</div>
        <div className="font-bold">{t('GAME_IMPORT_TAX')}</div>
        <div className="font-bold">{t('GAME_VAT_TAX')}</div>
        {taxViews}
      </div>
    </div>
  );
};
