import { applyToJobApi, getJobMarketOffersApi } from '../../../api/shared-api';
import { AxiosResponse } from 'axios';
interface UseJobMarketProps {
  countryId: number;
  page: number;
}

interface ApplyToJobProps {
  employer: number;
  orgType: number;
  salary: number;
  onSuccess?: (response: AxiosResponse) => void;
  onError?: (error: any) => void;
}
export const useJobMarket = ({ countryId, page }: UseJobMarketProps) => {
  const getOffers = async () => {
    try {
      const resp = await getJobMarketOffersApi({
        countryId,
        page,
      });
      return resp.data;
    } catch (e) {
      return e;
    }
  };

  const applyToJob = async (props: ApplyToJobProps) => {
    try {
      const resp = await applyToJobApi({
        employer: props.employer,
        orgType: props.orgType,
        salary: props.salary,
      });
      return resp.data;
    } catch (err: any) {
      throw new Error(err.response.data).message;
    }
  };

  return {
    getOffers,
    applyToJob,
  };
};
