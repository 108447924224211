import { ReactNode, ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { joinClasses } from '../../helpers/joinClasses';

type ButtonProps = {
  children: ReactNode;
  color?: keyof typeof COLOR;
  className?: string;
  fullWidth?: boolean;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const COLOR = {
  blue: 'bg-blue-600 hover:bg-blue-700',
  green: 'bg-green-600 hover:bg-green-700',
  red: 'bg-red-600 hover:bg-red-700',
} as const;

export const Button = ({
  children,
  color = 'blue',
  className = '',
  fullWidth = false,
  ...buttonProps
}: ButtonProps) => {
  const defaultClasses = `${COLOR[color]} py-2 px-3 rounded-md font-bold text-gray-100 transition transform ease-in-out enabled:hover:scale-105 duration-300 disabled:!bg-neutral-700 disabled:hover:!bg-neutral-700 disabled:cursor-not-allowed`;

  return (
    <button className={joinClasses(defaultClasses, className, fullWidth ? 'w-full' : '')} {...buttonProps}>
      {children}
    </button>
  );
};
