import { useNavigate, useParams } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../../components/SectionHeader';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { avatarClass, BASE_URL, BEARER, defaultTransition, themeButtonClass } from '../../../constants/constants';
import { countryFlags, rankImages } from '../../../helpers/Helpers';
import { Divider } from '../../../components/Divider';
import { animate } from '../../../util/animate';
import { BattleCard } from '../../Battles/BattleCard';
import DoesNotExist from '../../Util/DoesNotExist';
import ReactTooltip from 'react-tooltip';
import StarIcon from '../../Util/Icons/star/StarIcon';
import ChartIcon from '../../Util/Icons/ChartIcon';
import UnitMembers from './UnitMembers';
import { ArrowCircleLeftIcon, CogIcon } from '@heroicons/react/solid';
import { UnitEdit } from './UnitEdit';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { imageAsBackground } from '../../../helpers/image-as-background';
import { fromCDN } from '../../../helpers/Images';
import { useHelper } from '../../../hooks/use-helper';
import { useUser } from '../../../hooks/use-user';
import { NoMilitaryUnit } from './NoMilitaryUnit';

export default function MilitaryUnit() {
  let params = useParams();
  let navigate = useNavigate();
  const { user, setUser } = useUser();
  const { t, i18n } = useTranslation('common');
  const [myUnit, setMyUnit] = useState();
  const { helpers } = useHelper();
  const [battles, setBattles] = useState();
  const [unitStars, setUnitStars] = useState();
  const [unitDoesNotExist, setUnitDoesNotExist] = useState();
  const [members, setMembers] = useState();
  const [editUnit, setEditUnit] = useState();

  const [shouldUpdateMembers, setShouldUpdateMembers] = useState();
  const [shouldUpdateEdit, setShouldUpdateEdit] = useState(false);

  async function getMilitaryUnitDetails() {
    await axios
      .get(BASE_URL + '/api/military-unit/' + params.id, {
        headers: { Authorization: 'Bearer ' + BEARER },
      })
      .then((response) => {
        if (response.data.length === 0) {
          setUnitDoesNotExist(<NoMilitaryUnit />);
          return;
        }
        let stars = [];
        for (let x = 1; x <= response.data.LEVEL; x++) {
          stars.push(<StarIcon />);
        }
        setUnitStars(stars);
        setMyUnit(response.data);
        getBattles();
        animate({ targetElementId: 'militaryunitdetails', animation: 'fadeIn' });
      })
      .catch((error) => {});
  }

  function resolveCoverAvatar(MU_COVER) {
    let muCover = '';

    if (MU_COVER === 0 || MU_COVER === '0') {
      muCover = `url(${fromCDN.fetchStaticGameImage('/icons/military3', 'jpg')})`;
    } else {
      muCover = `url(${fromCDN.fetchMilitaryUnitCover(MU_COVER)})`;
    }
    return muCover;
  }

  async function getBattles() {
    await axios
      .get(BASE_URL + '/api/battles', {
        headers: { Authorization: 'Bearer ' + BEARER },
      })
      .then((response) => {
        setBattles(response.data);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getMilitaryUnitDetails();
  }, [params]);

  async function handleMembersModal() {
    if (members) {
      return false;
    }
    await axios
      .get(BASE_URL + '/api/military-unit/members/' + params.id, {
        headers: { Authorization: 'Bearer ' + BEARER },
      })
      .then((response) => {
        setMembers(response.data);
        animate({ targetElementId: 'membersdiv', animation: 'fadeIn' });
      })
      .catch((error) => {});
  }

  useEffect(() => {
    if (shouldUpdateMembers) {
      setMembers(shouldUpdateMembers['MEMBERS']);
      setMyUnit(shouldUpdateMembers['UNIT']);
      animate({ targetElementId: 'membersdiv', animation: 'fadeIn' });
    }
  }, [setShouldUpdateMembers, shouldUpdateMembers]);

  function handleEditUnit() {
    setEditUnit(
      <UnitEdit
        myUnit={myUnit}
        setMyUnit={setMyUnit}
        setEditUnit={setEditUnit}
        resolveCoverAvatar={resolveCoverAvatar}
        setShouldUpdateEdit={setShouldUpdateEdit}
      />,
    );
  }

  useEffect(() => {
    if (shouldUpdateEdit) {
      setEditUnit(
        <UnitEdit
          myUnit={myUnit}
          setMyUnit={setMyUnit}
          setEditUnit={setEditUnit}
          resolveCoverAvatar={resolveCoverAvatar}
          setShouldUpdateEdit={setShouldUpdateEdit}
        />,
      );
      setShouldUpdateEdit(false);
    }
  }, [shouldUpdateEdit, setShouldUpdateEdit]);

  async function joinUnit() {
    await axios
      .post(
        BASE_URL + '/api/military-unit/join-unit',
        {
          unitId: myUnit.ID,
        },
        {
          headers: { Authorization: 'Bearer ' + BEARER },
        },
      )
      .then((response) => {
        setUser(response.data['USER']);
        setMyUnit(response.data['UNIT']);
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });
  }

  async function resignUnit() {
    await axios
      .post(
        BASE_URL + '/api/military-unit/resign-unit',
        {
          unitId: myUnit.ID,
        },
        {
          headers: { Authorization: 'Bearer ' + BEARER },
        },
      )
      .then((response) => {
        setUser(response.data['USER']);
        setMyUnit(response.data['UNIT']);
      })
      .catch((error) => {
        ApplicationAlert(t(error.response.data)).error();
      });
  }

  const sectionTitle = t('GAME_MILITARY_UNIT') + (myUnit ? ` | ${myUnit.NAME}` : '');

  return (
    <>
      <div
        className={'min-h-screen max-w-13xl mx-auto text-gray-200 bg-fixed'}
        style={imageAsBackground(fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg'))}
      >
        <div className="max-w-7xl mx-auto py-2  ">
          <SectionHeader title={sectionTitle} />
          <div
            id={'militaryunitdetails'}
            className={'justify-center text-center m-3 animate__animated animate__fadeIn'}
          >
            {editUnit}
            {myUnit && !editUnit ? (
              <>
                <div className={''}>
                  <div
                    className={'h-32 w-full'}
                    style={{
                      backgroundImage: resolveCoverAvatar(myUnit.MU_COVER),
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className={'flex'}>
                      <img
                        className={'absolute w-20 h-20 ml-10 mt-6 ' + avatarClass}
                        src={fromCDN.fetchMilitaryUnitAvatar(myUnit.AVATAR)}
                      />
                    </div>
                    <div className={'text-white text-right m-5'}>
                      <span
                        data-tip
                        data-for={'rankingdesc'}
                        onClick={() => navigate('/rankings/' + myUnit.COUNTRY + '/5/1')}
                      >
                        <ChartIcon
                          classNames={
                            'float-right h-10 w-10 m-1 cursor-pointer text-indigo-100 hover:text-indigo-300 ' +
                            defaultTransition
                          }
                        />
                      </span>
                      <ReactTooltip id={'rankingdesc'} effect="solid" data-html={true}>
                        {t('GAME_COMMUNITY_RANKINGS')}
                      </ReactTooltip>
                      <h1 className={'font-bold text-lg'}>{myUnit.NAME}</h1>
                      <h1 className={'font-bold text-lg'}>{myUnit.INFO}</h1>
                      <h1
                        className={'font-bold text-lg inline-flex cursor-pointer'}
                        onClick={() => navigate('/country/society/' + myUnit.COUNTRY)}
                      >
                        <span className={'mr-1'}>{t('GAME_LOCATION')}:</span> {countryFlags(myUnit.COUNTRY, 'mx-auto')}
                        <span className={'ml-1'}>{helpers.Countries[myUnit.COUNTRY].Name}</span>
                      </h1>
                    </div>
                  </div>
                </div>
                <div className={'m-5'}>
                  <div className={'grid grid-cols-1 sm:grid-cols-2 gap-8'}>
                    <div>
                      <header className=" bg-gradient-to-br from-edom-dark-red to-red-700 mb-6 text-white shadow-lg rounded">
                        <div className="max-w-6xl mx-auto py-2 px-4 sm:px-6 lg:px-8 ">
                          <h1 className=" font-bold ">
                            <span className={''}>{t('GAME_MILITARY_UNIT_FIGHT_ORDER')}</span>
                          </h1>
                        </div>
                      </header>

                      {myUnit.BATTLE && battles && battles[myUnit.BATTLE] ? (
                        <BattleCard item={battles[myUnit.BATTLE]} />
                      ) : (
                        <DoesNotExist t={t} title={'GAME_MILITARY_UNIT_NO_ORDER'} />
                      )}
                    </div>

                    {members ? (
                      <>
                        <div id={'membersdiv'} className={'grid grid-cols-3 sm:grid-cols-5 gap-4 m-5 gap-8 '}>
                          <div
                            onClick={() => setMembers(null)}
                            className={'absolute -mt-7 cursor-pointer ' + defaultTransition}
                          >
                            <ArrowCircleLeftIcon className={'h-8 w-8 text-blue-300'} />
                          </div>
                          {members.map((item, key) => {
                            return (
                              <UnitMembers
                                item={item}
                                unit={myUnit}
                                shouldUpdateMembers={shouldUpdateMembers}
                                setShouldUpdateMembers={setShouldUpdateMembers}
                              />
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <div className={'border-1 border-gray-100 shadow justify-center'}>
                        <div className={'font-extrabold m-1'}>
                          <div className={'absolute ml-3 mt-1'}>
                            <div className={'inline-flex'}>
                              {user.id === myUnit.OWNER ? (
                                <CogIcon
                                  data-tip
                                  data-for={'settingsdescr'}
                                  className={'h-5 w-5 mx-auto cursor-pointer ' + defaultTransition}
                                  onClick={() => {
                                    handleEditUnit();
                                  }}
                                />
                              ) : (
                                ''
                              )}
                              {user.id === myUnit.CAPTAIN.id ||
                              user.id === myUnit.CAPTAIN2.id ||
                              user.id === myUnit.CAPTAIN3.id ||
                              user.id === myUnit.OWNER ? (
                                <img
                                  data-tip
                                  data-for={'setbattleorder'}
                                  src={fromCDN.fetchStaticGameImage('/menu/battles')}
                                  onClick={() =>
                                    navigate('/battles', { state: { unitCaptain: true, unitId: myUnit.ID } })
                                  }
                                  className={'h-8 w-8 cursor-pointer -mt-2.5 ml-1' + defaultTransition}
                                />
                              ) : (
                                ''
                              )}
                            </div>

                            <ReactTooltip id={'setbattleorder'} effect="solid" data-html={true}>
                              {t('GAME_UNIT_SET_ORDER')}
                            </ReactTooltip>
                            <ReactTooltip id={'settingsdescr'} effect="solid" data-html={true}>
                              {t('GAME_SETTINGS')}
                            </ReactTooltip>
                          </div>
                          {t('GAME_MILITARY_UNIT_COMMANDER')}
                        </div>

                        <div className={'p-3 cursor-pointer inline-flex ' + defaultTransition}>
                          <img
                            className={'h-14 w-14 mx-auto ' + avatarClass}
                            src={fromCDN.fetchUserAvatar(myUnit.U_AVATAR)}
                            alt={'avatar'}
                            onClick={() => {
                              navigate('/profile/' + myUnit.U_ID);
                            }}
                          />

                          <div className={'font-bold text-gray-200 ml-1 '}>
                            {myUnit.U_NAME.substring(0, 13)}

                            <div className={'text-left justify-start'}>
                              <p className={'inline-flex mt-2 '}>
                                <span data-tip data-for={'commanderdesc'} className={' -mt-1'}>
                                  {' '}
                                  {rankImages(user['MILITARY_RANK'], 'w-8 h-8 mx-auto')}
                                </span>
                              </p>
                              <ReactTooltip id={'commanderdesc'} effect="solid" data-html={true}>
                                {t('GAME_MILITARY_RANK_' + user['MILITARY_RANK'])}
                              </ReactTooltip>
                            </div>
                          </div>
                        </div>

                        <>
                          <Divider margin={'m-2'} />

                          <p className={'font-extrabold text-lg text-gray-200'}>{t('GAME_MILITARY_UNIT_CAPTAINS')}</p>
                          <div className={'grid grid-cols-1 sm:grid-cols-3'}>
                            {myUnit.CAPTAIN.id && (
                              <div>
                                <div
                                  className={'p-3 cursor-pointer inline-flex ' + defaultTransition}
                                  onClick={() => {
                                    navigate('/profile/' + myUnit.CAPTAIN.id);
                                  }}
                                >
                                  <img
                                    className={'h-14 w-14 mx-auto ' + avatarClass}
                                    src={fromCDN.fetchUserAvatar(myUnit.CAPTAIN.AVATAR)}
                                    alt={'avatar'}
                                  />
                                  <div className={'font-bold text-gray-200 ml-1 '}>
                                    {myUnit.CAPTAIN.user.substring(0, 13)}

                                    <div className={'text-left justify-start'}>
                                      <p className={'inline-flex mt-2 '}>
                                        <span data-tip data-for={'captaindesc'} className={' -mt-1'}>
                                          {' '}
                                          {rankImages(myUnit.CAPTAIN['MILITARY_RANK'], 'w-8 h-8 mx-auto')}
                                        </span>
                                      </p>
                                      <ReactTooltip id={'captaindesc'} effect="solid" data-html={true}>
                                        {t('GAME_MILITARY_RANK_' + myUnit.CAPTAIN['MILITARY_RANK'])}
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {myUnit.CAPTAIN2.id ? (
                              <div className={' justify-center text-center'}>
                                <div
                                  className={'p-3 cursor-pointer inline-flex ' + defaultTransition}
                                  onClick={() => {
                                    navigate('/profile/' + myUnit.CAPTAIN2.id);
                                  }}
                                >
                                  <img
                                    className={'h-14 w-14 mx-auto ' + avatarClass}
                                    src={fromCDN.fetchUserAvatar(myUnit.CAPTAIN2.AVATAR)}
                                    alt={'avatar'}
                                  />
                                  <div className={'font-bold text-gray-300 ml-1 '}>
                                    {myUnit.CAPTAIN2.user.substring(0, 13)}

                                    <div className={'text-left justify-start'}>
                                      <p className={'inline-flex mt-2 '}>
                                        <span data-tip data-for={'captaindesc2'} className={' -mt-1'}>
                                          {' '}
                                          {rankImages(myUnit.CAPTAIN2['MILITARY_RANK'], 'w-8 h-8 mx-auto')}
                                        </span>
                                      </p>
                                      <ReactTooltip id={'captaindesc2'} effect="solid" data-html={true}>
                                        {t('GAME_MILITARY_RANK_' + myUnit.CAPTAIN2['MILITARY_RANK'])}
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}

                            {myUnit.CAPTAIN3.id ? (
                              <div className={' justify-center text-center'}>
                                <div
                                  className={'p-3 cursor-pointer inline-flex ' + defaultTransition}
                                  onClick={() => {
                                    navigate('/profile/' + myUnit.CAPTAIN3.id);
                                  }}
                                >
                                  <img
                                    className={'h-14 w-14 mx-auto ' + avatarClass}
                                    src={fromCDN.fetchUserAvatar(myUnit.CAPTAIN3.AVATAR)}
                                    alt={'avatar'}
                                  />
                                  <div className={'font-bold text-gray-300 ml-1 '}>
                                    {myUnit.CAPTAIN3.user.substring(0, 13)}

                                    <div className={'text-left justify-start'}>
                                      <p className={'inline-flex mt-2 '}>
                                        <span data-tip data-for={'captaindesc3'} className={' -mt-1'}>
                                          {rankImages(myUnit.CAPTAIN3['MILITARY_RANK'], 'w-8 h-8 mx-auto')}
                                        </span>
                                      </p>
                                      <ReactTooltip id={'captaindesc3'} effect="solid" data-html={true}>
                                        {t('GAME_MILITARY_RANK_' + myUnit.CAPTAIN3['MILITARY_RANK'])}
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </>
                        <div className={'text-left m-5'}>
                          <p className={'font-bold '}>
                            {t('GAME_MILITARY_MEMBERS_UNIT')} : {myUnit.MEMBERS}
                          </p>
                          <div className={'text-center'}>
                            <Divider margin={'m-2'} />
                            {myUnit.MEMBERS > 1 ? (
                              <button
                                onClick={() => handleMembersModal(myUnit.ID)}
                                className={themeButtonClass + ' bg-green-300 text-gray-800'}
                              >
                                {t('GAME_MILITARY_UNIT_SHOW_MEMBERS')}
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        <div className={'text-right'}>
                          {user.UNIT === myUnit.ID ? (
                            <button
                              onClick={() => resignUnit()}
                              className={themeButtonClass + ' bg-red-500 ' + defaultTransition}
                            >
                              {t('GAME_UNIT_LEAVE_UNIT')}
                            </button>
                          ) : (
                            ''
                          )}
                          {user.UNIT === 0 ? (
                            <button
                              onClick={() => joinUnit()}
                              className={themeButtonClass + ' bg-green-500 ' + defaultTransition}
                            >
                              {t('GAME_UNIT_JOIN_UNIT')}
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              unitDoesNotExist
            )}
          </div>
        </div>
      </div>
    </>
  );
}
