import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import axios from '../../../api';
import { Notifications } from '../../../util/Notifications';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { imageAsBackground } from '../../../helpers/image-as-background';

import { fromCDN } from '../../../helpers/Images';
import { Loader } from '../../../components/Loader';

var passwordValidator = require('password-validator');

var passwordSchema = new passwordValidator();

passwordSchema
  .is()
  .min(8) // Minimum length 8
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits(1) // Must have at least 2 digits
  .has()
  .symbols();

export const ResetPassword: FC = () => {
  let params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [message, setMessage] = useState<ReactNode | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [rePassword, setRePassword] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (password !== rePassword) {
      setMessage(
        <div role="alert">
          <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">Error</div>
          <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
            <p>Password does not match</p>
          </div>
        </div>,
      );
    } else {
      setMessage('');
    }
  }, [rePassword]);

  function callApi() {
    if (password !== rePassword || passwordSchema.validate(password, { details: true }).length) {
      return;
    }

    axios
      .post('/api/reset-password', { confirmation_code: params.confirmationCode, password: password })
      .then((response) => {
        setIsRedirect(true);
        setIsLoading(false);
        ApplicationAlert(response.data).success();
        return;
      })
      .catch((error) => {
        setIsLoading(false);
        ApplicationAlert(error.response.data).error();
        return;
      });
  }

  useEffect(() => {
    if (isRedirect) {
      navigate('/');
    }
  }, [isRedirect, isLoading]);

  return (
    <div
      className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      style={imageAsBackground(fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg'))}
    >
      <div
        className="max-w-md w-full space-y-8  rounded-lg p-5"
        style={imageAsBackground(fromCDN.fetchStaticImgImage('/table_item'))}
      >
        <div>
          <img className="mx-auto h-24 w-auto" src={fromCDN.fetchStaticImgImage('/logo-l')} alt="Workflow" />
          <h2 className="mt-6 text-center   font-extrabold text-white">Confirm your password</h2>
        </div>
        {isLoading ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : (
          <div className="mt-8 space-y-6">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded shadow-sm -space-y-px">
              <div>
                <input
                  type="password"
                  required={true}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
              <div>
                <input
                  type="password"
                  required={true}
                  onChange={(e) => {
                    setRePassword(e.target.value);
                  }}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Retype password"
                />
              </div>
            </div>
            <div className="text-right">
              <Link to="/" className="font-medium text-gray-50 hover:text-gray-200">
                Back to login
              </Link>
            </div>

            <button
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={(e) => {
                callApi();
              }}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
              Save
            </button>
            {message ? message : ''}
            {passwordSchema.validate(password, { details: true }).length ? (
              <Notifications requirements={passwordSchema.validate(password, { details: true })} />
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    </div>
  );
};
