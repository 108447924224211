import React, { useEffect, useState, useId, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import ApplicationAlert from './ApplicationAlert';
import { authApi } from '../api';

const MAX_FILE_SIZE = 524288;
const ACCEPTABLE_IMAGE_TYPE = ['image/jpeg', 'image/jpg', 'image/png'];

interface FileUploadProfileProps {
  url: string;
  formParams?: any;
  fileKey?: string;
  setter: (arg: any) => void;
}
export const FileUpload = ({ url, formParams, fileKey = 'file', setter }: FileUploadProfileProps) => {
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<Element | null>(null);
  const [avatar, setAvatar] = useState('');
  const id = useId();

  const { t } = useTranslation('common');

  const handleUploadFiles = useCallback(
    (file: File[]) => {
      if (file.length === 0) {
        return; // No file to process, exit early
      }

      const image = file[0];

      if (!ACCEPTABLE_IMAGE_TYPE.includes(image.type)) {
        ApplicationAlert('Something went wrong. Only .jpg files are allowed').error();
        setUploadProgress(null);
        setAvatar('');
        return;
      }

      if (image.size > MAX_FILE_SIZE) {
        ApplicationAlert('File too big. Maximum file size is 256kb').error();
        return;
      }

      let formData = new FormData();

      formData.append(fileKey, image);
      if (formParams) {
        formData.append(formParams['name'], formParams['VALUE']);
      }

      authApi
        .post(url, formData, {
          onUploadProgress: (e) => {
            console.log(e);
          },
        })
        .then((response) => {
          setUploadProgress(null);
          ApplicationAlert('Uploaded!').success();
          setter(response.data);
        })
        .catch((error) => {
          ApplicationAlert(`Something went wrong - ${error.response.data as string}`).error();
          setUploadProgress(null);
        });

      setAvatar('');
    },
    [fileKey, formParams, setter, url],
  );

  useEffect(() => {
    if (fileToUpload) {
      handleUploadFiles([fileToUpload]);
    }
  }, [fileToUpload, handleUploadFiles]);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFileToUpload(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      {uploadProgress ? (
        uploadProgress
      ) : (
        <section className="container">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} value={avatar} className="focus:outline-none" accept="image/jpeg" />
            <div
              data-tip={true}
              data-for={id}
              className="flex mb-2 mr-3 justify-center text-gray-200/40 cursor-pointer hover:scale-105 hover:text-gray-200/60"
            >
              <button type="button" className="focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <ReactTooltip id={id} effect="solid" data-html={true}>
            {t('GAME_ACCEPTED_IMAGE_TYPES')}
          </ReactTooltip>
        </section>
      )}
    </>
  );
};
