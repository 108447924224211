import {
  AttackerRibbon,
  AttackerTopPlacementChildMedal,
  DefenderExtendTopList,
  DefenderTopPlacementChild1,
  DefenderTopPlacementParent,
} from './Styles/BattlefieldStyles';
import { avatarClass, lightTransition } from '../../constants/constants';
import { numberFormat } from '../../helpers/Helpers';
import { BattlefieldTopList } from './BattlefieldTopList';
import React, { Dispatch, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fromCDN } from '../../helpers/Images';
import { BattlefieldTypesApiProps } from '../../shared-types/battlefield-types';
interface BattlefieldDefenderTopThreeProps {
  battleDetails: BattlefieldTypesApiProps['BATTLE'];
  setShowTopList: Dispatch<React.SetStateAction<boolean | JSX.Element>>;
  handleAnimation: (arg1: string, arg2: string) => void;
}
export const BattlefieldDefenderTopThree: FC<BattlefieldDefenderTopThreeProps> = ({
  battleDetails,
  setShowTopList = () => {},
  handleAnimation,
}: BattlefieldDefenderTopThreeProps) => {
  let navigate = useNavigate();
  useEffect(() => {}, [battleDetails]);
  return (
    <>
      <div
        className="absolute w-32 h-16 pb-3 pt-2 pl-1 top-24 right-0  justify-center text-center"
        style={DefenderTopPlacementParent()}
      >
        <span
          className={'cursor-pointer ' + lightTransition}
          style={DefenderTopPlacementChild1()}
          onClick={() => {
            navigate(`/profile/${battleDetails.topDefenders[0]['U_ID'] ?? 0}`);
          }}
        >
          {battleDetails.topDefenders.length ? (
            <>
              <img
                className={'h-8 w-8 mx-auto mt-8  ' + avatarClass}
                src={fromCDN.fetchUserAvatar(battleDetails.topDefenders[0]['U_AVATAR'])}
              />
              <span style={AttackerRibbon()}></span>
              <span className="font-bold text-yellow-600">{battleDetails.topDefenders[0]['U_NAME']}</span>
              <p className=" font-bold text-gray-100 -mt-2">
                {numberFormat(Number(battleDetails.topDefenders[0]['T_DMG']))}
              </p>
              <b className="block " style={AttackerTopPlacementChildMedal()}></b>
            </>
          ) : (
            ''
          )}
        </span>
        <div
          id="defendertop3image"
          className="cursor-pointer"
          style={DefenderExtendTopList()}
          onMouseEnter={(e) => {
            handleAnimation(e.currentTarget.id, 'swing');
          }}
          onClick={() => {
            setShowTopList((prevState) => {
              if (!prevState) {
                return <BattlefieldTopList topList={battleDetails.topDefenders} />;
              } else {
                return false;
              }
            });
          }}
        ></div>
      </div>
    </>
  );
};
