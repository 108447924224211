import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppPageTemplate } from '../../../components/AppPageTemplate';
import { RankingsMenu } from './RankingsMenu';
import { authApi } from '../../../api';
import { RankingsProps } from '../../../shared-types/types';
import { AxiosResponse } from 'axios';
import ApplicationAlert from '../../../components/ApplicationAlert';
import { useTranslation } from 'react-i18next';
import { TopPlayersByExperience } from './TopPlayersByExperience';
import { TopCountriesByActivePopulation } from './TopCountriesByActivePopulation';
import { RANKINGS_TYPES } from './constants/constants';
import { TopPlayersByMilitaryRank } from './TopPlayersByMilitaryRank';
import {imageAsBackground} from '../../../helpers/image-as-background';
import { fromCDN } from '../../../helpers/Images';

export const RankingsMain: FC = () => {
  const params = useParams();
  const [details, setDetails] = useState<RankingsProps[] | null>(null);
  const [rankingId, setRankingId] = useState<number>(Number(params.rankingType));
  const { t } = useTranslation('common');

  const handleSelectedRankings = (rankingId: number) => {
    setRankingId(rankingId);
    setDetails(null);
    authApi
      .get(`api/rankings/${rankingId ?? 0}/${params.frompage ?? 0}/${params.topage ?? 0}/${params.countryid ?? 0}`)
      .then((r: AxiosResponse<RankingsProps[]>) => {
        setDetails(r.data);
      })
      .catch((e) => {
        ApplicationAlert(t(e.response.data)).error();
      });
  };

  useEffect(() => {
    handleSelectedRankings(Number(params.rankingType));
  }, [params]);

  return (
    <div
      className="min-h-screen max-w-13xl mx-auto px-3 bg-fixed"
      style={imageAsBackground(fromCDN.fetchStaticImage('t_profileclearbg', 'svg'))}
    >
      <AppPageTemplate
        section={{ title: t('GAME_RANKINGS') }}
        children={
          <>
            <RankingsMenu handleSelected={handleSelectedRankings} />
            {details && rankingId === RANKINGS_TYPES.TOP_PLAYERS_BY_EXPERIENCE && TopPlayersByExperience(details)}
            {details &&
              rankingId === RANKINGS_TYPES.TOP_COUNTRIES_BY_ACTIVE_PLAYERS &&
              TopCountriesByActivePopulation({
                details: details,
              })}
            {details &&
              rankingId === RANKINGS_TYPES.TOP_PLAYERS_BY_MILITARY_RANKING &&
              TopPlayersByMilitaryRank({
                details: details,
              })}
          </>
        }
      />
    </div>
  );
};
