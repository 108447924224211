import { Suspense } from 'react';

import VipStars from './VipStars';
import { ProfileLocation } from './ProfileLocation';
import ProfileMemberAndRank from './ProfileMemberAndRank';
import ProfilePoliticsUnitNewsPaper from './ProfilePoliticsUnitNewsPaper';
import { ProfileTabs } from './OthersProfile/ProfileTabs';
import { imageAsBackground } from '../../helpers/image-as-background';

import FireIcon from '../Util/Icons/FireIcon';
import { fromCDN } from '../../helpers/Images';
import { useHelper } from '../../hooks/use-helper';
import { Loader } from '../../components/Loader';
import { AvatarWithDefaultFallback } from '../../components/ImageWithFallback';
import { UserProps } from '../../shared-types/user-type';
import { TFunction } from 'i18next';

interface MyProfileProps {
  user: UserProps;
  setUser: (arg: UserProps) => void;
  translation: TFunction;
}
export const MyProfile = ({ user, setUser, translation }: MyProfileProps) => {
  const { helpers } = useHelper();

  return (
    <>
      <div
        className="min-h-screen max-w-13xl mx-auto pt-7"
        style={imageAsBackground(fromCDN.fetchStaticGameImage('/v2/backgrounds/homebg', 'svg'))}
      >
        <div className="min-h-screen max-w-13xl mx-auto text-gray-200">
          <div className="max-w-7xl mx-auto pt-3 pb-10">
            <article className="flex flex-wrap md:flex-nowrap mx-auto max-w-xxl gap-4">
              <div className="bg-gradient-to-br from-indigo-500/20 to-red-600/20 w-full md:w-1/3 h-auto rounded-lg pt-4">
                <div className="w-auto px-4">
                  <div className="w-full flex flex-col items-center px-4 gap-6">
                    {/* Avatar Container */}
                    <div className="flex flex-col items-center">
                      <div className="relative">
                        <AvatarWithDefaultFallback
                          className="h-50 w-50 shadow-xl rounded-lg drop-shadow-[0_2px_1px_rgba(0,0,0,0.6)]"
                          avatarId={user.AVATAR}
                        />

                        {/* Level Badge */}
                        <span className="bg-green-600 text-white pt-1 px-3 pb-1 rounded-b-lg absolute bottom-0 left-0 transform translate-y-1/2 shadow-lg">
                          Lvl {user.CURRENT_LEVEL}
                        </span>
                      </div>

                      {/* Username Section */}
                      <div className="flex items-center gap-1 mt-2">
                        {user.ctime > parseInt(String(Date.now() / 1000)) - 900 && (
                          <span className="w-3 h-3 bg-green-500 rounded-full"></span>
                        )}
                        <span className="text-gray-200 text-lg font-bold p-2 bg-gray-300 bg-opacity-30 rounded-lg shadow-inner">
                          {user.user}
                        </span>
                      </div>
                      <div
                        className="text-gray-50 font-medium py-2 px-6 mt-2 rounded-lg flex items-center bg-opacity-70 shadow-md"
                        style={imageAsBackground(fromCDN.fetchStaticGameImage('/profile/button'))}
                      >
                        <FireIcon className="h-8 w-8 mr-3 text-yellow-500" />
                        <span className="text-lg">
                          {user.FIRE_POWER.toLocaleString('us', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <VipStars gold={user.BUY_GOLD} tooltip={translation('GAME_PREMIUM')} />
                <div className="space-y-3 bg-black/40 py-8">
                  <ProfileLocation
                    title={translation('GAME_LOCATION')}
                    flag={user.COUNTRY}
                    region={helpers.Regions[user.REGION].Name}
                  />
                  <ProfileLocation
                    title={translation('GAME_CITIZENSHIP')}
                    flag={user.CITIZENSHIP}
                    region={helpers.Countries[user.CITIZENSHIP].Name}
                  />
                </div>

                <ProfileMemberAndRank user={user} translation={translation} />
                <Suspense fallback={<Loader />}>
                  <ProfilePoliticsUnitNewsPaper user={user} translation={translation} />
                </Suspense>
              </div>
              <div className="w-full md:w-2/3 h-auto px-2 justify-center text-center">
                <ProfileTabs user={user} translation={translation} />
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};
