import { useCallback, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Picker } from 'emoji-mart';

import { useKeyDown } from '../hooks/use-key-down';

import { SmileyEmoji } from '../containers/Util/Icons/SmileyEmoji';
import { useClickOutside } from '../hooks/use-click-outside';
interface EmojiPIckerProps {
  onEmojiSelect: (arg: any) => undefined;
}
export const EmojiPicker = ({ onEmojiSelect }: EmojiPIckerProps) => {
  const [showPicker, setShowPicker] = useState(false);

  const [data, setData] = useState(null);
  const picker = useRef();
  const btnRef = useRef();

  const dismissPicker = useCallback(() => {
    setShowPicker(false);
  }, []);

  useClickOutside([btnRef, picker], dismissPicker);
  useKeyDown('Escape', dismissPicker);

  useEffect(() => {
    fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data')
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      });
  }, []);

  useEffect(() => {
    new Picker({
      onEmojiSelect,
      data,
      ref: picker,
    });
  }, [showPicker, data]);

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setShowPicker((prevState) => !prevState);
        }}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        ref={btnRef}
        data-testid="emojipicker"
        className="absolute z-10 right-4 top-1/2 -translate-y-1/2 transition ease-in-out duration-500 outline-none focus:ring-2 focus:ring-yellow-500 rounded-full"
      >
        <SmileyEmoji className="h-6 w-6 text-yellow-400 hover:text-yellow-500 select-none" />
      </button>
      {showPicker && (
        <div className="absolute -left-20 right-0 sm:right-3 top-1/2 translate-y-3 z-50">
          <div
            data-testid="pickeropen"
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */
            ref={picker}
            style={{
              width: '100%',
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              /* @ts-ignore */
              'max-width': '200px',
            }}
          />
        </div>
      )}
    </>
  );
};
